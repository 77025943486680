import { useEffect, useRef } from 'react';
import io, { Socket } from 'socket.io-client';
import { EnvironmentVariable } from '@/global/constant/Environment';
import { useAppSelector } from '@/app/hooks';
import { getToken } from '@/features/video/store/authSlice';

const useMediaSocket = (ws: string) => {
  const socket = useRef<Socket>();
  const token = useAppSelector(getToken);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const disconnect = () => {
    if (socket.current) {
      socket.current.emit('forceDisconnect', { event: 'forceDisconnect' });
    }
  };

  const createSocket = () => {
    if (token && ws) {
      const socketIo = io(`${EnvironmentVariable.baseUrl}/${ws}`, {
        auth: {
          Authorization: `${token || ''}`
        },
        extraHeaders: {
          Authorization: `${token || ''}`
        },
        withCredentials: true,
        transports: ['websocket', 'polling']
      });
      socket.current = socketIo;
      return socketIo;
    }
  };

  return { createSocket, socket: socket.current, disconnect };
};

export default useMediaSocket;
