/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import styled from '@emotion/styled';
import { saveActUndoRedo, selectVideo, setVersionDraftSelected, update } from '../../store/videoSlice';
import { useEffect, useMemo, useRef } from 'react';
import PlaySeek from '@/assets/images/playseek.svg';
import { plusSecond } from '@/utils/moveSecond';

import TrackControlVideoControl from './drag-components/Container';
import { RightDown, LeftDown } from '@/components/SVG';
import ClockwisePlat from './ruler/ClockwisePlat';
import { Transcribe } from '../../models/VideoFrame';
import Ruler from './ruler/Ruler';
import useDraft from '@/components/hooks/useDraft';

export const TIME_PX = 30 / 1300; // 30s 1300px that means minimize time and resolution

const VideoControl: React.FC = () => {
  const { convertDraft } = useDraft();

  const {
    addingPlayableItems,
    addingImages,
    addingTexts,
    addingCards,
    totalPlayed,
    manualPause,
    subtitlesInit,
    waitingForLoadVideo,
    listVersions,
    versionDraftSelected,
    isFinishedLoading
  } = useAppSelector(selectVideo);

  const totalPlayIntervalRef = useRef<NodeJS.Timeout>();

  const controlTrackRef = useRef<HTMLDivElement>(null);

  const maxDuration = useMemo(() => {
    const maxPlayable = addingPlayableItems
      .filter((x) => !x.isHide)
      .reduce((out, item) => {
        return item.end && item.end > out ? item.end : out;
      }, 0);
    const maxImage = addingImages.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);
    const maxText = addingTexts.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);
    const maxCard = addingCards.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);
    return Math.max(maxPlayable, maxImage, maxText, maxCard);
  }, [addingImages, addingPlayableItems, addingTexts, addingCards]);

  const totalDuration = useMemo(() => {
    // IT HAVE A CASE ADD NEW VIDEO HAVE THE DURATION BIGGER THAN CURRENT MAX DURATION
    return maxDuration;
  }, [maxDuration]);

  const totalItems = useMemo(() => {
    return (
      addingPlayableItems.filter((x) => !x.isHide).length +
      addingImages.length +
      addingTexts.length +
      addingCards?.length
    );
  }, [addingImages, addingPlayableItems, addingTexts, addingCards]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (controlTrackRef.current) {
      dispatch(update({ heightOfFrame: controlTrackRef.current?.clientHeight }));
    }
  }, [controlTrackRef]);

  useEffect(() => {
    dispatch(update({ maxDuration, totalDuration }));
  }, [totalDuration]);

  const playScreenChange = useMemo(() => {
    if (controlTrackRef.current) {
      const width = controlTrackRef.current.clientWidth;
      return Math.ceil(totalPlayed / TIME_PX / width) - 1;
    }
    return 0;
  }, [totalPlayed]);

  useEffect(() => {
    if (controlTrackRef.current) {
      const width = controlTrackRef.current.clientWidth;
      controlTrackRef.current.scrollTo({
        left: width * (Math.ceil(totalPlayed / TIME_PX / width) - 1),
        behavior: 'auto'
      });
    }
  }, [playScreenChange]);

  useEffect(() => {
    if (controlTrackRef.current !== null) {
      controlTrackRef.current.addEventListener('scroll', (event) => {
        dispatch(update({ leftMarginControlScroll: controlTrackRef.current?.scrollLeft }));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (totalPlayIntervalRef.current && (manualPause || waitingForLoadVideo || totalPlayed >= maxDuration)) {
      clearTimeout(totalPlayIntervalRef.current);
      if (totalPlayed > maxDuration) {
        dispatch(update({ totalPlayed: 0, manualPause: true }));
      }
    } else {
      totalPlayIntervalRef.current = setTimeout(() => {
        const date = new Date();
        const play = Math.floor(totalPlayed);
        const timeSpace = date.getMilliseconds() / 1333;
        // 100ms -> 1000 ~ 75ms -> 1333

        const resultStamp = timeSpace === 0 ? totalPlayed + 0.075 : play + timeSpace;

        if (resultStamp < totalPlayed) {
          dispatch(update({ totalPlayed: totalPlayed + 0.1 }));
        } else {
          if (totalPlayed >= maxDuration) {
            dispatch(update({ totalPlayed: maxDuration + 0.075 }));
            setTimeout(() => {
              dispatch(update({ totalPlayed: 0, manualPause: true }));
            }, 50);
          } else {
            dispatch(update({ totalPlayed: resultStamp }));
          }
          // dispatch(update({ totalPlayed: resultStamp }));
        }
      }, 75);
    }

    // Cleanup function to clear interval on unmount
    return () => {
      if (totalPlayIntervalRef.current) {
        clearTimeout(totalPlayIntervalRef.current);
      }
    };
  }, [manualPause, totalPlayed, dispatch, maxDuration, waitingForLoadVideo]);

  // re-calculate subtitle to save to server
  useEffect(() => {
    const modifyListSubtitles =
      subtitlesInit && !!subtitlesInit.length && typeof subtitlesInit === 'object'
        ? subtitlesInit?.map((item: any) =>
            addingPlayableItems
              ?.filter((x) => !x.isHide)
              // eslint-disable-next-line array-callback-return
              ?.map((video: any) => {
                if (item?.file === video?.filePath) {
                  return {
                    id: item?.id,
                    file: item?.file,
                    trim: video?.trim,
                    start: video?.start,
                    transcribe: item?.transcribe?.map((datum: Transcribe) => {
                      return {
                        start: plusSecond(datum?.start, video?.start as number),
                        end: plusSecond(datum?.end, video?.start as number),
                        id: datum.id,
                        sub: datum.sub
                      };
                    })
                  };
                }
                // return video;
              })
          )
        : [];

    const result = modifyListSubtitles
      ?.filter(Boolean) // remove any null or undefined values
      .map((arr) => arr.filter(Boolean)) // remove any null or undefined values in each sub-array
      .flat() // flatten the array
      .map((obj: any) => ({
        id: obj.id,
        file: obj.file,
        trim: obj.trim,
        start: obj.start,
        transcribe: obj.transcribe
      })); // create a new array with 'file' and 'transcribe' property

    dispatch(
      update({
        subtitles: result
      })
    );
  }, [addingPlayableItems, subtitlesInit]);

  const handleSeekChange = (value: number) => {
    //dispatch(update({ waitingForLoadVideo: false }));
    dispatch(update({ totalPlayed: value, manualSeeking: true }));
  };

  const syncDraftDataToState = async (data: any) => {
    // const updateState = await convertDraft(data);
    dispatch(update(data));
  };

  const handleUndoRedoDraft = async (type?: string) => {
    dispatch(update({ manualPause: true }));
    dispatch(saveActUndoRedo());
    let versionDraft = 0;
    if (type === 'undo') {
      if (versionDraftSelected > 0) {
        versionDraft = versionDraftSelected - 1;
      }
    } else {
      if (versionDraftSelected === listVersions?.length - 1) {
        versionDraft = listVersions?.length - 1;
      } else {
        versionDraft = versionDraftSelected + 1;
      }
    }
    dispatch(setVersionDraftSelected(versionDraft));
    if (versionDraft >= 0 && listVersions.length) {
      const dataSync = listVersions[versionDraft];
      syncDraftDataToState(dataSync);
    }
  };
  const disableUndo = versionDraftSelected === 0 || listVersions?.length <= 1;
  const disableRedo = versionDraftSelected === (listVersions?.length - 1 || 0) || listVersions?.length <= 1;

  return (
    <Container playSeekIcon={PlaySeek} totalItems={totalItems}>
      <TrackControl ref={controlTrackRef}>
        <Ruler value={totalDuration} />
        <ClockwisePlat value={totalPlayed} onChange={handleSeekChange} />
        <TrackControlVideoControl />
        {isFinishedLoading && (
          <ActionsContainer>
            <Action onClick={() => !disableUndo && handleUndoRedoDraft('undo')} isDisabled={disableUndo}>
              <LeftDown fill={disableUndo ? '#B5ADB0' : '#070550'} />
              <ActionText isDisabled={disableUndo}>Undo</ActionText>
            </Action>
            <Action
              onClick={() => !disableRedo && handleUndoRedoDraft('redo')}
              isDisabled={versionDraftSelected === (listVersions?.length - 1 || 0) || listVersions?.length === 1}
            >
              <ActionText isDisabled={disableRedo}>Redo</ActionText>
              <RightDown fill={disableRedo ? '#B5ADB0' : '#070550'} />
            </Action>
          </ActionsContainer>
        )}
      </TrackControl>
    </Container>
  );
};

export default VideoControl;

const Container = styled.div<{ playSeekIcon: any; totalItems: number }>`
  flex: 1;
`;

const TrackControl = styled.div`
  outline: ${({ theme }) => `0.5px solid ${theme.altGrey.shade5}`};
  background: ${({ theme }) => theme.neutral.white};
  border-radius: 4px;
  height: calc(100vh - 500px);
  overflow: auto;
  scroll-behavior: smooth;
  overflow-y: overlay;
  padding: 14px;
`;

const ActionsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 46%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    right: 46%;
    bottom: -10px;
  }
  display: flex;
  width: 140px;
  height: 40px;
  justify-content: space-around;
`;

const Action = styled.div<{ isDisabled?: boolean }>`
  width: 60px;
  height: 25px;
  outline: ${({ theme }) => `0.5px solid ${theme.altGrey.shade5}`};
  // opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme, isDisabled }) => (isDisabled ? theme.altGrey.shade4 : theme.neutral.white)};
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  box-shadow: 3px 5px 21px -7px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 3px 5px 21px -7px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 3px 5px 21px -7px rgba(0, 0, 0, 0.27);

  &:hover {
    background: ${({ theme, isDisabled }) => (isDisabled ? theme.altGrey.shade4 : theme.altGrey.shade3)};
  }
`;

const ActionText = styled.p<{ isDisabled?: boolean }>`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.neutral.N30 : theme.altGrey.shade9)};
`;

// const SplitWrapper = styled.div`
//   cursor: pointer;
//   background: #ffffff;
//   height: 32px;
//   width: 32px;
//   border: 1px solid rgba(7, 5, 80, 0.2);
//   border-radius: 12px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
