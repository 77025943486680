import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import useRequest from '@/components/hooks/useRequest';
import { SubtitleDetail } from '@/features/video/models/VideoFrame';
import { deleteSubtitle } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/app/hooks';
import { selectVideo, update } from '@/features/video/store/videoSlice';

interface IDeleteFileNameProps {
  isOpen: boolean;
  onClickClose: () => void;
  subtitleDetail?: SubtitleDetail;
  refetchListSubtitles?: () => void;
}

const DeleteFileNameSubtitleModal: React.FunctionComponent<IDeleteFileNameProps> = ({
  isOpen,
  onClickClose,
  subtitleDetail,
  refetchListSubtitles = () => null
}) => {
  const { request } = useRequest();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { addingPlayableItems, subtitles, subtitlesInit } = useAppSelector(selectVideo);

  // remove idSubtitleLinked when deleting the subtitle file
  const handleUnLinkSubtitle = () => {
    const modifyListVideos = addingPlayableItems
      .filter((x) => !x.isHide)
      .map((item: any) => {
        if (item.idSubtitleLinked === subtitleDetail?.id) {
          return { ...item, idSubtitleLinked: '' };
        } else {
          return { ...item };
        }
      });

    const newSubtitle = subtitles?.filter((item) => item?.file !== subtitleDetail?.file);
    const newSubtitleInit = subtitlesInit?.filter((item) => item?.file !== subtitleDetail?.file);

    dispatch(
      update({
        subtitles: newSubtitle as any,
        subtitlesInit: newSubtitleInit as any,
        addingPlayableItems: modifyListVideos
      })
    );
  };


  const onDelete = async () => {
    const editSub = await request(deleteSubtitle(subtitleDetail?.id as string));
    if (editSub?.data?.status === 200) {
      handleUnLinkSubtitle();
      refetchListSubtitles();
    } else {
      message.error('Delete subtitle unsuccessfully!');
    }
    onClickClose();
  };

  const onClose = () => {
    onClickClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="470px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Are you sure to delete this item?</TitleConfirm>
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
        <Button variant="alert" onClick={onDelete}>
          Delete
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default DeleteFileNameSubtitleModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;
