import { createAuthAction } from './commonAction';
import { AUTH } from './actionType';
import { EnvironmentVariable } from '@/global/constant/Environment';

const login = (token: string) =>
  createAuthAction(
    {
      type: AUTH.LOGIN,
      request: {
        method: 'post',
        url: `${EnvironmentVariable.PLATFORM_API_URL}/v${EnvironmentVariable.PLAT_FORM_API_VERSION}/studio/login`
      }
    },
    token
  );

export { login };
