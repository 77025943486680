import React from 'react';

import { DropdownArrow, Info, PlusCircle, CloseFilled } from '@/components/SVG';
import ToolTip from '@/components/ToolTip';
import Typography from '@/components/Typography';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StylesConfig } from 'react-select';
import { useTheme } from '@emotion/react';
import { DropDownProps } from './DropDown.props';
import { Container, LabelContainer, SelectWrapper, TipIcon, CreateLabel } from './DropDown.style';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow fill="#070550" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseFilled width={16.67} height={16.67} fill={'#B5ADB0' || '#F0ECEE'} />
    </components.ClearIndicator>
  );
};

const DropDown = (props: DropDownProps): JSX.Element => {
  const theme = useTheme();

  const commonStyles: StylesConfig<any, any> = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: '12px',
      backgroundColor: props.bgColor ? props.bgColor : theme.grey.shade3,
      fontFamily: 'Objective',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.grey.shade7,
      boxShadow: 'none',
      border: props.customBorder ? props.customBorder : 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme.grey.shade7,
      fontWeight: 500
    }),
    option: (provided: any, state) => ({
      ...provided,
      color: theme.grey.shade7,
      fontFamily: 'Objective',
      fontSize: '14px',
      lineHeight: '24px',

      backgroundColor: state.isSelected
        ? theme.brand.secondary
        : state.isFocused
        ? theme.grey.shade2
        : theme.grey.noshade,
      ':active': {
        ...provided[':active'],
        backgroundColor: theme.brand.secondary,
        fontWeight: 500
      }
    }),
    menuList: (provided: any, state) => ({
      ...provided,
      height: '120px'
    }),
    valueContainer: (provided: any, state) => ({
      ...provided,
      paddingTop: '9.5px',
      paddingBottom: '9.5px'
    }),
    input: (provided: any, state) => ({
      ...provided,
      padding: '0',
      margin: '0'
    }),
    placeholder: (provided: any, state) => ({
      ...provided,
      color: theme.grey.shade7
    }),
    indicatorSeparator: () => ({ display: 'none' })
  };
  const {
    label,
    isCreatable,
    tooltip,
    isFullWidth,
    customMargin,
    customLabel,
    placeholderCreatable = 'Create',
    createOptionPosition = 'first',
    isColorBlack,
    containerStyles,
    ...selectProps
  } = props;

  return (
    <Container margin={customMargin} style={containerStyles}>
      {label && (
        <LabelContainer>
          <Typography variant="overline" color={isColorBlack ? 'black' : 'shade6'}>
            {label}
          </Typography>
        </LabelContainer>
      )}
      {customLabel && customLabel}
      <SelectWrapper isFullWidth={isFullWidth}>
        {isCreatable ? (
          <CreatableSelect
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder="Select or create"
            {...selectProps}
            styles={commonStyles}
            createOptionPosition={createOptionPosition}
            isValidNewOption={() => true}
            formatCreateLabel={(inputValue) => {
              const inputValWithColon = inputValue ? ': ' + inputValue : '';
              return (
                <CreateLabel active={inputValue !== ''} onClick={(e: any) => e.preventDefault()}>
                  <PlusCircle
                    width={16.67}
                    height={16.67}
                    fill={inputValue !== '' ? theme.brand.secondary : theme.brand.primary}
                  />
                  <Typography>
                    {' '}
                    {placeholderCreatable}
                    {inputValWithColon}
                  </Typography>
                </CreateLabel>
              );
            }}
            onCreateOption={(inputValue) => {
              if (inputValue && selectProps.onChange) {
                selectProps.onChange(
                  selectProps.isMulti
                    ? [
                        ...(selectProps.value?.length > 0 ? [...selectProps.value] : []),
                        ...[{ value: null, label: inputValue }]
                      ]
                    : { value: null, label: inputValue },
                  { action: 'create-option', option: {} }
                );
              }
            }}
            // menuIsOpen
          ></CreatableSelect>
        ) : (
          <Select
            placeholder="Select"
            components={{ DropdownIndicator, ClearIndicator }}
            {...selectProps}
            styles={commonStyles}
          ></Select>
        )}
        {tooltip && (
          <TipIcon>
            <ToolTip arrow content={tooltip}>
              <Info width={24} height={24} />
            </ToolTip>
          </TipIcon>
        )}
      </SelectWrapper>
    </Container>
  );
};

export default React.memo(DropDown);
