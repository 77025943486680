import { createAuthAction } from '../commonAction';
import { BRANDKIT } from '../actionType';

import { API } from '@/const';

export type BrandKitUploadType = 'videos' | 'logos' | 'musics' | 'images';

export const uploadLogos = (files: File[]) => (token?: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('logos', files[i]);
  }

  return createAuthAction(
    {
      type: BRANDKIT.UPLOAD_FILES,
      request: { url: `${API.VERSION}/brand-kit/upload-logos`, method: 'POST', data: formData }
    },
    token
  );
};

export const uploadFonts = (files: File[]) => (token?: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('fonts', files[i]);
  }

  return createAuthAction(
    {
      type: BRANDKIT.UPLOAD_FILES,
      request: { url: `${API.VERSION}/brand-kit/upload-fonts`, method: 'POST', data: formData }
    },
    token
  );
};

export const brandKitUpload = (files: File[], type: BrandKitUploadType) => (token?: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(type, files[i]);
  }

  return createAuthAction(
    {
      type: BRANDKIT.UPLOAD_FILES,
      request: { url: `${API.VERSION}/brand-kit/upload-${type}`, method: 'POST', data: formData }
    },
    token
  );
};

export const updateBrandKitService = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.UPDATE_BRANDKIT,
      request: {
        url: `${API.VERSION}/brand-kit`,
        method: 'PUT',
        data: data
      }
    },
    token
  );
};
