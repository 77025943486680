/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import VideoControl from './components/video-control/VideoControl';
import VideoFrame from './components/video-frame/VideoFrame';
import VideoSlideBarV2 from './components/video-slidebar/VideoSlideBarV2';
import useAutoSave from '@/components/hooks/useAutoSave';
import AnimationSliderBar from './components/video-slidebar/AnimationSliderBar';
import ProgressLoadingLayout from '@/layout/ProgressLoading';
import ModalWarningCaption from '@/components/WarningCaptionModal';
import ModalUploadVideoCaption from '@/components/UploadVideoModal';
import './Video.scss';
import useRequest from '@/components/hooks/useRequest';
import { useEffect, useRef, useState } from 'react';
import {
  getAllMedia,
  getBrandKit,
  getDraft,
  getFont,
  getLanguages,
  getTemplateById,
  getPlatformVideo as getFlatFormData
} from './store/videoAPI';
import { useDispatch } from 'react-redux';
import {
  addPlayableItem,
  closeDrawerAnimationBar,
  saveStoryHubMainVideoId,
  selectVideo,
  setFinishedLoading,
  unSelectElement,
  unSelectTextOrImage,
  update
} from './store/videoSlice';

import { updateStory } from './store/storySlice';

import { getDuration } from './services/videoService';
import { useAppSelector } from '@/app/hooks';
import { PlayableType } from './models/FrameItem';
import useDraft from '@/components/hooks/useDraft';
import OutsideClickHandler from 'react-outside-click-handler';
import SideBarTop from '@/layout/SideBar/SideBarTop';
import { storeBrandKit } from './store/brandKitSlice';
import { useSearchParams } from 'react-router-dom';
import { templateById } from './store/templateSlice';
import useMediaSocket from '@/components/hooks/useMediaSocket';

const VideoLayout: React.FC = () => {
  useAutoSave();
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const actionEdit = searchParams.get('action');
  const templateParamId = searchParams.get('template');
  const actionAddCaption = searchParams?.get('addCaption');
  const {
    contentId,
    contentType,
    playerSize,
    isInitial,
    isDrawerAnimationBar,
    addingPlayableItems,
    isFinishedLoading
  } = useAppSelector(selectVideo);

  const dispatch = useDispatch();
  const { convertDraft, pushVersionDraftToState } = useDraft();
  const noFirstSync = useRef(true);
  const { createSocket, socket } = useMediaSocket('video');
  const [percent, setPercent] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false);

  // const getSubtitle = useCallback(
  //   async (subId: string) => {
  //     const res = await request(fetchSubtitle(subId));
  //     if (!res?.error) {
  //       const data = res?.data?.data;
  //       if (data) {
  //         dispatch(
  //           updateSubtitle({
  //             id: subId,
  //             language: { label: data.language, langCode: data.code },
  //             subLines: data.transcribe
  //           })
  //         );
  //       }
  //     }
  //   },
  //   [request, dispatch]
  // );

  const syncDraftDataToState = async (data: any) => {
    if (noFirstSync.current) {
      const updateState = await convertDraft(data);
      dispatch(update(updateState));
      pushVersionDraftToState(updateState);
      noFirstSync.current = false;
    }

    // if (updateState.subtitles?.length) {
    //   updateState.subtitles.forEach(async (sub) => {
    //     await getSubtitle(sub.id);
    //   });
    // }
  };

  const syncFlatFormDataToState = async (mainVideo: any) => {
    let { duration, resolution } = mainVideo;

    if (!duration || !resolution) {
      const _ = await getDuration(mainVideo.videoLink?.link);
      duration = _.duration;
      resolution = _.resolution;
    }

    dispatch(
      addPlayableItem({
        name: 'Main Video',
        filePath: mainVideo.videoLink?.link,
        fileKey: mainVideo.videoLink?.s3Object?.Key,
        type: PlayableType.video,
        resolution: resolution,
        duration: duration,
        main: mainVideo.isMain,
        thumbnails: mainVideo?.thumbnails || []
      })
    );

    if (contentType === 'STORY_VIDEO') {
      dispatch(update({ contentId: mainVideo.contentId, platFromVideoId: mainVideo.videoId, title: mainVideo.title }));
    }
  };

  const handleCloseAnimationBar = () => {
    dispatch(closeDrawerAnimationBar());
    dispatch(unSelectElement());
    dispatch(unSelectTextOrImage());
  };

  const handleMoveItemsToNextLine = (addingItems?: any) => {
    return addingItems.map((item: any) => {
      return {
        ...item,
        line: item.line + 1
      };
    });
  };

  const getTemplateToApplyAndEdit = (data?: any, isApply?: boolean) => {
    const defaultVideoFromStory = isApply ? addingPlayableItems.filter((item) => item?.main && !item?.isHide) : [];
    const dataRes = data;
    const addingPlayableTemp = [...dataRes.addingPlayableItems];
    const addingTextsTemp = [...dataRes.addingTexts];
    const addingImagesTemp = [...dataRes.addingImages];
    const addingCardsTemp = dataRes?.addingCards?.length ? [...dataRes?.addingCards] : [];

    const newPayload = {
      ...dataRes,
      addingPlayableItems: !!defaultVideoFromStory?.length
        ? defaultVideoFromStory.concat(handleMoveItemsToNextLine(addingPlayableTemp))
        : addingPlayableTemp,
      addingImages: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingImagesTemp) : addingImagesTemp,
      addingTexts: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingTextsTemp) : addingTextsTemp,
      addingCards: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingCardsTemp) : addingCardsTemp,
      crop: {
        x: dataRes?.crop?.x,
        y: dataRes?.crop?.y,
        expectRatio: dataRes?.crop?.expectRatio,
        width: dataRes?.crop?.width,
        height: dataRes?.crop?.height
      }
    };

    return newPayload;
  };

  // Get platform by socket start
  const getFlatFormDataPromiseResolver = useRef<any>(null);
  // TODO use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _getFlatFormData = (contentId: string, contentType: string) => {
    return new Promise((resolve) => {
      getFlatFormDataPromiseResolver.current = resolve;
      const sk = createSocket();
      if (sk) {
        const socketPayload = {
          contentId: contentId,
          contentType: contentType
        };
        sk.emit('getPlatformVideos', {
          event: 'getPlatformVideos',
          ...socketPayload
        });
      }
    });
  };
  socket?.on('getPlatformVideos', (res) => {
    if (res?.status === 200) {
      getFlatFormDataPromiseResolver?.current(res);
    }
  });
  // Get platform by socket end

  const delayAndSetPercent = async () => {
    await new Promise((resolve) => setTimeout(resolve, 600)); // 600ms delay
    setPercent(100);
  };

  useEffect(() => {
    async function loadData() {
      let percent = 0;
      let isComplete = false;

      function cheatPercent() {
        if (isFinishedLoading || isComplete || percent >= 85) {
          return; // Stop if loading is finished or percent reaches 85%
        }

        // Increase percent by random value between 1 and 8 every 400 ms
        percent = Math.min(100, percent + Math.floor(Math.random() * 8));
        setPercent(percent);

        setTimeout(cheatPercent, 400); // Schedule next increment
      }
      cheatPercent();

      dispatch(update({ isLoadingData: true }));
      let { data }: any = await request(getFlatFormData(contentId as string, contentType as string));
      let flatFormDataRes = data;

      dispatch(updateStory({ stories: flatFormDataRes?.data }));

      // let mainVideo = flatFormDataRes?.data?.find((video: any) => video.isMain) || flatFormDataRes?.data?.[0];

      let mainVideo =
        Array.isArray(flatFormDataRes?.data) && flatFormDataRes.data.length > 0
          ? flatFormDataRes.data.find((video: any) => video.isMain) || flatFormDataRes.data[0]
          : undefined;

      mainVideo = { ...mainVideo, contentId, contentType };

      if (mainVideo === 'undefined' || !mainVideo) {
        dispatch(update({ isLoadingData: false }));
        return;
      }
      const draftId = mainVideo?.videoId ? mainVideo.videoId : contentId;
      dispatch(saveStoryHubMainVideoId(mainVideo?.videoId));
      dispatch(update({ title: mainVideo?.title }));

      // TODO update, remove main video feature
      let res = {} as any;
      try {
        res = await request(getDraft(draftId));
        if (res?.data?.data && Object.keys(res.data.data).length) {
          await delayAndSetPercent();
          isComplete = true;
        } else if (res?.data?.data && Object.keys(res.data.data).length === 0) {
          res = await request(getDraft(contentId));
          await delayAndSetPercent();
          isComplete = true;
        }
      } catch (error) {
        // console.error("Failed to fetch draft data:", error);
      }

      // let res = await request(getDraft(draftId));
      // if (res?.data?.data && Object.keys(res.data.data).length === 0) {
      //   res = await request(getDraft(contentId as string));
      // }

      if (templateParamId && actionEdit === 'template_edit') {
        const resEditTemplate = await request(getTemplateById(templateParamId as string));
        const newPayload = getTemplateToApplyAndEdit(resEditTemplate?.data?.data, false);
        const updateState = await convertDraft(newPayload);
        await dispatch(update(updateState));
        // await dispatch(updateFromTemplate(newPayload));
        await dispatch(templateById(resEditTemplate?.data?.data));
        if (noFirstSync.current) {
          pushVersionDraftToState(updateState);
          noFirstSync.current = false;
        }
      } else if (templateParamId && !actionEdit) {
        const dataTemplate = await request(getTemplateById(templateParamId as string));
        const newPayload = getTemplateToApplyAndEdit(dataTemplate?.data?.data, true);
        const updateState = await convertDraft(newPayload);
        await dispatch(update(updateState));
        // await dispatch(updateFromTemplate(newPayload));
        await dispatch(templateById(dataTemplate?.data?.data));
        if (noFirstSync.current) {
          pushVersionDraftToState(updateState);
          noFirstSync.current = false;
        }
      } else {
        if (res?.data?.data && Object.keys(res.data.data).length) {
          await syncDraftDataToState(
            contentId
              ? {
                  ...res.data.data,
                  contentId: mainVideo.contentId,
                  platFromVideoId: mainVideo.videoId,
                  contentType: contentType,
                  title: mainVideo.title
                }
              : res.data.data
          );
        } else if (flatFormDataRes?.data) {
          await syncFlatFormDataToState(mainVideo);
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 600)); // Pending delay
      dispatch(setFinishedLoading());
      dispatch(update({ isLoadingData: false }));
    }
    if (playerSize.width && isInitial && contentId && contentType) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerSize]);

  useEffect(() => {
    handleRequestBrandKit();
    request(getAllMedia);
    dispatch(update({ selectedTab: 'Media' }));
    request(getFont);
    request(getLanguages);
  }, []);

  useEffect(() => {
    const listStoriesIdTracking = JSON.parse(localStorage.getItem('listStoriesIdTracking') || '[]');
    const isStoryTracking = listStoriesIdTracking?.includes(contentId)
    if (actionAddCaption === 'true' && isFinishedLoading && !isStoryTracking) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [isFinishedLoading]);

  const handleRequestBrandKit = async () => {
    try {
      const data = await request(getBrandKit);
      dispatch(storeBrandKit(data?.data?.data));
    } catch (error) {}
    // const dataTemplate = await request(requestGetTemplate());
    // dispatch(getTemplate(dataTemplate?.data?.data));
  };

  const onCloseWarning = () => {
    setIsOpenModal(false);
  };

  const onConfirm = () => {
    setIsOpenUploadModal(true);
    setIsOpenModal(false);
  };

  const onCloseWarningUpload = () => {
    setIsOpenUploadModal(false);
  };

  return (
    <Container isFinishedLoading={isFinishedLoading}>
      <ProgressLoadingLayout percentLoading={percent} />
      <VideoContainer>
        <SideBarTop />
        <VideoFrameContainer>
          <VideoSideBarContainer>
            <VideoSlideBarV2 />
          </VideoSideBarContainer>
          <VideoFrame />
        </VideoFrameContainer>
        <VideoControl />
        <OutsideClickHandler onOutsideClick={handleCloseAnimationBar}>
          <AnimationSliderBar show={isDrawerAnimationBar} />
        </OutsideClickHandler>
        <ModalWarningCaption isOpen={isOpenModal} onClickApply={onConfirm} onClickClose={onCloseWarning} />
        <ModalUploadVideoCaption isOpen={isOpenUploadModal} onClickClose={onCloseWarningUpload} />
      </VideoContainer>
    </Container>
  );
};

export default VideoLayout;

const Container = styled.div<{ isFinishedLoading?: boolean }>`
  overflow: ${({ isFinishedLoading }) => (!isFinishedLoading ? 'auto' : 'hidden')};
`;

const VideoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // width: calc(100vw - 94px);
  flex-shrink: 0;
`;

const VideoSideBarContainer = styled.div`
  background: ${({ theme }) => theme.neutral.white};
  max-width: 490px;
  min-width: 490px;
  // height: 100%;
  max-height: 459px;
  min-height: 349px;
  margin-right: 10px;
  border-radius: 4px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 433px;
    min-width: 433px;
  }
  outline: ${({ theme }) => `0.5px solid ${theme.altGrey.shade5}`};
`;

const VideoFrameContainer = styled.div`
  flex-grow: 1;
  // height: calc(100% - 33px);
  max-height: 459px;
  min-height: 459px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-height: 350px;
    min-height: 350px;
  }
  background: #ebe7e9;
  display: flex;
  margin-bottom: 10px;
`;
