import { createAuthAction } from './commonAction';
import { BRANDKIT, SUBTITLE, TEMPLATE, VIDEO } from './actionType';
import { API } from '@/const';

export const getAllMedia = (token?: string) =>
  createAuthAction(
    {
      type: VIDEO.GET_MEDIA,
      request: { url: `${API.VERSION}/media` }
    },
    token
  );

export const uploadFiles = (files: File[]) => (token?: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  return createAuthAction(
    {
      type: VIDEO.UPLOAD_FILES,
      request: { url: `${API.VERSION}/media`, method: 'post', data: formData }
    },
    token
  );
};

export const UpdateMediaLocal = (updateData: any) => {
  return {
    type: VIDEO.UPDATE_MEDIA_LOCAL,
    meta: {
      mutations: {
        [VIDEO.GET_MEDIA]: {
          updateData: () => {
            return updateData;
          },
          local: true
        }
      }
    }
  };
};

export const saveDraft = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.SAVE_DRAFT,
      request: { url: `${API.VERSION}/video/draft`, method: 'post', data }
    },
    token
  );
};

export const getDraft = (contentId?: string) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GET_DRAFT,
      request: {
        url: contentId ? `${API.VERSION}/video/draft/video/${contentId}` : `${API.VERSION}/video/drafts/latest`
      }
    },
    token
  );
};

export const API_GOOGLE_FONTS_URL =
  'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyBDmXIS5vcJKwo3HhlOAu4tZ5IFyrDqVP8';

export const getFont = () => {
  return {
    type: VIDEO.GET_FONT,
    request: { url: API_GOOGLE_FONTS_URL }
  };
};

export const getPlatformVideo = (contentId: string, contentType: string) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GET_PLATFORM_VIDEO,
      request: { url: `${API.VERSION}/video/platform`, params: { contentId, contentType } }
    },
    token
  );
};

export const uploadSubtitle = (files: File[], language?: string) => (token?: string) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  return createAuthAction(
    {
      type: VIDEO.UPLOAD_SUBTITLE,
      request: { url: `${API.VERSION}/subtitle`, method: 'post', data: formData }
    },
    token
  );
};

export const generateSubtitle = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GENERATE_SUBTITLE,
      request: { url: `${API.VERSION}/subtitle/generate`, method: 'post', data: data }
    },
    token
  );
};

export const getLanguages = (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GET_LANGUAGES,
      request: { url: `${API.VERSION}/setting/languages` }
    },
    token
  );
};

export const fetchSubtitle = (id: string) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GET_SUBTITLE,
      request: { url: `${API.VERSION}/subtitle/${id}` }
    },
    token
  );
};

export const syncVideo = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.SYNC_VIDEO,
      request: { url: `${API.VERSION}/video/sync`, method: 'post', data: data }
    },
    token
  );
};

export const getBrandKit = (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.GET_BRANDKIT,
      request: {
        url: `${API.VERSION}/brand-kit`
      }
    },
    token
  );
};

export const updateBrandKit = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.UPDATE_BRANDKIT,
      request: {
        url: `${API.VERSION}/brand-kit`,
        method: 'post',
        data: data
      }
    },
    token
  );
};

export const getTemplate = (tag?: string) => (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.GET_TEMPLATE,
      request: {
        url: `${API.VERSION}/template`,
        params: { tag }
      }
    },
    token
  );
};

export const getTemplateByCompany = (tag?: string) => (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.GET_TEMPLATE,
      request: {
        url: `${API.VERSION}/template/findBy/company`,
        params: { tag }
      }
    },
    token
  );
};

export const getTemplateByGlobal = (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.GET_TEMPLATE,
      request: {
        url: `${API.VERSION}/template/findBy/global`
      }
    },
    token
  );
};

export const getTemplateById = (templateId?: string) => (token?: string) => {
  return createAuthAction(
    {
      type: BRANDKIT.GET_TEMPLATE,
      request: {
        url: `${API.VERSION}/template/${templateId}`
      }
    },
    token
  );
};

export const deleteMedia = (mediaId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.DELETE_MEDIA,
      request: {
        url: `${API.VERSION}/media/${mediaId}`,
        method: 'delete'
      }
    },
    token
  );
};

export const moveTemplate = (templateId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: TEMPLATE.MOVE_TEMPLATE,
      request: {
        url: `${API.VERSION}/template/typeCompany/${templateId}`,
        method: 'put'
      }
    },
    token
  );
};

export const uploadThumbnail = (files: File[]) => (token?: string) => {
  const formData = new FormData();
  formData.append('file', files[0]);

  return createAuthAction(
    {
      type: TEMPLATE.UPLOAD_THUMBNAIL,
      request: { url: `${API.VERSION}/template/upload`, method: 'post', data: formData }
    },
    token
  );
};

export const saveAsTemplate = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: TEMPLATE.SAVE_TEMPLATE,
      request: {
        url: `${API.VERSION}/template`,
        method: 'post',
        data: data
      }
    },
    token
  );
};

export const editTemplate = (data: any, templateId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: TEMPLATE.EDIT_TEMPLATE,
      request: {
        url: `${API.VERSION}/template/${templateId}`,
        method: 'PUT',
        data: data
      }
    },
    token
  );
};

export const getVideoTypes = (token?: string) => {
  return createAuthAction(
    {
      type: TEMPLATE.VIDEO_TYPE,
      request: {
        url: `${API.VERSION}/template/sync/getCategories`
      }
    },
    token
  );
};

export const getAllSubtitles = (contentId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: SUBTITLE.GET_SUBTITLE,
      request: {
        url: `${API.VERSION}/subtitle?contentId=${contentId}`
      }
    },
    token
  );
};

export const deleteSubtitle = (subtitleId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: SUBTITLE.DELETE_SUBTITLE,
      request: {
        url: `${API.VERSION}/subtitle/${subtitleId}`,
        method: 'delete'
      }
    },
    token
  );
};

export const editSubtitle = (subtitleId: string, data?: any) => (token?: string) => {
  return createAuthAction(
    {
      type: SUBTITLE.UPDATE_SUBTITLE,
      request: {
        url: `${API.VERSION}/subtitle/${subtitleId}`,
        data: data,
        method: 'put'
      }
    },
    token
  );
};

export const downloadSubtitleFile = (data: any) => (token?: string) => {
  return createAuthAction(
    {
      type: TEMPLATE.SAVE_TEMPLATE,
      request: {
        url: `${API.VERSION}/subtitle/download`,
        method: 'post',
        data: data
      }
    },
    token
  );
};

export const uploadSubtitleV2 = (files: File[], data?: any) => (token?: string) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  formData.append('langName', data?.langName);
  formData.append('langCode', data?.langCode);
  formData.append('contentId', data?.contentId);
  return createAuthAction(
    {
      type: VIDEO.UPLOAD_SUBTITLE,
      request: { url: `${API.VERSION}/subtitle/upload`, method: 'post', data: formData }
    },
    token
  );
};

export const getSubtitleById = (subtitleId: string) => (token?: string) => {
  return createAuthAction(
    {
      type: SUBTITLE.GET_SUBTITLE,
      request: {
        url: `${API.VERSION}/subtitle/${subtitleId}`
      }
    },
    token
  );
};

export const getLimitTime = (token?: string) => {
  return createAuthAction(
    {
      type: VIDEO.GET_LIMIT_TIME,
      request: {
        url: `${API.VERSION}/video/export/limit`
      }
    },
    token
  );
};

export const translateSubtitles = (data?: any) => (token?: string) => {
  return createAuthAction(
    {
      type: SUBTITLE.UPDATE_SUBTITLE,
      request: {
        url: `${API.VERSION}/subtitle/translate`,
        data: data,
        method: 'post'
      }
    },
    token
  );
};
