/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@/components/Button';
import './Header.scss';
import styled from '@emotion/styled';
import { BackArrow, FloppyDisk, ArrowFrontlineDown } from '@/components/SVG';
import useDraft from '@/components/hooks/useDraft';
import { Popover } from 'antd';
import useSocket from '@/components/hooks/useSocket';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectSubtitle as getSubtitlesByUserId } from '@/features/video/store/subtitleSlice';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useSelector } from 'react-redux';
import { getRole, getToken } from '@/features/video/store/authSlice';
import { ExportType } from '@/types';
import useRequest from '@/components/hooks/useRequest';
import { getLimitTime, syncVideo } from '@/features/video/store/videoAPI';
import { message, notification } from 'antd';
import Typography from '@/components/Typography';
import axios from 'axios';
import ModalSaveTemplate from '@/features/video/components/video-slidebar/template-tab/components/SaveTemplate';
import SelectResolutionModal, { Option } from './SelectResolutionModal';
import { EmployerRole } from '@/types/store/LoginState';
import ProgressBarModal from './ProgressBarModal';
import { ContentType } from '@/constants/contentType';
import { EnvironmentVariable } from '@/global/constant/Environment';
import { useSearchParams } from 'react-router-dom';
import { useIsOnline } from 'react-use-is-online';

const StudioHeaderV2: React.FC<{}> = () => {
  const { buildDraftObj } = useDraft();
  const { createSocket, socket, disconnect } = useSocket('video');
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const {
    exporting,
    addingPlayableItems,
    contentId,
    videoId,
    contentType,
    subtitles,
    maxDuration,
    title,
    language,
    subtitlesInit,
    isFinishedLoading
  } = useAppSelector(selectVideo);
  const { subtitles: listSubtitlesByUserId } = useSelector(getSubtitlesByUserId);
  const token = useSelector(getToken);
  const { isOffline } = useIsOnline();
  const { request } = useRequest();
  const [exportType, setExportType] = useState('');
  const [isOpenSaveTemplate, setIsOpenSaveTemplate] = useState<boolean>(false);
  const [isOpenSelectResolution, setIsOpenSelectResolution] = useState<boolean>(false);
  const [resolutionSelected, setResolutionSelected] = useState<Option | null>();
  const [isOpenProcessBar, setIsOpenProcessBar] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const role = useAppSelector(getRole);
  const [api, contextHolder] = notification.useNotification();
  const ratioSize = searchParams.get('size');
  const actionEdit = searchParams.get('action');

  useEffect(() => {
    socket?.on('export', (data) => {
      console.info('exportVideo', data);
      if (data?.Location) {
        message.success(`${exportType === 'sync' ? 'Sync' : 'Export'} successfully`);
        disconnect();
        const updateData = {
          downloadLink: data?.Location,
          downloadFileName: addingPlayableItems?.[0]?.name,
          exportProcess: 0
        };
        dispatch(update(updateData));
        if (exportType === 'sync') {
          onSync(data?.Location);
        } else {
          onDownload(data?.Location);
        }
      } else if (data?.data === 'Server generated event') {
        dispatch(update({ exportProcess: data?.count }));
      } else if (data?.percent) {
        setPercent(data?.percent);
      } else if (data?.error === '' || data?.error) {
        message.error('Unable to export video, adjust timeline layers and try again.');
        disconnect();
        dispatch(
          update({
            exporting: ExportType.done
          })
        );
        setIsOpenProcessBar(false);
        setPercent(0);
      }
    });
  }, [socket, exportType]);

  const onExport = () => {
    if (exportType === 'download') {
      setIsOpenSelectResolution(false);
      setIsOpenProcessBar(true);
    } else {
      setIsOpenSelectResolution(false);
    }
    const listSubtitlesMatch = listSubtitlesByUserId.filter((sub: any) =>
      subtitlesInit?.some((item) => item.id === sub.id)
    );
    // get langName of first item to compare others
    const sameLanguage = listSubtitlesMatch[0]?.langName;
    // check same language in list subtitles match with above condition
    const isSameLanguage = listSubtitlesMatch?.every((item: any) => item.langName === sameLanguage);
    if (!isSameLanguage) {
      message.warn('Export excludes captions: at least one video is linked to several captions.');
    }

    const draftObj = buildDraftObj({
      isExporting: true,
      actionExport: true,
      exportResolution: resolutionSelected?.value
    });
    const sk = createSocket();
    if (sk) {
      dispatch(update({ exporting: ExportType.exporting }));
      console.info({ event: 'export', ...draftObj });
      sk.emit('export', { event: exportType === 'download' ? 'export' : "save-to-story", ...draftObj });
    }
  };

  const handleExport = async (type: string) => {
    if (isOffline) {
      message.error('Something went wrong. Please try again');
    } else if (!addingPlayableItems?.filter((vd: any) => !vd?.isHide)?.length) {
      message.error('There’s no video in the timeline.');
    } else {
      const res = await request(getLimitTime);
      const timeLimit = res?.data?.data?.exportLimit;
      const isTimeLimitExceeded = maxDuration > timeLimit * 60;
      // check time limit to export
      if (timeLimit <= 0 || !isTimeLimitExceeded) {
        setExportType(type);
        if (type === 'download') {
          setPercent(1);
          setIsOpenSelectResolution(true);
        } else {
          setIsOpenSelectResolution(true);
          // onExport();
        }
      }
      if (isTimeLimitExceeded) {
        openNotificationWithIcon(timeLimit);
      }
    }
  };

  const openNotificationWithIcon = (timeLimit?: number) => {
    api.warning({
      message: <p style={{ fontWeight: 700, fontSize: '14px' }}>Video is too long</p>,
      description: `Your video needs to be within ${timeLimit} minute length`,
      placement: 'top'
    });
  };

  const onSync = async (link: string) => {
    const data = {
      contentId,
      videoId: contentType === ContentType.library ? contentId : videoId,
      contentType,
      subtitles: subtitles,
      videoLink: link,
      thumbnail: '',
      language: language
    };
    const res = await request(syncVideo([data]));
    if (res?.error) {
      message.error('Error on sync video to platform');
    } else {
      dispatch(
        update({
          exporting: ExportType.done
        })
      );
      setIsOpenProcessBar(false);
      setPercent(0);
    }
  };

  const options = [
    { label: 'Download', type: 'download' },
    { label: 'Save to Story', type: 'sync' }
  ];

  const onDownload = async (link: string) => {
    //window.open(link, '_blank');
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;
    const fileName = title?.replace(regex, '');
    const blob = await axios.get(link, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      responseType: 'blob'
    });
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = `${fileName || new Date().getTime()}.mp4`;
    await a.click();
    await dispatch(
      update({
        exporting: ExportType.done
      })
    );
    // update 100% downloading and save video
    await setPercent(100);
    await setTimeout(() => {
      setIsOpenProcessBar(false);
      setPercent(0);
    }, 500);
  };

  const onClickClose = () => {
    setIsOpenSaveTemplate(false);
  };

  const onClickCancelExport = () => {
    setIsOpenProcessBar(false);
    dispatch(
      update({
        exporting: ExportType.done
      })
    );
    disconnect();
  };

  const redirect = useCallback(() => {
    window.location.replace(`${EnvironmentVariable.PLATFORM_URL}/employer/creative-studio`);
  }, []);

  return (
    <>
      <HeaderContainer style={{ padding: '10px 40px' }}>
        <BackStudioContainer onClick={redirect}>
          <IconBack>
            <BackArrow />
          </IconBack>
          <TextCreateStudio>Create Studio</TextCreateStudio>
        </BackStudioContainer>
        {token && (
          <ActionsContainer>
            {/* disable function save template */}
            {role === EmployerRole && (ratioSize || actionEdit) && (
              <SaveTemplate
                variant="outline"
                onClick={() => setIsOpenSaveTemplate(true)}
                size="xs"
                disabled={!isFinishedLoading && !ratioSize}
              >
                <SaveTemplateWrapper>
                  save template
                  <FloppyDisk />
                </SaveTemplateWrapper>
              </SaveTemplate>
            )}
            {contextHolder}
            <Popover
              title=""
              trigger={isFinishedLoading ? 'hover' : 'none'}
              overlayInnerStyle={{ borderRadius: '8px' }}
              content={options
                .filter((opt) => (contentId ? true : opt.type !== 'sync'))
                .map(({ label, type }) => {
                  return (
                    <OptionContainer
                      key={label}
                      onClick={() => {
                        handleExport(type);
                      }}
                    >
                      <Typography>{label}</Typography>
                    </OptionContainer>
                  );
                })}
            >
              {ratioSize || actionEdit ? null : (
                <ButtonContainer
                  iconPosition="after"
                  disabled={exporting === ExportType.exporting}
                  loading={exporting === ExportType.exporting}
                >
                  <SaveTemplateWrapper>
                    Export
                    <ArrowFrontlineDown />
                  </SaveTemplateWrapper>
                </ButtonContainer>
              )}
            </Popover>
          </ActionsContainer>
        )}
      </HeaderContainer>
      <ModalSaveTemplate isOpen={isOpenSaveTemplate} onClickClose={onClickClose} />
      <ProgressBarModal
        isOpen={isOpenProcessBar}
        percent={percent}
        type={exportType}
        onClickClose={onClickCancelExport}
      />
      <SelectResolutionModal
        isOpen={isOpenSelectResolution}
        onClickClose={() => setIsOpenSelectResolution(false)}
        onClickExport={onExport}
        setResolutionSelected={setResolutionSelected}
      />
    </>
  );
};

export default StudioHeaderV2;

const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

const BackStudioContainer = styled.div`
  display: flex;
`;

const IconBack = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${({ theme }) => theme.altGrey.shade4};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.altGrey.shade5};
  }
`;

const TextCreateStudio = styled.p`
  font-size: 16px;
  height: 18px;
  font-weight: 600;
  margin: 4px 0 0 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const ButtonContainer = styled(Button)`
  height: 32px;
`;

const SaveTemplate = styled(Button)`
  height: 32px;
`;

const SaveTemplateWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    margin-top: -4px;
  }
`;

const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.neutral.white};
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 4px 12px rgba(41, 43, 50, 0.08);
  width: 100%;
`;
const OptionContainer = styled.div<{ selected?: boolean }>`
  padding: 8px;
  cursor: pointer;
  ${({ selected, theme }) => {
    return `
    background:${selected ? theme.brand.mint : ''};
    `;
  }}
  &:hover {
    background: ${({ theme }) => theme.altGrey.shade4};
  }
`;
