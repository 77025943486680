export const VIDEO = {
  GET_MEDIA: 'VIDEO.GET_MEDIA',
  UPDATE_MEDIA_LOCAL: 'VIDEO.UPDATE_MEDIA_LOCAL',
  UPLOAD_FILES: 'VIDEO.UPLOAD_FILES',
  SAVE_DRAFT: 'VIDEO.SAVE_DRAFT',
  GET_DRAFT: 'VIDEO.GET_DRAFT',
  GET_FONT: 'VIDEO.GET_FONT',
  GET_PLATFORM_VIDEO: 'VIDEO.GET_PLATFORM_VIDEO',
  UPLOAD_SUBTITLE: 'VIDEO.UPLOAD_SUBTITLE',
  GENERATE_SUBTITLE: 'VIDEO.GENERATE_SUBTITLE',
  GET_LANGUAGES: 'VIDEO.GET_LANGUAGES',
  GET_SUBTITLE: 'VIDEO.GET_SUBTITLE',
  SYNC_VIDEO: 'VIDEO.SYNC_VIDEO',
  GET_STORIES: 'VIDEO.GET_STORIES',
  GET_THUMBNAILS: 'VIDEO.GET_THUMBNAILS',
  DELETE_MEDIA: 'VIDEO.DELETE_MEDIA',
  GET_LIMIT_TIME: 'VIDEO.GET_LIMIT_TIME',
  UNDO: 'VIDEO.UNDO',
  REDO: 'VIDEO.REDO'
};

export const AUTH = {
  LOGIN: 'AUTH.LOGIN'
};

export const BRANDKIT = {
  GET_BRANDKIT: 'BRANDKIT.GET_BRANDKIT',
  UPLOAD_FILES: 'BRANDKIT.UPLOAD_FILES',
  UPDATE_BRANDKIT: 'BRANDKIT.UPDATE_BRANDKIT',
  GET_FONT: 'BRANDKIT.GET_FONT',
  GET_TEMPLATE: 'BRANDKIT.GET_TEMPLATE'
};

export const TEMPLATE = {
  MOVE_TEMPLATE: 'TEMPLATE.MOVE_TEMPLATE',
  UPLOAD_THUMBNAIL: 'TEMPLATE.UPLOAD_THUMBNAIL',
  SAVE_TEMPLATE: 'TEMPLATE.SAVE_TEMPLATE',
  EDIT_TEMPLATE: 'TEMPLATE.EDIT_TEMPLATE',
  VIDEO_TYPE: 'TEMPLATE.VIDEO_TYPE'
};

export const SUBTITLE = {
  GET_SUBTITLE: 'SUBTITLE.GET_SUBTITLE',
  UPDATE_SUBTITLE: 'SUBTITLE.UPDATE_SUBTITLE',
  DELETE_SUBTITLE: 'SUBTITLE.DELETE_SUBTITLE'
};
