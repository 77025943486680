import React from 'react';
import styled from '@emotion/styled';
import './styles.scss';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  closeDrawerAnimationBar,
  selectVideo,
  unSelectElement,
  unSelectTextOrImage,
  update
} from '@/features/video/store/videoSlice';
import { Times } from '@/components/SVG';
import { AddingImage, AddingText } from '@/features/video/models/FrameItem';

interface Option {
  label: string;
  value: string;
}

const listAnimations = [
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'Fly in',
    value: 'flyin'
  },
  {
    label: 'Fade in',
    value: 'fadein'
  },
  {
    label: 'Wipe in',
    value: 'wipein'
  },
  {
    label: 'Zoom in',
    value: 'zoomin'
  }
];

interface IAllSubtitlesProps {
  show?: boolean;
}

const AnimationSliderBar: React.FC<IAllSubtitlesProps> = ({ show }) => {
  const { elementSelected, addingTexts, addingImages, isSelectTextElement } = useAppSelector(selectVideo);
  const dispatch = useAppDispatch();

  const [selected, setSelected] = React.useState<number | null>();
  const [optionSelected, setOptionSelected] = React.useState<Option | null>();

  const handleCloseAnimationBar = () => {
    dispatch(closeDrawerAnimationBar());
    dispatch(unSelectElement());
    dispatch(unSelectTextOrImage());
    setOptionSelected(null);
    setSelected(null);
  };

  const handleSelectAnimation = (idx: number, option: Option) => {
    setSelected(idx);
    setOptionSelected(option);
    const animationValue = {
      animation: {
        type: option?.value,
        start: 0,
        duration: 1.5
      }
    };

    const newPayload = {
      ...elementSelected,
      animation: option?.value === 'none' ? {} : animationValue.animation
    };

    const modifyAddingTexts = addingTexts?.map((item: AddingText) => {
      if (item.uid === newPayload.uid) {
        return {
          ...newPayload
        };
      } else {
        return { ...item };
      }
    });

    const modifyAddingImages = addingImages?.map((item: AddingImage) => {
      if (item.uid === newPayload.uid) {
        return {
          ...newPayload
        };
      } else {
        return { ...item };
      }
    });

    isSelectTextElement
      ? dispatch(
          update({
            addingTexts: modifyAddingTexts
          })
        )
      : dispatch(
          update({
            addingImages: modifyAddingImages
          })
        );

    // console.log('changed: ', isSelectTextElement ? modifyAddingTexts : modifyAddingImages);
  };

  const handleApplyForAll = () => {
    const newAllPayloadTexts = [] as AddingText[];
    const newAllPayloadImages = [] as AddingImage[];
    const animationValue = {
      animation: {
        type: optionSelected?.value,
        start: 0,
        duration: 1.5
      }
    };
    // eslint-disable-next-line array-callback-return
    addingTexts.map((item) => {
      const newPayload = {
        ...item,
        animation: optionSelected?.value === 'none' ? {} : animationValue.animation
      };
      newAllPayloadTexts.push(newPayload as AddingText | any);
    });

    // eslint-disable-next-line array-callback-return
    addingImages.map((item) => {
      const newPayload = {
        ...item,
        animation: optionSelected?.value === 'none' ? {} : animationValue.animation
      };
      newAllPayloadImages.push(newPayload as AddingImage | any);
    });

    isSelectTextElement
      ? dispatch(
          update({
            addingTexts: newAllPayloadTexts
          })
        )
      : dispatch(
          update({
            addingImages: newAllPayloadImages
          })
        );
    dispatch(closeDrawerAnimationBar());
    dispatch(unSelectElement());
    dispatch(unSelectTextOrImage());
    setOptionSelected(null);
    setSelected(null);
  };

  return (
    <DrawerAnimationVisible className={show ? 'side-drawer open' : 'side-drawer'}>
      <HeaderBar>
        <TextAnimation>Animation</TextAnimation>
        <Times style={{ cursor: 'pointer', marginTop: '-8px' }} onClick={handleCloseAnimationBar} />
      </HeaderBar>
      <ContainerList>
        {listAnimations.map((item, idx) => (
          <Animation
            key={idx}
            isBg={idx !== 0}
            onClick={() => handleSelectAnimation(idx, item)}
            isSelected={selected === idx}
          >
            <BoxOption>
              <TextOption>{item?.label}</TextOption>
            </BoxOption>
          </Animation>
        ))}
      </ContainerList>
      {false && <ButtonApplyAll onClick={handleApplyForAll}>Apply to all</ButtonApplyAll>}
    </DrawerAnimationVisible>
  );
};

export default AnimationSliderBar;

const DrawerAnimationVisible = styled.div`
  background: #f6f3f4;
`;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

const TextAnimation = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.brand.primary};
`;

const ContainerList = styled.div<{ hasFiles?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow: auto;
  margin-left: 24px;
`;

const Animation = styled.div<{ isSelected?: boolean; isBg?: boolean }>`
  width: 96px;
  height: 96px;
  padding: 24px;
  border-radius: 12px;
  border: ${({ isSelected, theme }) => (isSelected ? `1px solid ${theme.brand.success}` : '1px solid #D9D9D9')};
  background: ${({ isBg, theme }) => !isBg && theme.grey.shade6};
  background-size: cover;
  background-image: ${({ isBg, theme }) => isBg && `url('/assets/video-editor/sample-frame-img.svg')`};
  cursor: pointer;
`;

const BoxOption = styled.div`
  background: rgba(222, 217, 219, 0.37);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 14px;
  height: 24px;
  margin-top: 10px;
  margin-left: -17px;
  width: 80px;
`;

const TextOption = styled.p`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.neutral.white};
`;

const ButtonApplyAll = styled.div`
  bottom: 30px;
  left: 5%;
  width: 90%;
  position: absolute;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.brand.primary};
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.brand.primary};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
