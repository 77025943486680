import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { BackArrow } from '@/components/SVG';
import DropDown from '@/components/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguagesSubtitles } from '@/features/video/store/videoSlice';
import { Language } from '@/features/video/models/VideoFrame';
import FileUpload from '@/components/FileUpload';
import { useSearchParams } from 'react-router-dom';
import useRequest from '@/components/hooks/useRequest';
import { getAllSubtitles, uploadSubtitleV2 } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { getSubtitles } from '@/features/video/store/subtitleSlice';
import Spinner from '@/components/Spinner';

interface IUploadCaptionViewProps {
  onBackToOveral: () => void;
}

const UploadCaption: React.FC<IUploadCaptionViewProps> = ({ onBackToOveral }) => {
  const dispatch = useDispatch();
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const languagesOptions = useSelector(getLanguagesSubtitles);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [fileSelected, setFileSelected] = useState<File[] | null>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onFilesChange = async (fls: File[] | null) => {
    setFileSelected(fls);
  };

  const handleUploadSubtitle = async () => {
    const payload = {
      langName: selectedLanguage?.label,
      langCode: selectedLanguage?.langCode,
      contentId: contentId
    };
    setIsUploading(true);
    const res = await request(uploadSubtitleV2(fileSelected as File[], payload));

    if (res?.data?.status === 200) {
      handleGetSubtitles();
      message.success('Upload file successfully!');
      setIsUploading(false);
    } else {
      message.error('Upload file unsuccessfully!');
      setIsUploading(false);
    }
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  const handleCancelEdit = () => {
    setIsDisabled(true);
    setSelectedLanguage(null);
    setFileSelected(null);
  };

  useEffect(() => {
    if (fileSelected?.length && selectedLanguage) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [fileSelected, selectedLanguage]);

  const onClearFiles = () => {
    setFileSelected(null);
  };

  return (
    <Container>
      <BackSubContainer
        onClick={() => {
          onBackToOveral();
        }}
      >
        <IconBack>
          <BackArrow />
        </IconBack>
        <TextBackToCaptions>Back to Captions</TextBackToCaptions>
      </BackSubContainer>
      <WrapperContent>
        <DropDown
          placeholder="Select language"
          options={languagesOptions}
          label={'Caption Language'}
          customMargin="0px"
          value={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value)}
        />
        <Box />
        <FileUpload
          callBackClearFiles={onClearFiles}
          uploadedFiles={fileSelected as any}
          handleDrop={onFilesChange}
          subLabel="Support only .SRT .VTT files"
          allowTypes={['vtt', 'srt']}
        />
      </WrapperContent>
      <WrapperBtn>
        <CancelBtn onClick={() => handleCancelEdit()}>
          <span>Cancel</span>
        </CancelBtn>
        <SaveBtn
          onClick={() => {
            !isDisabled && handleUploadSubtitle();
          }}
          isDisable={isDisabled}
        >
          {isUploading ? <Spinner width={14} height={14} /> : <span>Save</span>}
        </SaveBtn>
      </WrapperBtn>
    </Container>
  );
};

export default UploadCaption;

const Container = styled.div`
  height: 100%;
`;

const BackSubContainer = styled.div`
  cursor: pointer;
  height: 42px;
  background: ${({ theme }) => theme.grey.shade3};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextBackToCaptions = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 12px;
  margin-left: 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const IconBack = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px 6px;
  cursor: pointer;
  margin-top: 6px;
`;

const Box = styled.div`
  margin-bottom: 12px;
`;

const WrapperContent = styled.div`
  padding: 12px;
`;

const WrapperBtn = styled.div<{ width?: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  width: calc(100% - 24px);
  justify-content: space-between;
  margin-left: 12px;
`;

const CancelBtn = styled.div`
  width: calc(50% - 6px);
  margin-bottom: 12px;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.neutral.white};
  outline: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:hover {
    opacity: 0.7;
  }

  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;

const SaveBtn = styled(CancelBtn)<{ isDisable?: boolean }>`
  background-color: ${({ theme }) => theme.brand.secondary};
  outline: none;
  background-color: ${({ theme, isDisable }) => (isDisable ? '#f5f4f4' : theme.brand.secondary)};
  ${({ isDisable }) => (isDisable ? `opacity: 0.7; cursor: not-allowed;` : '')}
`;
