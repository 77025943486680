import useSocket from './useSocket';
import { useEffect, useRef } from 'react';
import { AddingPlayableItem } from '@/features/video/models/FrameItem';

const useBlurBackgroundThumbnail = () => {
  const { createSocket, socket, disconnect } = useSocket('video');
  const waitingBlueBack = useRef({} as any);
  const sk = useRef<any>(null);

  const getVideoBlurThumbnail = (item: AddingPlayableItem) => {
    return new Promise((resolve) => {
      if (!waitingBlueBack.current[item.uid]) {
        if (!sk.current) sk.current = createSocket();
        if (sk) {
          sk.current.emit('generateBlurImage', {
            event: 'generateBlurImage',
            videoId: item.uid,
            videoLink: item.filePath
          });
          waitingBlueBack.current[item.uid] = [resolve];
        }
      } else {
        waitingBlueBack.current[item.uid].push(resolve);
      }
    });
  };

  useEffect(() => {
    socket?.on('generateBlurImage', (data) => {
      if (data?.videoId) {
        if (waitingBlueBack.current[data.videoId]) {
          const resolvers = waitingBlueBack.current[data.videoId];
          delete waitingBlueBack.current[data.videoId];
          resolvers.forEach((resolve: any) => {
            resolve(data);
          });
        }
      }
    });
  }, [socket]);

  return {
    getVideoBlurThumbnail,
    disconnect
  };
};

export default useBlurBackgroundThumbnail;
