import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { Card, TextWrap, TextFrame, VideoRecorder, ThunderIcon } from '@/components/SVG';
import Typography from '@/components/Typography';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVideo, setCaptionSettings, update } from '@/features/video/store/videoSlice';
import useWindowDimensions from '@/utils/getWindowSize';
import { ObjectType } from '@/features/video/models/FrameItem';
import { useSearchParams } from 'react-router-dom';
import ToolTip from '@/components/ToolTipV2';

interface IAppProps {}

const menu = [
  { icon: ThunderIcon, name: 'Templates' },
  { icon: VideoRecorder, name: 'Media' },
  { icon: TextFrame, name: 'Text Effects & Cards' },
  { icon: TextWrap, name: 'Captions' },
  { icon: Card, name: 'Transitions' }

  // { icon: TemplateIcon, name: 'Template' },
  // { icon: MediaIcon, name: 'Media' },
  // { icon: BrandKitIcon, name: 'Brand Kit' }
];

const SideBarTop: React.FunctionComponent<IAppProps> = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const sideBarRef = useRef<any>(null);
  const { width } = useWindowDimensions();
  const { subtitleStyle } = useSelector(selectVideo);

  useEffect(() => {
    if (sideBarRef.current) {
      const instance = sideBarRef.current.getBoundingClientRect();
      dispatch(update({ sideBarWidth: instance.width }));
    }
  }, [sideBarRef, width, dispatch]);

  const { selectedTab } = useSelector(selectVideo);

  const onSelectTab = (tab: string) => {
    dispatch(update({ selectedTab: tab }));
    dispatch(update({ selectedObj: { uid: '', type: ObjectType.image } }));
    dispatch(update({ selectedCard: { cardId: '', textId: '' } }));
  };

  const [searchParams] = useSearchParams();
  const isTemplateMode = searchParams.get('action') || searchParams.get('size');

  const handleSelectTab = (tabName: string, indexTab?: number) => {
    if (indexTab !== 3 || !isTemplateMode) {
      onSelectTab(tabName);
    }
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <SideBarContainer ref={sideBarRef}>
      {menu.map((item, index) => {
        const Icon = item.icon;
        const isSelected = selectedTab === item.name;
        return (
          <IconContainer
            key={index}
            onClick={() => {
              handleSelectTab(item.name, index);
              handleClearCaptionSetting();
            }}
            style={{
              cursor: `${index === 3 && isTemplateMode && 'not-allowed'}`,
              opacity: `${index === 3 && isTemplateMode && 0.5}`
            }}
          >
            <ToolTip
              arrow
              position="top"
              widthTooltip={95}
              maxWidth="171px"
              background="#1B1C1E"
              content={'Caption is not available in template mode.'}
              checkConstantly={1}
              disabled={index !== 3 || !isTemplateMode}
            >
              <Icon
                width={24}
                height={24}
                fillbg={isSelected ? theme.brand.mint : theme.grey.shade2}
                fill={isSelected ? 'white' : '#070550'}
              />
              <Typography
                variant="small"
                color={'shade7'}
                style={{
                  fontWeight: 600,
                  width: 'auto',
                  textAlign: 'center',
                  margin: '7px 0 0 5px',
                  fontSize: '12px'
                }}
              >
                {item.name}
              </Typography>
            </ToolTip>
          </IconContainer>
        );
      })}
    </SideBarContainer>
  );
};

export default SideBarTop;

const SideBarContainer = styled.div`
  display: flex;
  padding: 0 60px;
  align-items: center;
  gap: 32px;
  flex-basic: 80px;
  flex-shrink: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 20px 8px;
  }
  height: 44px;
  margin: 10px 0;
  background: ${({ theme }) => theme.neutral.white};
  justify-content: space-around;
  border-radius: 4px;
  outline: ${({ theme }) => `0.5px solid ${theme.altGrey.shade5}`};
`;

const IconContainer = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  justify-content: space-around;
`;
