/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Language, SubLine, SubtitleDetail } from '@/features/video/models/VideoFrame';
import { useDispatch } from 'react-redux';
import useRequest from '@/components/hooks/useRequest';
import { downloadSubtitleFile, getAllSubtitles } from '@/features/video/store/videoAPI';
import { MagicWand, VerticalThreeDots, Link } from '@/components/SVG';
import { Popover, message, Tooltip } from 'antd';
import { useAppSelector } from '@/app/hooks';
import DeleteFileNameSubtitleModal from '../../../../subtitles-tab/components/setup-subtitles/DeleteSubtitleFileModal';
import UnlinkSubtitleModal from '../../../../subtitles-tab/components/setup-subtitles/UnlinkSubtitleModal';
import LinkSubtitleModal from '../../../../subtitles-tab/components/setup-subtitles/LinkSubtitleModal';
import WarningDeleteSubModal from '../../../../subtitles-tab/components/setup-subtitles/WarningDeleteSubModal';
import {
  getSubtitles,
  selectSubtitle as getSubtitlesByUserId,
  selectSubtitleDetail
} from '@/features/video/store/subtitleSlice';
import { useSearchParams } from 'react-router-dom';
import { selectVideo, setTabEditCaption, setCaptionViewMode } from '@/features/video/store/videoSlice';
import ToggleSettings from '@/components/ToggleSettings';
import ArrowFrontlineUp from '@/components/SVG/ArrowFrontlineUp';
import Spinner from '@/components/Spinner';

export enum CaptionView {
  overal = 'OVERAL',
  uploadCaption = 'UPLOAD',
  generateCaption = 'GENERATE',
  editCaption = 'EDIT'
}

interface IAllCaptionViewProps {
  isOpen?: boolean;
  onUploadSuccess?: (id: string, subtitles: SubLine[], language: Language) => void;
}

const AllCaptions: React.FC<IAllCaptionViewProps> = () => {
  const dispatch = useDispatch();
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const { subtitles } = useAppSelector(getSubtitlesByUserId);
  const [isOpenDeleteFileName, setIsOpenDeleteFileName] = useState<boolean>(false);
  const [isOpenUnlinkSub, setIsOpenUnlinkSub] = useState<boolean>(false);
  const [isOpenLinkSubtitle, setIsOpenLinkSubtitle] = useState<boolean>(false);
  const [isOpenWarningDeleteSub, setIsOpenWarningDeleteSub] = useState<boolean>(false);
  const [subtitleSelected, setSubtitleSelected] = useState<SubtitleDetail>();
  const { addingPlayableItems } = useAppSelector(selectVideo);
  const [isShareTeam, setIsShareTeam] = useState<boolean>(false);
  const [isDownloadVTT, setIsDownloadVTT] = useState<boolean>(false);
  const [isDownloadSRT, setIsDownloadSRT] = useState<boolean>(false);

  const getVideoNameByCaption = (videoLink: string) => {
    const videoFind = addingPlayableItems.find((x: any) => x.filePath === videoLink);

    return videoFind?.name;
  };

  // const _getSubtitles = React.useCallback(() => {
  //   console.log('subtitles: ', subtitles);

  //   const obj: any = {};
  //   subtitles.forEach((item: any) => {
  //     obj[item.name] = item;
  //   });

  //   console.log('ok: ', Object.keys(obj).map((key) => obj[key]));

  //   return Object.keys(obj).map((key) => obj[key]);
  // }, [subtitles]);

  const selectSubtitle = (sub: SubtitleDetail) => {
    dispatch(selectSubtitleDetail(sub));
  };

  const onClickCloseDeleteFileName = () => {
    setIsOpenDeleteFileName(false);
  };

  const onClickCloseLinkSubtitle = () => {
    setIsOpenLinkSubtitle(false);
  };

  const onClickCloseUnLinkSubtitle = () => {
    setIsOpenUnlinkSub(false);
  };

  const onClickCloseWarningDeleteSub = () => {
    setIsOpenWarningDeleteSub(false);
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  const handleShowDeleteSubtitle = (item: any) => {
    if (item?.file) {
      setIsOpenWarningDeleteSub(true);
    } else {
      setIsOpenDeleteFileName(true);
    }
  };

  const downloadEmployeeData = (urlDownload: string, type: string, name?: string) => {
    fetch(urlDownload).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${name}.${type}`;
        a.click();
      });
    });
  };

  const handleDownloadCaption = async (val: string) => {
    if (val === 'vtt') {
      setIsDownloadVTT(true);
    } else {
      setIsDownloadSRT(true);
    }
    const bodyReq = {
      subtitles: subtitleSelected?.transcribe,
      format: val
    };

    const downloadFile = await request(downloadSubtitleFile(bodyReq));
    if (downloadFile?.data?.status === 200) {
      await downloadEmployeeData(downloadFile?.data?.data?.Location, val, subtitleSelected?.name);
      message.success('Downloaded subtitle successfully!');
      setIsDownloadSRT(false);
      setIsDownloadVTT(false);
    } else {
      message.error('Download subtitle unsuccessfully!');
      setIsDownloadSRT(false);
      setIsDownloadVTT(false);
    }
  };

  const onSelectEditMode = () => {
    dispatch(setTabEditCaption(0));
    dispatch(setCaptionViewMode(CaptionView.editCaption))
  };

  const onSelectTranslateMode = () => {
    dispatch(setTabEditCaption(2));
    dispatch(setCaptionViewMode(CaptionView.editCaption))
  };

  const onLinkCaption = () => {
    setIsOpenLinkSubtitle(true);
  };

  const onUnlinkCaption = () => {
    setIsOpenUnlinkSub(true);
  };

  return (
    <Container>
      <WrapperCap>
        {subtitles?.map((item: any, idx: number) => (
          <SubWrapper
            key={idx}
            onClick={() => {
              selectSubtitle(item);
              onSelectEditMode();
            }}
          >
            <SubtitleFile onClick={() => selectSubtitle(item)} key={item?.id}>
              <DFlex>
                <TypeCap>
                  <TextTypeCap>{item?.langName}</TextTypeCap>
                </TypeCap>
                <TextFile>{item?.name ? item?.name : 'Unknown'}</TextFile>
              </DFlex>
              <ListIcons>
                {item?.file ? (
                  <Tooltip title="Linked">
                    <BoxIcon
                      // style={{ cursor: 'pointer' }}
                      isLinked={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubtitleSelected(item);
                        onUnlinkCaption();
                      }}
                    >
                      <Link width={14} height={14} />
                    </BoxIcon>
                  </Tooltip>
                ) : (
                  <BoxIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setSubtitleSelected(item);
                      onLinkCaption();
                    }}
                  >
                    <Link width={14} height={14} />
                  </BoxIcon>
                )}
                <Popover
                  content={actionContent(
                    handleShowDeleteSubtitle,
                    handleDownloadCaption,
                    onSelectEditMode,
                    onSelectTranslateMode,
                    onLinkCaption,
                    onUnlinkCaption,
                    isDownloadVTT,
                    isDownloadSRT,
                    item?.file ? true : false
                  )}
                  overlayInnerStyle={{ borderRadius: '8px' }}
                  trigger="click"
                  placement="bottom"
                >
                  <div
                    className="box-action"
                    style={{ marginLeft: '3px', marginTop: '2px', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSubtitleSelected(item);
                      selectSubtitle(item);
                    }}
                  >
                    <VerticalThreeDots fill="gray" width={20} height={20} />
                  </div>
                </Popover>
              </ListIcons>
            </SubtitleFile>
          </SubWrapper>
        ))}
        {!subtitles?.length && <EmtpyCap>Your video doesn’t have any caption yet.</EmtpyCap>}
      </WrapperCap>
      <WrapperBtn>
        <UploadBtn onClick={() => {
          dispatch(setCaptionViewMode(CaptionView.uploadCaption))
        }
          }>
          <span>Upload Caption</span> <ArrowFrontlineUp width={15} height={15} />
        </UploadBtn>
        <GenerateBtn onClick={() => {
          dispatch(setCaptionViewMode(CaptionView.generateCaption))
        }}>
          <span>Auto Generate Caption</span> <MagicWand width={15} height={15} />
        </GenerateBtn>
      </WrapperBtn>
      <>
        <DeleteFileNameSubtitleModal
          isOpen={isOpenDeleteFileName}
          onClickClose={onClickCloseDeleteFileName}
          subtitleDetail={subtitleSelected}
          refetchListSubtitles={handleGetSubtitles}
        />
        <UnlinkSubtitleModal
          isOpen={isOpenUnlinkSub}
          onClickClose={onClickCloseUnLinkSubtitle}
          subtitleDetail={subtitleSelected}
          refetchListSubtitles={handleGetSubtitles}
        />
        <LinkSubtitleModal
          isOpen={isOpenLinkSubtitle}
          onClickClose={onClickCloseLinkSubtitle}
          subtitleDetail={subtitleSelected}
          refetchListSubtitles={handleGetSubtitles}
        />
        <WarningDeleteSubModal isOpen={isOpenWarningDeleteSub} onClickClose={onClickCloseWarningDeleteSub} />
      </>
    </Container>
  );
};

export default AllCaptions;

const actionContent = (
  handleShowDeleteSubtitle?: any,
  handleDownloadCaption?: any,
  onSelectEditMode?: any,
  onSelectTranslateMode?: any,
  onLinkCaption?: any,
  onUnlinkCaption?: any,
  isDownloadVTT?: boolean,
  isDownloadSRT?: boolean,
  linked?: boolean
) => {
  const fileTypeRender = (
    <ActionContentWrapper>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          handleDownloadCaption('vtt');
        }}
      >
        {isDownloadVTT ? <Spinner width={14} height={14} /> : '.VTT'}
      </div>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          handleDownloadCaption('srt');
        }}
      >
        {isDownloadSRT ? <Spinner width={14} height={14} /> : '.SRT'}
      </div>
    </ActionContentWrapper>
  );
  return (
    <ActionContentWrapper>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          linked ? onUnlinkCaption() : onLinkCaption();
        }}
      >
        {linked ? 'Unlink Video' : 'Link Video'}
      </div>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          onSelectEditMode();
        }}
      >
        Edit
      </div>
      <Popover placement="right" overlayInnerStyle={{ borderRadius: '8px' }} content={fileTypeRender}>
        <div
          className="action-item"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Download
        </div>
      </Popover>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          onSelectTranslateMode();
        }}
      >
        Translate
      </div>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          handleShowDeleteSubtitle();
        }}
      >
        Delete
      </div>
    </ActionContentWrapper>
  );
};

const Container = styled.div`
  height: 100%;
`;

const WrapperCap = styled.div`
  height: calc(100% - 155px);
  overflow: auto;
`;

const SubWrapper = styled.div`
  width: auto;
  background: ${({ theme }) => theme.grey.shade3};
  border-radius: 12px;
  margin: 12px 12px 0 12px;
  padding-bottom: 6px;
  padding-left: 12px;
  cursor: pointer;

  &:hover {
    .btn-remove {
      display: flex;
    }
  }
`;

const SubtitleFile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;'
`;

const TextFile = styled.p`
  font-weight: 600;
  font-size: 14px;
  // text-transform: uppercase;
  color: ${({ theme }) => theme.brand.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
`;

const ListIcons = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 12px;
`;

const Box = styled.div`
  margin-bottom: 12px;
`;

const TypeCap = styled.div`
  padding: 0 3px;
  border: ${({ theme }) => `1px solid ${theme.grey.shade7}`};
  width: fit-content;
  margin-right: 6px;
  border-radius: 6px;
  height: 19px;
  margin-top: 2px;
`;

const TextTypeCap = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.grey.shade7};
  margin-bottom: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
`;

const DFlex = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: -10px;
  width: calc(100% - 100px);
`;

export const ActionContentWrapper = styled.div`
  .action-item {
    padding: 5px 10px;
    &:hover {
      background: ${({ theme }) => theme.altGrey.shade4};
      cursor: pointer;
      border-radius: 2px;
    }
  }
`;

const EmtpyCap = styled.p<{ width?: number }>`
  position: absolute;
  bottom: 52%;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.grey.shade7};
`;

const WrapperBtn = styled.div<{ width?: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const UploadBtn = styled.div`
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-bottom: 12px;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: #f5f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  &:hover {
    opacity: 0.7;
  }
  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;

const GenerateBtn = styled(UploadBtn)`
  background-color: ${({ theme }) => theme.brand.secondary};
`;

const BoxIcon = styled.div<{ isLinked?: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-top: 3px;
  background-color: ${({ theme, isLinked }) => (isLinked ? theme.brand.secondary : theme.altGrey.noshade)};
  cursor: pointer;
`;
