/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, Slider } from 'antd';
import {
  selectVideo,
  setShowCaption,
  splitPlayableItem,
  update,
  updatePlayableItem
} from '@/features/video/store/videoSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import './VideoFrame.scss';
import { CcOffIcon, CcOnIcon, CropFrame, Minus, PauseIcon, SplitIcon } from '@/components/SVG';
import { VideoService } from '@/features/video/services/videoService';
import { selectSubtitle as getSubtitlesByUserId, setSubtitleViewing } from '@/features/video/store/subtitleSlice';
import { plusSecond } from '@/utils/moveSecond';
import { v4 as uuidv4 } from 'uuid';
import {
  Container,
  ListCcs,
  MuteVolume,
  OptionCc,
  PlayControl,
  PlayControllerSection,
  PopoverCc,
  SeekBack,
  SeekForward,
  SplitWrapper,
  TimeInfo,
  VerticalLine,
  Volume,
  VolumeControlSection,
  ZoomIcon
} from './styles';
import Typography from '@/components/Typography';
import RatioOptions from '../ratio-option';
import { AddingPlayableItem, PlayableType } from '@/features/video/models/FrameItem';
import { RatioResolution } from '@/features/video/constant/resolution';
import { IResolution } from '@/components/hooks/useDraft';
import { FHDScreen } from '@/constants/screens';
import Add from '@/components/SVG/Add';

interface Option {
  label: string;
  value: [];
}

const DEFAULT_VOLUME = 0.5;
const DEFAULT_SEEK_DURATION = 15;
const cropRatioCenter = {
  ratio11: {
    x: 420,
    width: '1080px'
  },
  ratio916: {
    x: 656,
    width: '608px'
  }
};
interface Props {
  audioWrapperRef: React.RefObject<HTMLDivElement>;
  playerRef: React.MutableRefObject<any>;
  playerWrapperRef: React.RefObject<HTMLDivElement>;
  playerSizeHD: IResolution;
  getRatioWidthHeight: any;
  setPlayerSizeHD: React.Dispatch<React.SetStateAction<IResolution>>;
  videoSelectedId: string;
}

const VideoControlBar: React.FC<Props> = (props) => {
  const { audioWrapperRef, playerRef, playerWrapperRef, getRatioWidthHeight, setPlayerSizeHD, videoSelectedId } = props;

  const {
    volume,
    nextAspectRatio,
    totalPlayed,
    manualPause,
    totalDuration,
    addingPlayableItems,
    timeLineSelected,
    addingImages,
    addingTexts,
    addingCards
  } = useAppSelector(selectVideo);
  const [openRatio, setOpenRatio] = useState(false);
  const [ccSelected, setCcSelected] = React.useState<number | null>();
  const { subtitleViewing } = useAppSelector(getSubtitlesByUserId);
  const [listLanguagesForCc, setListLanguagesForCc] = React.useState<any>([]);
  const { subtitles: subtitlesByStudio } = useAppSelector(getSubtitlesByUserId);
  const zoom = useRef<number>(1);

  const dispatch = useAppDispatch();

  const handleViewCcOnStudio = async (subtitleVersion: any[], id?: number) => {
    const subtitleSelectedForView = getSelectedProperties(subtitleVersion);
    // save the subtitle for cc to store
    handleSetCcToStore(subtitleSelectedForView);
    setCcSelected(id);
    dispatch(setShowCaption(true));
  };

  const videoList = useMemo(() => {
    return addingPlayableItems.filter((item) => item.type === PlayableType.video && !item.isHide);
  }, [addingPlayableItems]);

  const handleSetCcToStore = (subtitleSelectedForView: any) => {
    const listVdSortByStart = videoList.sort((a: any, b: any) => a.start - b.start);
    const payloadSubtitleUpdate = listVdSortByStart
      ?.filter((vd) => vd.idSubtitleLinked)
      .map((vd: any) => {
        const matchingSub = subtitleSelectedForView.find((sub: any) => vd.filePath === sub.file);

        const newTranscribe = matchingSub?.transcribe?.map((scribe: any) => {
          let newStart = plusSecond(scribe?.start, vd?.start as number);
          let newEnd = plusSecond(scribe?.end, vd?.start as number);

          return {
            ...scribe,
            start: newStart,
            end: newEnd
          };
        });

        return {
          ...matchingSub,
          trim: vd?.trim,
          start: vd?.start,
          transcribe: newTranscribe
        };
      });

    dispatch(setSubtitleViewing(payloadSubtitleUpdate));
  };

  // get fields id, file, transcribe
  const getSelectedProperties = (subtitleVersion: any[]) => {
    return subtitleVersion.map((obj: any) => {
      const { file, id, transcribe } = obj;
      return { file, id, transcribe };
    });
  };

  // set addingPlayableItems to default (reset zoom and position)
  const resetVideos = () => {
    const defaultCrop = {
      width: FHDScreen.width,
      height: FHDScreen.height,
      x: 0,
      y: 0
    };
    const resetListVideo = addingPlayableItems?.map((item) => {
      // just set default for videos
      if (item?.type === PlayableType.video) {
        return {
          ...item,
          zoom: 1,
          crop: defaultCrop
        };
      } else {
        // this case is AUDIO
        return item;
      }
    });
    return resetListVideo;
  };

  const onSelectedRatio = (ratio: keyof typeof RatioResolution) => {
    if (playerWrapperRef.current !== null) {
      const resolution = RatioResolution['16:9'] || '1920x1080';
      dispatch(update({ nextAspectRatio: '16:9', resolution }));
      switch (ratio) {
        case '9:16':
          dispatch(
            update({
              nextAspectRatio: ratio,
              crop: {
                expectRatio: ratio,
                // calculate the position crop for real view in UI
                x: cropRatioCenter.ratio916.x,
                y: 0, // always 0
                width: cropRatioCenter.ratio916.width,
                height: FHDScreen.height
              },
              addingPlayableItems: resetVideos()
            })
          );
          break;
        case '16:9':
          dispatch(
            update({
              nextAspectRatio: '16:9',
              crop: {
                expectRatio: '16:9',
                x: 0,
                y: 0,
                width: FHDScreen.width,
                height: FHDScreen.height
              },
              addingPlayableItems: resetVideos()
            })
          );
          break;
        default:
          dispatch(
            update({
              nextAspectRatio: ratio,
              crop: {
                expectRatio: ratio,
                // calculate the position crop for real view in UI
                x: cropRatioCenter.ratio11.x,
                y: 0,
                width: FHDScreen.height,
                height: FHDScreen.height
              },
              addingPlayableItems: resetVideos()
            })
          );
          break;
      }
    }
    setOpenRatio(false);
  };

  const onPlay = () => {
    dispatch(update({ waitingForLoadVideo: false }));
    if (manualPause) {
      dispatch(update({ manualPause: false }));
    } else {
      dispatch(update({ manualPause: true }));
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenRatio(newOpen);
    const nextPlayerSizeHD = getRatioWidthHeight('16:9');
    setPlayerSizeHD(nextPlayerSizeHD as IResolution);
  };

  const replay = (value: number) => {
    const newValue = totalPlayed - value;
    dispatch(update({ totalPlayed: newValue < 0 ? 0 : totalPlayed - value }));
  };

  const forward = (value: number) => {
    dispatch(update({ totalPlayed: totalPlayed + value }));
  };

  const handleSoundChange = (value: number) => {
    addingPlayableItems
      .filter((x) => !x.isHide)
      .forEach((item) => {
        const audioControl = audioWrapperRef?.current?.querySelector(`[id='${item.uid}']`) as HTMLAudioElement;
        if (item.type === PlayableType.audio && audioControl) {
          audioControl.volume = (item.volume / 100) * value;
        } else {
          const refElm = playerRef.current.get(item.uid);
          refElm.volume = (item.volume / 100) * value;
        }
      });
    dispatch(update({ volume: value }));
  };

  // HERE
  const onZoomInOut = (val: number) => {
    const video = addingPlayableItems.find((item) => item.uid === videoSelectedId);
    if (video && video.zoom) {
      const size = video.zoom + val;
      if (size < 1 || size > 3) {
        return;
      }
      zoom.current = size;
      const crop = { ...video.crop };

      if (size === 1) {
        crop.x = 0;
        crop.y = 0;
      }

      const videoUpdated = {
        ...video,
        zoom: size,
        crop: crop
      };

      dispatch(updatePlayableItem(videoUpdated));
    }
  };

  // get list cc
  useEffect(() => {
    groupListSubtitles();
  }, [subtitlesByStudio]);

  const groupListSubtitles = async () => {
    const listSubtitlesLinked = subtitlesByStudio?.filter((sub: any) => sub.file !== '');
    const groupedObj = listSubtitlesLinked?.reduce((acc: any, item: any) => {
      if (!acc[item.langName]) {
        acc[item.langName] = {
          label: item.langName,
          value: [item]
        };
      } else {
        acc[item.langName].value.push(item);
      }
      return acc;
    }, {});

    setListLanguagesForCc(Object.values(groupedObj));
  };

  const onSplitTimeline = () => {
    // check whether current timeline is selected
    if (timeLineSelected?.uid) {
      const timeLineItem = getTimelineItem() as any;
      // check whether current timeline on play
      if (timeLineItem && totalPlayed > timeLineItem?.start && totalPlayed < timeLineItem?.end) {
        if (timeLineSelected.type === PlayableType.video || timeLineSelected.type === PlayableType.audio) {
          handleSlipPlayableItem(timeLineItem);
          return;
        }

        // if (timeLineSelected.type === ObjectType.text) {
        //   const timeLineItem = addingTexts.find((item) => item.uid === timeLineSelected.uid);
        //   const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingText);
        //   dispatch(splitTextItem({ newItem, originalItem }));
        //   return;
        // }

        // if (timeLineSelected.type === ObjectType.image) {
        //   const timeLineItem = addingImages.find((item) => item.uid === timeLineSelected.uid);
        //   const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingImage);
        //   dispatch(splitImageItem({ newItem, originalItem }));
        //   return;
        // }

        // if (timeLineSelected.type === ObjectType.card) {
        //   const timeLineItem = addingCards.find((item) => item.uid === timeLineSelected.uid);
        //   const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingCard);
        //   dispatch(splitCardItem({ newItem, originalItem }));
        // }
      }
    }
  };

  const handleSlipPlayableItem = (timeLineSelected: AddingPlayableItem) => {
    // deep clone
    let originalItem = JSON.parse(JSON.stringify(timeLineSelected));
    // handle current Item
    if (timeLineSelected.end) {
      originalItem.trim.end = timeLineSelected.trim.end - (timeLineSelected.end - totalPlayed);
    }
    originalItem.end = totalPlayed;
    //handle new Item
    const newItem = JSON.parse(JSON.stringify(timeLineSelected));
    newItem.uid = uuidv4();
    newItem.start = totalPlayed;
    newItem.main = false;
    if (timeLineSelected.end) {
      newItem.trim.start = timeLineSelected.trim.end - (timeLineSelected.end - totalPlayed);
    }
    // if (newItem.end - newItem.start < MIN_GAP_DURATION || originalItem.end - originalItem.start < MIN_GAP_DURATION) {
    //   return;
    // }
    dispatch(splitPlayableItem({ newItem, originalItem }));
    // remove item selected
    dispatch(update({ timeLineSelected: { uid: '' } }));
  };

  const getTimelineItem = () => {
    const mergeItemsTimeline = [
      ...addingImages,
      ...addingPlayableItems.filter((x) => !x.isHide),
      ...addingTexts,
      ...addingCards
    ];
    return mergeItemsTimeline.find((elm) => elm.uid === timeLineSelected.uid);
  };

  useEffect(() => {
    if (listLanguagesForCc?.length === 0) {
      dispatch(setShowCaption(true));
    }
  }, [listLanguagesForCc]);

  return (
    <Container>
      <div style={{ display: 'flex', height: '30px', gap: '10px', alignItems: 'center' }}>
        <Popover
          title=""
          trigger={'click'}
          open={openRatio}
          onOpenChange={handleOpenChange}
          content={<RatioOptions value={nextAspectRatio ? nextAspectRatio : '16:9'} onChange={onSelectedRatio} />}
        >
          <CropFrame />
        </Popover>
        <TimeInfo>
          <Typography variant="label" color="shade7" style={{ lineHeight: '25px' }}>
            {VideoService.convertDurationToHHMMSS(Math.round(totalPlayed) || 0)}
          </Typography>
          <Typography variant="label" color="shade6" style={{ lineHeight: '25px' }}>
            /{VideoService.convertDurationToHHMMSS(totalDuration > 0 ? Math.round(totalDuration) : 0)}
          </Typography>
        </TimeInfo>
        <ZoomIcon>
          <Minus
            style={{ cursor: `${zoom.current <= 1 ? 'not-allowed' : 'zoom-out'}` }}
            onClick={() => onZoomInOut(-0.2)}
          />
          <VerticalLine />
          <Add
            style={{ cursor: `${zoom.current >= 3 ? 'not-allowed' : 'zoom-in'}` }}
            fill="#030220"
            onClick={() => onZoomInOut(0.2)}
          />
        </ZoomIcon>
        {timeLineSelected.type === PlayableType.video && (
          <SplitWrapper id="split-timeline-02de3" onMouseDown={onSplitTimeline}>
            <SplitIcon />
            <Typography variant="caption" weight="600" style={{ marginLeft: '4px' }}>
              Split
            </Typography>
          </SplitWrapper>
        )}
      </div>
      <PlayControllerSection>
        <SeekBack onClick={() => replay(DEFAULT_SEEK_DURATION)} />
        {manualPause ? <PlayControl onClick={onPlay} /> : <PauseIcon width={38} height={38} onClick={onPlay} />}
        <SeekForward onClick={() => forward(DEFAULT_SEEK_DURATION)} />
      </PlayControllerSection>
      <VolumeControlSection>
        {volume === 0 ? (
          <MuteVolume
            onClick={() => {
              handleSoundChange(DEFAULT_VOLUME);
            }}
          />
        ) : (
          <Volume
            onClick={() => {
              handleSoundChange(0);
            }}
          />
        )}
        <Slider
          className="sound-slider"
          min={0}
          max={1}
          step={0.01}
          value={volume}
          onChange={handleSoundChange}
          //volume from 0 -> 1
          tooltip={{ formatter: (value) => parseInt(((value || 0) * 100).toString()) }}
        />
        {!!listLanguagesForCc?.length && (
          <PopoverCc>
            <Popover
              showArrow={false}
              trigger="click"
              overlayInnerStyle={{ borderRadius: '4px', marginBottom: '-14px' }}
              content={
                <ListCcs>
                  <OptionCc
                    onClick={() => {
                      dispatch(setSubtitleViewing([]));
                      setCcSelected(null);
                      dispatch(setShowCaption(false));
                    }}
                  >
                    {listLanguagesForCc?.length ? 'Off' : 'None'}
                  </OptionCc>
                  {listLanguagesForCc?.map((item: Option, idx: number) => (
                    <OptionCc
                      selected={idx === ccSelected}
                      key={idx}
                      onClick={() => handleViewCcOnStudio(item?.value, idx)}
                    >
                      {item?.label}
                    </OptionCc>
                  ))}
                </ListCcs>
              }
            >
              {subtitleViewing?.length ? <CcOnIcon /> : <CcOffIcon />}
            </Popover>
          </PopoverCc>
        )}
      </VolumeControlSection>
    </Container>
  );
};

export default VideoControlBar;
