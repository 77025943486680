import styled from '@emotion/styled';

export const Container = styled.div``;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 4px 0;

  p {
    margin-left: 8px;
    margin-top: 2px;
  }
`;
