import React, { useState, useEffect } from 'react';
import CaptionsStyles from './Features/CaptionsStyles';
import CaptionsSettings from './Features/CaptionsSettings';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { selectVideo, setCaptionSettings, setCaptionViewMode } from '@/features/video/store/videoSlice';
import { useSelector } from 'react-redux';
import UploadCaption from './Features/CaptionsSettings/UploadCaption';
import GenerateCaption from './Features/CaptionsSettings/GenerateCaption';
import EditCaption from './Features/CaptionsSettings/EditCaption';

const enum CaptionView {
  overal = 'OVERAL',
  uploadCaption = 'UPLOAD',
  generateCaption = 'GENERATE',
  editCaption = 'EDIT'
}

interface IFeaturesTab {}

const FeaturesTab: React.FC<IFeaturesTab> = () => {
  const [activeKey, setActiveKey] = useState<number>(0);
  const dispatch = useDispatch();
  const { subtitleStyle, captionViewMode } = useSelector(selectVideo);

  const TabsConf = [
    {
      name: 'All Captions',
      comp: <CaptionsSettings />
    },
    {
      name: 'Captions Styles',
      comp: <CaptionsStyles />
    }
  ];

  const onBackToOveral = () => {
    dispatch(setCaptionViewMode(CaptionView.overal))
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  useEffect(() => {
    if(captionViewMode === CaptionView.overal) {
      setActiveKey(0)
    }
  }, [captionViewMode])

  return (
    <>
      {captionViewMode === CaptionView.overal && (
        <>
          <RulerContainer>
            {TabsConf.map((item, idx) => (
              <TabItem
                key={idx}
                isSelected={activeKey === idx}
                onClick={() => {
                  setActiveKey(idx);
                  handleClearCaptionSetting();
                }}
              >
                <div>{item.name}</div>
              </TabItem>
            ))}
          </RulerContainer>
          {TabsConf.map((item, idx) => idx === activeKey && <>{item.comp}</>)}
        </>
      )}
      {captionViewMode === CaptionView.uploadCaption && <UploadCaption onBackToOveral={onBackToOveral} />}
      {captionViewMode === CaptionView.generateCaption && <GenerateCaption onBackToOveral={onBackToOveral} />}
      {captionViewMode === CaptionView.editCaption && <EditCaption onBackToOveral={onBackToOveral} />}
    </>
  );
};

export default FeaturesTab;

const RulerContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

const TabItem = styled.div<{ isSelected?: boolean }>`
  width: calc(100% / 2);
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `${theme.brand.secondary} solid 2px` : `${theme.grey.shade3} solid 2px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.altGrey.shade8};
  }
`;

/*
const Number = styled.div`
  height: 20px;
  width: auto;
  min-width: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.altGrey.shade4};
  text-align: center;
  font-size: 10px;
  margin-left: 5px;
  padding-top: 3px;
`;
*/
