
import styled from '@emotion/styled';
import Typography from '../Typography';

export const Container = styled.div<{
  hoverColor?: string;
  disabled?: boolean;
}>`
  display: flex;
  position: relative;

  ${({ hoverColor }) => {
    if (hoverColor !== 'none') {
      return `svg:hover {
        fill: ${hoverColor}};
        path {
          fill: ${hoverColor}};
        }
      }`;
    }
  }}
`;

export const Content = styled(Typography)`
  height: 100%;
  flex-wrap: wrap;
  font-weight: 400;
  line-height: 14px;
`;

export const ToolTipArrow = styled.div<{
  direction: {
    horizontal: string;
    vertical: string;
    right?: number;
    left?: number;
    top?: number;
    bottom?: number;
  };
  maxWidth?: string;
  maxHeight?: string;
  background?: string;
  textColor?: string;
  width: number;
}>`
  position: fixed;
  background-color: ${({ theme, background }) =>
    background || theme.altGrey.shade9};
  color: ${({ theme, textColor }) => textColor || theme.neutral.white};
  border-radius: 6px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  filter: drop-shadow(0px 6px 16px rgba(41, 43, 50, 0.12));
  ${({ direction }) => {
    if (direction) {
      return `
      left: ${direction.left};
      right: ${direction.right};
      bottom: ${direction.bottom};
      top: ${direction.top};
      `;
    }
  }};

  max-width: ${({ maxWidth }) => maxWidth ?? '380px'};
  max-height: ${({ maxHeight }) => maxHeight ?? '300px'};
  min-width: ${({ width }) => width + 'px'};
  padding: 8px 6px;
  flex-wrap: wrap;
  z-index: 111;
  &::after {
    content: '';
    height: 16px;
    width: 16px;
    background-color: ${({ theme, background }) =>
      background || theme.altGrey.shade9};
    border-radius: 2px;
    position: absolute;

    ${({ direction }) => {
      if (direction.vertical === 'top') {
        return `
        bottom: -12px; 
        `;
      } else {
        return `
        top: 4px; 
        `;
      }
    }};
    ${({ direction }) => {
      if (direction.horizontal === 'right') {
        return `
        right: 18px;
        `;
      } else {
        return `
        left: calc(50% - 8px);;
        `;
      }
    }};
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    z-index: -1;
  }
  @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

`;
