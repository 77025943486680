import React, { useEffect, useState } from 'react';

import { ToggleSettingsProps } from './ToggleSettings.props';
import { Container, Heading, Description, ContentContainer, ToggleBox, MovingBox } from './ToggleSettings.style';
import { IconCheck } from '../SVG';

const ToggleSettings = (props: ToggleSettingsProps): JSX.Element => {
  const { heading, description, flag = false, color, toggleClicked, className } = props;
  const [value, setValue] = useState(flag);
  const toggled = () => {
    setValue(value ? false : true);
    toggleClicked(value ? false : true);
  };

  useEffect(() => {
    setValue(flag);
  }, [flag]);

  return (
    <Container className={className}>
      <ContentContainer>
        <Heading variant="label" color="primary">
          {heading}
        </Heading>
        {description && (
          <Description variant="label" color="primary">
            {description}
          </Description>
        )}
      </ContentContainer>
      <ToggleBox
        on={value}
        color={color}
        onClick={(e: any) => {
          e.stopPropagation();
          toggled();
        }}
      >
        <MovingBox>
          <IconCheck width={11} height={9}></IconCheck>
        </MovingBox>
      </ToggleBox>
    </Container>
  );
};

export default React.memo(ToggleSettings);
