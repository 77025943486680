import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoState } from './videoSlice';

// Define the initial state using that type
const initialState: any = {
  templates: [],
  companyTemplates: [],
  globalTemplates: [],
  listTagsUser: [],
  listTagsCompany: [],
  listVideoTypes: [],
  templateById: {}
};
export const templateSlice = createSlice({
  name: 'template',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    getTemplate: (state, action: PayloadAction<Partial<VideoState>>) => {
      const data = action.payload as any[];
      return {
        ...state,
        templates: data
      };
    },
    getCompanyTemplate: (state, action: PayloadAction<Partial<VideoState>>) => {
      const data = action.payload as any[];
      return {
        ...state,
        companyTemplates: data
      };
    },
    getGlobalTemplate: (state, action: PayloadAction<Partial<VideoState>>) => {
      const data = action.payload as any[];
      return {
        ...state,
        globalTemplates: data
      };
    },
    templateById: (state, action: PayloadAction<Partial<VideoState>>) => {
      const data = action.payload as any;
      return {
        ...state,
        templateById: data
      };
    },
    getListTagsUser: (state, action: PayloadAction<Partial<object>>) => {
      const data = action.payload as any;
      return {
        ...state,
        listTagsUser: data
      };
    },
    getListTagsCompany: (state, action: PayloadAction<Partial<object>>) => {
      const data = action.payload as any;
      return {
        ...state,
        listTagsCompany: data
      };
    },
    getListVideoTypes: (state, action: PayloadAction<Partial<object>>) => {
      const data = action.payload as any;
      return {
        ...state,
        listVideoTypes: data
      };
    }
  }
});

export const {
  getTemplate,
  getCompanyTemplate,
  getGlobalTemplate,
  templateById,
  getListTagsUser,
  getListTagsCompany,
  getListVideoTypes
} = templateSlice.actions;

export const selectTemplate = (state: RootState) => state.template;

export default templateSlice.reducer;
