export enum FHDScreen {
  width = '1920px',
  height = '1080px'
}

export enum FHDPixel {
  width = 1920,
  height = 1080
}

export enum HDPixel {
  width = 1280,
  height = 720
}

export const ExpectRatioFHD = '16:9';
export const resolutionFHD = '1920x1080';
export const widthPlayerUI13Inch = 666; // rounded exactly 662
export const widthPlayerUI24Inch = 980; // rounded exactly 969
