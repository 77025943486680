import styled from '@emotion/styled';
import Typography from '../Typography';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 1px;
`;

export const HiddenTickBox = styled.input`
  position: absolute;
  opacity: 0;
`;

export const InnerTick = styled.span<{
  scale: number;
  disabled: boolean;
}>`
  width: 6px;
  height: 10px;
  border: ${({ theme, disabled }) => {
    if (disabled) return `solid ${theme.altGrey.shade6}`;

    return `solid ${theme.neutral.white}`;
  }};
  border-radius: 2px;
  border-width: 0 2px 2px 0;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%) rotate(45deg) ${({ scale }) => `scale(${scale}, ${scale})`};
`;

export const CustomTickBox = styled.span<{
  size: number;
  checked: boolean;
  disabled: boolean;
}>`
  border-radius: 4px;
  position: relative;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border: ${({ checked, theme, disabled }) => {
    if (checked && !disabled) return `1px solid ${theme.altBrand.mint}`;

    return `1px solid ${theme.altGrey.shade6}`;
  }};
  background-color: ${({ checked, theme, disabled }) => {
    if (disabled) return theme.altGrey.shade3;

    if (checked) return theme.altBrand.mint;

    return theme.neutral.white;
  }};

  &:hover {
    border: ${({ theme }) => {
      return `1px solid ${theme.states.hover}`;
    }};
    background-color: ${({ theme, checked }) => {
      if (checked) return `1px solid ${theme.states.hover}`;
    }};
  }
`;

export const Label = styled(Typography)`
  margin-left: 8px;
  color: ${({ theme }) => theme.altGrey.shade9};
`;
