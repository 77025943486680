import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import { Checkbox } from '../CheckboxList/CheckboxList.style';
import TickBox from '../TickBox';
import Typography from '../Typography';
import { useAppSelector } from '@/app/hooks';
import { selectVideo } from '@/features/video/store/videoSlice';

interface IModalWarningCaptionProps {
  isOpen: boolean;
  onClickClose: () => void;
  onClickApply: () => void;
}

const ModalWarningCaption: React.FunctionComponent<IModalWarningCaptionProps> = ({
  isOpen,
  onClickClose,
  onClickApply
}) => {
  const theme = useTheme();
  const { contentId } = useAppSelector(selectVideo);

  const [check, setCheck] = React.useState(false);

  const onConfirm = () => {
    if (check) {
      const listStoriesIdTracking = JSON.parse(localStorage.getItem('listStoriesIdTracking') || '[]');
      const pushId = listStoriesIdTracking.concat(contentId);
      localStorage.setItem('listStoriesIdTracking', JSON?.stringify(pushId));
    }
    onClickApply();
  };

  const onClose = () => {
    if (check) {
      const listStoriesIdTracking = JSON.parse(localStorage.getItem('listStoriesIdTracking') || '[]');
      const pushId = listStoriesIdTracking.concat(contentId);
      localStorage.setItem('listStoriesIdTracking', JSON?.stringify(pushId));
    }
    onClickClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="483px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Caption editing</TitleConfirm>
      <MessageConfirm>Please upload your Video here to generate caption.</MessageConfirm>
      <ActionsContainer>
        <Checkbox>
          <TickBox checked={check} onClick={() => setCheck(!check)} />
          <Typography
            onClick={() => setCheck(!check)}
            variant="body"
            altColor="shade7"
            style={{ fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}
          >
            Don't show this again
          </Typography>
        </Checkbox>
        <FooterDialog>
          <Button onClick={onClose} variant="outline" style={{ marginRight: '10px' }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            OK
          </Button>
        </FooterDialog>
      </ActionsContainer>
    </Modal>
  );
};

export default ModalWarningCaption;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: space-between;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

export const MessageConfirm = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
  font-family: 'Objective';
  font-weight: 400;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

export const FooterDialog = styled.div`
  display: flex;
  justify-content: end;
`;
