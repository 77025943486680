import React from 'react';

import { SVGProps } from './SVG.props';

const Crop = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 32} height={height || 32} viewBox="0 0 33 33" fill="none" {...props}>
      <rect width="32" height="32" rx="12" fill="white" />
      <g opacity="0.1">
        <path
          d="M0 16C0 3.04 3.04 0 16 0C28.96 0 32 3.04 32 16C32 28.96 28.96 32 16 32C3.04 32 0 28.96 0 16Z"
          fill="white"
        />
      </g>
      <path
        d="M23.0001 20H22.0001V11C22.0001 10.7348 21.8948 10.4804 21.7072 10.2929C21.5197 10.1054 21.2653 10 21.0001 10H12.0001V9C12.0001 8.73478 11.8948 8.48043 11.7072 8.29289C11.5197 8.10536 11.2653 8 11.0001 8C10.7349 8 10.4806 8.10536 10.293 8.29289C10.1055 8.48043 10.0001 8.73478 10.0001 9V10H9.00012C8.73491 10 8.48055 10.1054 8.29302 10.2929C8.10548 10.4804 8.00012 10.7348 8.00012 11C8.00012 11.2652 8.10548 11.5196 8.29302 11.7071C8.48055 11.8946 8.73491 12 9.00012 12H10.0001V21C10.0001 21.2652 10.1055 21.5196 10.293 21.7071C10.4806 21.8946 10.7349 22 11.0001 22H20.0001V23C20.0001 23.2652 20.1055 23.5196 20.293 23.7071C20.4806 23.8946 20.7349 24 21.0001 24C21.2653 24 21.5197 23.8946 21.7072 23.7071C21.8948 23.5196 22.0001 23.2652 22.0001 23V22H23.0001C23.2653 22 23.5197 21.8946 23.7072 21.7071C23.8948 21.5196 24.0001 21.2652 24.0001 21C24.0001 20.7348 23.8948 20.4804 23.7072 20.2929C23.5197 20.1054 23.2653 20 23.0001 20ZM20.0001 20H12.0001V12H20.0001V20Z"
        fill={fill || '#070550'}
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="11.5" stroke="#070550" strokeOpacity="0.2" />
    </svg>
  );
};

export default Crop;
