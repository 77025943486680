import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const crossBlur = keyframes`
  0% {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  20% {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
  100% {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const fadeblack = keyframes`
  0% {
    opacity: 0.5;
    background-color: rgba(0, 0, 0);
  }
  15% {
    opacity: 0.55;
    background-color: rgba(0, 0, 0);
  }
  25% {
    opacity: 0.65;
    background-color: rgba(0, 0, 0);
  }
  35% {
    opacity: 0.75;
    background-color: rgba(0, 0, 0);
  }
  50% {
    opacity: 1;
    background-color: rgba(0, 0, 0);
  }
  65% {
    opacity: 0.75;
    background-color: rgba(0, 0, 0);
  }
  75% {
    opacity: 0.65;
    background-color: rgba(0, 0, 0);
  }
  85% {
    opacity: 0.55;
    background-color: rgba(0, 0, 0);
  }
  100% {
    opacity: 0.5;
    background-color: rgba(0, 0, 0);
    display: none;
  }
`;

const fadewhite = keyframes`
  0% {
    opacity: 0.5;
    background-color: rgba(252, 252, 252);
  }
  15% {
    opacity: 0.55;
    background-color: rgba(252, 252, 252);
  }
  25% {
    opacity: 0.65;
    background-color: rgba(252, 252, 252);
  }
  35% {
    opacity: 0.75;
    background-color: rgba(252, 252, 252);
  }
  50% {
    opacity: 1;
    background-color: rgba(252, 252, 252);
  }
  65% {
    opacity: 0.75;
    background-color: rgba(252, 252, 252);
  }
  75% {
    opacity: 0.65;
    background-color: rgba(252, 252, 252);
  }
  85% {
    opacity: 0.55;
    background-color: rgba(252, 252, 252);
  }
  100% {
    opacity: 0.5;
    background-color: rgba(252, 252, 252);
    display: none;
  }
`;

// const wipeleft = keyframes`
//   0% {
//     transform: translateX(100%);
//     background-color: rgba(252, 252, 252);
//   }
//   15% {
//     transform: translateX(85%);
//     background-color: rgba(252, 252, 252);
//   }
//   25% {
//     transform: translateX(75%);
//     background-color: rgba(252, 252, 252);
//   }
//   35% {
//     transform: translateX(65%);
//     background-color: rgba(252, 252, 252);
//   }
//   50% {
//     transform: translateX(50%);
//     background-color: rgba(252, 252, 252);
//   }
//   65% {
//     transform: translateX(35%);
//     background-color: rgba(252, 252, 252);
//   }
//   75% {
//     transform: translateX(25%);
//     background-color: rgba(252, 252, 252);
//   }
//   85% {
//     transform: translateX(15%);
//     background-color: rgba(252, 252, 252);
//   }
//   100% {
//     transform: translateX(0);
//     background-color: rgba(252, 252, 252);
//     display: none;
//   }
// `;

const wipeleft = keyframes`
  0% {
    transform: translateX(100%);
  }
  5% {
    transform: translateX(95%);
  }
  10% {
    transform: translateX(90%);
  }
  15% {
    transform: translateX(85%);
  }
  20% {
    transform: translateX(80%);
  }
  25% {
    transform: translateX(75%);
  }
  30% {
    transform: translateX(70%);
  }
  35% {
    transform: translateX(65%);
  }
  40% {
    transform: translateX(60%);
  }
  45% {
    transform: translateX(55%);
  }
  50% {
    transform: translateX(50%);
  }
  55% {
    transform: translateX(45%);
  }
  60% {
    transform: translateX(40%);
  }
  65% {
    transform: translateX(35%);
  }
  70% {
    transform: translateX(30%);
  }
  75% {
    transform: translateX(25%);
  }
  80% {
    transform: translateX(20%);
  }
  85% {
    transform: translateX(15%);
  }
  90% {
    transform: translateX(10%);
  }
  95% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
    display: none;
  }
`;

const wiperight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  5% {
    transform: translateX(-95%);
  }
  10% {
    transform: translateX(-90%);
  }
  15% {
    transform: translateX(-85%);
  }
  20% {
    transform: translateX(-80%);
  }
  25% {
    transform: translateX(-75%);
  }
  30% {
    transform: translateX(-70%);
  }
  35% {
    transform: translateX(-65%);
  }
  40% {
    transform: translateX(-60%);
  }
  45% {
    transform: translateX(-55%);
  }
  50% {
    transform: translateX(-50%);
  }
  55% {
    transform: translateX(-45%);
  }
  60% {
    transform: translateX(-40%);
  }
  65% {
    transform: translateX(-35%);
  }
  70% {
    transform: translateX(-30%);
  }
  75% {
    transform: translateX(-25%);
  }
  80% {
    transform: translateX(-20%);
  }
  85% {
    transform: translateX(-15%);
  }
  90% {
    transform: translateX(-10%);
  }
  95% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
    display: none;
  }
`;

const wipeup = keyframes`
  0% {
    transform: translateY(100%);
  }
  5% {
    transform: translateY(95%);
  }
  10% {
    transform: translateY(90%);
  }
  15% {
    transform: translateY(85%);
  }
  20% {
    transform: translateY(80%);
  }
  25% {
    transform: translateY(75%);
  }
  30% {
    transform: translateY(70%);
  }
  35% {
    transform: translateY(65%);
  }
  40% {
    transform: translateY(60%);
  }
  45% {
    transform: translateY(55%);
  }
  50% {
    transform: translateY(50%);
  }
  55% {
    transform: translateY(45%);
  }
  60% {
    transform: translateY(40%);
  }
  65% {
    transform: translateY(35%);
  }
  70% {
    transform: translateY(30%);
  }
  75% {
    transform: translateY(25%);
  }
  80% {
    transform: translateY(20%);
  }
  85% {
    transform: translateY(15%);
  }
  90% {
    transform: translateY(10%);
  }
  95% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(0);
    display: none;
  }
`;

const wipedown = keyframes`
  0% {
    transform: translateY(-100%);
    background-color: rgba(252, 252, 252);
  }
  5% {
    transform: translateY(-95%);
    background-color: rgba(252, 252, 252);
  }
  10% {
    transform: translateY(-90%);
    background-color: rgba(252, 252, 252);
  }
  15% {
    transform: translateY(-85%);
    background-color: rgba(252, 252, 252);
  }
  20% {
    transform: translateY(-80%);
    background-color: rgba(252, 252, 252);
  }
  25% {
    transform: translateY(-75%);
    background-color: rgba(252, 252, 252);
  }
  30% {
    transform: translateY(-70%);
    background-color: rgba(252, 252, 252);
  }
  35% {
    transform: translateY(-65%);
    background-color: rgba(252, 252, 252);
  }
  40% {
    transform: translateY(-60%);
    background-color: rgba(252, 252, 252);
  }
  45% {
    transform: translateY(-55%);
    background-color: rgba(252, 252, 252);
  }
  50% {
    transform: translateY(-50%);
    background-color: rgba(252, 252, 252);
  }
  55% {
    transform: translateY(-45%);
    background-color: rgba(252, 252, 252);
  }
  60% {
    transform: translateY(-40%);
    background-color: rgba(252, 252, 252);
  }
  65% {
    transform: translateY(-35%);
    background-color: rgba(252, 252, 252);
  }
  70% {
    transform: translateY(-30%);
    background-color: rgba(252, 252, 252);
  }
  75% {
    transform: translateY(-25%);
    background-color: rgba(252, 252, 252);
  }
  80% {
    transform: translateY(-20%);
    background-color: rgba(252, 252, 252);
  }
  85% {
    transform: translateY(-15%);
    background-color: rgba(252, 252, 252);
  }
  90% {
    transform: translateY(-10%);
    background-color: rgba(252, 252, 252);
  }
  95% {
    transform: translateY(-5%);
    background-color: rgba(252, 252, 252);
  }
  100% {
    transform: translateY(0);
    background-color: rgba(252, 252, 252);
    display: none;
  }
`;

export const TransitionCrossBlur = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation: ${crossBlur} 2s linear infinite;
`;

export const TransitionFadeToBlack = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation: ${fadeblack} 3s ease-in-out;
`;

export const TransitionFadeToWhite = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation: ${fadewhite} 3s ease-in-out;
`;

export const TransitionWipeLeft = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation-name: ${wipeleft};
  animation-duration: 1.3s;
  animation-timing-function: linear;
`;

export const TransitionWipeRight = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation-name: ${wiperight};
  animation-duration: 1.3s;
  animation-timing-function: linear;
`;

export const TransitionWipeUp = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation-name: ${wipeup};
  animation-duration: 1.3s;
  animation-timing-function: linear;
`;

export const TransitionWipeDown = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
}>`
  width: 100%;
  height: 100%;
  animation-name: ${wipedown};
  animation-duration: 1.3s;
  animation-timing-function: linear;
`;
