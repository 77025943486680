import React from 'react';
import { selectVideo } from '@/features/video/store/videoSlice';
import styled from '@emotion/styled';
import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import { VideoRecordFilled } from '@/components/SVG';

interface Props {
  percentLoading: number
}

const ProgressLoadingLayout: React.FC<Props> = ({ percentLoading }) => {
  const { isFinishedLoading } = useSelector(selectVideo);

  return (
    <ProgressLoading finishedLoading={isFinishedLoading}>
      <LoadingBox>
        <WrapperBox>
          <WrapperIcon>
            <VideoRecordFilled />
          </WrapperIcon>
        </WrapperBox>
          <LoadingText>Loading your project...</LoadingText>
        <Progress percent={percentLoading} strokeColor={'#91DBB6'} style={{ width: '100%' }} strokeWidth={6} status="active" showInfo={false} />
        <PercentText>{percentLoading}%</PercentText>
      </LoadingBox>
    </ProgressLoading>
  );
};

export default ProgressLoadingLayout;

const ProgressLoading = styled.div<{ finishedLoading?: boolean }>`
  display: flex;
  width: calc(100vw - 80px);
  height: calc(100vh - 70px);
  position: relative;
  display: ${({ finishedLoading }) => (finishedLoading ? 'none' : 'block')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 20px;
  }
  z-index: ${({ finishedLoading }) => (finishedLoading ? 0 : 9999)};
  background: ${({ theme }) => theme.neutral.white};
  border: ${({ theme }) => `1px solid ${theme.altGrey.shade4}`};
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 40px;
`;

const LoadingBox = styled.div<{ finishedLoading?: boolean }>`
  width: 50%;
  position: absolute;
  top: 36%;
  left: 25%;
`;

const WrapperBox = styled.div`
  display: flex;
  justify-content: center;
`;

const WrapperIcon = styled.div`
  margin-right: 6px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: ${({ theme }) => theme.brand.arctic};
  padding: 11px;
  margin-bottom: 4px;
`;

const LoadingText = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.brand.primary};
`;

const PercentText = styled.p`
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.brand.primary};
  margin-top: 2px;
`;