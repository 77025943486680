import { useEffect, useRef } from 'react';
import io, { Socket } from 'socket.io-client';
import { EnvironmentExportVariable } from '@/global/constant/Environment';
import { useAppSelector } from '@/app/hooks';
import { getToken } from '@/features/video/store/authSlice';

const useSocket = (ws: string) => {
  const socket = useRef<Socket>();
  const token = useAppSelector(getToken);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [ws, token]);

  const disconnect = () => {
    if (socket.current) {
      socket.current.emit('forceDisconnect', { event: 'forceDisconnect' });
      socket.current = undefined;
    }
  };

  const createSocket = () => {
    if (socket.current) {
      return;
    }
    if (token && ws) {
      const socketIo = io(`${EnvironmentExportVariable.baseUrl}`, {
        auth: {
          Authorization: `${token || ''}`
        },
        extraHeaders: {
          Authorization: `${token || ''}`
        },
        withCredentials: true,
        transports: ['websocket', 'polling'],
        path: '/ws/socket.io'
      });
      socket.current = socketIo;
      return socketIo;
    }
  };

  return { createSocket, socket: socket.current, disconnect };
};

export default useSocket;
