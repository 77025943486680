import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import Typography from '@/components/Typography';
import { selectedAddingObjDetails, update, updateSelectedCard } from '@/features/video/store/videoSlice';
import ListTagNames from '../../../../Media-tab/components/ListTagNames';
import styled from '@emotion/styled';
import { selectBrandKit } from '@/features/video/store/brandKitSlice';
import { Card, ObjectType } from '@/features/video/models/FrameItem';
import { BackArrow } from '@/components/SVG';
import ColorStyles from '../commons/ColorStyles';
import ElementText from '../commons/ElementText';

interface ICardsTab {}

const CardsTab: React.FC<ICardsTab> = () => {
  const dispatch = useAppDispatch();
  const cardNameDetails = useAppSelector(selectedAddingObjDetails) as any;

  const { colors } = useAppSelector(selectBrandKit);
  const [backgroundColorCard, setBgColorCard] = useState(cardNameDetails?.backgroundColor);
  const [selectedColor, setSelectedColor] = useState<number>();

  const handleStyleChangeCardBg = (elements: any, uid?: string) => {
    dispatch(updateSelectedCard({ uid, ...elements }));
  };

  const onSelectObj = (uid: string, type: ObjectType, tab?: string, tabInside?: string) => {
    dispatch(update({ selectedObj: { uid, type } }));
    dispatch(update({ selectedTab: 'Text Effects & Cards' }));
    dispatch(update({ selectedTabInside: 'Cards' }));
  };

  useEffect(() => {
    setBgColorCard(cardNameDetails?.backgroundColor);
  }, [cardNameDetails?.backgroundColor]);

  return (
    <Container>
      {(cardNameDetails?.type !== ObjectType.card || cardNameDetails?.type !== ObjectType.graphic) && !cardNameDetails && (
        <ContainerCard>
          <Typography altVariant="overlineBig" color="shade7" style={{ marginBottom: '6px' }}>
            Name Card
          </Typography>
          <ListTagNames type="card" />
          <Typography altVariant="overlineBig" color="shade7" style={{ marginTop: '15px', marginBottom: '6px' }}>
            Title Card
          </Typography>
          <ListTagNames type="graphic" />
        </ContainerCard>
      )}
      {cardNameDetails && (cardNameDetails?.type === ObjectType.card || cardNameDetails?.type === ObjectType.graphic) && (
        <>
          <BackSubContainer
            onClick={() => {
              // setView(Views.Subtitle);
              onSelectObj('', ObjectType.image);
            }}
          >
            <IconBack>
              <BackArrow />
            </IconBack>
            <TextBackToCaptions>Back to Text Effects & Cards</TextBackToCaptions>
          </BackSubContainer>
          <ColorStyles
            label="Background Colour"
            listColorsBrandKit={colors}
            selectedColor={selectedColor}
            color={backgroundColorCard}
            onSelectColor={setSelectedColor}
            setColorPicked={setBgColorCard}
            handleStyleChange={handleStyleChangeCardBg}
            valueStyles={cardNameDetails}
          />
          <FrontStyleContainer isGraphic={cardNameDetails?.type === ObjectType.graphic}>
            {cardNameDetails?.elementTexts?.map((item: Card, idx: number) => (
              <ElementText
                key={idx}
                label="Colour"
                listColorsBrandKit={colors}
                selectedColor={selectedColor}
                color={item?.styles?.color}
                valueStyles={item.styles}
                isChangeTextColor={true}
                title={item?.name}
                textId={item?.uid}
                textItem={item}
                isGraphic={cardNameDetails?.type === ObjectType.graphic}
              />
            ))}
          </FrontStyleContainer>
        </>
      )}
    </Container>
  );
};

export default CardsTab;

const Container = styled.div<{ width?: number }>`
  margin-top: 5px;
  // padding: 0 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  height: 378px;
  overflow: auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 290px;
  }
`;

const ContainerCard = styled.div<{ width?: number }>`
  display: flex;
  height: 370px;
  width: 100%;
  flex-direction: column;
`;

const FrontStyleContainer = styled.div<{ isGraphic?: boolean }>`
  margin-top: ${({ isGraphic }) => (isGraphic ? '12px' : '16px')};
  // margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BackSubContainer = styled.div`
  cursor: pointer;
  height: 45px;
  // background: ${({ theme }) => theme.altGrey.shade4};
  background: ${({ theme }) => theme.grey.shade3};
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0;
  border-radius: 4px 4px 0;
`;

const TextBackToCaptions = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 14px;
  margin-left: 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const IconBack = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px 6px;
  cursor: pointer;
  margin-top: 8px;
`;
