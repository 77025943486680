import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/app/hooks';
import { selectVideo, setCaptionViewMode, update } from '../../store/videoSlice';
import { MediaIcon, SubtitleIcon, TextIcon } from '@/components/SVG';
import './VideoSlidebar.scss';
import { useDispatch } from 'react-redux';
import EditAudio from './edit-audio-tab';
import Media from './v2/Media';
import TextEffects from './v2/TextEffects';
// import Captions from './v2/Captions';
import Transitions from './v2/Transitions';
import Presets from './v2/Presets';
import { ObjectType } from '../../models/FrameItem';
import CaptionsV2 from './v2/CaptionsV2';

const { TabPane } = Tabs;

const TabsConf = [
  {
    name: 'Templates',
    comp: <Presets />
  },
  {
    name: 'Media',
    comp: <Media />
  },
  {
    name: 'Text Effects & Cards',
    icon: <MediaIcon />,
    comp: <TextEffects />
  },
  {
    name: 'Captions',
    icon: <TextIcon />,
    comp: <CaptionsV2 />
  },
  {
    name: 'Transitions',
    icon: <SubtitleIcon />,
    comp: <Transitions />
  },
  // {
  //   name: 'Template',
  //   comp: <TemplateTab />
  // },

  // {
  //   name: 'Stories',
  //   comp: <TemplateTab />
  // },
  // {
  //   name: 'Brand Kit',
  //   comp: <BrandKitTab />
  // },
  {
    name: 'Edit Audio',
    comp: <EditAudio />
  }
];

const VideoSlideBarV2: React.FC = () => {
  const { selectedObj, selectedTab, selectedTabInside } = useAppSelector(selectVideo);
  const [activeKey, setActiveKey] = useState<string>('0');
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedObj?.type === ObjectType.text) {
      setActiveKey(TabsConf.findIndex((tab) => tab.name === 'Text Effects & Cards').toString() || '0');
      dispatch(update({ selectedTab: 'Text Effects & Cards' }));
      dispatch(update({ selectedTabInside: 'Text Effects' }));
    }
  }, [selectedObj, dispatch]);

  useEffect(() => {
    const index = TabsConf.findIndex((item) => item.name === selectedTab);
    setActiveKey(index?.toString() || '0');
    if (selectedTab !== 'Edit Audio') {
      dispatch(update({ previousTab: selectedTab }));
    }
    if(selectedTab === 'Captions') {
      dispatch(setCaptionViewMode('OVERAL'))
    } else {
      dispatch(setCaptionViewMode('UPLOAD')) // trick when back to Captions will select tab All Captions
    }
  }, [dispatch, selectedTab, selectedTabInside]);

  return (
    <>
      <Tabs className="slidebar-tabs" activeKey={activeKey} onChange={(atvKey) => setActiveKey(atvKey)}>
        {TabsConf.map(({ name, icon, comp }, index) => {
          return (
            <TabPane
              tab={
                <div className="tab-title">
                  <span className="title">{name}</span>
                </div>
              }
              key={index}
            >
              {comp}
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default VideoSlideBarV2;
