const Environment: Record<string, object> = {
  local: {
    baseUrl: 'https://studiodev-api.themartec.com',
    version: 1,
    PLATFORM_URL: 'http://localhost:3000',
    PLATFORM_API_URL: 'https://apidev.themartec.com',
    PLAT_FORM_API_VERSION: 1
  },
  development: {
    baseUrl: 'https://studiodev-api.themartec.com',
    version: 1,
    PLATFORM_URL: 'https://appdev.themartec.com',
    PLATFORM_API_URL: 'https://apidev.themartec.com',
    PLAT_FORM_API_VERSION: 1
  },
  staging: {
    baseUrl: 'https://studiostaging-api.themartec.com',
    version: 1,
    PLATFORM_URL: 'https://appstaging.themartec.com',
    PLATFORM_API_URL: 'https://apistaging.themartec.com',
    PLAT_FORM_API_VERSION: 1
  },
  production: {
    baseUrl: 'https://studio-api.themartec.com',
    version: 1,
    PLATFORM_URL: 'https://app.themartec.com',
    PLATFORM_API_URL: 'https://api.themartec.com',
    PLAT_FORM_API_VERSION: 1
  }
};

const EnvironmentExport: Record<string, object> = {
  local: {
    baseUrl: 'wss://batch-video-dev.themartec.com'
  },
  development: {
    baseUrl: 'wss://batch-video-dev.themartec.com'
  },
  staging: {
    baseUrl: 'wss://batch-video-staging.themartec.com'
  },
  production: {
    baseUrl: 'wss://batch-video.themartec.com'
  }
};

const envName = process.env.REACT_APP_NODE_ENV || 'local';
const EnvironmentVariable: Record<string, any> = Environment[envName];
const EnvironmentExportVariable: Record<string, any> = EnvironmentExport[envName];

export { EnvironmentVariable, EnvironmentExportVariable };
