import * as React from 'react';
import styled from '@emotion/styled';
import { ChromePicker, ColorResult, RGBColor } from 'react-color';
import Typography from '../Typography';
import { convertRgbaToHex } from '@/utils/convertColor';

interface IColorPickerProps {
  label?: string;
  defaultColor?: string;
  containerStyle?: React.CSSProperties;
  color?: string;
  onColorChange?: (color: string) => void;
  onSelectColor?: (val: number) => void;
  selected?: boolean;
  topPosition?: number;
  rightPostition?: number;
  bgColor?: string;
  disableAlpha?: boolean;
}

const toRgba = (color: RGBColor): string => {
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
};

const ColorPicker: React.FunctionComponent<IColorPickerProps> = ({
  label,
  color = 'rgba(255,255,255,1)',
  containerStyle,
  onColorChange,
  onSelectColor = () => null,
  selected,
  topPosition,
  rightPostition,
  bgColor,
  disableAlpha
}) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState<boolean>(false);

  const onChange = (color: ColorResult) => {
    onSelectColor(-1);
    return onColorChange?.(toRgba(color.rgb));
  };

  return (
    <Container style={containerStyle}>
      {label && (
        <Typography variant="overline" color="shade6" style={{ marginBottom: '4px' }}>
          {label}
        </Typography>
      )}
      <ColorField selected={selected} bgColor={bgColor}>
        <ColorSample bgColor={color} onClick={() => setIsPickerOpen(true)} />
        <Typography
          variant="label"
          color="shade6"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'uppercase' }}
        >
          {convertRgbaToHex(color)}
        </Typography>
      </ColorField>
      {isPickerOpen && (
        <ColorContainer>
          <ColorBlurContainer onClick={() => setIsPickerOpen(false)} />
          <ColorPickerContainer topPosition={topPosition} leftPostition={rightPostition}>
            <ChromePicker color={color} onChange={onChange} disableAlpha={disableAlpha} />
          </ColorPickerContainer>
        </ColorContainer>
      )}
    </Container>
  );
};

export default ColorPicker;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ColorField = styled.div<{ selected?: boolean; bgColor?: string }>`
  padding: 8px 12px;
  border: ${({ selected, theme }) => (selected ? 'none' : `0.5px solid ${theme.altGrey.shade3}`)};
  border-radius: 12px;
  display: flex;
  gap: 4px;
  background: ${({ bgColor, theme }) => (bgColor ? `${theme.neutral.white}` : '')};
`;

const ColorSample = styled.div<{ bgColor?: string }>`
  min-width: 32px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid #f0ecee;
`;

const ColorContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const ColorBlurContainer = styled.div`
  display: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
`;

const ColorPickerContainer = styled.div<{ topPosition?: number; leftPostition?: number }>`
  top: ${({ topPosition }) => (topPosition ? `${topPosition}px` : '0')};
  left: ${({ leftPostition }) => (leftPostition ? `${leftPostition}px` : '80px')};
  position: absolute;
`;
