import React from 'react';

import { SVGProps } from './SVG.props';

const PauseIcon = (props: SVGProps): JSX.Element => {
  const { width, height } = props;
  return (
    <svg width={width || 512} height={height || 512} viewBox="0 0 512 512" fill="none" {...props}>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M2321 5110 c-497 -48 -990 -251 -1376 -565 -114 -92 -294 -274 -384
-387 -229 -287 -417 -675 -495 -1023 -49 -218 -60 -325 -60 -575 0 -250 11
-357 60 -575 79 -355 272 -749 509 -1040 92 -114 274 -294 387 -384 287 -229
675 -417 1023 -495 218 -49 325 -60 575 -60 250 0 357 11 575 60 261 58 603
204 828 353 389 259 688 599 893 1016 125 255 196 484 241 775 24 161 24 539
0 700 -45 291 -116 520 -241 775 -134 272 -283 480 -498 692 -211 209 -404
346 -673 478 -252 124 -486 197 -765 240 -126 19 -468 27 -599 15z m445 -411
c501 -48 948 -261 1310 -623 254 -254 422 -527 529 -856 163 -506 133 -1053
-85 -1541 -178 -400 -523 -777 -909 -994 -652 -365 -1450 -365 -2102 0 -324
182 -642 500 -824 824 -332 593 -364 1308 -85 1932 174 390 500 753 879 976
376 223 845 325 1287 282z"
        />
        <path
          d="M1985 3787 c-60 -28 -105 -71 -131 -124 -18 -36 -19 -80 -22 -1068
-3 -1154 -8 -1077 70 -1161 66 -71 176 -97 272 -65 49 17 111 73 139 125 l22
41 0 1045 c0 1000 -1 1047 -19 1080 -67 127 -214 183 -331 127z"
        />
        <path
          d="M3040 3801 c-76 -23 -147 -89 -171 -157 -12 -32 -14 -222 -14 -1069
l0 -1030 23 -45 c32 -60 63 -92 120 -120 122 -62 286 -2 343 125 18 38 19 94
19 1076 l0 1035 -26 52 c-53 107 -184 166 -294 133z"
        />
      </g>
    </svg>
  );
};

export default PauseIcon;
