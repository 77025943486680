import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowFrontlineDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg {...props} width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.33341 9.72386V2.66667C7.33341 2.29848 7.63189 2 8.00008 2C8.36827 2 8.66675 2.29848 8.66675 2.66667V9.72386L10.862 7.5286C11.1224 7.26825 11.5445 7.26825 11.8048 7.52859C12.0652 7.78894 12.0652 8.21105 11.8048 8.4714L8.47149 11.8047C8.21114 12.0651 7.78903 12.0651 7.52868 11.8047L4.19534 8.4714C3.93499 8.21106 3.93499 7.78895 4.19534 7.5286C4.45569 7.26825 4.8778 7.26825 5.13815 7.5286L7.33341 9.72386Z"
        fill={fill || "#070550"}
      />
      <path
        d="M2.66675 14C2.66675 13.6318 2.96522 13.3333 3.33341 13.3333H12.6667C13.0349 13.3333 13.3334 13.6318 13.3334 14C13.3334 14.3682 13.0349 14.6667 12.6667 14.6667H3.33341C2.96522 14.6667 2.66675 14.3682 2.66675 14Z"
        fill={fill || "#070550"}
      />
    </svg>
  );
};

export default ArrowFrontlineDown;
