import React from 'react';
import Spinner from '@/components/Spinner';
import { Theme } from '@/types/theme/Theme';
import { ButtonProps } from './Button.props';
import { ButtonContainer, LoadingContainer, IconContainer, Text, IconWrapper } from './Button.style';

const LOADING_ICON_SIZE_MAPPING = {
  xs: 12,
  sm: 16,
  md: 18,
  lg: 24
};

const Button = React.forwardRef((props: ButtonProps, ref): JSX.Element => {
  const {
    loading,
    icon,
    text,
    color,
    iconPosition = 'after',
    variant = 'primary',
    textVariant = 'overline',
    textWeight = '800',
    takeFullWidth = false,
    smaller = false,
    size,
    disabled,
    children,
    isDownloading,
    onClick = (e) => {
      return null;
    },
    ...buttonProps
  } = props;

  let textColor: keyof Theme['brand'] | keyof Theme['grey'];

  if (
    variant === 'primary' ||
    variant === 'alert' ||
    variant === 'secondary' ||
    variant === 'plain' ||
    variant === 'tertiary' ||
    variant === 'canary' ||
    variant === 'arctic'
  ) {
    textColor = 'primary';
  } else if (variant === 'outline') {
    textColor = 'primary';
  } else if (variant === 'normal') {
    textColor = 'secondary';
  } else if (variant === 'deny') {
    textColor = 'shade1';
  } else if (variant === 'linkedin') {
    textColor = 'noshade';
  } else if (variant === 'blue') {
    textColor = 'noshade';
  } else {
    textColor = 'shade5';
  }

  return (
    <ButtonContainer
      ref={ref}
      disabled={disabled}
      color={color}
      variant={disabled ? 'disabled' : variant}
      hasText={text && text?.length > 0 ? true : false}
      iconPosition={iconPosition}
      takeFullWidth={takeFullWidth}
      smaller={smaller}
      size={size}
      loading={loading ? `${loading}` : ''}
      isDownloading={isDownloading}
      onClick={(e) => {
        (!loading || !disabled || !isDownloading) && onClick(e);
      }}
      {...buttonProps}
    >
      {iconPosition === 'before' && <IconWrapper>{icon}</IconWrapper>}
      {(text || children) && (
        <Text variant={textVariant} color={disabled ? 'shade5' : textColor} weight={textWeight}>
          {text ?? children}
        </Text>
      )}
      {loading && (
        <LoadingContainer>
          <Spinner width={LOADING_ICON_SIZE_MAPPING[size || 'md']} height={LOADING_ICON_SIZE_MAPPING[size || 'md']} />
        </LoadingContainer>
      )}
      <IconContainer>{iconPosition === 'after' && !loading && icon}</IconContainer>
    </ButtonContainer>
  );
});

Button.displayName = 'Button';

export default Button;
