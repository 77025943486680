import { CSSProperties } from 'react';

const TEXT_STYLES: Record<string, CSSProperties & { text: string }> = {
  heading1: {
    fontSize: '92px',
    lineHeight: '32px',
    color: '#070550',
    text: 'Headline Text',
    fontWeight: 500
  },
  heading2: {
    fontSize: '52px',
    lineHeight: '24px',
    color: '#070550',
    text: 'Body Text',
    fontWeight: 400
  },
  heading3: {
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '18px',
    color: '#070550',
    text: 'Caption'
  }
};

export const TextPosition = [
  {
    id: 0,
    position: {
      width: 304,
      height: 286
    }
  },
  {
    id: 1,
    position: {
      width: 477,
      height: 304
    }
  },
  {
    id: 2,
    position: {
      width: 533,
      height: 318
    }
  }
];

export { TEXT_STYLES };
