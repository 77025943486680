import React from 'react';

import { SVGProps } from './SVG.props';

const AddBlack = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 16 16" fill="none" {...props}>
      <g id="plus-incircle-filled">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.3333 8.6666C11.7015 8.6666 12 8.36813 12 7.99994C12 7.63175 11.7015 7.33327 11.3333 7.33327H8.66667V4.66667C8.66667 4.29848 8.36819 4 8 4C7.63181 4 7.33333 4.29848 7.33333 4.66667V7.33327H4.66667C4.29848 7.33327 4 7.63175 4 7.99994C4 8.36813 4.29848 8.6666 4.66667 8.6666H7.33333V11.3333C7.33333 11.7015 7.63181 12 8 12C8.36819 12 8.66667 11.7015 8.66667 11.3333V8.6666H11.3333Z"
          fill={fill || '#030220'}
        />
      </g>
    </svg>
  );
};

export default AddBlack;
