import React from 'react';

import { SVGProps } from './SVG.props';

const Times = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.90036 17.7802C5.75392 17.6338 5.75392 17.3964 5.90036 17.2499L17.2494 5.90085C17.3959 5.75441 17.6333 5.75441 17.7798 5.90085L18.098 6.21905C18.2444 6.3655 18.2444 6.60293 18.098 6.74938L6.74889 18.0984C6.60244 18.2449 6.36501 18.2449 6.21856 18.0984L5.90036 17.7802Z"
        fill={fill || '#070550'}
      />
      <path
        d="M6.21858 5.90085C6.36503 5.7544 6.60247 5.7544 6.74891 5.90085L18.098 17.2499C18.2444 17.3964 18.2444 17.6338 18.098 17.7802L17.7798 18.0984C17.6333 18.2449 17.3959 18.2449 17.2494 18.0984L5.90038 6.74938C5.75394 6.60293 5.75394 6.3655 5.90038 6.21905L6.21858 5.90085Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88713 5.56939C6.21663 5.23989 6.75086 5.23989 7.08037 5.56939L11.9992 10.4882L16.918 5.5694C17.2475 5.23989 17.7817 5.23989 18.1112 5.5694L18.4294 5.88759C18.7589 6.2171 18.7589 6.75133 18.4294 7.08084L13.5106 11.9996L18.4294 16.9185C18.7589 17.248 18.7589 17.7822 18.4294 18.1117L18.1112 18.4299C17.7817 18.7594 17.2475 18.7594 16.918 18.4299L11.9992 13.5111L7.08035 18.4299C6.75084 18.7594 6.21661 18.7594 5.8871 18.4299L5.56891 18.1117C5.2394 17.7822 5.2394 17.248 5.56891 16.9185L10.4877 11.9996L5.56893 7.08083C5.23942 6.75133 5.23942 6.2171 5.56893 5.88759L5.88713 5.56939ZM11.1506 12.6625L6.29811 17.5151L6.48372 17.7007L11.3363 12.8482L11.1506 12.6625ZM11.9992 12.1853L11.8136 11.9996L11.9992 11.814L12.1848 11.9996L11.9992 12.1853ZM12.6621 12.8482L17.5146 17.7007L17.7002 17.5151L12.8477 12.6625L12.6621 12.8482ZM12.8477 11.3367L17.7002 6.48422L17.5146 6.2986L12.6621 11.1511L12.8477 11.3367ZM11.3363 11.1511L11.1506 11.3367L6.29813 6.48421L6.48375 6.2986L11.3363 11.1511ZM6.55002 17.767C6.54997 17.7669 6.55006 17.767 6.55002 17.767V17.767Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Times;
