import * as React from 'react';
import Movable from '../Movable';
import Draggable from '../Draggable';
import { DraggableData, DraggableEvent } from 'react-draggable';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectVideo, setCaptionSettings, update, updateSelectedCard } from '@/features/video/store/videoSlice';
import '../../constants/animations/styles.scss';
import ContentEditable from 'react-contenteditable';
import { FHDPixel } from '@/constants/screens';
import { CARD_LAYER_PADDING_X, CARD_LAYER_PADDING_Y, CARD_TEXT_ELEMENT_MARGIN } from '@/constants/commons';
import { Card } from '@/features/video/models/FrameItem';
import { Global } from '@emotion/core';
import { getFormatFile } from '@/utils/getFormatFont';
import Fonts from '@/utils/fonts';
import { replaceSpecialCharacter } from '@/utils/htmlHelper';
import { httpToHttps } from '@/utils/string/httpConverter';

interface IMovableTagNameProps {
  id: string;
  onResize: (width: number, height: number, uid: string) => void;
  url?: string;
  onDragStop?: (e: DraggableEvent, data: DraggableData) => void;
  onFocus?: (id: string) => void;
  onClickRemove?: (id: string) => void;
  isSelected?: boolean;
  width?: number;
  height?: number;
  x: number;
  y: number;
  show?: boolean;
  item?: any;
  backgroundColor?: string;
  zIndex?: number;
}

const MovableTagName: React.FunctionComponent<IMovableTagNameProps> = ({
  onResize,
  id,
  onDragStop,
  onFocus,
  onClickRemove,
  isSelected,
  width,
  height,
  x,
  y,
  show,
  item,
  backgroundColor,
  zIndex
}) => {
  const nameRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const locationRef = React.useRef<HTMLDivElement>(null);
  const [target, setTarget] = React.useState<any>();
  const dispatch = useAppDispatch();
  const { totalPlayed, playerSizeHD, subtitleStyle } = useAppSelector(selectVideo);

  const targetRef = React.useRef<any>(null);
  const [frame] = React.useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: '50% 50%'
  });

  React.useEffect(() => {
    //@ts-ignore
    setTarget(targetRef.current);
  }, []);

  React.useEffect(() => {
    // load font
    // eslint-disable-next-line array-callback-return
    item?.elementTexts.map((ele: any) => {
      Fonts(ele?.styles.fontName!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (targetRef.current) {
      targetRef.current.style.width = `${width}px`;
      targetRef.current.style.height = `${height}px`;
    }
  }, [width, height]);

  const handlePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window?.Clipboard;
    let plainText = clipboardData.getData('text/plain');
    plainText = replaceSpecialCharacter(plainText);
    document.execCommand('insertText', false, plainText);
  };

  // resize font size of text that match with UI and export
  const convertFontSize = (size?: string) => {
    const fontSize = parseInt(size?.replace('px', '') as string);
    const currentPlayerSizeHd = playerSizeHD as { width: number; height: number };
    if (fontSize) {
      const newSize = (currentPlayerSizeHd?.width / FHDPixel.width) * fontSize;
      return `${newSize}px`;
    }
    return null;
  };

  const onTextChange = (value: string, tagNameId: string, uid: string) => {
    let index = item?.elementTexts?.findIndex((text: any) => text.uid === tagNameId);
    let newTexts = [...item?.elementTexts];
    newTexts[index] = { ...newTexts[index], value };

    let textElementsPosition = getTextElementsPosition({ x, y });
    // Update textElements position
    newTexts = newTexts.map((ele: any, idx: number) => {
      if (ele) {
        return {
          ...ele,
          position: {
            x: textElementsPosition[idx]?.x,
            y: textElementsPosition[idx]?.y
          }
        };
      }
      return ele;
    });

    dispatch(updateSelectedCard({ uid, elementTexts: newTexts, isChanged: true }));
  };

  const unSelectObjectTimeline = () => {
    dispatch(
      update({
        timeLineSelected: { uid: '', type: '' }
      })
    );
  };

  const onClickTagName = () => {
    dispatch(update({ selectedTab: 'Text Effects & Cards' }));
    dispatch(update({ selectedTabInside: 'Cards' }));
    unSelectObjectTimeline();
  };

  const selectAllText = (ref: any) => {
    if (ref.current) {
      const range = document.createRange();
      range.selectNodeContents(ref.current);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const handleDoubleClick = (id?: number) => {
    switch (id) {
      case 0:
        selectAllText(nameRef);
        break;
      case 1:
        selectAllText(titleRef);
        break;
      default:
        selectAllText(locationRef);
        break;
    }
  };

  const getFontWeight = (fontWeight: string | number) => {
    if (['bold', 'normal'].includes(fontWeight as string)) {
      return fontWeight;
    }
    const fontWeightNumber = parseInt(fontWeight as string);
    if (isNaN(fontWeightNumber) || fontWeightNumber < 700) {
      return 'normal';
    }

    return 'bold';
  };

  const getTextElementsPosition = (cardPosition = { x: 0, y: 0 }) => {
    const nameElement = nameRef.current;
    const titleElement = titleRef.current;
    const locationElement = locationRef.current;
    const textElements = [nameElement, titleElement, locationElement];
    // get relative position of text elements
    return textElements.map((ele) => {
      if (ele) {
        const rect = ele.getBoundingClientRect();

        return {
          y: rect.top - targetRef.current.getBoundingClientRect().top + cardPosition.y,
          x: rect.left - targetRef.current.getBoundingClientRect().left + cardPosition.x
        };
      }
      return undefined;
    });
  };

  const updateTextElementsPosition = (data: DraggableData) => {
    let textElements = item?.elementTexts || [];
    let textElementsPosition = getTextElementsPosition(data);
    // Update textElements position
    textElements = textElements.map((ele: any, idx: number) => {
      if (ele) {
        return {
          ...ele,
          position: {
            x: textElementsPosition[idx]?.x,
            y: textElementsPosition[idx]?.y
          }
        };
      }
      return ele;
    });

    dispatch(updateSelectedCard({ uid: item?.uid, elementTexts: textElements, isChanged: true }));
  };

  const onPositionChangeHandler = (e: DraggableEvent, data: DraggableData) => {
    onDragStop?.(e, data);
    updateTextElementsPosition(data);
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <Draggable
      onDragStop={onPositionChangeHandler}
      onClickRemove={() => onClickRemove?.(id)}
      position={{ x, y }}
      zIndex={zIndex || 8}
    >
      <div
        onClick={() => {
          onFocus?.(id);
          onClickTagName();
          handleClearCaptionSetting();
        }}
        className={totalPlayed === 0 ? 'hidden' : show ? `${item?.animation?.type}` : 'hidden'}
        style={{
          zIndex: zIndex,
          backgroundColor: backgroundColor,
          height: height,
          width: width,
          display: show ? 'block' : 'none'
        }}
      >
        <TagNameContainer
          width={width}
          height={height}
          paddingX={convertFontSize(`${CARD_LAYER_PADDING_X}px`) as string}
          paddingY={convertFontSize(`${CARD_LAYER_PADDING_Y}px`) as string}
          ref={targetRef}
        >
          {item?.elementTexts?.map((tagName: Card, idx: number) => (
            <div key={idx}>
              <ContentEditable
                innerRef={idx === 0 ? nameRef : idx === 1 ? titleRef : locationRef}
                html={tagName?.value}
                onFocus={() => {
                  onFocus?.(tagName?.uid);
                  onClickTagName();
                }}
                onPaste={handlePaste}
                onDoubleClick={() => handleDoubleClick(idx)}
                style={{
                  margin: '0px',
                  marginTop: idx === 0 ? 0 : convertFontSize(`${CARD_TEXT_ELEMENT_MARGIN}px`),
                  border: 'none',
                  cursor: 'text',
                  background: tagName?.styles.backgroundColor,
                  fontFamily: tagName?.styles.fontName,
                  fontSize: convertFontSize(tagName?.styles.fontSize as string),
                  color: tagName?.styles.color,
                  lineHeight: convertFontSize(tagName?.styles.fontSize as string),
                  fontStyle: tagName?.styles.fontStyle,
                  fontWeight: getFontWeight(tagName?.styles.fontWeight || 400),
                  display: `${tagName?.isShow === false ? 'none' : 'block'}`,
                  fontSmoothing: 'antialiased',
                  '-webkit-font-smoothing': 'antialiased'
                }}
                className={`target ${show ? `${item?.animation?.type}` : 'hidden'}`}
                onChange={(e) => onTextChange(e.currentTarget.innerHTML || '', tagName?.uid, item?.uid)}
                onClick={onClickTagName}
              />
              <Global
                key={tagName?.styles?.fontFile}
                styles={[
                  {
                    '@font-face': {
                      fontFamily: tagName?.styles?.fontName,
                      src: `url(${httpToHttps(tagName?.styles?.fontFile as string)}) ${getFormatFile(
                        tagName?.styles?.fontFile as string
                      )}`
                    }
                  }
                ]}
              />
            </div>
          ))}
        </TagNameContainer>
        <Movable
          useResizeObserver
          draggable={false}
          target={target}
          resizable={isSelected}
          keepRatio={false}
          throttleResize={1}
          edge={false}
          zoom={1}
          onResizeStart={(e) => {
            e.setOrigin(['%', '%']);
            e.dragStart && e.dragStart.set(frame.translate);
          }}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            onResize(e.width, e.height, id);
          }}
          frame={frame}
        />
      </div>
    </Draggable>
  );
};

export default MovableTagName;

const TagNameContainer = styled.div<{ width?: number; height?: number; paddingX?: string; paddingY?: string }>`
  width: ${({ width }) => `${width || 200}px`};
  height: ${({ height }) => `${height || 100}px`};
  padding: ${({ paddingX, paddingY }) => `${paddingY} ${paddingX} `};
`;
