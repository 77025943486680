import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const DropContainer = styled.div<{ hasFile?: boolean }>`
  height: 120px;
  min-width: ${({ hasFile }) => (hasFile ? '100px' : '100%')};
  display: flex;
  justify-content: center;
  margin-right: 16px;
  align-items: center;
  background: #ffffff;
  border: 1px dashed ${(props) => props.theme.grey.shade6};
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const DropContainerSimple = styled.div<{ hasFile?: boolean; height?: string }>`
  height: ${({ height }) => (height ? height : '38px')};
  min-width: ${({ hasFile }) => (hasFile ? '100px' : '100%')};
  display: flex;
  justify-content: center;
  margin-right: 16px;
  align-items: center;
  background: #ffffff;
  border: 1px dashed ${(props) => props.theme.grey.shade6};
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const DropContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const DropContentSimple = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
