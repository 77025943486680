import React from 'react';

import { SVGProps } from './SVG.props';

const RightDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" {...props}>
      <g id="curvearrow-rightdown">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.91688 5.99984C5.03006 5.99984 2.6015 8.04942 1.98443 10.8118C1.90417 11.1712 1.5478 11.3974 1.18847 11.3171C0.829131 11.2369 0.602903 10.8805 0.683171 10.5212C1.43036 7.1762 4.37861 4.6665 7.91688 4.6665C10.4388 4.6665 12.6619 5.9426 14.0005 7.88542V5.79304C14.0005 5.42485 14.2989 5.12637 14.6671 5.12637C15.0353 5.12637 15.3338 5.42485 15.3338 5.79304V10.3645C15.3338 10.5721 15.237 10.7679 15.0721 10.894C14.9072 11.0202 14.6929 11.0622 14.4925 11.0078L9.99223 9.78607C9.6369 9.6896 9.42706 9.32335 9.52353 8.96802C9.61999 8.6127 9.98625 8.40285 10.3416 8.49932L13.2995 9.30237C12.277 7.33344 10.2467 5.99984 7.91688 5.99984Z"
          fill={fill || '#030220'}
        />
      </g>
    </svg>
  );
};

export default RightDown;
