import React from 'react';
import AllCaptions from './AllCaptions';
import styled from '@emotion/styled';

interface ICaptionsSettingsViewProps {
}

const CaptionsSettings:React.FC<ICaptionsSettingsViewProps> = () => {
  return (
    <Container>
      <AllCaptions />
    </Container>
  );
};

export default CaptionsSettings;

const Container = styled.div`
  height: 100%;
`;
