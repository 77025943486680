import React, { useState } from 'react';
import styled from '@emotion/styled';
import RenameCaptionFile from './Features/RenameFile';
import EditCaption from './Features/EditCaption';
import Translate from './Features/Translate';
import { useAppSelector } from '@/app/hooks';
import { selectVideo } from '@/features/video/store/videoSlice';

interface IFeaturesTab {
  onBackToOveral?: () => void;
}

const FeaturesTab: React.FC<IFeaturesTab> = ({ onBackToOveral }) => {
  const { tabEditCaption } = useAppSelector(selectVideo);
  const [activeKey, setActiveKey] = useState<number>(tabEditCaption || 0);

  const TabsConf = [
    {
      name: 'Rename',
      comp: <RenameCaptionFile onBackToOveral={onBackToOveral} />
    },
    {
      name: 'Edit Caption',
      comp: <EditCaption />
    },
    {
      name: 'Translate',
      comp: <Translate />
    }
  ];

  return (
    <>
      <RulerContainer>
        {TabsConf.map((item, idx) => (
          <TabItem key={idx} isSelected={activeKey === idx} onClick={() => setActiveKey(idx)}>
            <div>{item.name}</div>
          </TabItem>
        ))}
      </RulerContainer>
      {TabsConf.map((item, idx) => idx === activeKey && <>{item.comp}</>)}
    </>
  );
};

export default FeaturesTab;

const RulerContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

const TabItem = styled.div<{ isSelected?: boolean }>`
  width: calc(100% / 2);
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `${theme.brand.secondary} solid 2px` : `${theme.grey.shade3} solid 2px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.altGrey.shade8};
  }
`;
