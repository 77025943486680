import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import useRequest from '@/components/hooks/useRequest';
import { SubtitleDetail, Transcribe } from '@/features/video/models/VideoFrame';
import { editSubtitle } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { useAppSelector } from '@/app/hooks';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useDispatch } from 'react-redux';
import { selectSubtitleDetail } from '@/features/video/store/subtitleSlice';

interface IDeleteProps {
  isOpen: boolean;
  onClickClose: () => void;
  subtitleDetail?: SubtitleDetail;
  refetchSubtitle?: () => void;
  subtitleSelected?: Transcribe;
}

const DeleteSubtitleModal: React.FunctionComponent<IDeleteProps> = ({
  isOpen,
  onClickClose,
  subtitleDetail,
  refetchSubtitle = () => null,
  subtitleSelected
}) => {
  const dispatch = useDispatch();
  const { request } = useRequest();
  const theme = useTheme();
  const { subtitles, subtitlesInit } = useAppSelector(selectVideo);

  const onDelete = async () => {
    const subtitleLinked = subtitles?.some((i) => i?.id?.includes(subtitleDetail?.id));
    const bodyReq = {
      transcribe: subtitleDetail?.transcribe.filter((sub) => sub.sub !== subtitleSelected?.sub)
    };
    const editSub = await request(editSubtitle(subtitleDetail?.id as string, bodyReq));
    if (editSub?.data?.status === 200) {
      refetchSubtitle();
      dispatch(
        selectSubtitleDetail({
          ...subtitleDetail,
          transcribe: subtitleDetail?.transcribe.filter((sub) => sub.sub !== subtitleSelected?.sub)
        })
      );
      if (subtitleLinked) {
        handleRemoveSubtitleInStore();
      }
    } else {
      message.error('Delete subtitle unsuccessfully!');
    }
    onClickClose();
  };

  const onClose = () => {
    onClickClose();
  };

  const handleRemoveSubtitleInStore = () => {
    const modifySubtitles = subtitles?.map((item: any) => {
      if (item.file === subtitleDetail?.file) {
        return {
          id: subtitleDetail?.id,
          file: item?.file,
          transcribe: subtitleDetail?.transcribe.filter((sub) => sub.sub !== subtitleSelected?.sub)
        };
      } else {
        return { ...item };
      }
    });
    const modifySubtitlesInit = subtitlesInit?.map((item: any) => {
      if (item.file === subtitleDetail?.file) {
        return {
          id: subtitleDetail?.id,
          file: item?.file,
          transcribe: subtitleDetail?.transcribe.filter((sub) => sub.sub !== subtitleSelected?.sub)
        };
      } else {
        return { ...item };
      }
    });

    dispatch(
      update({
        subtitles: modifySubtitles,
        subtitlesInit: modifySubtitlesInit
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="470px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Are you sure to delete this item?</TitleConfirm>
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
        <Button variant="alert" onClick={onDelete}>
          Delete
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default DeleteSubtitleModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;
