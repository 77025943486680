import React, { useEffect } from 'react';
import FeaturesTab from './FeaturesTab';
import styled from '@emotion/styled';
import useRequest from '@/components/hooks/useRequest';
import { useAppDispatch } from '@/app/hooks';
import { getBrandKit as requestGetBrandKit } from '@/features/video/store/videoAPI';
import { storeBrandKit } from '@/features/video/store/brandKitSlice';

interface ITextEffects {}

const TextEffects: React.FC<ITextEffects> = () => {
  const { request } = useRequest();
  const dispatch = useAppDispatch();

  const handleGetBrandkit = async () => {
    const data = await request(requestGetBrandKit);
    await dispatch(storeBrandKit(data?.data?.data));
  };

  useEffect(() => {
    handleGetBrandkit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <FeaturesTab />
    </Container>
  );
};

export default TextEffects;

const Container = styled.div<{ width?: number }>`
  margin-top: 5px;
  padding: 0 10px;
`;
