import React from 'react';

import { SVGProps } from './SVG.props';

const VideoRecorder = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 29} height={height || 28} viewBox="0 0 29 28" fill="none" {...props}>
      <path
        d="M0.25 14C0.25 2.66 2.91 0 14.25 0C25.59 0 28.25 2.66 28.25 14C28.25 25.34 25.59 28 14.25 28C2.91 28 0.25 25.34 0.25 14Z"
        fill={fillbg || "#F2F0F1"}
      />
      <g clipPath="url(#clip0_469_13851)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.25 10C6.25 8.89543 7.14543 8 8.25 8H16.25C17.3546 8 18.25 8.89543 18.25 10V11.075L21.3725 10.0342C21.5758 9.96644 21.7993 10.0005 21.9731 10.1258C22.147 10.2511 22.25 10.4524 22.25 10.6667V17.6667C22.25 17.8896 22.1386 18.0977 21.9531 18.2214C21.7677 18.345 21.5327 18.3678 21.3269 18.2821L18.25 17V18C18.25 19.1046 17.3546 20 16.25 20H8.25C7.14543 20 6.25 19.1046 6.25 18V10ZM18.25 15.5556L20.9167 16.6667V11.5916L18.25 12.4805V15.5556ZM8.25 9.33333C7.88181 9.33333 7.58333 9.63181 7.58333 10V18C7.58333 18.3682 7.88181 18.6667 8.25 18.6667H16.25C16.6182 18.6667 16.9167 18.3682 16.9167 18V10C16.9167 9.63181 16.6182 9.33333 16.25 9.33333H8.25Z"
          fill={fill ||"#070550"}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_13851">
          <rect width="16" height="16" fill="white" transform="translate(6.25 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoRecorder;
