import React from 'react';
import styled from '@emotion/styled';
import { AddIcon } from '@/components/SVG';
import Audio from '@/assets/images/audio.png';

interface IProps {
  onAdd?: () => void;
  width?: string;
  id: string;
}

const TagName: React.FC<IProps> = ({ onAdd, width }) => {
  return (
    <TagNameContainer bg={Audio} width={width}>
      <GraphicIconContainer>
        <ContentContainer width={width}>
          <Name>Name</Name>
          <Position>Position</Position>
          <Location>Location</Location>
        </ContentContainer>
      </GraphicIconContainer>
      <AddIconContainer onClick={onAdd}>
        <AddIcon />
      </AddIconContainer>
    </TagNameContainer>
  );
};

export default TagName;

const TagNameContainer = styled.div<{ bg: string; width?: string }>`
  width: ${({ width }) => width};
  height: 120px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 120px;
  }

  position: relative;
  border-radius: 8px;
  background: ${({ theme }) => theme.grey.shade4};
  background-color: ${({ theme }) => theme.grey.shade4};
  background-size: contain;
  background-position: center;

  &:hover {
    .btn-remove {
      display: flex;
    }
  }
`;

const AddIconContainer = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 6px;
  padding-left: 4px;
  padding-top: 3px;
  cursor: pointer;
`;

const GraphicIconContainer = styled.div``;

const ContentContainer = styled.div<{ width?: string }>`
  width: 98%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px;
  margin-top: 25px;
  margin-left: 1%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 75px;
  }
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
`;

const Position = styled.div`
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const Location = styled.div`
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
