import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';

interface IWarningDeleteSubProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const WarningDeleteSubModal: React.FunctionComponent<IWarningDeleteSubProps> = ({ isOpen, onClickClose }) => {
  const theme = useTheme();

  const onClose = () => {
    onClickClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="470px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Delete warning</TitleConfirm>
      <TextWarning>You must unlink this subtitle before deleting</TextWarning>
      <ActionsContainer>
        <Button onClick={onClose}>OK</Button>
      </ActionsContainer>
    </Modal>
  );
};

export default WarningDeleteSubModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 22px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;

export const TextWarning = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #070550;
  margin-top: 10px;
`;
