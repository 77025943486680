import { VideoService } from '@/features/video/services/videoService';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { TIME_PX } from '../VideoControl';

interface IRulerSlicer {
  value: number;
}

const Ruler: React.FC<IRulerSlicer> = ({ value }) => {

  const times = useMemo(() => {
    const t = Array.from(new Array(value <  30 ? 31 : Math.ceil(value))).map((_, index) => VideoService.convertDurationToHHMMSS(index));
    return t;
  }, [value]);

  return (
    <RulerContainer className="ruler-container">
      <RulerWrapper>
        {times.map((time) => {
          return (
            <Block>
              <Time>{time}</Time>
              <CM />
            </Block>
          );
        })}
      </RulerWrapper>
    </RulerContainer>
  );
};

export default Ruler;

const RulerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 16px;
`;

const RulerWrapper = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  padding-top: 6px;
  border-radius: 4px;
  background-color: #F5F4F4;
`;

const Block = styled.div`
  min-width: ${() => `${1 / TIME_PX}px`};
  &:first-child {
    visibility: hidden;
  }
`;

const Time = styled.div`
  transform: translateX(-50%);
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #030220;
`;

const CM = styled.div`
  border-left: 1px solid #E6E3E4;
  height: 8px;
  width: 1px;
`;
