/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useAppSelector } from '@/app/hooks';
import {
  selectVideo,
} from '@/features/video/store/videoSlice';
import {
  PlayableType
} from '@/features/video/models/FrameItem';
import { PADDING_FRAME_CONTROL, PADDING_LAYOUT } from './ItemTypes';
interface Props {
  transformLeft: number;
  boxId: string;
  boxHoverId: string;
  type: string;
}

export const TimeLineBehavior = (props: Props) => {
  const { transformLeft, boxId, boxHoverId, type } = props;
  const { leftMarginControlScroll, } =
    useAppSelector(selectVideo);
  // const dispatch = useDispatch();

  const [localMousePos, setLocalMousePos] = useState({ x: 0 });

  const handleMouseMove = (event: any) => {
    // 👇 Get mouse position relative to element
    if (boxHoverId === boxId && type === PlayableType.video) {
      const localX = event.clientX - transformLeft - PADDING_LAYOUT - PADDING_FRAME_CONTROL + leftMarginControlScroll;
      setLocalMousePos({ x: localX });
    }
  };

  // const onSplitTimeline = (e: React.MouseEvent) => {
  //   const timeLineItem = getTimelineItem() as any;
  //   // check whether current timeline on play
  //   const currentTime = (leftMarginControlScroll + e.clientX - PADDING_LAYOUT - PADDING_FRAME_CONTROL) * TIME_PX;
  //   if (timeLineItem && currentTime > timeLineItem?.start && currentTime < timeLineItem?.end) {
  //     if (type === PlayableType.video) {
  //       handleSlipPlayableItem(timeLineItem, currentTime);
  //       return;
  //     }

  //     if (timeLineSelected.type === ObjectType.text) {
  //       const timeLineItem = addingTexts.find((item) => item.uid === timeLineSelected.uid);
  //       const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingText, currentTime);
  //       dispatch(splitTextItem({ newItem, originalItem }));
  //       return;
  //     }

  //     if (timeLineSelected.type === ObjectType.image) {
  //       const timeLineItem = addingImages.find((item) => item.uid === timeLineSelected.uid);
  //       const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingImage, currentTime);
  //       dispatch(splitImageItem({ newItem, originalItem }));
  //       return;
  //     }

  //     if (timeLineSelected.type === ObjectType.card) {
  //       const timeLineItem = addingCards.find((item) => item.uid === timeLineSelected.uid);
  //       const { newItem, originalItem } = handleSlipStaticItem(timeLineItem as AddingCard, currentTime);
  //       dispatch(splitCardItem({ newItem, originalItem }));
  //     }
  //   }
  // }

  // const handleSlipPlayableItem = (timeLineSelected: AddingPlayableItem, currentTime: number) => {
  //   // deep clone
  //   let originalItem = JSON.parse(JSON.stringify(timeLineSelected));
  //   // handle current Item
  //   if (timeLineSelected.end) {
  //     originalItem.trim.end = timeLineSelected.trim.end - (timeLineSelected.end - currentTime);
  //   }
  //   originalItem.end = currentTime;
  //   //handle new Item
  //   const newItem = JSON.parse(JSON.stringify(timeLineSelected));
  //   newItem.uid = uuidv4();
  //   newItem.start = currentTime;
  //   newItem.main = false;
  //   if (timeLineSelected.end) {
  //     newItem.trim.start = timeLineSelected.trim.end - (timeLineSelected.end - currentTime);
  //   }

  //   dispatch(splitPlayableItem({ newItem, originalItem }));
  //   // remove item selected
  //   dispatch(update({ timeLineSelected: { uid: '' } }));
  // };

  // const handleSlipStaticItem = (timeLineSelected: AddingImage | AddingText | AddingCard, currentTime: number) => {
  //   // deep clone
  //   let originalItem = JSON.parse(JSON.stringify(timeLineSelected));
  //   // handle current Item
  //   originalItem.end = currentTime;
  //   //handle new Item
  //   const newItem = JSON.parse(JSON.stringify(timeLineSelected));
  //   newItem.uid = uuidv4();
  //   newItem.start = currentTime;
  //   // remove item selected
  //   return { newItem, originalItem };
  // };

  //   const onRestoreItem = () => {
  //     const itemSelected = addingPlayableItems
  //       .filter((x) => !x.isHide)
  //       .find((playItem) => playItem.uid === timeLineSelected.uid);
  //     if (itemSelected) {
  //       const itemsInOneLine = getItemsOnLine(itemSelected.line as number, itemSelected.uid);
  //       const elm: any = {
  //         uid: itemSelected.uid,
  //         start: itemSelected.start,
  //         end: (itemSelected.start || 0) + itemSelected.duration,
  //         line: itemSelected.line,
  //         type: itemSelected.type,
  //         trim: { start: 0, end: itemSelected.duration }
  //       };

  //       if (!itemsInOneLine.length) {
  //         onDurationChange(elm as any);
  //       }
  //       const { side, timeLines }: any = onCheckOverlapSide(elm, itemsInOneLine);

  //       if (side === 'left') {
  //         onDurationChange(elm as any);
  //         const gap = elm.end - timeLines[0].start;
  //         timeLines.forEach((element: any) => {
  //           const elm = {
  //             uid: element.uid,
  //             start: element.start + gap,
  //             end: element.end + gap,
  //             type: element.type,
  //             line: element.line
  //           };
  //           onDurationChange(elm);
  //         });
  //         return;
  //       }
  //       if (side === 'noOver') {
  //         onDurationChange(elm as any);
  //       }
  //     }
  //   };

  // const getItemsOnLine = useCallback(
  //   (line: number, id: string) => {
  //     const videoInOneLine = addingPlayableItems.filter((item) => item.line === line && !item.isHide);
  //     const textInOneLine = addingImages.filter((item) => item.line === line);
  //     const imgInOneLine = addingTexts.filter((item) => item.line === line);
  //     const cardsInOneLine = addingCards.filter((item) => item.line === line);
  //     return [...videoInOneLine, ...textInOneLine, ...imgInOneLine, ...cardsInOneLine].filter(
  //       (items) => items.uid !== id
  //     );
  //   },
  //   [addingPlayableItems, addingImages, addingTexts, addingCards]
  // );

  // const onDurationChange = (props: {
  //   type: string;
  //   uid: string;
  //   start: number;
  //   end: number;
  //   line: number;
  //   trim?: { start: number; end: number };
  // }) => {
  //   const { uid, type, start, end, line, trim } = props;
  //   if (type === PlayableType.video || type === PlayableType.audio) {
  //     const castType = type as PlayableType;
  //     dispatch(
  //       updatePlayableItem(
  //         Object.assign({ uid, type: castType, start: start > 0 ? start : 0, end, line }, trim ? { trim } : {})
  //       )
  //     );
  //   } else if (type === ObjectType.image) {
  //     dispatch(updateSelectedImage({ uid, start: start > 0 ? start : 0, end, line }));
  //   } else if (type === ObjectType.text) {
  //     dispatch(updateSelectedText({ uid, start: start > 0 ? start : 0, end, line }));
  //   } else {
  //     dispatch(updateSelectedCard({ uid, start: start > 0 ? start : 0, end, line }));
  //   }
  // };

  // const getTimelineItem = () => {
  //   const mergeItemsTimeline = [
  //     ...addingImages,
  //     ...addingPlayableItems.filter((x) => !x.isHide),
  //     ...addingTexts,
  //     ...addingCards
  //   ];
  //   return mergeItemsTimeline.find((elm) => elm.uid === timeLineSelected.uid);
  // };

  return {
    localMousePos,
    // onSplitTimeline,
    handleMouseMove
  };
};
