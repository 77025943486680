import React from 'react';

import { SVGProps } from './SVG.props';

const Thunder = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 29} height={height || 28} viewBox="0 0 29 28" fill="none" {...props}>
      <path
        d="M0.25 14C0.25 2.66 2.91 0 14.25 0C25.59 0 28.25 2.66 28.25 14C28.25 25.34 25.59 28 14.25 28C2.91 28 0.25 25.34 0.25 14Z"
        fill={fillbg || '#F2F0F1'}
      />
      <g clipPath="url(#clip0_469_13869)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1352 6.73903C16.4124 6.8798 16.5555 7.19351 16.4801 7.49514L15.3538 12.0001H19.8333C20.0977 12.0001 20.3371 12.1563 20.4436 12.3983C20.55 12.6403 20.5034 12.9224 20.3248 13.1173L12.9914 21.1173C12.7757 21.3526 12.4234 21.402 12.1513 21.235C11.8792 21.068 11.7637 20.7316 11.8758 20.4327L13.538 16.0001H9.16666C8.90798 16.0001 8.67265 15.8505 8.56293 15.6162C8.45321 15.382 8.48891 15.1054 8.65451 14.9067L15.3212 6.90666C15.5202 6.66781 15.8579 6.59826 16.1352 6.73903ZM10.59 14.6668H14.5C14.7186 14.6668 14.9234 14.774 15.0479 14.9537C15.1725 15.1334 15.201 15.3628 15.1242 15.5675L14.331 17.6827L18.3178 13.3335H14.5C14.2947 13.3335 14.1009 13.2389 13.9745 13.0771C13.8482 12.9152 13.8034 12.7043 13.8532 12.5051L14.4797 9.99915L10.59 14.6668Z"
          fill={fill || '#070550'}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_13869">
          <rect width="16" height="16" fill="white" transform="translate(6.25 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Thunder;
