import React from 'react';

import { SVGProps } from './SVG.props';

const Mirror = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99967 0.666992C8.36786 0.666992 8.66634 0.965469 8.66634 1.33366V14.667C8.66634 15.0352 8.36786 15.3337 7.99967 15.3337C7.63148 15.3337 7.33301 15.0352 7.33301 14.667V1.33366C7.33301 0.965469 7.63148 0.666992 7.99967 0.666992Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M1.33301 4.00033C1.33301 3.63214 1.63148 3.33366 1.99967 3.33366H5.99967C6.36786 3.33366 6.66634 3.63214 6.66634 4.00033C6.66634 4.36852 6.36786 4.66699 5.99967 4.66699H2.66634V11.3337H5.99967C6.36786 11.3337 6.66634 11.6321 6.66634 12.0003C6.66634 12.3685 6.36786 12.667 5.99967 12.667H1.99967C1.63148 12.667 1.33301 12.3685 1.33301 12.0003V4.00033Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M9.99967 4.66699C10.3679 4.66699 10.6663 4.36852 10.6663 4.00033C10.6663 3.63214 10.3679 3.33366 9.99967 3.33366C9.63148 3.33366 9.33301 3.63214 9.33301 4.00033C9.33301 4.36852 9.63148 4.66699 9.99967 4.66699Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M12.6663 4.00033C12.6663 4.36852 12.3679 4.66699 11.9997 4.66699C11.6315 4.66699 11.333 4.36852 11.333 4.00033C11.333 3.63214 11.6315 3.33366 11.9997 3.33366C12.3679 3.33366 12.6663 3.63214 12.6663 4.00033Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M13.9997 4.66699C14.3679 4.66699 14.6663 4.36852 14.6663 4.00033C14.6663 3.63214 14.3679 3.33366 13.9997 3.33366C13.6315 3.33366 13.333 3.63214 13.333 4.00033C13.333 4.36852 13.6315 4.66699 13.9997 4.66699Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M14.6663 6.00033C14.6663 6.36851 14.3679 6.66699 13.9997 6.66699C13.6315 6.66699 13.333 6.36851 13.333 6.00033C13.333 5.63214 13.6315 5.33366 13.9997 5.33366C14.3679 5.33366 14.6663 5.63214 14.6663 6.00033Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M13.9997 8.66699C14.3679 8.66699 14.6663 8.36851 14.6663 8.00033C14.6663 7.63214 14.3679 7.33366 13.9997 7.33366C13.6315 7.33366 13.333 7.63214 13.333 8.00033C13.333 8.36851 13.6315 8.66699 13.9997 8.66699Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M14.6663 10.0003C14.6663 10.3685 14.3679 10.667 13.9997 10.667C13.6315 10.667 13.333 10.3685 13.333 10.0003C13.333 9.63214 13.6315 9.33366 13.9997 9.33366C14.3679 9.33366 14.6663 9.63214 14.6663 10.0003Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M13.9997 12.667C14.3679 12.667 14.6663 12.3685 14.6663 12.0003C14.6663 11.6321 14.3679 11.3337 13.9997 11.3337C13.6315 11.3337 13.333 11.6321 13.333 12.0003C13.333 12.3685 13.6315 12.667 13.9997 12.667Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M12.6663 12.0003C12.6663 12.3685 12.3679 12.667 11.9997 12.667C11.6315 12.667 11.333 12.3685 11.333 12.0003C11.333 11.6321 11.6315 11.3337 11.9997 11.3337C12.3679 11.3337 12.6663 11.6321 12.6663 12.0003Z"
        fill={fill ? fill : '#070550'}
      />
      <path
        d="M9.99967 12.667C10.3679 12.667 10.6663 12.3685 10.6663 12.0003C10.6663 11.6321 10.3679 11.3337 9.99967 11.3337C9.63148 11.3337 9.33301 11.6321 9.33301 12.0003C9.33301 12.3685 9.63148 12.667 9.99967 12.667Z"
        fill={fill ? fill : '#070550'}
      />
    </svg>
  );
};

export default Mirror;
