import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language, LanguageV2, Resolution, Subtitle, SubtitleStyles } from '../models/VideoFrame';
import {
  AddingCard,
  AddingImage,
  AddingPlayableItem,
  AddingText,
  Card,
  ObjectType,
  PlayableType
} from '../models/FrameItem';
import { PlayerState } from 'video-react';
import { createSelector } from 'reselect';
import { DURATION_STATIC_TYPE, findMaxLine, resizeImageToMin } from '@/features/video/services/videoService';
import { VIDEO } from './actionType';
import { getMutationSelector, getQuerySelector } from '@redux-requests/core';
import { ExportType } from '@/types';
import { ExpectRatioFHD, FHDScreen } from '@/constants/screens';

interface FrameObj {
  uid: string;
  type: ObjectType;
}

interface CardObj {
  cardId: string;
  textId: string;
}

export interface CropRatio {
  expectRatio?: string;
  x: number;
  y: number;
  width?: string | number;
  height?: string | number;
}

export interface VideoState extends PlayerState {
  addingTexts: AddingText[];
  addingImages: AddingImage[];
  addingPlayableItems: AddingPlayableItem[];
  addingCards: AddingCard[];
  originResolution?: Resolution;
  nextAspectRatio?: string;
  selectedObj?: FrameObj;
  totalPlayed: number;
  manualSeeking?: boolean;
  manualPause?: boolean;
  isInitial?: boolean;
  exporting?: ExportType;
  // subtitles?: Subtitle[];
  subtitles?: any[];
  subtitlesInit?: any[];
  selectedSubtitleLanguage?: Language;
  contentId?: string;
  contentType?: string;
  downloadLink?: string;
  downloadFileName?: string;
  platFromVideoId?: string;
  playerSize: { width: number; height: number };
  exportProcess: number;
  selectedTab?: string;
  selectedTabInside?: string;
  title?: string;
  resolution?: string;
  subtitleStyle?: SubtitleStyles | any;
  captionStyleSettings?: SubtitleStyles | any;
  maxDuration: number;
  widthOfTotalDurationFrame: number;
  timePerPixel: number;
  totalDuration: number;
  isLoadingData: boolean;
  width?: string;
  height?: string;
  crop?: CropRatio | any;
  storyHubMainVideoId?: string;
  language?: LanguageV2;
  timeLineSelected: { uid: string; type: string } | any;
  isDrawerAnimationBar?: boolean;
  elementSelected?: any;
  isSelectTextElement?: boolean;
  audioSelected: {
    uid: string;
    volume?: number;
    type?: string;
  };
  previousTab: string;
  currentTimeSelected: number;
  playerSizeHD?: { width: number; height: number };
  sideBarWidth: 0;
  selectedCard?: CardObj;
  waitingForLoadVideo: boolean;
  resizeLeftX: { value: number; id: string };
  screenDimension: string;
  quantityBrandItems: number;
  quantityStoryItems: number;
  quantityUploadItems: number;
  heightOfFrame: number;
  leftMarginControlScroll: number;
  createdAt?: string;
  listVersions: any[];
  versionDraftSelected: number;
  actionUndoRedo?: boolean;
  backgroundColor: string;
  isShowCaption?: boolean;
  counterLoadingVideo?: number;
  timeStampSeek?: number;
  idElementSelected?: string;
  tabEditCaption?: number; // 0 - rename caption file / 1 - edit caption / 2 - translate
  captionViewMode?: string; // UPLOAD - overal / UPLOAD - upload file / GENERATE - generate caption / EDIT - edit caption
  isFinishedLoading?: boolean;
}

// Define the initial state using that type
const initialState: VideoState = {
  /**
   * Returns the URL of the current video
   */
  currentSrc: '',
  /**
   * Returns the length of the current video (in seconds)
   */
  duration: 0,
  /**
   * Returns the current playback position in the video (in seconds)
   */
  currentTime: 0,
  /**
   * Returns the current seeking position in the video (in seconds)
   */
  seekingTime: 0,
  /**
   * Returns a TimeRanges object representing the buffered parts of the video
   */
  buffered: {},
  /**
   * Returns whether the player needs to buffer the next frame
   */
  waiting: false,
  manualSeeking: false,
  /**
   * Returns whether the user is currently seeking in the video
   */
  seeking: false,
  /**
   * Returns whether the player has been paused
   */
  paused: true,
  /**
   * Returns whether the player has been paused by the player itself
   */
  autoPaused: false,
  /**
   * Returns whether the playback of the video has ended or not
   */
  ended: false,
  /**
   * Returns the speed of the video playback
   */
  playbackRate: 1,
  /**
   * Returns whether the video is muted or not
   */
  muted: false,
  /**
   * Returns the volume of the video.
   */
  volume: 1,
  /**
   * Returns the current ready state of the video
   */
  readyState: 0,
  /**
   * Returns the current network state of the video
   */
  networkState: 0,
  /**
   * Returns the volume of the video
   */
  videoWidth: 0,
  /**
   * Returns the height of the video
   */
  videoHeight: 0,
  /**
   * Returns whether the video has been started
   */
  hasStarted: true,
  /**
   * Returns whether the user is in activity.
   */
  userActivity: false,
  /**
   * Returns whether the player is in activity.
   */
  isActive: true,
  /**
   * Returns whether the player is in fullscreen.
   */
  isFullscreen: false,
  /**
   * Set the id of the video element.
   */
  videoId: '',
  title: '',

  addingTexts: [],
  addingImages: [],
  addingPlayableItems: [],
  addingCards: [],
  selectedObj: undefined,
  totalPlayed: 0,
  manualPause: true,
  isInitial: true,
  exporting: ExportType.idle,
  subtitles: [],
  subtitlesInit: [],
  contentId: '',
  contentType: '',
  downloadLink: '',
  downloadFileName: '',
  playerSize: { width: 0, height: 0 },
  exportProcess: 0,
  selectedTab: 'Media',
  selectedTabInside: 'Brand',
  resolution: '1920x1080',
  maxDuration: 0,
  totalDuration: 1,
  widthOfTotalDurationFrame: 1,
  timePerPixel: 1,
  isLoadingData: false,
  width: '',
  height: '',
  crop: { expectRatio: '', x: 0, y: 0, width: 0, height: 0 },
  storyHubMainVideoId: '',
  language: {
    name: '',
    code: ''
  },
  timeLineSelected: {
    uid: '',
    type: ''
  },
  isDrawerAnimationBar: false,
  elementSelected: null,
  isSelectTextElement: false,
  audioSelected: {
    uid: '',
    volume: 100
  },
  previousTab: 'Media',
  currentTimeSelected: 0,
  playerSizeHD: { width: 0, height: 0 },
  sideBarWidth: 0,
  selectedCard: undefined,
  waitingForLoadVideo: false,
  resizeLeftX: { value: 0, id: '' },
  screenDimension: '1920x1080',
  quantityBrandItems: 0,
  quantityStoryItems: 0,
  quantityUploadItems: 0,
  heightOfFrame: 0,
  leftMarginControlScroll: 0,
  createdAt: new Date().toISOString(),
  listVersions: [],
  versionDraftSelected: -1,
  actionUndoRedo: false,
  isShowCaption: true,
  counterLoadingVideo: 0,
  timeStampSeek: 0,
  backgroundColor: '#000000',
  idElementSelected: '',
  tabEditCaption: 0,
  captionViewMode: 'OVERAL',
  isFinishedLoading: false
};

export const videoSlice = createSlice({
  name: 'video',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    update: (state, action: PayloadAction<Partial<VideoState>>) => {
      // console.log('payload: ', action.payload);
      return {
        ...state,
        ...action.payload,
        loadingIcon: false,
        createdAt: new Date().toISOString()
      };
    },
    requestUploadVideo: (state) => ({
      ...state,
      loadingIcon: true
    }),
    addAddingTexts: (state, action: PayloadAction<Partial<AddingText>>) => {
      const line = findMaxLine({
        addingPlayableItems: state.addingPlayableItems,
        addingImages: state.addingImages,
        addingTexts: state.addingTexts,
        addingCards: state.addingCards,
        totalPlayed: state.totalPlayed
      });
      //end
      const text = new AddingText(
        action.payload.value || '',
        action.payload.styles ? { ...action.payload.styles, color: 'rgba(255,255,255,1)' } : {},
        action.payload.fontFile,
        {
          uid: '',
          start: state.totalPlayed,
          end: state.totalPlayed + DURATION_STATIC_TYPE,
          line: line,
          type: ObjectType.text,
          name: action.payload.name,
          position: action.payload.position,
          x: action.payload.position?.x,
          y: action.payload.position?.y
        }
      );
      return {
        ...state,
        addingTexts: state.addingTexts?.concat(text)
      };
    },
    addImages: (state, action: PayloadAction<Partial<AddingImage>>) => {
      const lastImageIndex = state.addingImages.length;
      const { width, height } = resizeImageToMin(action.payload.width, action.payload.height);
      const line = findMaxLine({
        addingPlayableItems: state.addingPlayableItems,
        addingImages: state.addingImages,
        addingTexts: state.addingTexts,
        addingCards: state.addingCards,
        totalPlayed: state.totalPlayed
      });

      const image = new AddingImage(
        action.payload.filePath || '',
        action.payload.fileKey || '',
        action.payload?.isLogo,
        {
          uid: '',
          name: `Image ${lastImageIndex + 1 < 10 ? `0${lastImageIndex + 1}` : lastImageIndex + 1}`,
          start: state.totalPlayed,
          end: state.totalPlayed + DURATION_STATIC_TYPE,
          width: width,
          height: height,
          line: line,
          type: ObjectType.image
        }
      );

      return {
        ...state,
        addingImages: state.addingImages?.concat(image)
      };
    },
    addPlayableItem: (state, action: PayloadAction<Partial<AddingPlayableItem>>) => {
      const totalItems = [...state.addingImages, ...state.addingPlayableItems, ...state.addingTexts];
      const latestLine = totalItems.filter(
        (value: any, index, self) => self.findIndex((m: any) => m.line === value.line) === index
      ).length;
      let lastVideoIndex = 1;
      const itemTypes = state.addingPlayableItems.filter((item) => item.type === action.payload.type);
      lastVideoIndex = !!itemTypes.length ? itemTypes.length : 1;
      // calculate next position at line 0 when add new
      const videoInOneLine = state.addingPlayableItems.filter((item) => item.line === 0 && !item.isHide);
      const textInOneLine = state.addingImages.filter((item) => item.line === 0);
      const imgInOneLine = state.addingTexts.filter((item) => item.line === 0);
      const cardInOneLine = state.addingCards.filter((item) => item.line === 0);
      const itemsInOneLine = [...videoInOneLine, ...textInOneLine, ...imgInOneLine, ...cardInOneLine];
      let maxPosition = 0;
      itemsInOneLine.forEach((item) => {
        if (item.end && item.end > maxPosition) {
          maxPosition = item.end;
        }
      });
      let startPosition = maxPosition;
      let endPosition = action.payload.duration ? action.payload.duration + maxPosition : 0;
      if (action.payload.type === PlayableType.audio) {
        startPosition = 0;
        endPosition = action.payload.duration ? action.payload.duration : 0;
      }
      //end
      const { filePath, type, duration, fileKey, resolution, main, thumbnails, idSubtitleLinked, blurBackground } =
        action.payload;
      const playableItem = new AddingPlayableItem({
        filePath: filePath || '',
        type: type!!,
        duration: duration || 0,
        fileKey: fileKey || '',
        trim: { start: 0, end: duration || 0 },
        resolution: resolution || { width: 1920, height: 1080 },
        main: main || false,
        thumbnails: thumbnails || [],
        frameStatus: {
          uid: '',
          name: `${
            action.payload.type === PlayableType.video
              ? action.payload.name
              : `Music ${lastVideoIndex + 1 < 10 ? `0${lastVideoIndex + 1}` : lastVideoIndex + 1}`
          } `,
          start: startPosition,
          end: endPosition
        },
        line: action.payload.line || action.payload.type === PlayableType.audio ? latestLine : 0,
        idSubtitleLinked: idSubtitleLinked || '',
        blurBackground
      });
      return {
        ...state,
        addingPlayableItems: state.addingPlayableItems?.concat(playableItem)
      };
    },
    addAddingCards: (state, action: PayloadAction<Partial<AddingCard>>) => {
      const line = findMaxLine({
        addingPlayableItems: state.addingPlayableItems,
        addingImages: state.addingImages,
        addingTexts: state.addingTexts,
        addingCards: state.addingCards,
        totalPlayed: state.totalPlayed
      });
      const payload = action.payload;
      const firstElement = payload?.elementTexts as any[];
      const textElement = [
        {
          uid: firstElement[0].uid,
          isShow: firstElement[0].isShow,
          name: firstElement[0].name,
          value: firstElement[0].value,
          styles: firstElement[0].styles,
          position: {
            x: 0,
            y: 0
          }
        }
      ];
      const listElements = payload.type === ObjectType.card ? payload.elementTexts : textElement;
      const card = new AddingCard({
        backgroundColor: action.payload.backgroundColor || '',
        elementTexts: listElements || [],
        isChanged: payload.isChanged,
        frameData: {
          uid: payload.uid || '',
          start: state.totalPlayed,
          end: state.totalPlayed + DURATION_STATIC_TYPE,
          line: line,
          name: payload.name,
          position: payload.position,
          width: payload.width,
          height: payload.height,
          type: payload.type
        }
      });
      return {
        ...state,
        addingCards: state.addingCards?.concat(card)
      };
    },
    updateSelectedText: (state, action: PayloadAction<Partial<AddingText>>) => {
      const selectedTextIndex = state.addingTexts?.findIndex((text) => action.payload.uid === text.uid);
      if (selectedTextIndex !== undefined && selectedTextIndex >= 0) {
        const selectedText = state.addingTexts[selectedTextIndex];
        let newItems = [...state.addingTexts];
        newItems[selectedTextIndex] = { ...selectedText, ...action.payload };
        return {
          ...state,
          addingTexts: newItems
        };
      }
      return state;
    },
    updateSelectedImage: (state, action: PayloadAction<Partial<AddingImage>>) => {
      const selectedImageIndex = state.addingImages.findIndex((img) => action.payload.uid === img.uid);
      if (selectedImageIndex !== undefined && selectedImageIndex >= 0) {
        const selectedImg = state.addingImages[selectedImageIndex];
        let newItems = [...state.addingImages];
        newItems[selectedImageIndex] = { ...selectedImg, ...action.payload };
        return {
          ...state,
          addingImages: newItems
        };
      }
      return state;
    },
    updateSelectedCard: (state, action: PayloadAction<Partial<AddingCard>>) => {
      const selectedCardIndex = state.addingCards.findIndex((card) => action.payload.uid === card.uid);
      if (selectedCardIndex !== undefined && selectedCardIndex >= 0) {
        const selectedCard = state.addingCards[selectedCardIndex];
        let newItems = [...state.addingCards];
        newItems[selectedCardIndex] = { ...selectedCard, ...action.payload };
        return {
          ...state,
          addingCards: newItems
        };
      }
      return state;
    },
    updatePlayableItem: (state, action: PayloadAction<Partial<AddingPlayableItem | any>>) => {
      const selectedItemIndex = state.addingPlayableItems.findIndex((item) => action.payload.uid === item.uid);
      if (selectedItemIndex !== undefined && selectedItemIndex >= 0) {
        const selectedItem = state.addingPlayableItems[selectedItemIndex];
        let newItems = [...state.addingPlayableItems];
        newItems[selectedItemIndex] = { ...selectedItem, ...action.payload };
        return {
          ...state,
          addingPlayableItems: newItems
        };
      }
      return state;
    },
    reduceCurrentLineItem: (state) => {
      const addingPlayableItems = [...state.addingPlayableItems];
      const addingImageItems = [...state.addingImages];
      const addingTextItems = [...state.addingTexts];

      return {
        ...state,
        addingPlayableItems: addingPlayableItems.map((item) => {
          return { ...item, line: item.line && item.line - 1 };
        }),
        addingImages: addingImageItems.map((item) => {
          return { ...item, line: item.line && item.line - 1 };
        }),
        addingTexts: addingTextItems.map((item) => {
          return { ...item, line: item.line && item.line - 1 };
        })
      };
    },
    splitPlayableItem: (
      state,
      action: PayloadAction<{ newItem: AddingPlayableItem; originalItem: AddingPlayableItem } | any>
    ) => {
      const playableList = [...state.addingPlayableItems];

      const { newItem, originalItem } = action.payload;
      const index = playableList.findIndex((elm) => elm.uid === originalItem.uid);
      playableList.splice(index, 1, originalItem);
      playableList.push(newItem);

      return {
        ...state,
        addingPlayableItems: playableList
      };
    },
    splitImageItem: (state, action: PayloadAction<{ newItem: AddingImage; originalItem: AddingImage } | any>) => {
      const imageList = [...state.addingImages];
      const { newItem, originalItem } = action.payload;
      const index = imageList.findIndex((elm) => elm.uid === originalItem.uid);
      imageList.splice(index, 1, originalItem);
      imageList.push(newItem);
      return {
        ...state,
        addingImages: imageList
      };
    },
    splitCardItem: (state, action: PayloadAction<{ newItem: AddingCard; originalItem: AddingCard } | any>) => {
      const cardList = [...state.addingCards];
      const { newItem, originalItem } = action.payload;
      const index = cardList.findIndex((elm) => elm.uid === originalItem.uid);
      cardList.splice(index, 1, originalItem);
      cardList.push(newItem);
      return {
        ...state,
        addingCards: cardList
      };
    },
    splitTextItem: (state, action: PayloadAction<{ newItem: AddingText; originalItem: AddingText } | any>) => {
      const textList = [...state.addingTexts];
      const { newItem, originalItem } = action.payload;
      const index = textList.findIndex((elm) => elm.uid === originalItem.uid);
      textList.splice(index, 1, originalItem);
      textList.push(newItem);
      return {
        ...state,
        addingTexts: textList
      };
    },
    removePlayableItem: (state, action: PayloadAction<{ uid: string }>) => {
      return {
        ...state,
        addingPlayableItems: [...state.addingPlayableItems?.filter((img) => img.uid !== action.payload.uid)]
      };
    },
    removeImage: (state, action: PayloadAction<Partial<AddingImage>>) => {
      return {
        ...state,
        addingImages: [...state.addingImages?.filter((img) => img.uid !== action.payload.uid)]
      };
    },
    removeCard: (state, action: PayloadAction<Partial<AddingCard>>) => {
      return {
        ...state,
        addingCards: [...state.addingCards?.filter((card) => card.uid !== action.payload.uid)]
      };
    },
    removeText: (state, action: PayloadAction<Partial<AddingText>>) => {
      return {
        ...state,
        addingTexts: [...state.addingTexts?.filter((img) => img.uid !== action.payload.uid)]
      };
    },
    updateSubtitle: (state, action: PayloadAction<Partial<Subtitle>>) => {
      const selectedSubtitleIndex = state.subtitles?.findIndex((sub) => sub.id === action.payload.id);
      if (selectedSubtitleIndex !== undefined && selectedSubtitleIndex >= 0) {
        const selectedItem = state.subtitles?.[selectedSubtitleIndex];
        let newItems = state.subtitles ? [...state.subtitles] : [];
        if (newItems.length) {
          newItems[selectedSubtitleIndex] = {
            ...selectedItem!,
            ...action.payload
          };
        }
        return {
          ...state,
          subtitles: newItems,
          subtitleStyle: action.payload?.subtitleStyle
        };
      }
    },
    updateFromTemplate: (state, action: PayloadAction<Partial<any>>) => {
      const data = action.payload;
      return {
        ...state,
        addingImages: [...data?.addingImages],
        addingPlayableItems: [...data?.addingPlayableItems],
        addingTexts: [...data?.addingTexts],
        addingCards: [...data?.addingCards],
        contentId: state?.contentId,
        contentType: state?.contentType,
        // crop: { expectRatio: state.nextAspectRatio as string, x: state.playerSize.width, y: state.playerSize.height },
        crop: { expectRatio: ExpectRatioFHD, x: 0, y: 0, width: FHDScreen.width, height: FHDScreen.height },
        duration: data?.duration,
        merge: {},
        resolution: state?.resolution,
        subtitleStyle: data?.subtitleStyle,
        subtitles: data?.subtitles,
        subtitlesInit: data?.subtitlesInit,
        title: state?.title,
        manualPause: state?.manualPause,
        nextAspectRatio: '16:9'
      };
    },
    saveStoryHubMainVideoId: (state, action: PayloadAction<Partial<string>>) => {
      return {
        ...state,
        storyHubMainVideoId: action.payload
      };
    },
    openDrawerAnimationBar: (state) => {
      return {
        ...state,
        isDrawerAnimationBar: true
      };
    },
    closeDrawerAnimationBar: (state) => {
      return {
        ...state,
        isDrawerAnimationBar: false
      };
    },
    selectElement: (state, action: PayloadAction<Partial<object>>) => {
      return {
        ...state,
        elementSelected: action.payload
      };
    },
    unSelectElement: (state) => {
      return {
        ...state,
        elementSelected: null
      };
    },
    selectTextOrImage: (state, action: PayloadAction<Partial<boolean>>) => {
      return {
        ...state,
        isSelectTextElement: action.payload
      };
    },
    unSelectTextOrImage: (state) => {
      return {
        ...state,
        isSelectTextElement: false
      };
    },
    setPlayerSizeHd: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        playerSizeHD: action.payload
      };
    },
    updateSelectedValueStylesInCard: (state, action: PayloadAction<Partial<any>>) => {
      const selectedCardIndex = state.addingCards.findIndex((card) => action.payload.cardId === card.uid);
      const selectedValueIndex = state.addingCards[selectedCardIndex]?.elementTexts?.findIndex(
        (text) => action.payload.textId === text.uid
      ) as number;
      const cardSelected = state.addingCards[selectedCardIndex] as any;
      const updatedElementTexts = action.payload.card.elementTexts.map((element: any) => {
        if (element.uid === action.payload.textId) {
          return {
            ...element,
            styles: {
              ...element.styles,
              ...action.payload?.cardEdited?.styles
            }
          };
        }
        return element;
      });

      const dataPayload = {
        ...action.payload.card,
        elementTexts: updatedElementTexts
      };

      if (selectedCardIndex !== undefined && selectedCardIndex >= 0) {
        const selectedCard = state.addingCards[selectedCardIndex]; // get card edited
        const textSelected = cardSelected?.elementTexts[selectedValueIndex]; // get elementText edited
        let newItems = [...(state.addingCards[selectedCardIndex]?.elementTexts as any)]; // get array elementTexts
        let newCard = [...(state.addingCards as any)]; // get array elementTexts

        newItems[selectedValueIndex] = { ...textSelected, ...action.payload.cardEdited };
        newCard[selectedCardIndex] = { ...selectedCard, ...dataPayload };

        return {
          ...state,
          addingCards: [...newCard]
        };
      }
      return state;
    },
    pushVersionDraft: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        listVersions: action.payload
      };
    },
    setVersionDraftSelected: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        versionDraftSelected: action.payload
      };
    },
    saveActUndoRedo: (state) => {
      return {
        ...state,
        actionUndoRedo: true
      };
    },
    clearActUndoRedo: (state) => {
      return {
        ...state,
        actionUndoRedo: false
      };
    },
    setBackgroundColor: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        backgroundColor: action.payload
      };
    },
    setShowCaption: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isShowCaption: action.payload
      };
    },
    setCounterLoadingVideo: (state) => {
      return {
        ...state,
        counterLoadingVideo: (state?.counterLoadingVideo as number) + 1
      };
    },
    clearCounterLoadingVideo: (state) => {
      return {
        ...state,
        counterLoadingVideo: 0
      };
    },
    setTimeStampSeek: (state) => {
      return {
        ...state,
        timeStampSeek: Date.now()
      };
    },
    unSelectAudio: (state) => {
      return {
        ...state,
        audioSelected: {
          uid: ''
        }
      };
    },
    selectIdElement: (state, action: PayloadAction<Partial<string>>) => {
      return {
        ...state,
        idElementSelected: action.payload
      };
    },
    unselectIdElement: (state) => {
      return {
        ...state,
        idElementSelected: ''
      };
    },
    setCaptionSettings: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        captionStyleSettings: action.payload
      };
    },
    setTabEditCaption: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        tabEditCaption: action.payload
      };
    },
    setCaptionViewMode: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        captionViewMode: action.payload
      };
    },
    setFinishedLoading: (state) => {
      return {
        ...state,
        isFinishedLoading: true
      };
    }
  }
});

export const {
  update,
  addAddingTexts,
  requestUploadVideo,
  addImages,
  addAddingCards,
  updateSelectedImage,
  updateSelectedText,
  updateSelectedCard,
  removeImage,
  removeText,
  removeCard,
  addPlayableItem,
  updatePlayableItem,
  removePlayableItem,
  updateSubtitle,
  updateFromTemplate,
  saveStoryHubMainVideoId,
  splitImageItem,
  splitPlayableItem,
  splitTextItem,
  splitCardItem,
  openDrawerAnimationBar,
  closeDrawerAnimationBar,
  selectElement,
  unSelectElement,
  selectTextOrImage,
  unSelectTextOrImage,
  reduceCurrentLineItem,
  setPlayerSizeHd,
  updateSelectedValueStylesInCard,
  pushVersionDraft,
  setVersionDraftSelected,
  saveActUndoRedo,
  clearActUndoRedo,
  setShowCaption,
  setCounterLoadingVideo,
  clearCounterLoadingVideo,
  setTimeStampSeek,
  setBackgroundColor,
  unSelectAudio,
  selectIdElement,
  unselectIdElement,
  setCaptionSettings,
  setTabEditCaption,
  setCaptionViewMode,
  setFinishedLoading
} = videoSlice.actions;

export const selectVideo = (state: RootState) => state.video;

export const selectedAddingObjDetails = createSelector(
  selectVideo,
  (
    video
  ):
    | (AddingImage & { type: string })
    | (AddingText & { type: string })
    | (AddingCard & { type: string })
    | undefined => {
    const selectedObj = video.selectedObj;
    if (selectedObj) {
      const obj =
        selectedObj.type === ObjectType.image
          ? video.addingImages?.find((img) => img.uid === selectedObj.uid)
          : selectedObj.type === ObjectType.text
          ? video.addingTexts?.find((text) => text.uid === selectedObj.uid)
          : video.addingCards?.find((card) => card.uid === selectedObj.uid);
      if (obj) {
        return { ...obj, type: selectedObj.type };
      }
    }
  }
);

export const selectedAddingCardDetails = createSelector(selectVideo, (video): AddingCard | undefined => {
  const selectedCard = video.selectedCard;
  if (selectedCard) {
    const cardObj = video?.addingCards?.find((card) => card.uid === selectedCard?.cardId) as any;
    const textObj = cardObj?.elementTexts?.find((text: Card) => text.uid === selectedCard?.textId);
    if (textObj) {
      return { ...textObj, cardId: selectedCard?.cardId };
    }
  }
});

export const getAudioTracks = createSelector(selectVideo, ({ addingPlayableItems, totalPlayed }) => {
  return addingPlayableItems.filter(
    //check totalPlayed in range start and end
    (item) => item.type === PlayableType.audio
  );
});

export const uploadSelector = getMutationSelector({ type: VIDEO.UPLOAD_FILES });

export const mediaSelector = getQuerySelector({ type: VIDEO.GET_MEDIA });

export const storiesSelector = getQuerySelector({ type: VIDEO.GET_STORIES });

export const getDraftSelector = getQuerySelector({ type: VIDEO.GET_DRAFT });

export const getMediaFiles = createSelector(mediaSelector, ({ data }) => {
  const media = data?.data || {};
  return Object.keys(media)
    .map((key) => media[key])
    .flat();
});

export const getFontsSelector = getQuerySelector({ type: VIDEO.GET_FONT });

export const getFontList = createSelector(getFontsSelector, ({ data }) => {
  return (
    data?.items
      .map((item: any) => ({ ...item, label: item.family, value: item.family }))
      .sort((a: any, b: any) => {
        return a.label.localeCompare(b.label);
      }) || []
  );
});

export const getPlatformVideoSelector = getQuerySelector({ type: VIDEO.GET_PLATFORM_VIDEO });

export const getStories = createSelector(getPlatformVideoSelector, ({ data }) => {
  return data?.data || [];
});

export const getLanguagesSelector = getQuerySelector({ type: VIDEO.GET_LANGUAGES });

export const getLanguagesSubtitles = createSelector(getLanguagesSelector, ({ data }) => {
  return data?.data?.languagesSubtitles?.map((lg: { label: string; langCode: string }) => {
    return { ...lg, value: lg.langCode };
  });
});

export const getLanguagesTranslate = createSelector(getLanguagesSelector, ({ data }) => {
  return data?.data?.languagesTranslate?.map((lg: { label: string; langCode: string }) => {
    return { ...lg, value: lg.langCode };
  });
});

export const getSelectedSubtitle = (state: RootState) => {
  return state.video.subtitles?.find(
    (sub) => sub?.language?.langCode === state.video.selectedSubtitleLanguage?.langCode
  );
};

export default videoSlice.reducer;
