import React, { useState, useEffect, useCallback } from 'react';
import BrandTab from './Features/Brand';
import StoriesTab from './Features/Stories';
import UploadTab from './Features/Upload';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useAppSelector } from '@/app/hooks';

interface IFeaturesTab {}

const FeaturesTab: React.FC<IFeaturesTab> = () => {
  const [activeKey, setActiveKey] = useState<number>(0);
  const dispatch = useDispatch();
  const { quantityBrandItems, quantityStoryItems, quantityUploadItems, selectedTabInside } =
    useAppSelector(selectVideo);

  const TabsConf = [
    {
      name: 'Brand',
      comp: <BrandTab />,
      quantity: quantityBrandItems
    },
    {
      name: 'Stories',
      comp: <StoriesTab />,
      quantity: quantityStoryItems
    },
    {
      name: 'Upload',
      comp: <UploadTab />,
      quantity: quantityUploadItems
    }
  ];

  useEffect(() => {
    switch (activeKey) {
      case 0:
        dispatch(update({ selectedTabInside: 'Brand' }));
        break;
      case 1:
        dispatch(update({ selectedTabInside: 'Stories' }));
        break;
      case 2:
        dispatch(update({ selectedTabInside: 'Upload' }));
        break;

      default:
        dispatch(update({ selectedTabInside: 'Group' }));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  const updateActiveKey = useCallback(() => {
    switch (selectedTabInside) {
      case 'Stories':
        setActiveKey(1);
        break;
      case 'Upload':
        setActiveKey(2);
        break;
      default:
        setActiveKey(0);
        break;
    }
  }, [selectedTabInside]);

  useEffect(() => {
    updateActiveKey();
  }, [updateActiveKey]);

  return (
    <>
      <RulerContainer>
        {TabsConf.map((item, idx) => (
          <TabItem key={idx} isSelected={activeKey === idx} onClick={() => setActiveKey(idx)}>
            <div>{item.name}</div>
            {/* <Number>{item.quantity}</Number> */}
          </TabItem>
        ))}
      </RulerContainer>
      {TabsConf.map((item, idx) => idx === activeKey && <>{item.comp}</>)}
    </>
  );
};

export default FeaturesTab;

const RulerContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

const TabItem = styled.div<{ isSelected?: boolean }>`
  width: calc(100% / 3);
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `${theme.brand.secondary} solid 2px` : `${theme.grey.shade3} solid 2px`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.altGrey.shade8};
  }
`;

/*
const Number = styled.div`
  height: 20px;
  width: auto;
  min-width: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.altGrey.shade4};
  text-align: center;
  font-size: 10px;
  margin-left: 5px;
  padding-top: 3px;
`;
*/
