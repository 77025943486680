import styled from '@emotion/styled';
import Typography from '../Typography';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  flex-direction: column;
`;

export const Heading = styled(Typography)`
  font-weight: bold;
`;

export const ToggleBox = styled.div<{
  on?: boolean;
  color?: string;
}>`
  cursor: pointer;
  width: 42px;
  height: 24px;
  border-radius: 8px;

  display: flex;

  align-items: center;
  transition: all 0.2s ease-in-out;

  ${({ on, color }) => {
    if (on === true) {
      return `
        justify-content: flex-end;
        background: ${color ? color : '#91DBB6'};

        svg {
          opacity: 1;
        }
      `;
    }

    return `
      justify-content: flex-start;
      background: #ded9db;

      svg {
        opacity: 0;
      }
    `;
  }}
`;

export const MovingBox = styled.span`
  margin: 2px;
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(181, 173, 176, 0.15);
  border-radius: 8px;
  background: #fff;
`;

export const Description = styled(Typography)``;
