import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Spinner from '@/components/Spinner';

interface IModalLoadingProps {
  isOpen?: boolean;
}

const ModalLoading: React.FunctionComponent<IModalLoadingProps> = ({ isOpen }) => {
  const theme = useTheme();

  return (
    <Modal
      isOpen={isOpen as boolean}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="130px"
      customFixedHeight="130px"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <Container>
        <Spinner width={40} height={40} />
      </Container>
      <MessageConfirm>Loading...</MessageConfirm>
    </Modal>
  );
};

export default ModalLoading;

export const Container = styled.div`
  margin-left: 14px;
`;

export const MessageConfirm = styled.div`
  margin-left: -5px;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
  font-family: 'Objective';
  font-weight: 600;
`;
