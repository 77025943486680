import React from 'react';

import { SVGProps } from './SVG.props';

const SplitIcon = (props: SVGProps): JSX.Element => {
  // const { width, height } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g id="mirror">
        <g id="line">
          <path d="M7.99992 0.666748C8.36811 0.666748 8.66659 0.965225 8.66659 1.33341V14.6667C8.66659 15.0349 8.36811 15.3334 7.99992 15.3334C7.63173 15.3334 7.33325 15.0349 7.33325 14.6667V1.33341C7.33325 0.965225 7.63173 0.666748 7.99992 0.666748Z" fill="#030220" />
          <path d="M1.33325 4.00008C1.33325 3.63189 1.63173 3.33341 1.99992 3.33341H5.99992C6.36811 3.33341 6.66659 3.63189 6.66659 4.00008C6.66659 4.36827 6.36811 4.66675 5.99992 4.66675H2.66659V11.3334H5.99992C6.36811 11.3334 6.66659 11.6319 6.66659 12.0001C6.66659 12.3683 6.36811 12.6667 5.99992 12.6667H1.99992C1.63173 12.6667 1.33325 12.3683 1.33325 12.0001V4.00008Z" fill="#030220" />
          <path d="M9.99992 4.66675C10.3681 4.66675 10.6666 4.36827 10.6666 4.00008C10.6666 3.63189 10.3681 3.33341 9.99992 3.33341C9.63173 3.33341 9.33325 3.63189 9.33325 4.00008C9.33325 4.36827 9.63173 4.66675 9.99992 4.66675Z" fill="#030220" />
          <path d="M12.6666 4.00008C12.6666 4.36827 12.3681 4.66675 11.9999 4.66675C11.6317 4.66675 11.3333 4.36827 11.3333 4.00008C11.3333 3.63189 11.6317 3.33341 11.9999 3.33341C12.3681 3.33341 12.6666 3.63189 12.6666 4.00008Z" fill="#030220" />
          <path d="M13.9999 4.66675C14.3681 4.66675 14.6666 4.36827 14.6666 4.00008C14.6666 3.63189 14.3681 3.33341 13.9999 3.33341C13.6317 3.33341 13.3333 3.63189 13.3333 4.00008C13.3333 4.36827 13.6317 4.66675 13.9999 4.66675Z" fill="#030220" />
          <path d="M14.6666 6.00008C14.6666 6.36827 14.3681 6.66675 13.9999 6.66675C13.6317 6.66675 13.3333 6.36827 13.3333 6.00008C13.3333 5.63189 13.6317 5.33341 13.9999 5.33341C14.3681 5.33341 14.6666 5.63189 14.6666 6.00008Z" fill="#030220" />
          <path d="M13.9999 8.66675C14.3681 8.66675 14.6666 8.36827 14.6666 8.00008C14.6666 7.63189 14.3681 7.33341 13.9999 7.33341C13.6317 7.33341 13.3333 7.63189 13.3333 8.00008C13.3333 8.36827 13.6317 8.66675 13.9999 8.66675Z" fill="#030220" />
          <path d="M14.6666 10.0001C14.6666 10.3683 14.3681 10.6667 13.9999 10.6667C13.6317 10.6667 13.3333 10.3683 13.3333 10.0001C13.3333 9.63189 13.6317 9.33341 13.9999 9.33341C14.3681 9.33341 14.6666 9.63189 14.6666 10.0001Z" fill="#030220" />
          <path d="M13.9999 12.6667C14.3681 12.6667 14.6666 12.3683 14.6666 12.0001C14.6666 11.6319 14.3681 11.3334 13.9999 11.3334C13.6317 11.3334 13.3333 11.6319 13.3333 12.0001C13.3333 12.3683 13.6317 12.6667 13.9999 12.6667Z" fill="#030220" />
          <path d="M12.6666 12.0001C12.6666 12.3683 12.3681 12.6667 11.9999 12.6667C11.6317 12.6667 11.3333 12.3683 11.3333 12.0001C11.3333 11.6319 11.6317 11.3334 11.9999 11.3334C12.3681 11.3334 12.6666 11.6319 12.6666 12.0001Z" fill="#030220" />
          <path d="M9.99992 12.6667C10.3681 12.6667 10.6666 12.3683 10.6666 12.0001C10.6666 11.6319 10.3681 11.3334 9.99992 11.3334C9.63173 11.3334 9.33325 11.6319 9.33325 12.0001C9.33325 12.3683 9.63173 12.6667 9.99992 12.6667Z" fill="#030220" />
        </g>
      </g>
    </svg>
  );
};

export default SplitIcon;
