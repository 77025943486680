import { CSSProperties, FC, useRef, useEffect } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import Box from './Box';
import ResizeAbleBox from './Resizeable';

function getStyles(left: number, top: number, isDragging: boolean, width: number, line: number): CSSProperties {
  const transform = `translate3d(${left > 0 ? left : 0}px, ${top}px, 0)`;
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    width: `${width}px`,
    zIndex: line
  };
}

export interface Trim {
  start: number | any;
  end: number | any;
}
export interface DraggableBoxProps {
  id: string;
  name: string | undefined;
  left: number;
  top: number;
  width: number;
  duration: number;
  trim: Trim;
  itemType: string;
  start: number | any;
  end: number | any;
  index: number;
  isMainVideo: boolean;
  thumbnails: string[] | any;
  line: number | any;
  isHide?: boolean;
  animation?: any;
  isApplyTransition?: boolean | any;
  isNeedApplyTransition?: boolean;
  idSubtitleLinked?: string;
}

export const DraggableBox: FC<DraggableBoxProps> = (props) => {
  const {
    id,
    name,
    left,
    top,
    width,
    duration,
    trim,
    itemType,
    start,
    end,
    index,
    isMainVideo,
    thumbnails,
    line,
    isHide,
    animation,
    isApplyTransition,
    isNeedApplyTransition,
    idSubtitleLinked
  } = props;
  const durationDisplay = end - start;

  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'box',
      item: { id, left, top, name, itemType, duration: durationDisplay || 0 },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [id, left, top, name, durationDisplay]
  )

  const convertDurationToHHMMSS = (duration: number): string => {
    const fullDateStr = new Date((Math.round(duration) || 0) * 1000)?.toISOString();
    const HHMMSS = fullDateStr.substr(11, 8);
    const display = HHMMSS.split(/^00:/);

    return display[1];
  }

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResizeAbleBox
      line={line}
      start={start as number}
      end={end as number}
      id={id}
      duration={duration}
      trim={trim}
      type={itemType}
      style={getStyles(left, top, isDragging, width, line)}
      width={width}
      isMainVideo={isMainVideo}
      isHide={isHide}
      isApplyTransition={isApplyTransition}
      isNeedApplyTransition={isNeedApplyTransition}
    >
      {!isHide && (
        <div style={{ flex: 1 }} ref={drag}>
          <div ref={ref}>
            <Box
              isMainVideo={isMainVideo}
              index={index}
              type={itemType}
              title={name}
              displayDuration={convertDurationToHHMMSS(durationDisplay || 0)}
              duration={duration}
              id={id}
              thumbnails={thumbnails}
              isFirstLine={line === 0 ? true : false}
              start={trim?.start}
              transformLeft={left}
              animation={animation}
              idSubtitleLinked={idSubtitleLinked}
            />
          </div>
        </div>
      )}
    </ResizeAbleBox>
  );
};
