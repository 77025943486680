import React from 'react';

import { SVGProps } from './SVG.props';

const StarYellow = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 12 12"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="star-filled" clipPath="url(#clip0_791_1072)">
        <path
          id="Shape"
          d="M5.04825 1.32226C5.34624 0.398115 6.65375 0.398114 6.95174 1.32226L7.78053 3.89252L10.4706 3.89252C11.4375 3.89252 11.8415 5.12825 11.0613 5.6994L8.87846 7.29747L9.71069 9.8784C10.0082 10.8011 8.95044 11.5648 8.16823 10.9922L5.99999 9.40481L3.83176 10.9922C3.04955 11.5648 1.99179 10.8011 2.2893 9.8784L3.12153 7.29747L0.938671 5.6994C0.15852 5.12825 0.562511 3.89252 1.52939 3.89252H4.21946L5.04825 1.32226Z"
          fill={fill || '#D9A21F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_791_1072">
          <rect width="12" height="12" fill={fill || '#D9A21F'} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarYellow;
