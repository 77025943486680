import React from 'react';
import { addAddingCards, selectVideo } from '@/features/video/store/videoSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import styled from '@emotion/styled';
import TagNameItem from './TagNameItem';
import GraphicItem from './GraphicItem';
import { addTemplateTagNameTemplate, tagNameTemplates } from './data';
import { ObjectType } from '@/features/video/models/FrameItem';

interface IListTagNamesProps {
  type?: string;
}

const ListTagNames: React.FunctionComponent<IListTagNamesProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const { addingCards, maxDuration, playerSize, playerSizeHD } = useAppSelector(selectVideo);

  const onAdd = async () => {
    const listCards = addingCards.filter((x) => x.type === ObjectType.card);
    const widthCardName = (playerSize?.width / 4) as number | any;
    const heightCardName = (playerSize?.height / 5) as number | any;
    const newCardAdded = addTemplateTagNameTemplate(
      listCards?.length,
      maxDuration,
      '',
      '',
      widthCardName,
      heightCardName,
      ObjectType.card
    );
    const { backgroundColor, elementTexts, uid, start, end, width, height, name, position, line, type } = newCardAdded;
    dispatch(
      addAddingCards({ backgroundColor, elementTexts, uid, start, end, width, height, name, position, line, type })
    );
  };

  const onAddGraphic = async () => {
    const listGraphics = addingCards.filter((x) => x.type === ObjectType.graphic);
    const widthGraphic = playerSizeHD?.width;
    const heightGraphic = playerSizeHD?.height;
    const newGraphicAdded = addTemplateTagNameTemplate(
      listGraphics?.length,
      maxDuration,
      '',
      '',
      widthGraphic,
      heightGraphic,
      ObjectType.graphic
    );
    const { backgroundColor, elementTexts, uid, start, end, width, height, name, position, line, type } =
      newGraphicAdded;
    dispatch(
      addAddingCards({ backgroundColor, elementTexts, uid, start, end, width, height, name, position, line, type })
    );
  };

  return (
    <Container>
      {type === 'card'
        ? tagNameTemplates.map(
            (item) =>
              item.type === 'card' && (
                <TagNameItem key={item?.id} width="calc(50% - 4px)" id={item.id} onAdd={() => onAdd()} />
              )
          )
        : tagNameTemplates.map(
            (item) =>
              item.type === 'graphic' && (
                <GraphicItem key={item?.id} width="calc(50% - 4px)" id={item.id} onAdd={() => onAddGraphic()} />
              )
          )}
    </Container>
  );
};

export default ListTagNames;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  overflow: auto;
  width: 100%;
`;
