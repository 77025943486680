import styled from '@emotion/styled';
import { pxToRem } from '../hooks/useTheme';

import { Variants, TypographyProps, AltVariants } from './Typography.props';

const styles = (breakpoints: string): Record<Variants, string> => {
  return {
    title1: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 62 : 24)};
        line-height: ${breakpoints === 'desktop' ? 72 : 36}px;
        font-family: 'Right Grotesk';
    `,
    title2: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 48 : 18)};
        line-height: ${breakpoints === 'desktop' ? 56 : 28}px;
        font-family: 'Right Grotesk';
    `,
    title3: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 40 : 14)};
        line-height: 48px;
        font-family: 'Right Grotesk';
    `,
    title4: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 32 : 20)};
        line-height: 40px;
        font-family: 'Right Grotesk';
    `,
    title5: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 24 : 18)};
        line-height: 32px;
        font-family: 'Right Grotesk';
    `,
    title6: `
        font-size: ${pxToRem(24)};
        line-height: 32px;
        font-family: 'Objective';
   `,
    title6v3: `
        font-size: ${pxToRem(20)};
        line-height: 32px;
        font-family: 'Objective';
   `,
    title7: `
        font-size: ${pxToRem(48)};
        line-height: 56px;
        font-family: 'Objective';
   `,
    title8: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 20 : 14)};
        line-height: 24px;
        font-family: 'Right Grotesk';
   `,
    title9: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 18 : 14)};
        line-height: 24px;
        font-family: 'Objective';
    `,
    body: `
        font-size: ${pxToRem(breakpoints === 'desktop' ? 16 : 14)};
        line-height: 24px;
        font-family: 'Objective';
    `,
    label: `
        font-size: ${pxToRem(14)};
        line-height: 24px;
        font-family: 'Objective';
    `,
    caption: `
        font-size: ${pxToRem(12)};
        line-height: 16px;
        font-family: 'Objective';
    `,
    small: `
        font-size: ${pxToRem(10)};
        line-height: 12px;
        font-family: 'Objective';
    `,
    verySmall: `
        font-size: ${pxToRem(8)};
        line-height: 10px;
        font-family: 'Objective';
    `,
    overline: `
        font-size: ${pxToRem(11)};
        line-height: 12px;
        text-transform: uppercase;
        letter-spacing: ${breakpoints === 'desktop' ? 2 : 1}px;
        font-family: 'Objective Bold';
        font-weight: bold;
    `,
    error: `
      font-weight: bold;
      font-size: ${pxToRem(11)};
      line-height: 16px;
    `
  };
};

const altStyles = (breakpoints: string): Record<AltVariants, string> => {
  return {
    title1: `
  font-size: ${pxToRem(64)};
  line-height: 72px;
  font-family: 'Right Grotesk';
`,
    title2: `
  font-size: ${pxToRem(48)};
  line-height: 56px;
  font-family: 'Right Grotesk';
`,
    title3: `
  font-size: ${pxToRem(40)};
  line-height: 48px;
  font-family: 'Right Grotesk';
`,
    title4: `
  font-size: ${pxToRem(32)};
  line-height: 40px;
  font-family: 'Right Grotesk';
`,
    title5: `
  font-size: ${pxToRem(24)};
  line-height: 32px;
  font-family: 'Right Grotesk';
`,
    title6: `
  font-size: ${pxToRem(18)};
  line-height: 24px;
  font-family: 'Right Grotesk';
`,
    title6v3: `
  font-size: ${pxToRem(20)};
  line-height: 24px;
  font-family: 'Objective';
`,
    copy: `
  font-size: ${pxToRem(18)};
  line-height: 24px;
  font-family: 'Objective';
`,
    body: `
  font-size: ${pxToRem(16)};
  line-height: 24px;
  font-family: 'Objective';
`,
    label: `
  font-size: ${pxToRem(14)};
  line-height: 24px;
  font-family: 'Objective';
`,
    caption: `
  font-size: ${pxToRem(12)};
  line-height: 16px;
  font-family: 'Objective';
`,
    overlineBig: `
  font-size: ${pxToRem(11)};
  line-height: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: 'Objective';
  font-weight: 800;
`,
    overlineSmall: `
  font-size: ${pxToRem(9)};
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Objective';
  font-weight: 800;
`,
    quote: `
  font-size: ${pxToRem(32)};
  line-height: 44px;
  font-family: 'Tiempos Headline';
`
  };
};

const weights: Record<string, string> = {
  Regular: '400',
  Medium: '500',
  Bold: '700',
  Overline: '800',
  Black: '900',
  Quote: '300'
};

const font: Record<string, string> = {
  title1: weights.Regular,
  title2: weights.Regular,
  title3: weights.Regular,
  title4: weights.Regular,
  title5: weights.Regular,
  body: weights.Medium,
  label: weights.Medium,
  caption: weights.Medium,
  small: weights.Medium,
  overline: weights.Black
};

const altFont: Record<string, string> = {
  title1: weights.Regular,
  title2: weights.Regular,
  title3: weights.Regular,
  title4: weights.Regular,
  title5: weights.Regular,
  title6: weights.Regular,
  copy: weights.Medium,
  body: weights.Medium,
  label: weights.Medium,
  caption: weights.Medium,
  overlineBig: weights.Overline,
  overlineSmall: weights.Overline,
  quote: weights.Quote
};

export const P = styled.p<TypographyProps>`
  ${({ variant }) =>
    (variant && styles('desktop')[variant || 'body']) || styles('desktop')['body']}; // with default of styles
  ${({ altVariant }) => altVariant && altStyles('desktop')[altVariant || 'body']};
  font-weight: ${({ variant, weight, altVariant }) =>
    weight ? weight : variant ? font[variant || 'body'] : altVariant ? altFont[altVariant || 'body'] : weights.Regular};
  color: ${({ theme, color = 'shade9', altColor, altColorProduct, altColorOption = 'primary' }) => {
    if (altColor) {
      return { ...theme.altGrey, ...theme.altBrand }[altColor];
    } else if (altColorProduct) {
      return theme['product'][altColorProduct][altColorOption];
    }
    return { ...theme.grey, ...theme.brand, ...theme.neutral }[color];
  }};
  text-align: ${({ align }) => align || 'left'};
  margin: 0;

  ${({ numOfLine }) => {
    if (numOfLine) {
      return `
      display: -webkit-box;
      -webkit-line-clamp: ${numOfLine};
      -webkit-box-orient: vertical;  
      overflow: hidden;`;
    }
  }}

  ${({ wordBreak }) => {
    if (wordBreak) return `word-break: break-word;`;
  }}

  @media screen and (max-width:${({ theme }) => theme.breakpoints.desktop}px) {
    ${({ variant }) => (variant && styles('mobile')[variant || 'body']) || styles('mobile')['body']};
  }
`;
