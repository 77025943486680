import videoReducer from '@/features/video/store/videoSlice';
import authReducer from '@/features/video/store/authSlice';
import storyReducer from '@/features/video/store/storySlice';
import brandKitReducer from '@/features/video/store/brandKitSlice';
import { handleRequests } from '@redux-requests/core';
import { createStore, applyMiddleware, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createRequestsStore } from '@redux-requests/core';
import { createAxiosInstance } from './axiosDriver';
import templateReducer from '@/features/video/store/templateSlice';
import subtitleReducer from '@/features/video/store/subtitleSlice';

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createAxiosInstance()
});

const rootReducers = combineReducers({
  requests: requestsReducer,
  video: videoReducer,
  auth: authReducer,
  brandKit: brandKitReducer,
  template: templateReducer,
  subtitle: subtitleReducer,
  stories: storyReducer
});

export const store = createStore(rootReducers, {}, applyMiddleware(thunk, ...requestsMiddleware));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const requestStore = createRequestsStore(store);
