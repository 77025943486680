import { CropIcon, FullScreenIcon } from '@/components/SVG';
import styled from '@emotion/styled';
import { Position } from '../../models/VideoFrame';

export const VideoFrameContainer = styled.div<{ isFullScreen?: boolean }>`
  /* height: 115%; */
  width: calc(100% - 640px);

  flex-grow: 1;
  ${({ isFullScreen }) => {
    return isFullScreen
      ? ` width: 100vw !important;
      z-index:999999;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;`
      : ``;
  }};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: calc(100% - 440px);
  }
`;

export const VideoSection = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #e6e3e4;
`;

export const Crop = styled(CropIcon)`
  cursor: pointer;
`;

export const FullScreen = styled(FullScreenIcon)`
  cursor: pointer;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  .origin-play-button,
  .video-react {
    background: transparent !important;
  }
  .video-react-loading-spinner {
    display: none !important;
  }
  .video-react-video {
    pointer-events: none;
  }
  .moveable-line {
    background: none !important;
  }
`;

export const MainRatioOverlaps = styled.div<{
  width?: number;
  height: number;
  bgUrl?: string;
  bgColor?: string;
  isBgBlur?: boolean;
  backgroundWidth?: number;
  backgroundHeight?: number;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  ${({ backgroundWidth, backgroundHeight }) => `background-size: ${backgroundWidth}px ${backgroundHeight}px;`}

  ${({ bgUrl, bgColor, isBgBlur }) =>
    isBgBlur && bgUrl ? `background-image: url(${bgUrl});` : `background-color: ${bgColor ? bgColor : '#000000'};`}
  .hidden {
    visibility: hidden;
    opacity: 0;

    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  }
  .show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
    transition-delay: 0s;
  }

  &.transition-hidden {
    video {
      visibility: hidden;
      opacity: 0;

      //transition: visibility 0s linear 0.33s, opacity 0.33s linear;
    }
  }
  &.transition-show {
    video {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0.6s, opacity 0.6s linear;
      transition-delay: 0s;
    }
  }
`;

export const RatioWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
`;

export const SideBox = styled.div`
  flex: 1;
  z-index: 1001;
  background-color: #f8f7f8;
`;

export const BoxPlayer = styled.div<{
  width?: number;
  height?: number;
  marginLeft?: number;
  isSelected: boolean;
  isShow: boolean;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  ${({ isSelected }) => isSelected && `outline: 1px solid #91DBB6;`}
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  z-index: 1;
  margin-left: ${({ marginLeft }) => `-${marginLeft}px`};
  box-sizing
`;

export const WrapperPlayer = styled.div<{
  width?: number;
  height?: number;
  topStackIndex: number;
}>`
  width: ${({ width }) => `${width}%`};
  height: ${({ height }) => `${height}%`};
  background-color: #000000;
  position: absolute;
  z-index: ${({ topStackIndex }) => topStackIndex};
`;

export const VideoBox = styled.div<{
  bgUrl?: string;
  bgColor?: string;
  isBgBlur?: boolean;
}>`
  width: 100%;
  height: 100%;
`;

export const VideoBoxTransition = styled.div<{
  width?: number | any;
  height?: number | any;
  marginLeft?: number | any;
  marginTop?: number | any;
  type?: string;
  crop?: Position;
}>`
  // margin-left: ${({ marginLeft }) => `${marginLeft < 0 ? 0 : marginLeft}%`};
  // margin-top: ${({ marginTop }) => `${marginTop < 0 ? 0 : marginTop}%`};
  // width: ${({ width }) => `${width > 100 ? 100 : width}%`};
  // height: ${({ height }) => `${height > 100 ? 100 : height}%`};
  margin-left: ${({ marginLeft }) => `-${marginLeft}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  z-index: 999;
  position: absolute;
  top: ${({ crop }) => (crop?.y ? `${crop?.y}px` : '0')};
  left: ${({ crop }) => (crop?.x ? `${crop?.x}px` : '0')};
`;

export const SubContainer = styled.div<{
  width?: number;
}>`
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? `${width}px` : '90%')};
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 27px;
  z-index: 999;
`;

export const SubtitleDiv = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  padding?: string;
}>`
  font-size: ${({ fontSize }) => `${fontSize}`};
  line-height: ${({ fontSize }) => `${fontSize}`};
  display: flex;
  justify-content: center;
  background: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: ${({ padding }) => padding};
  color: ${({ textColor }) => textColor};
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
`;

export const FitImageButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BoxPlayerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
