/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TextsTab from './Features/Texts';
import CardsTab from './Features/Cards';
import styled from '@emotion/styled';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ObjectType } from '@/features/video/models/FrameItem';
import FillerBackground from './Features/Background';

const TabsConf = [
  {
    name: 'Text Effects',
    comp: <TextsTab />
  },
  {
    name: 'Cards',
    comp: <CardsTab />
  },
  {
    name: 'Background',
    comp: <FillerBackground />
  }
];

interface IFeaturesTab {}

const FeaturesTab: React.FC<IFeaturesTab> = () => {
  const dispatch = useAppDispatch();
  const { selectedTabInside, selectedTab } = useAppSelector(selectVideo);
  const [activeKey, setActiveKey] = useState<number>(0);

  const handleSelectTab = (idx: number) => {
    setActiveKey(idx);
    if (idx === 1) {
      dispatch(update({ selectedTabInside: 'Cards' }));
    } else if(idx === 0) {
      dispatch(update({ selectedTabInside: 'Text Effects' }));
    }
    else {
      dispatch(update({ selectedTabInside: 'Background' }));
    }
    dispatch(update({ selectedObj: { uid: '', type: ObjectType.image } }));
  };

  useEffect(() => {
    switch (selectedTabInside) {
      case 'Cards':
        setActiveKey(1);
        break;
      case 'Background':
          setActiveKey(2);
          break;
      default:
        setActiveKey(0);
        break;
    }
  }, [selectedTabInside, selectedTab]);

  return (
    <>
      <RulerContainer>
        {TabsConf.map((item, idx) => (
          <TabItem key={idx} isSelected={activeKey === idx} onClick={() => handleSelectTab(idx)}>
            <p>{item.name}</p>
          </TabItem>
        ))}
      </RulerContainer>
      {TabsConf.map((item, idx) => idx === activeKey && <>{item.comp}</>)}
    </>
  );
};

export default FeaturesTab;

const RulerContainer = styled.div<{ width?: number }>`
  display: flex;
  justify-content: start;
  height: 40px;
  width: 365px;
  margin-bottom: 10px;
`;

const TabItem = styled.div<{ isSelected?: boolean }>`
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `${theme.brand.secondary} solid 2px` : `${theme.grey.shade3} solid 2px`};
  display: flex;
  justify-content: center;
  margin-top: 7px;
  cursor: pointer;
  font-weight: 600;
  padding: 0 25px;

  &:hover {
    color: ${({ theme }) => theme.altGrey.shade8};
  }
`;
