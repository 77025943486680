/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useDraft from './useDraft';

const useAutoSave = () => {
  const { doSaveDraft } = useDraft();
  const {
    addingImages,
    addingTexts,
    addingPlayableItems,
    addingCards,
    nextAspectRatio,
    isInitial,
    subtitles,
    contentId,
    contentType,
    subtitleStyle
  } = useSelector(selectVideo);

  const dispatch = useDispatch();

  const saveDraftCB = useDebouncedCallback(() => {
    doSaveDraft();
  }, 100);

  const updateState = useDebouncedCallback(() => {
    dispatch(update({ isInitial: false }));
  }, 100);

  useEffect(() => {
    if (!isInitial) {
      saveDraftCB();
    } else {
      updateState();
    }
  }, [
    JSON.stringify(addingImages),
    JSON.stringify(addingTexts),
    JSON.stringify(addingPlayableItems),
    JSON.stringify(addingCards),
    nextAspectRatio,
    subtitles,
    contentId,
    contentType,
    subtitleStyle
  ]);
};

export default useAutoSave;
