import styled from '@emotion/styled';
import * as React from 'react';
import LoadingIcon from './components/LoadingIcon/LoadingIcon';
import VideoLayout from './features/video/VideoLayout';
import Home from './features/home/Home';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { useAppSelector } from './app/hooks';
import { getToken } from './features/video/store/authSlice';
// import SideBar from './layout/SideBar';
import StudioHeaderV2 from './layout/header/HeaderV2';
import { useSelector } from 'react-redux';
import { selectVideo } from './features/video/store/videoSlice';

const App: React.FC = () => {
  const initializationLoading = () => (
    <div className="initialization-loading">
      <LoadingIcon />
    </div>
  );
  const { isFinishedLoading } = useSelector(selectVideo);
  const token = useAppSelector(getToken);

  return (
    <React.Suspense fallback={initializationLoading()}>
      <>
        <BrowserRouter>
          {token ? (
            <>
              <StudioHeaderV2 />
              <Container isFinishedLoading={isFinishedLoading}>
                {/* <SideBar /> */}
                <VideoLayout />
              </Container>
            </>
          ) : (
            <Home />
          )}
        </BrowserRouter>
      </>
    </React.Suspense>
  );
};

export default App;

const Container = styled.div<{ isFinishedLoading?: boolean }>`
  height: ${({ isFinishedLoading }) => (isFinishedLoading ? 'calc(100vh - 52px)' : 'auto')};
  // padding: 0 40px;
  padding: ${({ isFinishedLoading }) => (isFinishedLoading ? '0 40px' : 0)};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 20px;
  }
  background: ${({ theme }) => theme.altGrey.shade4};
  // overflow-x: hidden;
`;
