import { RequestAction } from '@redux-requests/core';

export const createAuthAction = (action: RequestAction, token?: string): RequestAction => {
  return {
    ...action,
    request: {
      ...action.request,
      headers: {
        Authorization: `Bearer ${token}`
      }
    },
    meta: {
      takeLatest: true,
      ...action.meta
    }
  };
};
