import React from 'react';

import { SVGProps } from './SVG.props';

const AlignLeft = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '18'}
      height={height || '19'}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 6.43178H3.6C3.44087 6.43178 3.28826 6.37534 3.17574 6.27487C3.06321 6.17441 3 6.03815 3 5.89607C3 5.75399 3.06321 5.61772 3.17574 5.51726C3.28826 5.41679 3.44087 5.36035 3.6 5.36035H14.4C14.5591 5.36035 14.7117 5.41679 14.8243 5.51726C14.9368 5.61772 15 5.75399 15 5.89607C15 6.03815 14.9368 6.17441 14.8243 6.27487C14.7117 6.37534 14.5591 6.43178 14.4 6.43178ZM3.6 11.7889H12C12.1591 11.7889 12.3117 11.8454 12.4243 11.9458C12.5368 12.0463 12.6 12.1826 12.6 12.3246C12.6 12.4667 12.5368 12.603 12.4243 12.7034C12.3117 12.8039 12.1591 12.8604 12 12.8604H3.6C3.44087 12.8604 3.28826 12.8039 3.17574 12.7034C3.06321 12.603 3 12.4667 3 12.3246C3 12.1826 3.06321 12.0463 3.17574 11.9458C3.28826 11.8454 3.44087 11.7889 3.6 11.7889ZM3.6 7.50321H12C12.1591 7.50321 12.3117 7.55965 12.4243 7.66012C12.5368 7.76058 12.6 7.89684 12.6 8.03892C12.6 8.181 12.5368 8.31726 12.4243 8.41773C12.3117 8.5182 12.1591 8.57464 12 8.57464H3.6C3.44087 8.57464 3.28826 8.5182 3.17574 8.41773C3.06321 8.31726 3 8.181 3 8.03892C3 7.89684 3.06321 7.76058 3.17574 7.66012C3.28826 7.55965 3.44087 7.50321 3.6 7.50321ZM3.6 9.64607H14.4C14.5591 9.64607 14.7117 9.70251 14.8243 9.80297C14.9368 9.90344 15 10.0397 15 10.1818C15 10.3239 14.9368 10.4601 14.8243 10.5606C14.7117 10.6611 14.5591 10.7175 14.4 10.7175H3.6C3.44087 10.7175 3.28826 10.6611 3.17574 10.5606C3.06321 10.4601 3 10.3239 3 10.1818C3 10.0397 3.06321 9.90344 3.17574 9.80297C3.28826 9.70251 3.44087 9.64607 3.6 9.64607Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default AlignLeft;
