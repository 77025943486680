/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { updatePlayableItem } from '@/features/video/store/videoSlice';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  selectVideo,
  update,
  updateSelectedImage,
  updateSelectedText,
  removeImage,
  removeText,
  getAudioTracks,
  setPlayerSizeHd,
  updateSelectedCard,
  setCounterLoadingVideo,
  clearCounterLoadingVideo,
  setCaptionSettings
} from '@/features/video/store/videoSlice';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { PlayerState } from 'video-react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { isInRange } from '@/features/video/services/videoService';
import { ObjectType, PlayableType } from '../../models/FrameItem';
import { calculateUIValue, IResolution } from '@/components/hooks/useDraft';
import { FHDPixel, FHDScreen, resolutionFHD, HDPixel } from '@/constants/screens';
import { selectSubtitle as getSubtitlesByUserId, setSubtitleViewing } from '@/features/video/store/subtitleSlice';

import { resizeFontSize } from '@/utils/resizeFontSize';
import useBlurBackgroundThumbnail from '@/components/hooks/useBlurBackgroundThumbnail';

type Position = {
  xRate: number;
  yRate: number;
};

export const useVideoFrameHook = () => {
  const playerRef = useRef<any>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);

  const {
    nextAspectRatio,
    addingTexts = [],
    addingImages,
    addingCards,
    totalPlayed,
    manualPause,
    playerSize,
    addingPlayableItems,
    crop,
    subtitleStyle,
    audioSelected,
    currentTimeSelected,
    volume,
    waitingForLoadVideo,
    screenDimension,
    totalDuration,
    counterLoadingVideo,
    timeStampSeek
  } = useAppSelector(selectVideo);

  const audioPlayingList = useAppSelector(getAudioTracks);
  const { subtitles: subtitlesByStudio } = useAppSelector(getSubtitlesByUserId);

  const dispatch = useAppDispatch();
  const [isFullScreen] = useState(false);
  const [widthHiddenLeft, setWidthHiddenLeft] = useState<number>(0);
  const [widthHiddenRight, setWidthHiddenRight] = useState<number>(0);
  const [playerSizeHD, setPlayerSizeHD] = useState<IResolution>({
    width: 0,
    height: 0
  });
  const [videoSelectedId, setVideoIdSelected] = useState('');

  const audioWrapperRef = useRef<HTMLDivElement>(null);
  const subtitleFontSizeFixed = parseInt(subtitleStyle?.fontSize?.replace('px', ''));
  const [subtitleFontSize, setSubtitleFontSize] = useState<number>(subtitleFontSizeFixed);
  const inWaitingStacksRef = useRef<any>(new Set());
  const [playerTrigger, setPlayerTrigger] = useState(0);
  const [waitingTrigger, setWaitingTrigger] = useState(0);
  const waitingIntervalRef = useRef<any>();
  const currentTime = useRef(0);
  const isFetchingBlurBackground = useRef(false);

  useEffect(() => {
    if (playerWrapperRef?.current && screenDimension) {
      switch (screenDimension) {
        case '9:16':
          setTemplateWithResolution(656, '608px');
          break;
        case '16:9':
          setTemplateWithResolution(0, '1920px');
          break;
        case '1:1':
          setTemplateWithResolution(420, '1080px', '4:5');
          break;
      }
    }
  }, [screenDimension, playerWrapperRef?.current]);

  const setTemplateWithResolution = (x: number, width: string, ratio?: string) => {
    dispatch(
      update({
        nextAspectRatio: ratio ? ratio : screenDimension,
        // resolution,
        resolution: resolutionFHD,
        width: FHDScreen.width,
        height: FHDScreen.height,
        crop: {
          expectRatio: screenDimension,
          // calculate the position crop for real view in UI
          x: x,
          y: 0,
          width: width,
          height: FHDScreen.height
        }
      })
    );
  };

  const getRatioWidthHeight = (ratio: string = '16:9') => {
    if (playerWrapperRef.current !== null) {
      switch (ratio) {
        case '9:16':
          return {
            width: playerWrapperRef?.current?.clientHeight * 0.5625, // 9/16
            height: playerWrapperRef?.current?.clientHeight
          };
        case '16:9':
        default:
          return {
            width: playerWrapperRef?.current?.clientHeight * 1.777, // 16/9
            height: playerWrapperRef?.current?.clientHeight
          };
      }
    }
  };

  useEffect(() => {
    if (playerWrapperRef.current !== null) {
      let nextPlayerSize: IResolution | undefined;
      if (nextAspectRatio === '4:5') {
        const minValue =
          playerWrapperRef.current.clientWidth > playerWrapperRef.current.clientHeight
            ? playerWrapperRef.current?.clientHeight
            : playerWrapperRef.current?.clientWidth;
        nextPlayerSize = { width: minValue, height: minValue };
      } else {
        nextPlayerSize = getRatioWidthHeight(nextAspectRatio);
      }

      if (nextPlayerSize) {
        const newImages = addingImages.map((item) => {
          const newPos = calculateUIValue(playerSize, nextPlayerSize!, {
            width: item?.x || 0,
            height: item?.y || 0
          });
          const newSize = calculateUIValue(playerSize, nextPlayerSize!, {
            width: item.width || 0,
            height: item.height || 0
          });

          return {
            ...item,
            x: newPos.x,
            y: newPos.y,
            width: item?.isLogo ? item.width : newSize.x,
            height: item?.isLogo ? item.height : newSize.y,
            position: {
              x: newPos.x,
              y: newPos.y
            }
          };
        });
        const newCards = addingCards.map((item) => {
          const newPos = calculateUIValue(playerSize, nextPlayerSize!, {
            width: item?.x || 0,
            height: item?.y || 0
          });
          const newSize = calculateUIValue(playerSize, nextPlayerSize!, {
            width: item.width || 0,
            height: item.height || 0
          });

          return {
            ...item,
            x: newPos.x,
            y: newPos.y,
            width: newSize.x,
            height: newSize.y,
            position: {
              x: newPos.x,
              y: newPos.y
            }
          };
        });

        const newText = addingTexts.map((item) => {
          const newPos = calculateUIValue(playerSize, nextPlayerSize!, {
            width: item?.x || 0,
            height: item?.y || 0
          });

          return {
            ...item,
            x: newPos.x,
            y: newPos.y,
            position: {
              x: newPos.x,
              y: newPos.y
            }
          };
        });

        dispatch(
          update({
            playerSize: nextPlayerSize,
            addingImages: newImages,
            addingTexts: newText,
            addingCards: newCards
          })
        );
      }
    }
  }, [nextAspectRatio, dispatch, isFullScreen]);

  const videoList = useMemo(() => {
    const playing = addingPlayableItems.filter((item) => item.type === PlayableType.video && !item.isHide);
    return playing;
  }, [addingPlayableItems]);

  const listTransitions = useMemo(() => {
    const listVdSortByStart = videoList.sort((a: any, b: any) => a.start - b.start);
    const filteredArr = [];
    for (let i = 0; i < listVdSortByStart.length - 1; i++) {
      const currentObj = listVdSortByStart[i];
      const nextObj = listVdSortByStart[i + 1];
      if (currentObj.end === nextObj.start) {
        filteredArr.push({
          trim: nextObj.trim,
          transition: currentObj.transition,
          resolution: currentObj.resolution,
          urlTransition: nextObj.filePath,
          zoom: nextObj.zoom,
          crop: nextObj.crop
        });
      }
    }
    return filteredArr;
  }, [addingPlayableItems]);

  const { getVideoBlurThumbnail } = useBlurBackgroundThumbnail();

  // Fetching Blur Background Thumbnail
  useEffect(() => {
    async function fetchVideoBlurThumbnail() {
      const videoWithoutBlurThumbnail = addingPlayableItems.filter(
        (item) => item.type === PlayableType.video && !item.blurBackground
      );
      let videoThumbnail: any = [];
      if (videoWithoutBlurThumbnail.length > 0) {
        isFetchingBlurBackground.current = true;
        try {
          videoThumbnail = await Promise.all(
            videoWithoutBlurThumbnail.map((item) => {
              return getVideoBlurThumbnail(item);
            })
          );
        } finally {
          isFetchingBlurBackground.current = false;
        }

        videoThumbnail.forEach((item: any) => {
          const { blurImage, videoId } = item;
          dispatch(updatePlayableItem({ uid: videoId, blurBackground: blurImage }));
        });
      }
    }

    fetchVideoBlurThumbnail();
  }, [addingPlayableItems]);

  useEffect(() => {
    if (inWaitingStacksRef.current?.size) {
      clearInterval(waitingIntervalRef.current);
      dispatch(update({ waitingForLoadVideo: true }));
      dispatch(setCounterLoadingVideo());
      videoList.forEach((item) => {
        const refElm = playerRef.current.get(item.uid);
        refElm.pause();
        if (counterLoadingVideo === 1) {
          // minus time player for first time loading video
          dispatch(update({ totalPlayed: totalPlayed - 0.2 }));
        }
      });
      waitingIntervalRef.current = setInterval(() => {
        setPlayerTrigger(Math.random() * 10);
      }, 100);
    } else {
      if (waitingIntervalRef.current && waitingForLoadVideo) {
        clearInterval(waitingIntervalRef.current);
        dispatch(update({ waitingForLoadVideo: false }));
      }
    }
  }, [waitingTrigger]);

  useEffect(() => {
    // behavior: seeking on the time line and auto seek correctly another videos
    if (playerRef.current) {
      videoList.forEach((item) => {
        const refElm = playerRef.current.get(item.uid);
        if (refElm && isInRange(item.start || 0, item.end || 0, totalPlayed)) {
          refElm.seek(totalPlayed - (item?.start || 0) + item?.trim?.start || 0);
        } else {
          refElm.seek(item?.trim?.start || 0);
        }
      });
    }
    // use timeStampSeek to check error seeking same time on timeline, cuz timeStampSeek is never duplicate so this function always works
  }, [currentTimeSelected, manualPause, videoList, timeStampSeek]);

  useEffect(() => {
    // handle play video list
    const waitingStack = inWaitingStacksRef.current;
    if (playerRef.current) {
      videoList.forEach((item) => {
        const refElm = playerRef.current.get(item.uid);
        const player: PlayerState = refElm?.getState()?.player;
        if (refElm) {
          if (manualPause) {
            refElm.pause();
          } else {
            const inRange = isInRange(item.start || 0, item.end || 0, totalPlayed);
            const isPause = player.paused;
            // check waiting to load video
            if (inRange && player.waiting) {
              waitingStack.add(item.uid);
              const timer = setTimeout(() => {
                setWaitingTrigger(Math.random() * 10);
                clearTimeout(timer);
              }, 100);
            } else if (inRange && isPause && !waitingForLoadVideo) {
              refElm.volume = (item.volume / 100) * volume;
              refElm.play();
              if (waitingStack.has(item.uid)) {
                waitingStack.delete(item.uid);
                const timer = setTimeout(() => {
                  setWaitingTrigger(Math.random() * 10);
                  clearTimeout(timer);
                }, 100);
              }
            } else if (!inRange && !isPause) {
              refElm.pause();
            } else {
              const timer = setTimeout(() => {
                setWaitingTrigger(Math.random() * 10);
                clearTimeout(timer);
                waitingStack.delete(item.uid);
              }, 100);
            }
          }
        }
      });
    }
  }, [totalPlayed, manualPause, playerTrigger]);

  useEffect(() => {
    // handle logic the audio when should play
    audioPlayingList.forEach((item) => {
      const audioControl = audioWrapperRef?.current?.querySelector(`[id='${item.uid}']`) as HTMLAudioElement;
      if (audioControl) {
        if (manualPause) {
          if (item.uid) {
            audioControl.pause();
          }
        } else {
          const inRange = isInRange(item.start || 0, item.end || 0, totalPlayed);
          const isPause = audioControl.paused;
          if ((!isPause && !inRange) || waitingForLoadVideo) {
            // stop play audio when out of range but status still play or waiting for load video
            audioControl.pause();
          } else if (inRange) {
            const currentTimePlay = totalPlayed - (item?.start || 0) + item.trim.start;

            const fadeInS = item.effects && item.effects[0].duration ? item.effects[0].duration : 0;
            const fadeOut = item.effects && item.effects[1].duration ? item.effects[1].duration : 0;
            //case fade in audio
            if (currentTimePlay <= item.trim.start + fadeInS) {
              const time = fadeInS - (item.trim.start + fadeInS - currentTimePlay);
              if (fadeInS) {
                const vol = ((item.volume / 100) * time) / fadeInS;
                audioControl.volume = vol > 1 ? 1 * volume : vol * volume;
                currentTime.current = currentTimePlay;
              } else {
                audioControl.volume = (item.volume / 100) * volume;
                currentTime.current = currentTimePlay;
              }
            } else {
              audioControl.volume = (item.volume / 100) * volume;
              currentTime.current = currentTimePlay;
            }

            //case fade out audio
            if (currentTimePlay >= item.trim.end - fadeOut) {
              const time = currentTimePlay - (item.trim.end - fadeOut);
              if (fadeOut) {
                const vol = item.volume / 100 - time / fadeOut;
                audioControl.volume = vol < 0 ? 0 : vol * volume;
                currentTime.current = currentTimePlay;
              }
            }

            if (isPause) {
              audioControl.play();
            }
          }
        }
      }
    });
    if (!waitingForLoadVideo) {
      // clear counter loading/preparing video
      dispatch(clearCounterLoadingVideo());
    }
  }, [totalPlayed, manualPause, waitingForLoadVideo]);

  useEffect(() => {
    // handle logic the audio when should play
    // case when click on control to seek video
    audioPlayingList.forEach((item) => {
      const audioControl = audioWrapperRef?.current?.querySelector(`[id='${item.uid}']`) as HTMLAudioElement;
      if (audioControl) {
        audioControl.currentTime = totalPlayed - (item?.start || 0) + item.trim.start;
      }
    });
  }, [currentTimeSelected]);

  useEffect(() => {
    //handle adjustment volume of audio
    audioPlayingList.forEach((item) => {
      const audioControl = audioWrapperRef?.current?.querySelector(`[id='${item.uid}']`) as HTMLAudioElement;
      if (item.uid === audioSelected?.uid && audioSelected?.volume !== undefined) {
        if (item.type === PlayableType.audio) {
          audioControl.volume = (audioSelected.volume / 100) * volume;
        } else {
          const refElm = playerRef.current.get(item.uid);
          refElm.volume = (audioSelected.volume / 100) * volume;
        }
      }
    });
  }, [audioSelected]);

  useEffect(() => {
    dispatch(setCaptionSettings(subtitleStyle))
  }, [subtitleStyle])

  const getMapPlayerVideo = () => {
    if (!playerRef.current) {
      // Initialize the Map on first usage.
      playerRef.current = new Map();
    }
    return playerRef.current;
  };

  const onTextChange = (value: string, uid: string) => {
    let index = addingTexts.findIndex((text) => text.uid === uid);
    const newTexts = [...addingTexts];
    newTexts[index] = { ...newTexts[index], value };

    dispatch(update({ addingTexts: newTexts }));
  };

  const onUpdateItemsPosition = (data: DraggableData, uid: string, type: string) => {
    if (type === ObjectType.image) {
      dispatch(updateSelectedImage({ uid, x: data.x, y: data.y, position: { x: data.x, y: data.y } }));
    } else if (type === ObjectType.text) {
      dispatch(updateSelectedText({ uid, x: data.x, y: data.y, position: { x: data.x, y: data.y } }));
    } else if (type === ObjectType.card) {
      dispatch(updateSelectedCard({ uid, x: data.x, y: data.y, position: { x: data.x, y: data.y } }));
    }
  };

  const onUpdateItemsPositionInGraphic = (data: DraggableData, uid: string, item?: any) => {
    const newTexts = [...item?.elementTexts];
    newTexts[0] = { ...newTexts[0], position: { x: data.x, y: data.y } };
    dispatch(updateSelectedCard({ uid, elementTexts: newTexts, type: ObjectType.graphic }));
  };

  const onUpdateImageSize = (width: number, height: number, uid: string, x?: number, y?: number) => {
    dispatch(
      updateSelectedImage(
        Object.assign({ uid, width: width, height: height }, x !== undefined ? { x } : {}, y !== undefined ? { y } : {})
      )
    );
  };

  const onUpdateTagNameSize = (width: number, height: number, uid: string, x?: number, y?: number) => {
    dispatch(
      updateSelectedCard(
        Object.assign({ uid, width: width, height: height }, x !== undefined ? { x } : {}, y !== undefined ? { y } : {})
      )
    );
  };

  const onSelectObj = (uid: string, type: ObjectType, tab?: string, tabInside?: string) => {
    dispatch(update({ selectedObj: { uid, type } }));
    dispatch(update({ selectedTab: tab ? tab : 'Media' }));
    dispatch(update({ selectedTabInside: tabInside }));
    if (type !== ObjectType.card) {
      onSelectTextInCardObj('', '');
    }
    dispatch(
      update({
        audioSelected: {
          uid: ''
        }
      })
    );
  };

  const onSelectTextInCardObj = (cardId: string, textId: string) => {
    dispatch(update({ selectedCard: { cardId, textId } }));
  };

  const onRemove = (uid: string, type: ObjectType) => {
    dispatch(type === ObjectType.image ? removeImage({ uid }) : removeText({ uid }));
  };

  // const onDragCrop = (e: DraggableEvent, data: DraggableData) => {
  //   setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  // };

  useEffect(() => {
    const nextPlayerSizeHD = getRatioWidthHeight('16:9');
    if (nextPlayerSizeHD) {
      setPlayerSizeHD(nextPlayerSizeHD as IResolution);
    }
  }, []);

  useEffect(() => {
    if (playerWrapperRef.current !== null) {
      if (crop?.expectRatio === '4:5') {
        // calculate the position crop for real view in UI
        setWidthHiddenLeft((crop?.x / FHDPixel.width) * playerSizeHD.width);
        setWidthHiddenRight(
          playerSizeHD.width - playerWrapperRef?.current?.clientHeight - (crop?.x / FHDPixel.width) * playerSizeHD.width
        );
      } else if (crop?.expectRatio === '9:16') {
        setWidthHiddenLeft((crop?.x / FHDPixel.width) * playerSizeHD.width);
        setWidthHiddenRight(
          playerSizeHD.width -
            playerWrapperRef?.current?.clientHeight * 0.5625 -
            (crop?.x / FHDPixel.width) * playerSizeHD.width
        );
      } else {
        setWidthHiddenLeft(0);
        setWidthHiddenRight(0);
      }
    }
    const sizeSubtitle = resizeFontSize(subtitleFontSizeFixed, playerSizeHD.width, crop?.expectRatio);
    setSubtitleFontSize(sizeSubtitle as number);
  }, [crop]);

  useEffect(() => {
    const playerSizeHD = getRatioWidthHeight('16:9');
    dispatch(setPlayerSizeHd(playerSizeHD));
    const sizeSubtitle = resizeFontSize(subtitleFontSizeFixed, playerSizeHD?.width as number, crop?.expectRatio);
    setSubtitleFontSize(sizeSubtitle as number);
  }, []);

  // get list cc
  useEffect(() => {
    groupListSubtitles();
  }, [subtitlesByStudio]);

  const groupListSubtitles = async () => {
    const listSubtitlesLinked = subtitlesByStudio.filter((sub: any) => sub.file !== '');
    const groupedObj = listSubtitlesLinked.reduce((acc: any, item: any) => {
      if (!acc[item.langName]) {
        acc[item.langName] = {
          label: item.langName,
          value: [item]
        };
      } else {
        acc[item.langName].value.push(item);
      }
      return acc;
    }, {});

    // setListLanguagesForCc(Object.values(groupedObj));
  };

  const onDragVideoStop = (e: any, data: any, id: string) => {
    const item = addingPlayableItems.find((item) => item.uid === id);
    const itemUpdate = {
      ...item,
      crop: {
        x: data.x,
        y: data.y
      }
    };
    dispatch(updatePlayableItem(itemUpdate));
  };

  const onFocusVideo = (id: string) => {
    setVideoIdSelected(id);
  };

  return {
    onRemove,
    // handleSaveCrop,
    // isShowCrop,
    // widthHiddenLeft,
    // widthHiddenRight,
    playerSizeHD,
    playerRef,
    playerWrapperRef,
    onSelectObj,
    // onDragCrop,
    onUpdateTagNameSize,
    onUpdateImageSize,
    onUpdateItemsPosition,
    onTextChange,
    // sizeCrop,
    // currentPosition,
    // subtitleFontSize,
    // isFullScreen,
    audioWrapperRef,
    videoList,
    getMapPlayerVideo,
    HDPixel,
    onUpdateItemsPositionInGraphic,
    listTransitions,
    totalDuration,
    getRatioWidthHeight,
    setPlayerSizeHD,
    onDragVideoStop,
    onFocusVideo,
    videoSelectedId
  };
};
