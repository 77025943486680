import React from 'react';

import { SVGProps } from './SVG.props';

const Pencil = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 17} height={height || 17} viewBox="0 0 17 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3619 2.02876C11.6223 1.76841 12.0444 1.76841 12.3047 2.02876L14.9714 4.69543C15.2317 4.95577 15.2317 5.37788 14.9714 5.63823L5.63807 14.9716C5.51304 15.0966 5.34347 15.1668 5.16666 15.1668H2.49999C2.13181 15.1668 1.83333 14.8684 1.83333 14.5002V11.8335C1.83333 11.6567 1.90357 11.4871 2.02859 11.3621L11.3619 2.02876ZM10.7761 4.50016L12.5 6.22402L13.5572 5.16683L11.8333 3.44297L10.7761 4.50016ZM11.5572 7.16683L9.83333 5.44297L4.10947 11.1668L5.83333 12.8907L11.5572 7.16683ZM4.89052 13.8335L3.16666 12.1096V13.8335H4.89052Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Pencil;
