/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import FeaturesTab from './FeaturesTab';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '@/app/hooks';
import {
  getAllSubtitles,
  getTemplate as requestGetTemplate,
  getTemplateByCompany
} from '@/features/video/store/videoAPI';
import { getCompanyTemplate, getTemplate } from '@/features/video/store/templateSlice';
import useRequest from '@/components/hooks/useRequest';
import { getSubtitles } from '@/features/video/store/subtitleSlice';
import { useSelector } from 'react-redux';
import { selectVideo, update } from '@/features/video/store/videoSlice';

interface IMedia {}

const CaptionsV2: React.FC<IMedia> = () => {
  const dispatch = useAppDispatch();
  const { request } = useRequest();
  const { subtitleStyle } = useSelector(selectVideo);
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  const handleGetTemplates = async () => {
    const dataTemplate = await request(requestGetTemplate());
    dispatch(getTemplate(dataTemplate?.data?.data));
    const dataCompanyTemplates = await request(getTemplateByCompany());
    dispatch(getCompanyTemplate(dataCompanyTemplates?.data?.data));
  };

  useEffect(() => {
    handleGetSubtitles();
    handleGetTemplates();
    if(!subtitleStyle) {
      const defaultSubtitleStyle = {
        fontSize: '40px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(0, 0, 0, 1)'
      }
      dispatch(
        update({
          subtitleStyle: defaultSubtitleStyle
        })
      );
    }
  }, []);

  return (
    <Container className="media-container">
      <FeaturesTab />
    </Container>
  );
};

export default CaptionsV2;

const Container = styled.div<{ width?: number }>`
  /* padding-top: 5px; */
  height: 100%;
`;
