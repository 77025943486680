import React from 'react';

import { SVGProps } from './SVG.props';

const Close = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="#070550"
      {...props}
    >
      <path
        d="M3.9326 11.8537C3.83497 11.756 3.83497 11.5977 3.9326 11.5001L11.4986 3.93406C11.5963 3.83643 11.7546 3.83643 11.8522 3.93406L12.0643 4.1462C12.162 4.24383 12.162 4.40212 12.0643 4.49975L4.49828 12.0658C4.40065 12.1634 4.24236 12.1634 4.14473 12.0658L3.9326 11.8537Z"
        fill={fill || '#070550'}
      />
      <path
        d="M4.14475 3.93406C4.24238 3.83643 4.40067 3.83643 4.4983 3.93406L12.0643 11.5001C12.162 11.5977 12.162 11.756 12.0643 11.8537L11.8522 12.0658C11.7546 12.1634 11.5963 12.1634 11.4987 12.0658L3.93261 4.49975C3.83498 4.40212 3.83498 4.24383 3.93261 4.1462L4.14475 3.93406Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.92377 3.71309C4.14344 3.49342 4.4996 3.49342 4.71927 3.71309L7.99847 6.99229L11.2777 3.71309C11.4973 3.49342 11.8535 3.49342 12.0732 3.71309L12.2853 3.92523C12.505 4.14489 12.505 4.50105 12.2853 4.72072L9.0061 7.99992L12.2853 11.2791C12.505 11.4988 12.505 11.855 12.2853 12.0746L12.0732 12.2868C11.8535 12.5064 11.4974 12.5064 11.2777 12.2868L7.99847 9.00755L4.71925 12.2868C4.49958 12.5064 4.14343 12.5064 3.92376 12.2868L3.71163 12.0746C3.49196 11.855 3.49196 11.4988 3.71163 11.2791L6.99084 7.99992L3.71164 4.72072C3.49197 4.50105 3.49197 4.14489 3.71164 3.92522L3.92377 3.71309ZM7.43278 8.44186L4.19776 11.6769L4.32151 11.8006L7.55653 8.56561L7.43278 8.44186ZM7.99847 8.12366L7.87473 7.99992L7.99847 7.87618L8.12221 7.99992L7.99847 8.12366ZM8.44041 8.56561L11.6754 11.8006L11.7992 11.6769L8.56416 8.44186L8.44041 8.56561ZM8.56416 7.55798L11.7992 4.32297L11.6754 4.19923L8.44041 7.43424L8.56416 7.55798ZM7.55653 7.43424L7.43279 7.55798L4.19778 4.32297L4.32152 4.19923L7.55653 7.43424ZM4.3657 11.8448C4.36567 11.8448 4.36573 11.8449 4.3657 11.8448Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Close;
