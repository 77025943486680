import React from 'react';

import { SVGProps } from './SVG.props';

const Minus = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="minus">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66699 8.00016C2.66699 7.63197 2.96547 7.3335 3.33366 7.3335L12.667 7.3335C13.0352 7.3335 13.3337 7.63197 13.3337 8.00016C13.3337 8.36835 13.0352 8.66683 12.667 8.66683L3.33366 8.66683C2.96547 8.66683 2.66699 8.36835 2.66699 8.00016Z"
          fill={fill || '#030220'}
        />
      </g>
    </svg>
  );
};

export default Minus;
