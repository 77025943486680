import styled from '@emotion/styled';
import * as React from 'react';
import ReactDraggable, { DraggableData, DraggableEvent } from 'react-draggable';
// import { Trash } from '@/components/SVG';

interface IDraggableProps {
  children: any;
  onDragStop?: (e: DraggableEvent, data: DraggableData) => void;
  onClickRemove?: () => void;
  onMouseDown?: (e: MouseEvent) => void;
  position?: { x: number; y: number };
  zIndex?: number;
  bounds?: any
}

const Draggable: React.FunctionComponent<IDraggableProps> = ({
  children,
  onDragStop,
  onClickRemove,
  position,
  zIndex,
  bounds,
  onMouseDown
}) => {
  const onStop = (e: DraggableEvent, data: DraggableData) => {
    onDragStop?.(e, data);
  };

  return (
    <ReactDraggable bounds={bounds} onStop={onStop} position={position} onMouseDown={onMouseDown}>
      <ChildrenContainer zIndex={zIndex}>
        {children}
      </ChildrenContainer>
    </ReactDraggable>
  );
};

export default Draggable;

const ChildrenContainer = styled.div<{ zIndex?: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex || 2};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  cursor: move;
  &:hover {
    svg {
      display: block !important;
    }
  }
`;

// const Remove = styled(Trash)`
//   top: 8px;
//   right: 8px;
//   cursor: pointer;
//   z-index: 4;
// `;
