/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import SearchBar from '@/components/SearchBar';
import styled from '@emotion/styled';
import ArrowFrontlineUp from '@/components/SVG/ArrowFrontlineUp';
import FileUpload, { FILE_TYPE_VALIDATE } from '@/components/FileUpload';
import MediaList from '../../commons/MediaAssets';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '@/app/hooks';
import { getMediaFiles, update } from '@/features/video/store/videoSlice';
import useRequest from '@/components/hooks/useRequest';
import Tabs from '../../commons/tabs/Tabs';
import { ObjectType, PlayableType } from '@/features/video/models/FrameItem';
import { deleteMedia, getAllMedia, uploadFiles } from '@/features/video/store/videoAPI';
import Spinner from '@/components/Spinner';

interface IUploadTab {}
const brandTabs: { name: string; key: string }[] = [
  {
    name: 'ALL',
    key: 'all'
  },
  // {
  //   name: 'Logos',
  //   key: 'logo'
  // },
  {
    name: 'Videos',
    key: 'video'
  },
  {
    name: 'Music',
    key: 'audio'
  },
  {
    name: 'Images',
    key: 'image'
  }
];

const UploadTab: React.FC<IUploadTab> = () => {
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>('all');
  const mediaFiles = useSelector(getMediaFiles);
  const [isUpload, setIsUpload] = useState(false);
  const [removedIds, setRemovedIds] = React.useState<string[]>([]);

  const { request } = useRequest();

  const handleSearch = async () => {
    // apply search
  };

  const onUploadFile = async (files: File[] | null) => {
      if (files) {
        setIsUpload(true);
        const res = await request(uploadFiles(files));
        if (!res?.error) {
          request(getAllMedia);
          setIsUpload(false);
        } else {
          setIsUpload(false);
        }
      }
    
  };


  const mediaList = useMemo(() => {
    const medias = mediaFiles.map((item: any) => {
      return {...item, Link: item?.filePath || item?.files?.link}
    })
    if(activeKey === 'video') {
      return medias.filter((item: any) => item.type === PlayableType.video)
     }
     if(activeKey === 'audio') {
      return medias.filter((item: any) => item.type === PlayableType.audio)
     }
     if(activeKey === 'image') {
      return medias.filter((item: any) => item.type === ObjectType.image)
     } 
     return medias
  // eslint-disable-next-line no-sparse-arrays
  }, [mediaFiles, activeKey]);

  const acceptTypes = useMemo(() => {
    if (activeKey === 'logo') {
      return ['image'];
    } else if (activeKey === 'all') {
      return ['image', 'video', 'audio'];
    } else {
      return [activeKey];
    }
  }, [activeKey]);

  const filterAssets = useMemo(() => {
    return mediaList.filter((asset) => !removedIds.includes(asset.id));
  }, [removedIds, mediaList]);


  const onRemove = async (id: string) => {  
    setRemovedIds([...removedIds, id]);
    await request(deleteMedia(id));
  };

  return (
    <Container>
      <ContentWrapper className="content">
        <SearchView>
          <SearchBar
            placeholder="Search for..."
            longSearchBar
            value={searchVal}
            didChanged={(value) => setSearchVal(value)}
            handleSearchTemplate={handleSearch}
          />
        </SearchView>
        <Tabs tabs={brandTabs} active={activeKey} onChange={setActiveKey} />
        <ScrollContent className="scroll">
          {!!filterAssets?.length ? (
            <MediaList mediaList={filterAssets} onRemoveMedia={(val)=> onRemove(val.id as string)} />
          ) : (
            <FileUpload isLoading={isUpload} handleDrop={onUploadFile} allowTypes={acceptTypes as any} />
          )}
        </ScrollContent>
      </ContentWrapper>
      <BottomView>
        <FileUpload
          isLoading={isUpload}
          allowTypes={acceptTypes as any}
          handleDrop={onUploadFile}
          CustomDropContainer={UploadWrapper}
          dropSection={
            <UploadBtn isLoading={isUpload}>
              <span style={{marginRight: '6px'}}>Upload</span> {isUpload ? <Spinner height={14} width={14} /> : <ArrowFrontlineUp /> }
            </UploadBtn>
          }
        />
      </BottomView>
    </Container>
  );
};

export default UploadTab;


const Container = styled.div<{ width?: number }>`
  height: 100%;
`;

const SearchView = styled.div<{ width?: number }>`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const ContentWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 32px;
`;

const UploadBtn = styled.div<{ isLoading?: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  text-align: center;
  background-color: #91dbb6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  ${({ isLoading }) => (isLoading ? `opacity: 0.5; cursor: not-allowed;` : '')}
  span {
    color: #070550;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
  }
`;

export const ScrollContent = styled.div`
  height: calc(100% - 206px);
  margin-top: 12px;
`;

const BottomView = styled.div<{ width?: number }>`
  height: 64px;
  width: 100%;
  box-shadow: 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  padding: 16px;
`;
