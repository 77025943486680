import React, { useState } from 'react';
import SearchBar from '@/components/SearchBar';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectTemplate } from '@/features/video/store/templateSlice';
import SegmentedControls from '@/components/SegmentedControls';
import { convertMinutes } from '@/utils/convertMinutes';
import Asset from './components/Assets';
import ModalApplyTemplate from '../../template-tab/components/ModalApplyTemplate';
import ModalLoading from '../../template-tab/components/ModalLoading';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import { useDebouncedCallback } from 'use-debounce';
import useDraft from '@/components/hooks/useDraft';
import { useSearchParams } from 'react-router-dom';

interface IPresets {}

const assetItems = [
  { label: 'All', value: '0' },
  { label: 'Brand', value: '1' }
  // { label: 'Discover', value: '1' },
  // { label: 'Global', value: '3' }
];

type Tab = { label: string; value: string };

const Presets: React.FC<IPresets> = () => {
  const { convertDraft } = useDraft();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const contentType = searchParams.get('contentType');
  const { templates, companyTemplates } = useAppSelector(selectTemplate);
  const { addingPlayableItems, resolution, title } = useAppSelector(selectVideo);

  const [searchVal, setSearchVal] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<Tab>(assetItems[0]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [templateSelected, setTemplateSelected] = React.useState<any>({});
  const [isOpenModalLoading, setIsOpenModalLoading] = React.useState<boolean>(false);

  const handleViewTemplates = () => {
    switch (selectedTab.value) {
      case '1':
        const listBrandTemplates = templates.filter((item: any) =>
          item?.name?.toLowerCase()?.includes(searchVal.toLowerCase())
        );
        return listBrandTemplates.sort((a: any, b: any) => Number(b.favorite) - Number(a?.favorite));
      // case '2':
      //   return companyTemplates.filter((item: any) => item?.name?.toLowerCase()?.includes(searchVal.toLowerCase()));
      default:
        const listAllTemplates = templates
          .concat(companyTemplates)
          .filter((item: any) => item?.name?.toLowerCase()?.includes(searchVal.toLowerCase()));
        return listAllTemplates.sort((a: any, b: any) => Number(b.favorite) - Number(a?.favorite));
    }
  };

  const handleSearchTemplate = async () => {
    handleViewTemplates();
  };

  const onSelectTemplate = (template: any) => {
    setIsOpen(true);
    removeMainPlayableItems(template);
  };

  const removeMainPlayableItems = (obj: any) => {
    setTemplateSelected({ ...obj });
  };

  const onCancelTemplate = () => {
    setIsOpen(false);
    setTemplateSelected({});
  };

  const handleMoveItemsToNextLine = (addingItems?: any) => {
    return addingItems.map((item: any) => {
      return {
        ...item,
        line: item.line + 1
      };
    });
  };

  const onApplyTemplate = async () => {
    const addingPlayableTemp = [...templateSelected.addingPlayableItems];
    const addingTextsTemp = [...templateSelected.addingTexts];
    const addingImagesTemp = [...templateSelected.addingImages];
    const addingCardsTemp = templateSelected?.addingCards?.length ? [...templateSelected?.addingCards] : [];
    const defaultVideoFromStory = addingPlayableItems.filter((item) => item?.main && !item?.isHide) as any;
    // adding links of default video from story to save draft - this field is required to compare from links to filePath to save on DB
    if (defaultVideoFromStory?.length > 0) {
      defaultVideoFromStory[0] = Object.assign(
        {
          links: { Location: defaultVideoFromStory[0]?.filePath }
        },
        defaultVideoFromStory[0]
      );
      // defaultVideoFromStory[0].links = {
      //   Location: defaultVideoFromStory[0]?.filePath
      // };
    }

    const newPayload = {
      ...templateSelected,
      addingPlayableItems: !!defaultVideoFromStory?.length
        ? defaultVideoFromStory.concat(handleMoveItemsToNextLine(addingPlayableTemp))
        : addingPlayableTemp,
      addingImages: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingImagesTemp) : addingImagesTemp,
      addingTexts: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingTextsTemp) : addingTextsTemp,
      addingCards: !!defaultVideoFromStory?.length ? handleMoveItemsToNextLine(addingCardsTemp) : addingCardsTemp,
      crop: templateSelected?.crop
    };

    const updateState = await convertDraft(newPayload);
    const newPayloadApplyTemplate = {
      ...updateState,
      width: '1920px',
      height: '1080px',
      resolution: resolution,
      title: title,
      contentId: contentId,
      contentType: contentType
    };

    await dispatch(update(newPayloadApplyTemplate as any));
    setIsOpen(false);
    setIsOpenModalLoading(true);
    // await dispatch(updateFromTemplate(newPayload));
    dispatch(update({ totalPlayed: 0 }));
    await reloadPage();
  };

  const reloadPage = useDebouncedCallback(() => {
    window.location.reload();
  }, 4200);

  return (
    <Container>
      <ContentWrapper>
        <SearchView>
          <SearchBar
            placeholder="Search for..."
            longSearchBar
            value={searchVal}
            didChanged={(value) => setSearchVal(value)}
            // handleSearchTemplate={handleSearch}
            handleSearchTemplate={handleSearchTemplate}
          />
        </SearchView>
        <TabView>
          <SegmentedControls
            options={assetItems.map((item) => item.label)}
            selectedOption={selectedTab.label}
            onClickControl={(value) => setSelectedTab(assetItems.find((item) => item.label === value) || assetItems[0])}
          />
        </TabView>
        <ContainerList>
          <TemplateWrapper className="template-wrapper">
            {!!handleViewTemplates()?.length &&
              handleViewTemplates().map((item: any, idx: number) => (
                <Asset
                  url={item.thumbnail}
                  key={idx}
                  width="calc(48%)"
                  duration={convertMinutes(+item?.duration?.toFixed())}
                  onAdd={() => onSelectTemplate(item)}
                  // onMoveTemplate={selectedTab.value === '1' ? onMoveTemplate : undefined}
                  id={item.id}
                  name={item?.name}
                  tag={item?.tag}
                  favorite={item?.favorite}
                />
              ))}
          </TemplateWrapper>
        </ContainerList>
      </ContentWrapper>
      <ModalApplyTemplate isOpen={isOpen} onClickClose={onCancelTemplate} onClickApply={onApplyTemplate} />
      {/* <Notify isOpen={isOpenNotify} confirm={() => setIsOpenNotify(false)} /> */}
      <ModalLoading isOpen={isOpenModalLoading} />
    </Container>
  );
};

export default Presets;

const Container = styled.div<{ width?: number }>`
  padding: 0 15px;
  padding-bottom: 10px;
  height: 100%;
`;

const ContentWrapper = styled.div`
  height: 100%;
`;

const SearchView = styled.div<{ width?: number }>`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const TabView = styled.div`
  margin-top: -20px;
  margin-right: 30px;
`;

const ContainerList = styled.div<{ hasFiles?: boolean }>`
  height: calc(100% - 115px);
  margin-top: 14px;
  overflow: auto;
`;

const TemplateWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
