import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Block = styled.div`
  border: 1px solid #ded9db;
  background: #ffffff;
  border-radius: 12px;
`;

export const EditAudioText = styled.p`
  margin-top: 7px;
  margin-left: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.altGrey.shade7};
  margin-bottom: 5px;
`;

export const AudioName = styled(Block)`
  display: flex;
  align-items: center;
  height: 43px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  background-color: #f6f3f4;
  border: unset;
  margin-left: 2%;
  width: 96%;
`;

export const AudioVolume = styled(Block)`
  padding: 3px 16px;
  display: flex;
  align-items: center;
  .ant-slider-track {
    background-color: #91dbb6 !important;
    height: 2px;
  }
  .ant-slider-rail {
    height: 2px;
  }
  .ant-slider-handle {
    border: solid 5px #91dbb6 !important;
    width: 16px;
    height: 16px;
    margin-top: -7px;
  }
`;
export const SpeedWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;
export const AudioSpeed = styled(Block)`
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  justify-content: space-between;
  justify-content: center;
  cursor: pointer;
  &.selected {
    border: 2px solid #91dbb6;
  }
`;
