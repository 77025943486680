import { memo, FC } from "react";
import './Loadingicon.scss';
import Spin from "./Spin";

interface IloadingIcon {
    width?: number;
    height?: number;
}

const LoadingIcon:FC<IloadingIcon> = (props) => {
    return (
        <div className="loading-icon">
            <div className="loading-icon__icon">
                <Spin {...props} />
            </div>
        </div>
    )
}

export default memo(LoadingIcon);
