import React from 'react';
import styled from '@emotion/styled';
import useRequest from '@/components/hooks/useRequest';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/app/hooks';
import { selectSubtitle, selectSubtitleDetail } from '@/features/video/store/subtitleSlice';
import { selectVideo } from '@/features/video/store/videoSlice';
import { SubLine, SubtitleLinked, Transcribe } from '@/features/video/models/VideoFrame';
import { getSubtitleById } from '@/features/video/store/videoAPI';
import SubtitleCard from '@/components/SubtitleCard';
import DeleteSubtitleModal from '@/features/video/components/video-slidebar/subtitles-tab/components/setup-subtitles/DeleteSubtitleModal';
import EditSubtitleModal from '@/features/video/components/video-slidebar/subtitles-tab/components/setup-subtitles/subtitle-detail/EditSubtitleModal';

interface IEditCaptionViewProps {}

const EditCaption: React.FC<IEditCaptionViewProps> = () => {
  const { request } = useRequest();
  const dispatch = useDispatch();
  const { subtitles } = useAppSelector(selectVideo);
  const { subtitleDetail } = useAppSelector(selectSubtitle);
  const [isOpenDeleteSub, setIsOpenDeleteSub] = React.useState<boolean>(false);
  const [subtitleSelected, setSubtitleSelected] = React.useState<Transcribe>();
  const [subtitleLinked, setSubtitleLinked] = React.useState<SubtitleLinked>();
  const [isOpeEditSub, setIsOpeEditSub] = React.useState<boolean>(false);
  const [subtitleIdSelectedToEdit, setSubtitleIdSelectedToEdit] = React.useState<number>();

  const onRemove = (sub: Transcribe) => {
    setIsOpenDeleteSub(true);
    setSubtitleSelected(sub);
  };

  const handleCloseDialog = () => {
    setIsOpenDeleteSub(false);
  };

  const refetchSubtitleById = async () => {
    const subtitleById = await request(getSubtitleById(subtitleDetail?.id));
    if (!subtitleDetail?.file) {
      await dispatch(selectSubtitleDetail(subtitleById?.data?.data));
    } else {
      const linkedSub = subtitles?.filter((item) => item.file === subtitleDetail?.file)?.flat() as any;
      setSubtitleLinked(linkedSub[0]);
    }
  };

  const onChangeSub = (subLine: Partial<SubLine>, id: number) => {};

  React.useEffect(() => {
    const subtitleLinked = subtitles?.some((i) => i?.id?.includes(subtitleDetail?.id));
    if (subtitleDetail?.file) {
      if (subtitleLinked) {
        const linkedSub = subtitles?.filter((item) => item.id === subtitleDetail?.id)?.flat() as any;
        setSubtitleLinked(linkedSub[0]);
      } else {
        setSubtitleLinked(subtitleDetail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitleDetail, subtitles, subtitleLinked]);

  return (
    <Container>
      {subtitleDetail?.file
        ? subtitleLinked?.transcribe?.map((item: Transcribe) => (
            <SubtitleCard
              start={item?.start}
              end={item?.end}
              text={item?.sub}
              onRemove={() => onRemove(item)}
              key={item?.id}
              onChange={onChangeSub}
              index={item?.id}
              onOpenEditSub={() => setIsOpeEditSub(true)}
              subtitleIdSelected={setSubtitleIdSelectedToEdit}
            />
          ))
        : subtitleDetail?.transcribe?.map((item: Transcribe) => (
            <SubtitleCard
              start={item?.start}
              end={item?.end}
              text={item?.sub}
              onRemove={() => onRemove(item)}
              key={item?.id}
              onChange={onChangeSub}
              index={item?.id}
              onOpenEditSub={() => setIsOpeEditSub(true)}
              subtitleIdSelected={setSubtitleIdSelectedToEdit}
            />
          ))}
      <DeleteSubtitleModal
        isOpen={isOpenDeleteSub}
        onClickClose={handleCloseDialog}
        subtitleDetail={subtitleDetail}
        refetchSubtitle={refetchSubtitleById}
        subtitleSelected={subtitleSelected}
      />
      <EditSubtitleModal
        isOpen={isOpeEditSub}
        onClickClose={() => setIsOpeEditSub(false)}
        subtitleDetail={subtitleDetail}
        subtitleId={subtitleIdSelectedToEdit as number}
        refetchSubtitle={refetchSubtitleById}
      />
    </Container>
  );
};

export default EditCaption;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  width: 96%;
  margin-left: 2%;
  padding: 4px 12px 12px;
  height: calc(100% - 93px);
  margin-top: 8px;
`;
