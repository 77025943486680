export const replaceSpecialCharacter = (htmlBody: string) => {
  let htmlStr = htmlBody
    // <br><div>ABC</div> => <br>ABC
    .replace(/<br><div>/gi, '<br>')
    // <div><br></div> => <br>
    .replace(/<div><br><\/div>/gi, '<br>')
    // <div>ABC</div> => <br>ABC
    .replace(/<div>/gi, '<br>')
    // <h1 => h6>ABC</h1 => h6> to <br>ABC
    .replace(/<h[1-6]>/gi, '<br>')
    // Replace <br/> to <br>
    .replace(/<br\s*\/?>/gi, '<br>')
    // Remove another tag except <br> or <br/>
    .replace(/<(?!br)([^>]+)>/gi, '')

  htmlStr = htmlDecode(htmlStr) || '';

  return htmlStr;
};

function htmlDecode(input: string) {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}
