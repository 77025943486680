import moment from 'moment';
import styled from '@emotion/styled';
import * as React from 'react';
import { Trash, Pencil } from '../SVG';
import Typography from '../Typography';
import { TimePicker } from 'antd';
import { SubLine } from '@/features/video/models/VideoFrame';
import iconDel from '../../assets/images/clock.png';

interface ISubtitleCardProps {
  text: string;
  start: string;
  end: string;
  index: number;
  onRemove: () => void;
  onChange: (text: Partial<SubLine>, index: number) => void;
  onOpenEditSub?: () => void;
  subtitleIdSelected?: (id: number) => void;
}

const IconDelete = (
  <img src={iconDel} alt="iconDelete" style={{ marginTop: '-3px', marginLeft: '-3px', marginRight: '5px' }} />
);

const SubtitleCard: React.FunctionComponent<ISubtitleCardProps> = ({
  text,
  start,
  end,
  onRemove,
  index,
  onChange,
  onOpenEditSub = () => null,
  subtitleIdSelected = () => null
}) => {
  return (
    <Container>
      <WrapperSub>
        <ContentEditAbleView>
          <p>{text}</p>
        </ContentEditAbleView>
        <PencilView
          onClick={() => {
            onOpenEditSub();
            subtitleIdSelected(index);
          }}
        />
      </WrapperSub>
      <TimeContainer>
        <Typography variant="label" color="shade6" style={{ fontSize: '12px'}}>
          Start
        </Typography>
        <TimeBox>
          <TimePicker
            value={moment(start, 'HH:mm:ss')}
            showNow={false}
            onChange={(value) => {
              onChange({ start: value?.format('HH:mm:ss') }, index);
            }}
            className="time-picker"
            allowClear={false}
            popupStyle={{ display: 'none' }}
            suffixIcon={IconDelete}
            style={{ height: '20px' }}
            disabled
          />
          {/* <Typography variant="label" color="shade9">
            {start}
          </Typography> */}
        </TimeBox>
        <Typography variant="label" color="shade6" style={{ fontSize: '12px'}}>
          End
        </Typography>
        <TimeBox>
          <TimePicker
            value={moment(end, 'HH:mm:ss')}
            showNow={false}
            onChange={(value) => {
              onChange({ end: value?.format('HH:mm:ss') }, index);
            }}
            className="time-picker"
            allowClear={false}
            popupStyle={{ display: 'none' }}
            suffixIcon={IconDelete}
            style={{ height: '20px' }}
            disabled
          />
        </TimeBox>
        <TrashIconContainer onClick={onRemove} />
      </TimeContainer>
    </Container>
  );
};

export default SubtitleCard;

const Container = styled.div`
  display: flex;
  border-radius: 12px;
  border: ${({ theme }) => `1px solid ${theme.grey.shade6}`};
  padding: 12px 16px;
  flex-direction: column;
`;

const WrapperSub = styled.div`
  display: flex;
`;

const ContentEditAbleView = styled.div`
  width: 95%;
`;

const PencilView = styled(Pencil)`
  width: 5%;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.altGrey.shade6};
  }
  &:hover {
    svg,
    path {
      fill: ${({ theme }) => theme.brand.primary};
    }
  }
  width: 18px;
  height: 18px;
`;

const TimeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -8px;
`;

const TimeBox = styled.div`
  display: flex;
  padding: 6px;
  gap: 8px;
  border: ${({ theme }) => `1px solid ${theme.grey.shade9}`};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  width: calc(50% - 60px);
  .ant-picker {
    all: unset;
  }
`;

const TrashIconContainer = styled(Trash)`
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-top: -4px;
  &:hover {
    svg,
    path {
      fill: ${({ theme }) => theme.product.error.primary};
    }
  },
  width: 21px;
  height: 21px;
`;
