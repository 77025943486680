import React from 'react';

import { SVGProps } from './SVG.props';

const CcOnIcon = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 28} height={height || 20} viewBox="0 0 28 20" fill="none" {...props}>
      <rect width="28" height="20" rx="4" fill={"#F2F0F1"} />
      <path
        d="M11.836 11.048L13.204 11.276C12.82 13.028 11.104 14.3 9.124 14.3C6.544 14.3 4.672 12.392 4.684 9.752C4.684 7.112 6.544 5.204 9.124 5.204C11.104 5.204 12.82 6.536 13.204 8.36L11.824 8.528C11.536 7.328 10.408 6.452 9.136 6.452C7.372 6.452 6.124 7.832 6.124 9.752C6.124 11.672 7.384 13.052 9.136 13.052C10.384 13.052 11.512 12.212 11.836 11.048ZM21.7266 11.048L23.0946 11.276C22.7106 13.028 20.9946 14.3 19.0146 14.3C16.4346 14.3 14.5626 12.392 14.5746 9.752C14.5746 7.112 16.4346 5.204 19.0146 5.204C20.9946 5.204 22.7106 6.536 23.0946 8.36L21.7146 8.528C21.4266 7.328 20.2986 6.452 19.0266 6.452C17.2626 6.452 16.0146 7.832 16.0146 9.752C16.0146 11.672 17.2746 13.052 19.0266 13.052C20.2746 13.052 21.4026 12.212 21.7266 11.048Z"
        fill={fill || "#030220"}
      />
    </svg>
  );
};

export default CcOnIcon;
