import styled from '@emotion/styled';

import Typography from '../Typography';

export const Container = styled.div<{
  hoverColor?: string;
  disabled?: boolean;
}>`
  display: flex;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  svg:hover {
    fill: ${({ theme, hoverColor }) => hoverColor || theme.grey.shade7};
    path {
      fill: ${({ theme, hoverColor }) => hoverColor || theme.grey.shade7};
    }
  }
`;

export const Content = styled(Typography)`
  height: 100%;
  flex-wrap: wrap;
  font-weight: 400;
`;

export const ToolTipArrow = styled.div<{
  direction: {
    horizontal: string;
    vertical: string;
    right?: number;
    left?: number;
    top?: number;
    bottom?: number;
  };
  maxWidth?: string;
  maxHeight?: string;
  background?: string;
  textColor?: string;
}>`
  position: fixed;
  background-color: ${({ theme, background }) => background || theme.altGrey.shade9};
  color:${({ theme, textColor }) => textColor || theme.neutral.white}
  border-radius: 4px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  filter: drop-shadow(0px 6px 16px rgba(41, 43, 50, 0.12));
  ${({ direction }) => {
    if (direction) {
      return `
      left: ${direction.left};
      right: ${direction.right};
      bottom: ${direction.bottom};
      top: ${direction.top};
      `;
    }
  }};

  max-width: ${({ maxWidth }) => maxWidth ?? '380px'};
  max-height: ${({ maxHeight }) => maxHeight ?? '300px'};
  padding: 4px 12px;
  flex-wrap: wrap;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  &::after {
    content: '';
    height: 16px;
    width: 16px;
    background-color: ${({ theme, background }) => background || theme.altGrey.shade9};
    border-radius: 2px;
    position: absolute;

    ${({ direction }) => {
      if (direction.vertical === 'top') {
        return `
        bottom: -12px; 
        `;
      } else {
        return `
        top: 4px; 
        `;
      }
    }};
    ${({ direction }) => {
      if (direction.horizontal === 'right') {
        return `
        right: 18px;
        `;
      } else {
        return `
        left: 18px;
        `;
      }
    }};
    transform: translateY(-50%) translateX(35%) rotate(45deg);
    z-index: -1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: calc(100vw - 100px);
  }
`;
