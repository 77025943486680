/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import DropDown from '@/components/DropDown';
import TextField from '@/components/TextField';
import ToggleSettings from '@/components/ToggleSettings';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectVideo } from '@/features/video/store/videoSlice';
import { message } from 'antd';
import FileUpload from '@/components/FileUpload';
import useRequest from '@/components/hooks/useRequest';
import {
  getTemplateByCompany,
  saveAsTemplate,
  editTemplate,
  uploadThumbnail,
  getTemplate as requestGetTemplate,
  getVideoTypes
} from '@/features/video/store/videoAPI';
import {
  getCompanyTemplate,
  getListVideoTypes,
  getTemplate,
  selectTemplate
} from '@/features/video/store/templateSlice';
import { FHDScreen } from '@/constants/screens';
import { useSearchParams } from 'react-router-dom';
import useDraft from '@/components/hooks/useDraft';

interface OptionTag {
  label: string;
  value: string;
}
interface ModalISaveTemplateProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const ModalSaveTemplate: React.FunctionComponent<ModalISaveTemplateProps> = ({ isOpen, onClickClose }) => {
  const dispatch = useAppDispatch();
  const { request } = useRequest();
  const { buildDraftObj } = useDraft();
  const theme = useTheme();
  const { addingPlayableItems, maxDuration, subtitlesInit, subtitles, subtitleStyle, language, crop, nextAspectRatio } =
    useAppSelector(selectVideo);
  const [searchParams] = useSearchParams();
  const { listVideoTypes, templateById } = useAppSelector(selectTemplate);
  const [selectedTag, setSelectedTag] = useState<OptionTag>();
  const [isShareTeam, setIsShareTeam] = useState<boolean>(false);
  const [imageUploaded, setImageUploaded] = useState<any>();
  const [thumbnail, setThumbnail] = useState<string>();
  // const [imageURL, setImageURL] = useState<string | null>('');
  const [title, setTitle] = useState('');
  const [listVideoTags, setListVideoTags] = useState<any>();
  const [queryStrings, setQueryStrings] = useState<object | any>({});
  const mainVideo = addingPlayableItems.find((item) => item.main) as any;
  const templateParamId = searchParams.get('template');
  const actionEdit = searchParams.get('action');

  const onClose = () => {
    onClickClose();
    setImageUploaded(null);
    setIsShareTeam(false);
    setSelectedTag({
      label: '',
      value: ''
    });
  };

  const onUploadThumbnail = async (files: File[] | any) => {
    await setImageUploaded(files[0]);
    // await setImageURL((window.URL || window.webkitURL).createObjectURL(files[0]));
    if (files) {
      const res = await request(uploadThumbnail(files));
      setThumbnail(res?.data?.data?.Link);
    }
  };

  const handleSaveTemplate = async () => {
    const draftObj = await buildDraftObj({ actionExport: true });

    const variableForm = {
      name: title,
      tag: selectedTag?.value,
      thumbnail: thumbnail,
      type: isShareTeam ? 'company' : '',
      demo: mainVideo?.filePath,
      duration: maxDuration,
      width: FHDScreen.width,
      height: FHDScreen.height,
      addingPlayableItems: draftObj?.addingPlayableItems,
      addingImages: draftObj?.addingImages,
      addingTexts: draftObj?.addingTexts,
      addingCards: draftObj?.addingCards,
      subtitlesInit: subtitlesInit,
      subtitles: subtitles,
      subtitleStyle: subtitleStyle
        ? subtitleStyle
        : {
            fontSize: '14px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            color: 'rgba(0, 0, 0, 1)'
          },
      queryString: queryStrings,
      language: language,
      crop: nextAspectRatio
        ? {
            expectRatio: nextAspectRatio,
            x: crop?.x ? crop?.x : nextAspectRatio === '16:9' ? 0 : nextAspectRatio === '4:5' ? 420 : 656,
            y: 0,
            width: crop?.width
              ? crop?.width
              : nextAspectRatio === '16:9'
              ? '1920px'
              : nextAspectRatio === '4:5'
              ? '1080px'
              : '608px',
            height: crop?.height ? crop?.height : '1080px'
          }
        : {
            expectRatio: '16:9',
            x: 0,
            y: 0,
            width: '1920px',
            height: '1080px'
          }
    };

    const res = actionEdit
      ? await request(editTemplate(variableForm, templateParamId as string))
      : await request(saveAsTemplate(variableForm));
    if (res?.data?.status === 200) {
      message.success('Template saved!');
    } else {
      message.error('Template saved unsuccessfully!');
    }
    await onClose();
    await handleGetTemplates();
  };

  // handle call templates when save template success
  const handleGetTemplates = async () => {
    const dataTemplate = await request(requestGetTemplate());
    dispatch(getTemplate(dataTemplate?.data?.data));
    const dataCompanyTemplates = await request(getTemplateByCompany());
    dispatch(getCompanyTemplate(dataCompanyTemplates?.data?.data));
  };

  useEffect(() => {
    const videosTags = listVideoTypes?.map((tag: any) => ({
      label: tag?.name,
      value: tag?.name
    }));
    setListVideoTags(videosTags);
    const videoTagOld = videosTags?.filter((item: OptionTag) => item?.value === templateById?.tag);
    setSelectedTag(videoTagOld[0]);
  }, [listVideoTypes, isOpen]);

  useEffect(() => {
    const params = {} as any;
    for (const [key, value] of searchParams.entries() as any) {
      params[key] = value;
    }
    setQueryStrings(params);
    // setImageURL(templateById?.thumbnail);
    setThumbnail(templateById?.thumbnail);
    setTitle(templateById?.name);
  }, [isOpen]);

  const handleGetVdTags = async () => {
    const listVideoTypes = await request(getVideoTypes);
    dispatch(getListVideoTypes(listVideoTypes?.data?.data));
  };

  useEffect(() => {
    handleGetVdTags();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="600px"
      overflow="hidden"
      isConfirmDlg={true}
      borderRadius="8"
    >
      <TitleConfirm>Save Template</TitleConfirm>
      <TextLabel style={{ marginBottom: '5px' }}>Thumbnail</TextLabel>
      <FileUpload
        allowTypes={['image']}
        handleDrop={onUploadThumbnail}
        isMultiple={false}
        isSimpleFile={true}
        uploadedFiles={imageUploaded}
        height="100px"
      />
      <TextLabel>Title</TextLabel>
      <Option>
        <TextField
          containerStyle={{ marginTop: '14px' }}
          // label="Brand Kit Name"
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Option>
      <TextLabel>Video tag</TextLabel>
      <Option>
        <DropDown
          placeholder="Select tag"
          options={listVideoTags}
          value={selectedTag}
          // isMulti
          onChange={(value) => setSelectedTag(value)}
        />
      </Option>
      <Share>
        <ToggleSettings
          className="setting-row"
          flag={isShareTeam}
          toggleClicked={() => {
            setIsShareTeam(!isShareTeam);
          }}
        />
        <ShareText>Share with team</ShareText>
      </Share>

      <ActionsContainer>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveTemplate}>
          Save
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default ModalSaveTemplate;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;

const TextLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
`;

const Option = styled.div`
  margin-top: -10px;
`;

const Share = styled.div`
  display: flex;
  margin-top: 24px;
`;

const ShareText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
`;
