import * as React from 'react';
import Movable from '../Movable';
import Draggable from '../Draggable';
import { DraggableData, DraggableEvent } from 'react-draggable';
import styled from '@emotion/styled';
import { Animation } from '../SVG';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  openDrawerAnimationBar,
  selectElement,
  selectTextOrImage,
  selectVideo,
  setCaptionSettings
} from '@/features/video/store/videoSlice';
import { Popover } from 'antd';
import Typography from '../Typography';
import '../../constants/animations/styles.scss';

interface IMovableImageProps {
  id: string;
  onResize: (width: number, height: number, uid: string) => void;
  url: string;
  onDragStop?: (e: DraggableEvent, data: DraggableData) => void;
  onFocus?: (id: string) => void;
  onClickRemove?: (id: string) => void;
  isSelected?: boolean;
  width?: number;
  height?: number;
  x: number;
  y: number;
  parentWidth: number;
  parentHeight: number;
  show?: boolean;
  item?: any;
  zIndex?: number;
}

const MovableImage: React.FunctionComponent<IMovableImageProps> = ({
  url = 'https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/6708ac88a51d3dfb364eaa364b125827',
  onResize,
  id,
  onDragStop,
  onFocus,
  onClickRemove,
  isSelected,
  width,
  height,
  x,
  y,
  parentWidth,
  parentHeight,
  show,
  item,
  zIndex
}) => {
  const [keepRatio, setKeepRatio] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { totalPlayed, subtitleStyle } = useAppSelector(selectVideo);

  const targetRef = React.useRef<any>(null);
  const [frame] = React.useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: '50% 50%'
  });

  const handleSelectImage = () => {
    dispatch(openDrawerAnimationBar());
    dispatch(selectElement(item));
    dispatch(selectTextOrImage(false));
  };

  React.useEffect(() => {
    if (targetRef.current) {
      targetRef.current.style.width = `${width}px`;
      targetRef.current.style.height = `${height}px`;
    }
  }, [width, height]);

  const checkResizeOnCorner = (x: number, y: number) => {
    if (x === 0 || y === 0) {
      setKeepRatio(false);
    } else {
      setKeepRatio(true);
    }
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <Draggable
      onDragStop={onDragStop}
      onClickRemove={() => onClickRemove?.(id)}
      position={{ x, y }}
      zIndex={zIndex || 10}
    >
      <Popover
        open={false}
        showArrow={false}
        trigger={'none'} // update click when open this one
        overlayInnerStyle={{ borderRadius: '12px', marginBottom: '-24px', height: '45px' }}
        content={
          <OptionContainer onClick={handleSelectImage}>
            <Animation width={21} height={20} />
            <Typography style={{ marginLeft: '8px', fontSize: '14px' }}>Animation</Typography>
          </OptionContainer>
        }
      >
        <div
          onClick={() => {
            onFocus?.(id);
            handleClearCaptionSetting();
          }}
          className={totalPlayed === 0 ? 'hidden' : show ? `${item?.animation?.type}` : 'hidden'}
          style={{
            zIndex: 10,
            display: show ? 'block' : 'none'
          }}
          ref={targetRef}
        >
          <WrapImage width={width} height={height} />
          <ImageContainer alt="img" width={width} height={height} src={url} />
          <Movable
            useResizeObserver
            draggable={false}
            target={targetRef.current}
            resizable={isSelected}
            keepRatio={keepRatio}
            throttleResize={1}
            edge={false}
            zoom={1}
            onResizeStart={(e) => {
              e.setOrigin(['%', '%']);
              e.dragStart && e.dragStart.set(frame.translate);
            }}
            onResize={(e) => {
              checkResizeOnCorner(e.direction[0], e.direction[1]);
              e.target.style.width = `${e.width}px`;
              e.target.style.height = `${e.height}px`;
              onResize(e.width, e.height, id);
            }}
            frame={frame}
          />
        </div>
      </Popover>
    </Draggable>
  );
};

export default MovableImage;

const WrapImage = styled.div<{ width?: number; height?: number }>`
  background: transparent;
  width: ${({ width }) => `${width || 200}px`};
  height: ${({ height }) => `${height || 100}px`};
  position: fixed;
  z-index: 3;
`;

const ImageContainer = styled.img<{ width?: number; height?: number }>`
  width: ${({ width }) => `${width || 200}px`};
  height: ${({ height }) => `${height || 100}px`};
  position: absolute;
  z-index: 2;
`;

const OptionContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: -4px;
`;
