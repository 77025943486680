import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';

interface IModalApplyTemplateProps {
  isOpen: boolean;
  onClickClose: () => void;
  onClickApply: () => void;
}

const ModalApplyTemplate: React.FunctionComponent<IModalApplyTemplateProps> = ({
  isOpen,
  onClickClose,
  onClickApply
}) => {
  const theme = useTheme();

  const onConfirm = () => {
    onClickApply();
  };

  const onClose = () => {
    onClickClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="383px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Confirmation</TitleConfirm>
      <MessageConfirm>Would you like to reset the timeline and apply this template?</MessageConfirm>
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
        <Button variant="alert" onClick={onConfirm}>
          Confirm
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default ModalApplyTemplate;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;

export const MessageConfirm = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
  font-family: 'Objective';
  font-weight: 400;
`;
