import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ColorPicker from '@/components/ColorPicker';
import {
  Bold,
  DropdownArrow,
  Italic,
  Underline,
  Eye,
  EyeOff,
  AlignLeft,
  AlignCenter,
  AlignRight
} from '@/components/SVG';
import DropDown from '@/components/DropDown';
import GroupDropdown from '@/components/DropDown/GroupDropdown.view';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectBrandKit } from '@/features/video/store/brandKitSlice';
import {
  getFontList,
  selectedAddingObjDetails,
  updateSelectedCard,
  updateSelectedValueStylesInCard
} from '@/features/video/store/videoSlice';
import Fonts from '@/utils/fonts';
import { AddingCard, FontConfig } from '@/features/video/models/FrameItem';
import { replaceFontLink } from '@/utils/replaceFontLink';

interface IProps {
  label?: string;
  listColorsBrandKit?: any[];
  selectedColor?: number;
  color?: string;
  valueStyles?: any;
  isChangeTextColor?: boolean;
  title?: string;
  textId?: string;
  textItem?: any;
  isGraphic?: boolean;
}

const ElementText: React.FC<IProps> = ({
  label,
  listColorsBrandKit,
  color,
  valueStyles,
  isChangeTextColor,
  title,
  textId,
  textItem,
  isGraphic
}) => {
  const dispatch = useAppDispatch();
  const { fonts } = useAppSelector(selectBrandKit);
  const fontList = useAppSelector(getFontList);
  const cardNameDetails = useAppSelector(selectedAddingObjDetails) as AddingCard | any;

  const [openCollapse, setOpenCollapse] = useState(true);
  const [fontOptions, setFontOptions] = useState<any[]>();
  const [brandKitFonts, setBrandKitFonts] = useState<any[]>();
  const [selectedColor, setSelectedColor] = useState<number>();
  const [isShowText, setIsShowText] = useState<boolean>(textItem?.isShow);

  const handleGetFontStyles = (fontWeight: string, fontStyle: string) => {
    if (fontWeight === 'bold' && fontStyle === 'italic') {
      return textItem?.styles?.listFontFiles?.['700italic'];
    }
    if (fontWeight === 'bold') {
      return textItem?.styles?.listFontFiles?.[700];
    }
    if (fontStyle === 'italic') {
      return textItem?.styles?.listFontFiles?.italic;
    }
    return textItem?.styles?.listFontFiles?.regular;
  };

  const handleStyleChangeText = (styles: any) => {
    dispatch(
      updateSelectedValueStylesInCard({
        cardId: cardNameDetails?.uid,
        textId: textId,
        card: cardNameDetails,
        cardEdited: {
          styles: { ...styles },
          uid: textId,
          value: textItem?.value,
          isShow: textItem?.isShow,
          name: textItem?.name,
          position: textItem?.position
        }
      })
    );
  };

  useEffect(() => {
    const listBrandKitFonts =
      fonts?.map((item: any) => ({
        ...item,
        label: item.fontName,
        value: item.fontName,
        fontName: item.fontName,
        family: item.fontName,
        fontFile: item.Link,
        isBrandKit: true
      })) || [];
    const groupDropdownOptions = [
      {
        label: 'BRAND KIT',
        options: listBrandKitFonts
      },
      {
        label: 'GENERAL',
        options: fontList
      }
    ];
    setBrandKitFonts(listBrandKitFonts);
    if (fonts?.length > 0) {
      setFontOptions(groupDropdownOptions);
    } else {
      setFontOptions([
        {
          label: 'GENERAL',
          options: fontList
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);

  const handleHideTextValue = () => {
    setIsShowText(!isShowText);
    let index = cardNameDetails?.elementTexts?.findIndex((text: any) => text.uid === textId);
    const newTexts = [...cardNameDetails?.elementTexts];
    newTexts[index] = { ...newTexts[index], isShow: !isShowText };
    dispatch(updateSelectedCard({ uid: cardNameDetails?.uid, elementTexts: newTexts }));
  };

  return (
    <Container>
      <NameContainer onClick={() => setOpenCollapse(!openCollapse)}>
        <LabelTitle>{title}</LabelTitle>
        <IconShowContainer>
          <IconEye
            onClick={(e) => {
              e.stopPropagation();
              handleHideTextValue();
            }}
          >
            {title !== 'Name' && (isShowText ? <Eye width={16} /> : <EyeOff width={15} />)}
          </IconEye>
          {openCollapse ? (
            <IconArrowTop>
              <IconArrow>
                <DropdownArrow />
              </IconArrow>
            </IconArrowTop>
          ) : (
            <IconArrow>
              <DropdownArrow />
            </IconArrow>
          )}
        </IconShowContainer>
      </NameContainer>
      {openCollapse && <Divide />}
      {openCollapse && (
        <FrontStyleContainer>
          <FontStyle>
            <TextLabel>Font Style</TextLabel>
            <StylePicker>
              {/* Group dropdown for fonts */}
              <GroupDropdown
                options={fontOptions}
                bgColor={'#fff'}
                width={'200px'}
                onChange={(value: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  !value?.isBrandKit
                    ? (Fonts(value.family),
                      handleStyleChangeText({
                        fontSize: textItem.styles.fontSize,
                        lineHeight: textItem.styles.lineHeight,
                        color: textItem.styles.color,
                        fontName: value.family,
                        fontFile: value.files ? replaceFontLink(value.files?.regular) : replaceFontLink(value.fontFile),
                        listFontFiles: value?.files
                      }))
                    : handleStyleChangeText({
                        fontSize: textItem.styles.fontSize,
                        lineHeight: textItem.styles.lineHeight,
                        color: textItem.styles.color,
                        fontName: value.family,
                        fontFile: value.files ? replaceFontLink(value.files?.regular) : replaceFontLink(value.fontFile),
                        listFontFiles: value?.files
                      });
                }}
                value={fontList?.find((font: FontConfig) => font.family === textItem.styles?.fontName)}
                valueBrandKit={brandKitFonts?.find((font: any) => font?.fontName === textItem.styles?.fontName)}
              />
              <DropDown
                placeholder="Size"
                options={new Array(100).fill(undefined).map((_, i) => ({ label: `${i + 1}px`, value: `${i + 1}px` }))}
                customMargin="0px"
                bgColor="#fff"
                containerStyles={{ maxWidth: '80px', minWidth: '80px' }}
                onChange={(value) => handleStyleChangeText({ ...textItem.styles, fontSize: value.value })}
                value={{ label: textItem.styles?.fontSize, value: textItem.styles?.fontSize }}
              ></DropDown>
              <IconContainer
                isEnabled={textItem?.styles?.listFontFiles?.[700]}
                isSelected={textItem.styles.fontWeight === 'bold'}
              >
                <Bold
                  onClick={() =>
                    textItem?.styles?.listFontFiles?.[700] &&
                    handleStyleChangeText({
                      ...textItem.styles,
                      fontWeight: textItem.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                      fontFile: handleGetFontStyles(
                        textItem.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                        textItem.styles.fontStyle as string
                      )
                    })
                  }
                />
              </IconContainer>
              <IconContainer
                isEnabled={textItem?.styles?.listFontFiles?.italic}
                isSelected={textItem.styles.fontStyle === 'italic'}
              >
                <Italic
                  onClick={() =>
                    textItem?.styles?.listFontFiles?.italic &&
                    handleStyleChangeText({
                      ...textItem.styles,
                      fontStyle: textItem.styles.fontStyle === 'italic' ? 'normal' : 'italic',
                      fontFile: handleGetFontStyles(
                        textItem.styles.fontWeight as string,
                        textItem.styles.fontStyle === 'italic' ? 'normal' : 'italic'
                      )
                    })
                  }
                />
              </IconContainer>
              {false && (
                <IconContainer isSelected={textItem?.styles.textDecoration === 'underline'}>
                  <Underline
                    onClick={() =>
                      handleStyleChangeText({
                        ...textItem?.styles,
                        textDecoration: textItem?.styles.textDecoration === 'underline' ? 'unset' : 'underline'
                      })
                    }
                  />
                </IconContainer>
              )}
              {isGraphic && (
                <IconAlignContainer
                  isEnabled={true}
                  isSelected={textItem.styles.textAlign === 'left'}
                  onClick={() => {
                    handleStyleChangeText({
                      ...textItem.styles,
                      textAlign: textItem.styles.textAlign === 'left' ? 'left' : 'left'
                    });
                  }}
                >
                  <AlignLeft />
                </IconAlignContainer>
              )}
              {isGraphic && (
                <IconAlignContainer
                  isEnabled={true}
                  isSelected={textItem.styles.textAlign === 'center'}
                  onClick={() => {
                    handleStyleChangeText({
                      ...textItem.styles,
                      textAlign: textItem.styles.textAlign !== 'center' ? 'center' : 'left'
                    });
                  }}
                >
                  <AlignCenter />
                </IconAlignContainer>
              )}
              {isGraphic && (
                <IconAlignContainer
                  isEnabled={true}
                  isSelected={textItem?.styles.textAlign === 'right'}
                  onClick={() => {
                    handleStyleChangeText({
                      ...textItem?.styles,
                      textAlign: textItem?.styles.textAlign !== 'right' ? 'right' : 'left'
                    });
                  }}
                >
                  <AlignRight />
                </IconAlignContainer>
              )}
            </StylePicker>
          </FontStyle>
          <ListColors>
            <SystemColorView>
              <TextLabel>{label}</TextLabel>
              <ColorPicker
                containerStyle={{
                  width: '100%',
                  border: `${selectedColor === -1 ? '2px solid #7ED0A7' : ''}`,
                  borderRadius: `${selectedColor === -1 ? '14px' : ''}`
                }}
                color={color}
                onColorChange={(color) => {
                  isChangeTextColor
                    ? handleStyleChangeText({ ...valueStyles, color: color })
                    : handleStyleChangeText({ ...valueStyles, backgroundColor: color });
                }}
                onSelectColor={setSelectedColor}
                selected={selectedColor === -1 ? true : false}
                bgColor="#fff"
                topPosition={title === 'Location' ? -220 : title === 'Name' ? -200 : -100}
              />
            </SystemColorView>
            <ListColourContainer>
              {listColorsBrandKit?.map((item, idx) => (
                <ColourBrandKit
                  key={idx}
                  bgColor={item}
                  selected={idx === selectedColor ? selectedColor : -2}
                  onClick={() => {
                    setSelectedColor(idx);
                    isChangeTextColor
                      ? handleStyleChangeText({ ...valueStyles, color: item })
                      : handleStyleChangeText({ ...valueStyles, backgroundColor: item });
                  }}
                />
              ))}
            </ListColourContainer>
          </ListColors>
        </FrontStyleContainer>
      )}
    </Container>
  );
};

export default ElementText;

const Container = styled.div`
  margin-bottom: 20px;
  background: #faf7f8;
  border-radius: 8px;
  width: 100%;
`;

const FrontStyleContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 5%;
`;

const FontStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const StylePicker = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const IconContainer = styled.div<{ isSelected?: boolean; isEnabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 12px;
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};
  background: ${({ isSelected, theme }) => (isSelected ? theme.altGrey.shade5 : theme.neutral.white)};
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.4)};
`;

const NameContainer = styled.div`
  background: #faf7f8;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Divide = styled.div`
  width: 96%;
  margin-left: 2%;
  border-top: ${({ theme }) => `1px solid ${theme.grey.shade4}`};
  margin-bottom: 5px;
`;

const TextLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey.shade7};
`;

const IconArrowTop = styled.div`
  transform: rotate(180deg);
  margin-top: -2px;
`;

const SystemColorView = styled.div`
  margin-right: 4px;
`;

const ListColors = styled.div`
  margin-top: -5px;
`;

const LabelTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.grey.shade7};
`;

const IconArrow = styled.div`
  margin-top: 5px;
`;

const IconShowContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const IconEye = styled.div`
  margin-top: 4px;
  margin-right: 12px;
`;

const ListColourContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const ColourBrandKit = styled.div<{ bgColor?: string; selected?: any }>`
  width: 60px;
  height: 24px;
  max-width: 60px;
  background-color: ${({ bgColor }) => bgColor};
  outline: ${({ selected }) => (selected >= 0 ? '2px solid #7ED0A7' : 'none')};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  margin-right: 4px;
`;

const IconAlignContainer = styled.div<{ isSelected?: boolean; isEnabled?: boolean }>`
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};
  display: flex;
  align-items: center;
  background: ${({ isSelected, theme }) => (isSelected ? theme.altGrey.shade5 : theme.altGrey.shade3)};
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 12px;
  margin-right: 5px;
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
`;
