import React from 'react';

import { SVGProps } from './SVG.props';

const LeftDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" {...props}>
      <g id="curvearrow-leftdown">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00033 7.88542C3.33891 5.9426 5.56197 4.6665 8.08391 4.6665C11.6222 4.6665 14.5704 7.1762 15.3176 10.5212C15.3979 10.8805 15.1717 11.2369 14.8123 11.3171C14.453 11.3974 14.0966 11.1712 14.0164 10.8118C13.3993 8.04942 10.9707 5.99984 8.08391 5.99984C5.75405 5.99984 3.72379 7.33344 2.70129 9.30237L5.65922 8.49932C6.01455 8.40285 6.3808 8.6127 6.47727 8.96802C6.57374 9.32335 6.36389 9.6896 6.00856 9.78607L1.50833 11.0078C1.30794 11.0622 1.09364 11.0202 0.928692 10.894C0.763746 10.7679 0.666992 10.5721 0.666992 10.3645V5.79304C0.666992 5.42485 0.965469 5.12637 1.33366 5.12637C1.70185 5.12637 2.00033 5.42485 2.00033 5.79304V7.88542Z"
          fill={fill || '#030220'}
        />
      </g>
    </svg>
  );
};

export default LeftDown;
