import React from 'react';

import { SVGProps } from './SVG.props';

const Media = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          d="M0.5 12C0.5 2.28 2.78 0 12.5 0C22.22 0 24.5 2.28 24.5 12C24.5 21.72 22.22 24 12.5 24C2.78 24 0.5 21.72 0.5 12Z"
          fill={fillbg || '#070550'}
        />
      </g>
      <path
        d="M16.0001 7H9.00006C8.60224 7 8.22071 7.15804 7.9394 7.43934C7.6581 7.72064 7.50006 8.10218 7.50006 8.5V15.5C7.50006 15.8978 7.6581 16.2794 7.9394 16.5607C8.22071 16.842 8.60224 17 9.00006 17H16.0001C16.0823 16.9989 16.1643 16.9905 16.2451 16.975L16.3951 16.94H16.4301H16.4551L16.6401 16.87L16.7051 16.835C16.7551 16.805 16.8101 16.78 16.8601 16.745C16.9268 16.6959 16.9903 16.6424 17.0501 16.585L17.0851 16.54C17.1341 16.4903 17.1793 16.4368 17.2201 16.38L17.2651 16.315C17.3 16.2593 17.3301 16.2008 17.3551 16.14C17.3688 16.116 17.3805 16.0909 17.3901 16.065C17.4151 16.005 17.4301 15.94 17.4501 15.875V15.8C17.4784 15.7023 17.4952 15.6016 17.5001 15.5V8.5C17.5001 8.10218 17.342 7.72064 17.0607 7.43934C16.7794 7.15804 16.3979 7 16.0001 7ZM9.00006 16C8.86745 16 8.74028 15.9473 8.64651 15.8536C8.55274 15.7598 8.50006 15.6326 8.50006 15.5V13.345L10.1451 11.695C10.1915 11.6481 10.2468 11.6109 10.3078 11.5856C10.3687 11.5602 10.4341 11.5471 10.5001 11.5471C10.5661 11.5471 10.6314 11.5602 10.6923 11.5856C10.7533 11.6109 10.8086 11.6481 10.8551 11.695L15.1551 16H9.00006ZM16.5001 15.5C16.4996 15.5616 16.4877 15.6227 16.4651 15.68C16.4536 15.7044 16.4403 15.7278 16.4251 15.75C16.4117 15.7712 16.3966 15.7912 16.3801 15.81L13.7051 13.135L14.1451 12.695C14.1915 12.6481 14.2468 12.6109 14.3078 12.5856C14.3687 12.5602 14.4341 12.5471 14.5001 12.5471C14.5661 12.5471 14.6314 12.5602 14.6923 12.5856C14.7533 12.6109 14.8086 12.6481 14.8551 12.695L16.5001 14.345V15.5ZM16.5001 12.93L15.5601 12C15.2739 11.7285 14.8945 11.5771 14.5001 11.5771C14.1056 11.5771 13.7262 11.7285 13.4401 12L13.0001 12.44L11.5601 11C11.2739 10.7285 10.8945 10.5771 10.5001 10.5771C10.1056 10.5771 9.7262 10.7285 9.44006 11L8.50006 11.93V8.5C8.50006 8.36739 8.55274 8.24021 8.64651 8.14645C8.74028 8.05268 8.86745 8 9.00006 8H16.0001C16.1327 8 16.2598 8.05268 16.3536 8.14645C16.4474 8.24021 16.5001 8.36739 16.5001 8.5V12.93Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Media;
