export const getFormatFile = (link: string) => {
  const typeFile = link?.substring(link.lastIndexOf('.') + 1, link.length);

  switch (typeFile) {
    case 'tff':
      return 'format("truetype")';
    case 'otf':
      return 'format("opentype")';
    case 'woff':
      return 'format("woff")';
    default:
      return 'format("woff2")';
  }
};
