/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import { selectVideo } from '../../../store/videoSlice';
// import { PERCENT_WIDTH_ACTUAL_PLAY } from './drag-components/DroppableDisplay';
import { TIME_PX } from '../VideoControl';
import { update } from '../../../store/videoSlice';
import { Tooltip } from 'antd';
import { VideoService } from '../../../services/videoService';

interface IRulerSlicer {
  value: number;
  onChange: (value: number) => void;
}

const ClockwisePlat: React.FC<IRulerSlicer> = ({ value, onChange }) => {
  const dispatch = useAppDispatch();
  const { totalDuration, manualPause, totalPlayed, heightOfFrame } = useAppSelector(selectVideo);
  const [mouseDown, setMouseDown] = useState(false);
  const slideHandler = useRef<HTMLDivElement>(null);
  const rulerContainer = useRef<any>(null);
  const [timeChange, setTimeChange] = useState(value);

  useEffect(() => {
    handleAutoMove(value);
  }, [value, totalDuration]);

  useEffect(() => {
    onChange(timeChange);
  }, [timeChange]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      dragNeedle(e.movementX);
    };

    if (mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown]);

  useEffect(() => {
    if (!rulerContainer.current) {
      return;
    }

    const handleMouseUp = () => {
      setMouseDown(false);
    };
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleAutoMove = (value: number) => {
    const dragPoint: any = slideHandler.current;
    if (!dragPoint) return;
    dragPoint.style.left = `${value / TIME_PX}px`;
  };

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  const getNumber = (input: string) => {
    const regex = /(\d+(\.\d+)?)px/;
    const match = input.match(regex);
    if (match) return parseFloat(match[1]);
  };

  const dragNeedle = (movementX: number) => {
    if (!manualPause) {
      dispatch(update({ manualPause: true }));
    }
    const dragPoint: any = slideHandler.current;
    const left = getNumber(dragPoint.style.left);
    if (!dragPoint || left === undefined) return;
    // leftPercent.current += movementX;
    if (left < 0) {
      dragPoint.style.left = '0px';
      return;
    }
    if (left >= totalDuration / TIME_PX) {
      return;
    }
    dragPoint.style.left = `${left + movementX}px`;
    setTimeChange((left + movementX) * TIME_PX);
  };

  return (
    <RulerContainer className="clockwise-container" ref={rulerContainer} width={100}>
      <Tooltip placement="top" title={VideoService.convertDurationToHHMMSS(Math.round(totalPlayed))}>
        <SliderHandle
          height={heightOfFrame}
          ref={slideHandler}
          className="slide-handle"
          onMouseDown={handleMouseDown}
          onMouseUp={() => setMouseDown(false)}
        />
      </Tooltip>
    </RulerContainer>
  );
};

export default ClockwisePlat;

const RulerContainer = styled.div<{ width: number }>`
  //width: ${({ width }) => `calc(${width}%)`};
  position: relative;
`;
const SliderHandle = styled.div<{ height: number }>`
  z-index: 100; // it have to higher than timeline box
  border: none;
  background: url('/assets/video-editor/playseek.svg') no-repeat;
  height: 148px;
  width: 12px;
  position: absolute;
  top: -55px;
  transition: all 0.1s ease;
  transform: translateX(-50%);
  background-position-x: right;
  &:hover {
    cursor: ew-resize;
  }
  &::after {
    content: '';
    border: 1px solid #070550;
    // height: ${({ height }) => (height ? `${height + 20}px` : 'calc(254px - 8px)')};
    height: 100vh;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
