/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import update from 'immutability-helper';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useDrop } from 'react-dnd';
import { DraggableBox, Trim } from './DraggableBox';
import type { DragItem } from './interfaces';
import {
  update as UpdateAction,
  selectVideo,
  updatePlayableItem,
  updateSelectedImage,
  updateSelectedText,
  reduceCurrentLineItem,
  updateSelectedCard,
  removePlayableItem,
  removeImage,
  removeText,
  removeCard,
  setTimeStampSeek,
  unSelectElement,
  unSelectAudio,
  unselectIdElement
} from '../../../store/videoSlice';
import { AddingPlayableItem, PlayableType, ObjectType, FPS } from '@/features/video/models/FrameItem';
import { TIME_PX } from '../VideoControl';
import useSocket from '@/components/hooks/useSocket';
import { useKeyPress } from '@/utils/hooks/useKeyPress';
import { PADDING_FRAME_CONTROL, PADDING_LAYOUT } from './ItemTypes';
import { ExportType } from '@/types';

export interface ContainerProps {
  snapToGrid?: boolean;
}

interface PlayableDisplay extends AddingPlayableItem {
  top: number;
  left: number;
}

interface IMoveBox {
  id: string;
  start: number;
  end: number;
  top?: number;
  type: string;
  currentLine?: number;
}

interface BoxMap {
  [key: string]: PlayableDisplay;
}
const GAP_VERTICAL_ROW = 36;
const FIRST_GAP_SPACE = 56;
//container is 100% but it can play max width is PERCENT_WIDTH_ACTUAL_PLAY%
// export const PERCENT_WIDTH_ACTUAL_PLAY = 90;

const DroppableDisplay: FC<ContainerProps> = () => {
  const dispatch = useAppDispatch();
  const keyPressed = useKeyPress('Delete', 'Backspace');
  const { createSocket, socket, disconnect } = useSocket('video');
  const [boxes, setBoxes] = useState<BoxMap>({});
  const [voiceRow, setVoiceRow] = useState<number[]>([]);
  const [diffRow, setDiffVoiceRow] = useState<number[]>([]);
  const {
    addingPlayableItems,
    addingImages,
    addingTexts,
    addingCards,
    isLoadingData,
    totalDuration,
    timeLineSelected,
    leftMarginControlScroll,
    exporting
  } = useAppSelector(selectVideo);

  const [gapVerticalRowLine, setGapVerticalRowLine] = useState<number[]>([]);
  const frame = document.getElementById('control-frame-editing') as HTMLElement;
  const itemEls = useRef(new Set());

  useEffect(() => {
    if (frame) {
      convertItemVideoToDisplay(frame);
    }
  }, [addingPlayableItems, addingImages, addingTexts, addingCards]);

  useEffect(() => {
    if (exporting !== ExportType.exporting) {
      handleGetThumbnailList();
    }
  }, [boxes, exporting]);

  useEffect(() => {
    const { uid, type, main } = timeLineSelected;
    if (keyPressed && uid) {
      if (type === PlayableType.video || type === PlayableType.audio) {
        if (main) {
          dispatch(updatePlayableItem(Object.assign({ uid, type: type, isHide: true })));
        } else {
          dispatch(removePlayableItem({ uid }));
        }
      } else if (type === ObjectType.image) {
        dispatch(removeImage({ uid }));
      } else if (type === ObjectType.text) {
        dispatch(removeText({ uid }));
      } else {
        dispatch(removeCard({ uid }));
      }
    }
  }, [dispatch, keyPressed]);

  const handleGetThumbnailList = async () => {
    const videoWithOutThumbnail = addingPlayableItems.filter(
      (item) => item.type === PlayableType.video && !item.thumbnails?.length && !item.isHide
    );
    const idList = itemEls.current;
    videoWithOutThumbnail.forEach((item, index) => {
      if (idList.has(videoWithOutThumbnail[index].uid)) {
        const sk = createSocket();
        if (sk) {
          const socketPayload = {
            videoId: item.uid,
            videoLink: item.filePath
          };
          sk.emit('generateThumbnails', {
            event: 'generateThumbnails',
            videoLink: socketPayload.videoLink,
            videoId: socketPayload.videoId
          });
        }
      }
      itemEls.current.add(item.uid);
    });
  };

  useEffect(() => {
    socket?.on('generateThumbnails', (data) => {
      if (data?.videoId) {
        const thumbnails = {
          id: data.videoId,
          results: data.thumbnails.map((link: string, index: number) => {
            return { urls: link, start: index * FPS };
          })
        };
        dispatch(
          updatePlayableItem({ uid: thumbnails.id, thumbnails: data.thumbnails, blurBackground: data.blurImage })
        );
        setBoxes(
          update(boxes, {
            [thumbnails.id]: {
              $merge: { thumbnails: thumbnails?.results }
            }
          })
        );
        disconnect();
      }
      if (data?.error || exporting === ExportType.exporting) {
        disconnect();
      }
    });
  }, [setBoxes, socket, exporting]);

  const moveBox = useCallback(
    (elm: IMoveBox) => {
      const { id, start, end, top, type, currentLine } = elm;
      //  rules: they only permit item have the type is audio on one line, and the remaining is permit on them same line
      let line = 0;
      // move the item from to other row to new row
      if (top !== undefined) {
        line = gapVerticalRowLine.findIndex((line) => line === top);
        onDurationChange(type, id, start, end, line);
      }
      // move the item on the same row. no need to check type to drop
      if (currentLine !== undefined) {
        onDurationChange(type, id, start, end, currentLine);
      }
    },
    [boxes]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'box',
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as {
          x: number;
          y: number;
        };

        let left = Math.round(item.left + delta.x) >= 0 ? Math.round(item.left + delta.x) : 0;
        let top = Math.round(item.top + delta.y);

        const rangeClosest = findLineShouldDropOn(top);
        dispatch(UpdateAction({ manualPause: true }));
        // prevent if move the audio type on the rest types

        if (item.itemType === PlayableType.audio && diffRow.includes(rangeClosest)) {
          return;
        }
        // prevent if move the other type on the audio type
        if (item.itemType !== PlayableType.audio && voiceRow.includes(rangeClosest)) {
          return;
        }

        handleOverlapsOnRow({
          left: left,
          top: rangeClosest,
          id: item.id,
          itemType: item.itemType,
          duration: item.duration
        });
        return undefined;
      }
    }),

    [moveBox]
  );

  useEffect(() => {
    // case: if all items not in the first line. It will move up one line
    const arrCombine = [
      ...addingPlayableItems.filter((x) => !x.isHide),
      ...addingImages,
      ...addingTexts,
      ...addingCards
    ];
    const existLine = arrCombine.filter((item) => item.line === 0);
    if (arrCombine.length && !existLine.length) {
      dispatch(reduceCurrentLineItem());
    }
  }, [moveBox]);

  const onDurationChange = (
    type: string,
    uid: string,
    start: number,
    end: number,
    line: number,
    trim?: { start: number; end: number }
  ) => {
    if (type === PlayableType.video || type === PlayableType.audio) {
      const castType = type as PlayableType;
      dispatch(
        updatePlayableItem(
          Object.assign({ uid, type: castType, start: start > 0 ? start : 0, end, line }, trim ? { trim } : {})
        )
      );
    } else if (type === ObjectType.image) {
      dispatch(updateSelectedImage({ uid, start: start > 0 ? start : 0, end, line }));
    } else if (type === ObjectType.text) {
      dispatch(updateSelectedText({ uid, start: start > 0 ? start : 0, end, line }));
    } else {
      dispatch(updateSelectedCard({ uid, start: start > 0 ? start : 0, end, line }));
    }
  };

  const convertItemVideoToDisplay = (frame: HTMLElement) => {
    let obj = {} as any;
    let rowVoice: number[] = [];
    let diffVoice: number[] = [];
    // const widthCanPlay = (frame.offsetWidth * PERCENT_WIDTH_ACTUAL_PLAY) / 100;

    addingPlayableItems
      .filter((x) => !x.isHide)
      .forEach((element, index) => {
        const videoNextVideoAppliedTransition = addingPlayableItems.find(
          (vd: any) => vd.line === element.line && vd.start === element.end
        );
        const videoPreAppliedTransition = addingPlayableItems.find(
          (vd: any) => element.start === vd.end && element.line === vd.line
        );

        const isBehideVdTransition =
          videoPreAppliedTransition === undefined
            ? false
            : element?.start === videoPreAppliedTransition?.end && videoPreAppliedTransition?.isApplyTransition
            ? true
            : false;

        const isNextVdTransition =
          videoNextVideoAppliedTransition === undefined
            ? false
            : element?.end === videoNextVideoAppliedTransition?.start
            ? true
            : false;

        let space = 0;
        if (element.line !== undefined && element.line >= 1) {
          space = element.line * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
        }

        const marginLeft = !!element.start ? element.start / TIME_PX : 0;

        let top = element.line !== undefined ? space : index * GAP_VERTICAL_ROW;
        const thumbnails = element.thumbnails?.length
          ? element.thumbnails.map((link: string, index: number) => {
              return { urls: link, start: index * FPS };
            })
          : [];
        obj[element.uid] = {
          ...element,
          top: top,
          left: marginLeft,
          thumbnails: thumbnails,
          isApplyTransition: isNextVdTransition && element.transition?.type ? true : false,
          isNeedApplyTransition: isBehideVdTransition
        };
      });

    setVoiceRow(rowVoice);
    setDiffVoiceRow(diffVoice);

    addingImages.forEach((element, index) => {
      const marginLeft = !!element.start ? element.start / TIME_PX : 0;
      let space = 0;
      if (element.line !== undefined && element.line >= 1) {
        space = element.line * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
      }
      diffVoice.push(space);

      obj[element.uid || uuidv4()] = {
        ...element,
        top: element.line !== undefined ? space : index * GAP_VERTICAL_ROW,
        left: marginLeft,
        type: ObjectType.image
      };
    });

    addingTexts.forEach((element, index) => {
      const marginLeft = !!element.start ? element.start / TIME_PX : 0;
      let space = 0;
      if (element.line !== undefined && element.line >= 1) {
        space = element.line * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
      }
      diffVoice.push(space);

      obj[element.uid || uuidv4()] = {
        ...element,
        top: element.line !== undefined ? space : index * GAP_VERTICAL_ROW,
        left: marginLeft,
        type: ObjectType.text,
        name: element.name ? element.name : element.value
      };
    });

    addingCards.forEach((element, index) => {
      const marginLeft = !!element.start ? element.start / TIME_PX : 0;
      let space = 0;
      if (element.line !== undefined && element.line >= 1) {
        space = element.line * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
      }
      diffVoice.push(space);

      obj[element.uid || uuidv4()] = {
        ...element,
        top: element.line !== undefined ? space : index * GAP_VERTICAL_ROW,
        left: marginLeft,
        type: ObjectType.card
      };
    });

    //make the line for drop
    if (countRow(obj) >= 4) {
      setGapVerticalRowLine(() =>
        Object.entries(obj).map((_, index) => {
          let space = 0;
          if (index >= 1) {
            space = index * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
          }

          return space;
        })
      );
    } else {
      const line = Array.from(Array(4).keys()).map((index: number) => {
        let space = 0;
        if (index >= 1) {
          space = index * GAP_VERTICAL_ROW + (FIRST_GAP_SPACE - GAP_VERTICAL_ROW);
        }

        return space;
      });
      setGapVerticalRowLine(line);
    }
    setBoxes(obj);
  };

  const countRow = (obj: any) => {
    if (Object.entries(obj).length) {
      const arr = Object.entries(obj).map(([key, value]) => value);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      return arr.filter((value: any, index, self) => self.findIndex((m: any) => m.line === value.line) === index)
        .length;
    }
    return 0;
  };

  const handleOverlapsOnRow = (position: DragItem) => {
    const cardsOnCurrentRow: { start: number; end: number; id: string }[] = [];

    const currentCard = {
      start: position.left * TIME_PX,
      end: position.left * TIME_PX + position.duration,
      id: position.id
    };

    Object.entries(boxes).forEach(([key, value]) => {
      if (position.top === value.top && position.id !== key) {
        cardsOnCurrentRow.push(value as any);
      }
    });

    if (!cardsOnCurrentRow.length) {
      // move box to the line without elm on that
      const elm: IMoveBox = {
        id: position.id,
        start: currentCard.start,
        end: currentCard.end,
        top: position.top,
        type: position.itemType
      };
      moveBox(elm);

      return;
    }

    const { side, timeLines }: any = onCheckOverlapSide(currentCard, cardsOnCurrentRow);

    if (side === 'left') {
      const elm: IMoveBox = {
        id: position.id,
        start: currentCard.start,
        end: currentCard.end,
        top: position.top,
        type: position.itemType
      };
      moveBox(elm);
      const gap = elm.end - timeLines[0].start;
      timeLines.forEach((element: any) => {
        const elm: IMoveBox = {
          id: element.uid,
          start: element.start + gap,
          end: element.end + gap,
          type: element.type,
          currentLine: element.line
        };
        moveBox(elm);
      });
      return;
    }

    if (side === 'right') {
      const duration = currentCard.end - currentCard.start;
      const elm: IMoveBox = {
        id: position.id,
        start: timeLines[0].end,
        end: timeLines[0].end + duration,
        top: position.top,
        type: position.itemType
      };
      moveBox(elm);

      if (timeLines.length > 1) {
        //case: check no need to move other time line if space is enough for change
        if (timeLines[1].start - timeLines[0].end + 0.1 >= duration) return;
        let preGap = 0;
        if (timeLines[1]) {
          //preGap mean that space between two element
          preGap = timeLines[1].start - timeLines[0].end;
        }
        timeLines.shift();
        let latestEndPosition = elm.end;
        //  let checkPoint = elm.end + (timeLines[0].end - timeLines[0].start) + preGap
        for (let i = 0; i < timeLines.length; i++) {
          if (timeLines[i + 1]) {
            // case: stop if the gap of next element is enough for place elm
            if (latestEndPosition < timeLines[i].start) break;
            // else: continue move.
            const box: IMoveBox = {
              id: timeLines[i].uid,
              start: latestEndPosition + preGap,
              end: timeLines[i].end - timeLines[i].start + latestEndPosition + preGap,
              type: timeLines[i].type,
              currentLine: timeLines[i].line
            };
            moveBox(box);
            preGap = timeLines[i + 1].start - timeLines[i].end;
            latestEndPosition = box.end;
          } else {
            //case: don't have the next elm. so the current is the last
            const box: IMoveBox = {
              id: timeLines[i].uid,
              start: latestEndPosition + preGap,
              end: timeLines[i].end - timeLines[i].start + latestEndPosition + preGap,
              type: timeLines[i].type,
              currentLine: timeLines[i].line
            };
            moveBox(box);
          }
        }
      }

      return;
    }

    const elm: IMoveBox = {
      id: position.id,
      start: currentCard.start,
      end: currentCard.end,
      top: position.top,
      type: position.itemType
    };
    moveBox(elm);
  };

  const findLineShouldDropOn = (num: number) => {
    let closestNum = gapVerticalRowLine[0];

    for (let i = 1; i < gapVerticalRowLine.length; i++) {
      const prevDiff = Math.abs(num - closestNum);
      const currDiff = Math.abs(num - gapVerticalRowLine[i]);

      if (currDiff < prevDiff) {
        closestNum = gapVerticalRowLine[i];
      }
    }

    return closestNum;
  };

  const heightFrame = useMemo(() => {
    if (!!gapVerticalRowLine.length) {
      const lines = [...gapVerticalRowLine];
      const val = lines.pop() || 0;
      return val + GAP_VERTICAL_ROW;
    }
    return 144;
  }, [gapVerticalRowLine]);

  const onSeekTimeLine = (e: React.MouseEvent) => {
    const target = e.target as HTMLDivElement;
    const boxId = `#time-box-${timeLineSelected.uid}`;
    const instance = target.closest(boxId);
    if (instance) {
      return;
    }
    const currentTime = (leftMarginControlScroll + e.clientX - PADDING_LAYOUT - PADDING_FRAME_CONTROL) * TIME_PX;
    const time = currentTime > totalDuration ? totalDuration : currentTime;

    // set this timestamp to handle error seek same time on timeline
    dispatch(setTimeStampSeek());
    dispatch(UpdateAction({ totalPlayed: time, currentTimeSelected: e.clientX }));
    dispatch(unSelectElement());
    dispatch(unSelectAudio());
    dispatch(unselectIdElement());
  };

  return (
    <div
      id="control-frame-editing"
      className="drop-area"
      ref={drop}
      style={{ position: 'relative', height: heightFrame, minHeight: '100%', minWidth: totalDuration / TIME_PX }}
      onClick={(e) => onSeekTimeLine(e)}
    >
      {!isLoadingData &&
        Object.entries(boxes).map(([key, value], index) => {
          let trim: Trim = {
            start: 0,
            end: 0
          };

          let timeDisplay = 0;

          let widthPx = 0;
          if (value.start !== undefined && value.end !== undefined) {
            timeDisplay = value.end - value.start;
            trim = value.trim;
          }

          widthPx = timeDisplay / TIME_PX;

          return (
            <DraggableBox
              key={key}
              id={key}
              index={index}
              name={value.name}
              left={value.left}
              width={widthPx}
              trim={trim}
              start={value.start}
              end={value.end}
              duration={value?.duration}
              top={value.top}
              itemType={value.type}
              isMainVideo={value.main}
              thumbnails={value.thumbnails}
              line={value.line}
              isHide={value?.isHide}
              animation={value?.animation}
              isApplyTransition={value?.isApplyTransition && value?.transition?.type}
              isNeedApplyTransition={value?.isNeedApplyTransition}
              idSubtitleLinked={value?.idSubtitleLinked}
            />
          );
        })}
    </div>
  );
};

export default DroppableDisplay;

export const onCheckOverlapSide = (card: any, cards: any[]) => {
  cards.sort((a, b) => a.start - b.start);
  const result = {
    side: '',
    timeLines: []
  } as any;

  for (let index = 0; index < cards.length; index++) {
    const { start, end } = cards[index];
    if (card.start <= start && card.end >= start) {
      //over left side
      result.timeLines = [];
      result.side = 'left';
      for (let idx = index; idx < cards.length; idx++) {
        result.timeLines.push(cards[idx]);
      }
      break;
    }
    if (card.start > start && card.start < end) {
      result.timeLines = [];
      // over right side
      result.side = 'right';
      for (let idx = index; idx < cards.length; idx++) {
        result.timeLines.push(cards[idx]);
      }
      break;
    }

    result.side = 'noOver';
    result.timeLines = cards;
  }
  return result;
};
