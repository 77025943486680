import React from 'react';

import { SVGProps } from './SVG.props';

const Text = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          d="M0.5 12C0.5 2.28 2.78 0 12.5 0C22.22 0 24.5 2.28 24.5 12C24.5 21.72 22.22 24 12.5 24C2.78 24 0.5 21.72 0.5 12Z"
          fill={fillbg || '#070550'}
        />
      </g>
      <path
        d="M16 8V9.66038L15.2125 9.75327C15.1042 9.67586 15 9.5133 14.9 9.2656H13.4V14.6531C13.7333 14.777 13.9625 14.9047 14.0875 15.0363L13.9625 16H11.0375L10.9125 15.0363C11.0208 14.9047 11.25 14.777 11.6 14.6531V9.2656H10.1C10 9.5133 9.89583 9.67586 9.7875 9.75327L9 9.66038V8H16Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Text;
