import * as React from 'react';
import Draggable from '../Draggable';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectVideo, setCaptionSettings, update, updateSelectedCard } from '@/features/video/store/videoSlice';
import '../../constants/animations/styles.scss';
import ContentEditable from 'react-contenteditable';
import { FHDPixel } from '@/constants/screens';
import { Global } from '@emotion/core';
import { getFormatFile } from '@/utils/getFormatFont';
import Fonts from '@/utils/fonts';
import { replaceSpecialCharacter } from '@/utils/htmlHelper';
import { httpToHttps } from '@/utils/string/httpConverter';

interface IMovableGraphicProps {
  id: string;
  onResize: (width: number, height: number, uid: string) => void;
  url?: string;
  onDragStop?: (e: DraggableEvent, data: DraggableData) => void;
  onFocus?: (id: string) => void;
  onClickRemove?: (id: string) => void;
  isSelected?: boolean;
  width?: number;
  height?: number;
  x: number;
  y: number;
  show?: boolean;
  item?: any;
  backgroundColor?: string;
  zIndex?: number;
}

const MovableGraphic: React.FunctionComponent<IMovableGraphicProps> = ({
  onResize,
  id,
  onDragStop,
  onFocus,
  onClickRemove,
  isSelected,
  width,
  height,
  x,
  y,
  show,
  item,
  backgroundColor,
  zIndex
}) => {
  const dispatch = useAppDispatch();
  const { totalPlayed, playerSizeHD, subtitleStyle } = useAppSelector(selectVideo);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const targetRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (targetRef.current) {
      targetRef.current.style.width = `${width}px`;
      targetRef.current.style.height = `${height}px`;
    }
  }, [width, height]);

  React.useEffect(() => {
    // load font
    Fonts(item?.elementTexts?.[0]?.styles.fontName!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window?.Clipboard;
    let plainText = clipboardData.getData('text/plain');
    plainText = replaceSpecialCharacter(plainText);
    document.execCommand('insertText', false, plainText);
  };

  // resize font size of text that match with UI and export
  const convertFontSize = (size?: string) => {
    const fontSize = parseInt(size?.replace('px', '') as string);
    const currentPlayerSizeHd = playerSizeHD as { width: number; height: number };
    if (fontSize) {
      const newSize = (currentPlayerSizeHd?.width / FHDPixel.width) * fontSize;
      return `${newSize}px`;
    }
    return null;
  };

  const onTextChange = (value: string, tagNameId: string, uid: string) => {
    let index = item?.elementTexts?.findIndex((text: any) => text.uid === tagNameId);
    const newTexts = [...item?.elementTexts];
    newTexts[index] = { ...newTexts[index], value };

    dispatch(updateSelectedCard({ uid, elementTexts: newTexts }));
  };

  const unSelectObjectTimeline = () => {
    dispatch(
      update({
        timeLineSelected: { uid: '', type: '' }
      })
    );
  };

  const onClickTagName = () => {
    dispatch(update({ selectedTab: 'Text Effects & Cards' }));
    dispatch(update({ selectedTabInside: 'Cards' }));
    unSelectObjectTimeline();
  };

  const elementPosition = {
    x: item?.elementTexts?.[0]?.position?.x,
    y: item?.elementTexts?.[0]?.position?.y
  };

  const handleDoubleClick = () => {
    if (contentRef.current) {
      const range = document.createRange();
      range.selectNodeContents(contentRef.current);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const getFontWeight = (fontWeight: string | number) => {
    if (['bold', 'normal'].includes(fontWeight as string)) {
      return fontWeight;
    }
    const fontWeightNumber = parseInt(fontWeight as string);
    if (isNaN(fontWeightNumber) || fontWeightNumber < 700) {
      return 'normal';
    }

    return 'bold';
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <div
      onClick={() => {
        onFocus?.(id);
        onClickTagName();
        handleClearCaptionSetting();
      }}
      className={totalPlayed === 0 ? 'hidden' : show ? 'show' : 'hidden'}
      style={{
        zIndex: zIndex,
        backgroundColor: backgroundColor,
        height: height,
        width: width,
        display: show ? 'block' : 'none',
        position: 'absolute'
      }}
    >
      <Draggable
        onDragStop={onDragStop}
        onClickRemove={() => onClickRemove?.(id)}
        position={elementPosition}
        zIndex={zIndex || 8}
      >
        <ContentEditable
          innerRef={contentRef}
          html={item?.elementTexts?.[0]?.value}
          onFocus={() => onFocus?.(id)}
          onPaste={handlePaste}
          onDoubleClick={handleDoubleClick}
          style={{
            margin: '0px',
            padding: '0px',
            paddingTop: '0px',
            border: 'none',
            cursor: 'text',
            background: item?.elementTexts?.[0]?.styles.backgroundColor,
            fontFamily: item?.elementTexts?.[0]?.styles.fontName,
            fontSize: convertFontSize(item?.elementTexts?.[0]?.styles.fontSize as string),
            color: item?.elementTexts?.[0]?.styles.color,
            // color: 'white',
            lineHeight: convertFontSize(item?.elementTexts?.[0]?.styles.fontSize as string),
            fontStyle: item?.elementTexts?.[0]?.styles.fontStyle,
            fontWeight: getFontWeight(item?.elementTexts?.[0]?.styles.fontWeight),
            textAlign: item?.elementTexts?.[0]?.styles.textAlign,
            fontSmoothing: 'antialiased',
            '-webkit-font-smoothing': 'antialiased'
          }}
          onChange={(e) => onTextChange(e.currentTarget.innerHTML || '', item?.elementTexts?.[0]?.uid, item?.uid)}
          // onClick={() => onSelectTextInCardObj(item?.uid, item?.elementTexts[0]?.uid)}
          onClick={onClickTagName}
        />
      </Draggable>

      <Global
        key={item?.elementTexts?.[0]?.styles.fontName}
        styles={[
          {
            '@font-face': {
              fontFamily: item?.elementTexts?.[0]?.styles.fontName,
              src: `url(${httpToHttps(item?.elementTexts?.[0]?.styles.fontFile)}) ${getFormatFile(
                item?.elementTexts?.[0]?.styles.fontFile as string
              )}`
            }
          }
        ]}
      />
    </div>
  );
};

export default MovableGraphic;
