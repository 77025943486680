import { ObjectType } from '@/features/video/models/FrameItem';
import { v4 as uuidv4 } from 'uuid';

export const tagNameTemplates = [
  {
    id: '0',
    type: 'card',
    name: 'Template tag name 01'
  },
  {
    id: '1',
    type: 'graphic',
    name: 'Template tag name 01'
  }
];

const lastElementShow = {
  uid: uuidv4(),
  name: 'Location',
  value: 'Location',
  isShow: true,
  styles: {
    color: 'rgba(0,0,0,1)',
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '32px'
  }
};

const lastElementHide = {
  uid: uuidv4(),
  name: 'Location',
  value: 'Location',
  isShow: false,
  styles: {
    color: 'rgba(0,0,0,1)',
    fontSize: '21px',
    fontWeight: 500,
    lineHeight: '32px'
  }
};

export const getAddingElementTexts = (fullName?: string, roleName?: string) => {
  return [
    {
      uid: uuidv4(),
      name: 'Name',
      value: fullName ? fullName : 'Name',
      isShow: true,
      styles: {
        color: 'rgba(0,0,0,1)',
        fontSize: '30px',
        fontWeight: 500,
        lineHeight: '32px'
      }
    },
    {
      uid: uuidv4(),
      name: 'Title',
      value: roleName ? roleName : 'Title',
      isShow: true,
      styles: {
        color: 'rgba(0,0,0,1)',
        fontSize: '21px',
        fontWeight: 500,
        lineHeight: '32px'
      }
    }
  ];
};

const nameTextGraphic = {
  uid: uuidv4(),
  name: 'Name',
  value: 'Your text here',
  isShow: true,
  styles: {
    color: 'rgba(0,0,0,1)',
    fontSize: '92px',
    fontWeight: 500,
    lineHeight: '32px'
  }
};

export const addTemplateTagNameTemplate = (
  addingCardsLength?: number | any,
  start?: number,
  fullName?: string,
  roleName?: string,
  width?: number,
  height?: number,
  type?: string
) => {
  const template = {
    uid: uuidv4(),
    start: start,
    end: (start as number) + 10,
    name: `${type === ObjectType.card ? 'Tag name ' : 'Graphic '} ${
      addingCardsLength > 9 ? `${addingCardsLength + 1}` : `0${addingCardsLength + 1}`
    }`,
    line: 0,
    position: {
      x: 0,
      y: 0
    },
    x: 0,
    y: 0,
    type: type,
    width: width ? width : 205,
    height: height ? height : 100,
    backgroundColor: 'rgba(255,255,255,1)',
    elementTexts:
      type === ObjectType.graphic
        ? [nameTextGraphic]
        : fullName || roleName
        ? getAddingElementTexts(fullName, roleName).concat(lastElementHide)
        : getAddingElementTexts().concat(lastElementShow)
  };
  return template;
};
