/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  selectVideo,
  getAudioTracks,
  update,
  updatePlayableItem,
  setCaptionSettings
} from '@/features/video/store/videoSlice';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import MovableText from '@/components/MovableText';
import MovableImage from '@/components/MovableImage';
import MovableTagName from '@/components/MovableTagName';
import MovableGraphic from '@/components/MovableGraphic';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import { TabInsideV2, TabV2, Transcribe } from '../../models/VideoFrame';
import { isInRange, isInRangeSubtitle, isInRangeVideo } from '@/features/video/services/videoService';
// import StudioHeader from '@/layout/header/Header';
import { AddingPlayableItem, ObjectType, PlayableType } from '../../models/FrameItem';
import { selectSubtitle as getSubtitlesByUserId } from '@/features/video/store/subtitleSlice';
import {
  BoxPlayer,
  MainRatioOverlaps,
  PlayerWrapper,
  RatioWrapper,
  SideBox,
  SubContainer,
  SubtitleDiv,
  VideoFrameContainer,
  VideoSection,
  VideoBox,
  VideoBoxTransition
} from './styles';
import LoadingIcon from '@/components/LoadingIcon/LoadingIcon';
import { useVideoFrameHook } from './useVideoFrame';
import '../../../../constants/transitions/styles.scss';
import { FHDPixel } from '@/constants/screens';
import {
  TransitionFadeToBlack,
  TransitionFadeToWhite,
  TransitionWipeLeft,
  TransitionWipeRight,
  TransitionWipeUp,
  TransitionWipeDown
} from './transitionStyles';
import Draggable from '@/components/Draggable';
import VideoControlBar from './components/video-control-bar';
import { SUBTITLE_LAYER_PADDING } from '@/constants/commons';

const VideoFrame: React.FC = () => {
  const {
    addingTexts = [],
    addingImages,
    selectedObj,
    totalPlayed,
    manualPause,
    subtitles,
    playerSize,
    subtitleStyle,
    addingCards,
    waitingForLoadVideo,
    isShowCaption,
    addingPlayableItems,
    crop,
    backgroundColor,
    captionStyleSettings
  } = useAppSelector(selectVideo);

  const audioPlayingList = useAppSelector(getAudioTracks);
  const { subtitleViewing } = useAppSelector(getSubtitlesByUserId);
  const [listSubViewing, setListSuvViewing] = useState<any>([]);
  const [hiddenCropUI, setHiddenCropUI] = useState<number>();
  const [isFocus, setFocus] = useState(false);

  const dispatch = useAppDispatch();

  const {
    onRemove,
    playerSizeHD,
    playerWrapperRef,
    onSelectObj,
    onUpdateTagNameSize,
    onUpdateImageSize,
    onUpdateItemsPosition,
    onTextChange,
    audioWrapperRef,
    videoList,
    getMapPlayerVideo,
    HDPixel,
    onUpdateItemsPositionInGraphic,
    playerRef,
    getRatioWidthHeight,
    setPlayerSizeHD,
    onDragVideoStop,
    onFocusVideo,
    videoSelectedId,
    listTransitions
  } = useVideoFrameHook();

  const layerPriority = (item: any, from: number | 100) => {
    if (isInRange(item.start || 0, item.end || 0, totalPlayed)) {
      return from - item.line || 0;
    }
    return 0;
  };

  const convertFontSize = (size?: string) => {
    const fontSize = parseInt(size?.replace('px', '') as string);
    const currentPlayerSizeHd = playerSizeHD as { width: number; height: number };
    if (fontSize) {
      const newSize = (currentPlayerSizeHd?.width / FHDPixel.width) * fontSize;
      return `${newSize}px`;
    }
    return null;
  };

  const renderPlayerForTransition = (filePath?: string, startVd?: number) => {
    return (
      <Player
        src={filePath}
        fluid={false}
        //@ts-ignore
        width={'100%'}
        //@ts-ignore
        height={'100%'}
        preload="auto"
        autoPlay={false}
        muted={true}
        duration={3}
        controls={false}
        startTime={startVd}
        currentTime={startVd}
      >
        <ControlBar autoHide={true} disableDefaultControls={true} disableCompletely={true} />
      </Player>
    );
  };

  const renderTransition = (type?: string, filePath?: string, startVd?: number) => {
    switch (type) {
      case 'fadeblack':
        return <TransitionFadeToBlack />;
      case 'fadewhite':
        return <TransitionFadeToWhite />;
      case 'wipeleft':
        return <TransitionWipeLeft>{renderPlayerForTransition(filePath, startVd)}</TransitionWipeLeft>;
      case 'wiperight':
        return <TransitionWipeRight>{renderPlayerForTransition(filePath, startVd)}</TransitionWipeRight>;
      case 'wipeup':
        return <TransitionWipeUp>{renderPlayerForTransition(filePath, startVd)}</TransitionWipeUp>;
      case 'wipedown':
        return <TransitionWipeDown />;
      default:
        break;
    }
  };

  const renderSubtitle = (subLine: string, idx: number) => {
    return (
      <SubContainer key={idx} width={playerSize.width * 0.9}>
        <SubtitleDiv
          textColor={captionStyleSettings?.color}
          backgroundColor={captionStyleSettings?.backgroundColor}
          fontSize={convertFontSize(captionStyleSettings?.fontSize) as string}
          padding={convertFontSize(`${SUBTITLE_LAYER_PADDING}px`) as string}
        >
          {subLine}
        </SubtitleDiv>
      </SubContainer>
    );
  };

  useEffect(() => {
    setListSuvViewing(subtitleViewing);
  }, [subtitleViewing]);

  const renderCaption = () => {
    return (
      isShowCaption &&
      (!!listSubViewing?.length
        ? listSubViewing?.map((item: any) =>
            item?.transcribe?.map((subLine: Transcribe, idx: number) => {
              return (
                isInRangeSubtitle(
                  subLine.start,
                  subLine.end,
                  totalPlayed,
                  item?.trim?.start,
                  item?.trim?.end,
                  item?.start
                ) && renderSubtitle(subLine.sub, idx)
              );
            })
          )
        : !!subtitles?.length &&
          typeof subtitles === 'object' &&
          subtitles?.map((item: any) =>
            item?.transcribe?.map((subLine: Transcribe, idx: number) => {
              return (
                isInRangeSubtitle(
                  subLine.start,
                  subLine.end,
                  totalPlayed,
                  item?.trim?.start,
                  item?.trim?.end,
                  item?.start
                ) && renderSubtitle(subLine.sub, idx)
              );
            })
          ))
    );
  };

  useEffect(() => {
    setHiddenCropUI((playerSizeHD.width * crop?.x) / FHDPixel.width);
  }, [crop]);

  const currentVideoPlaying = (() => {
    let temp = addingPlayableItems.map((item: AddingPlayableItem) => ({ priority: layerPriority(item, 1000), item }));
    temp = temp.sort((a, b) => b.priority - a.priority);
    return temp.find(({ item }) => {
      return item.type === PlayableType.video && isInRange(item.start || 0, item.end || 0, totalPlayed);
    })?.item;
  })();

  const isShowVideo = useCallback(
    (item: AddingPlayableItem) => {
      return (
        (isInRangeVideo(item.start || 0, item.end || 0, totalPlayed) &&
          currentVideoPlaying?.isBlurBackground &&
          currentVideoPlaying?.uid === item.uid) ||
        (!currentVideoPlaying?.isBlurBackground && currentVideoPlaying?.uid === item.uid)
      );
    },
    [currentVideoPlaying, totalPlayed]
  );

  const scaleFillContainer = (width: number, height: number) => {
    if (width && height) {
      const ratio = width / height;
      const ratioHD = playerSize.width / playerSize.height;
      if (ratio >= ratioHD) {
        return {
          backgroundWidth: playerSize.width,
          backgroundHeight: (playerSize.width * width) / height
        };
      } else {
        return {
          backgroundWidth: (playerSize.height * height) / width,
          backgroundHeight: playerSize.height
        };
      }
    }
  };

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <VideoFrameContainer>
      <VideoSection>
        <PlayerWrapper className="play-screen" ref={playerWrapperRef}>
          {waitingForLoadVideo && !manualPause && (
            <div style={{ zIndex: 9999, position: 'absolute' }}>
              <LoadingIcon width={30} height={30} />
            </div>
          )}
          <RatioWrapper className="RatioWrapper">
            <SideBox />
            <MainRatioOverlaps
              isBgBlur={currentVideoPlaying?.isBlurBackground}
              bgUrl={currentVideoPlaying?.blurBackground}
              bgColor={backgroundColor}
              width={parseInt(playerSize?.width as any)}
              height={parseInt(playerSize?.height as any)}
              {...scaleFillContainer(
                currentVideoPlaying?.width || playerSize?.width,
                currentVideoPlaying?.height || playerSize?.width
              )}
            >
              {addingImages.map((image: any, index) => {
                return (
                  <MovableImage
                    parentWidth={playerSize.width}
                    parentHeight={playerSize.height}
                    key={image.uid}
                    url={image.filePath ? image.filePath : image.links?.Location}
                    onResize={onUpdateImageSize}
                    id={image.uid || index}
                    onDragStop={(e, data) => onUpdateItemsPosition(data, image.uid, ObjectType.image)}
                    onFocus={(uid) => onSelectObj(uid, ObjectType.image)}
                    onClickRemove={(uid) => onRemove(uid, ObjectType.image)}
                    isSelected={selectedObj?.uid === image.uid}
                    width={image.width}
                    height={image.height}
                    x={image.x || 0}
                    y={image.y || 0}
                    show={isInRange(image.start || 0, image.end || 0, totalPlayed)}
                    item={image}
                    zIndex={layerPriority(image, 1000)}
                  />
                );
              })}
              {addingTexts.map((text, i) => {
                return (
                  <MovableText
                    key={text.uid || i}
                    value={text.value}
                    styles={text.styles}
                    fontFile={text.fontFile}
                    onChange={onTextChange}
                    id={text.uid}
                    onDragStop={(e, data) => {
                      onUpdateItemsPosition(data, text.uid, ObjectType.text);
                    }}
                    onFocus={(uid) => onSelectObj(uid, ObjectType.text, TabV2.effect, TabInsideV2.texts)}
                    onClickRemove={(uid) => onRemove(uid, ObjectType.text)}
                    isSelected={selectedObj?.uid === text.uid}
                    position={{ x: text.x || 0, y: text.y || 0 }}
                    show={isInRange(text.start || 0, text.end || 0, totalPlayed)}
                    item={text}
                    x={text?.x || 0}
                    y={text?.y || 0}
                    zIndex={layerPriority(text, 1000)}
                  />
                );
              })}
              {addingCards.map((card: any, index) => {
                return card?.type === ObjectType.card ? (
                  <MovableTagName
                    key={card.uid}
                    onResize={onUpdateTagNameSize}
                    id={card.uid || index}
                    onDragStop={(e, data) => onUpdateItemsPosition(data, card.uid, ObjectType.card)}
                    onFocus={(uid) => onSelectObj(uid, ObjectType.card, TabV2.effect, TabInsideV2.cards)}
                    onClickRemove={(uid) => onRemove(uid, ObjectType.card)}
                    isSelected={selectedObj?.uid === card.uid}
                    width={card.width}
                    height={card.height}
                    x={card.x || 0}
                    y={card.y || 0}
                    show={isInRange(card.start || 0, card.end || 0, totalPlayed)}
                    item={card}
                    backgroundColor={card?.backgroundColor}
                    zIndex={layerPriority(card, 1000)}
                  />
                ) : (
                  <MovableGraphic
                    key={card.uid}
                    onResize={onUpdateTagNameSize}
                    id={card.uid || index}
                    onDragStop={(e, data) => onUpdateItemsPositionInGraphic(data, card.uid, card)}
                    onFocus={(uid) => onSelectObj(uid, ObjectType.graphic)}
                    onClickRemove={(uid) => onRemove(uid, ObjectType.graphic)}
                    isSelected={selectedObj?.uid === card.uid}
                    width={card.width}
                    height={card.height}
                    x={card?.elementTexts?.[0]?.position?.x || 0}
                    y={card?.elementTexts?.[0]?.position?.y || 0}
                    show={isInRange(card.start || 0, card.end || 0, totalPlayed)}
                    item={card}
                    backgroundColor={card?.backgroundColor}
                    zIndex={layerPriority(card, 1000)}
                  />
                );
              })}
              {videoList?.map((item: AddingPlayableItem | any, idx: number) => {
                return (
                  <Draggable
                    key={item.uid}
                    onDragStop={(e, data) => onDragVideoStop(e, data, item.uid)}
                    position={item.crop}
                    zIndex={layerPriority(item, 100)}
                    onMouseDown={() => onFocusVideo(item.uid)}
                  >
                    <BoxPlayer
                      className="box-player"
                      width={playerSizeHD.width * (item.zoom || 1)}
                      height={playerSizeHD.height * (item.zoom || 1)}
                      marginLeft={hiddenCropUI}
                      data-test={backgroundColor}
                      isShow={isShowVideo(item)}
                      onClick={() => {
                        handleClearCaptionSetting();
                        dispatch(update({ selectedTab: 'Media' }));
                        dispatch(
                          update({
                            audioSelected: {
                              uid: ''
                            }
                          })
                        );
                        onSelectObj('', ObjectType.image);
                      }}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      isSelected={videoSelectedId === item.uid && isFocus}
                    >
                      <VideoBox key={item.uid} className="video-box">
                        <Player
                          ref={(node) => {
                            const map = getMapPlayerVideo();
                            if (node) {
                              map.set(item.uid, node);
                            } else {
                              map.delete(item.uid);
                            }
                          }}
                          src={item.filePath}
                          fluid={false}
                          //@ts-ignore
                          width={'100%'}
                          //@ts-ignore
                          height={'100%'}
                          // aspectRatio={nextAspectRatio}
                          preload="auto"
                          // startTime={totalPlayed}
                        >
                          <ControlBar disableDefaultControls={true} disableCompletely={true} />
                          <BigPlayButton position="center" className="origin-play-button" />
                        </Player>
                      </VideoBox>
                    </BoxPlayer>
                  </Draggable>
                );
              })}
              {listTransitions?.map((item: any, idx) => {
                const resolution = item?.resolution;
                return (
                  item?.transition?.start <= totalPlayed &&
                  item?.transition?.start +
                    (item?.transition?.type === 'fadewhite' || item?.transition?.type === 'fadeblack' ? 3 : 1.5) >=
                    totalPlayed &&
                  !manualPause && (
                    <VideoBoxTransition
                      key={idx}
                      // marginTop={
                      //   resolution?.height < HDPixelHeight ? (100 - (resolution?.height / HDPixelHeight) * 100) / 4 : 0
                      // }
                      // marginLeft={
                      //   resolution?.width < HDPixelWidth ? (100 - (resolution?.width / HDPixelWidth) * 100) / 2 : 0
                      // }
                      // width={resolution?.width < HDPixelWidth ? (resolution?.width / HDPixelWidth) * 100 : 100}
                      // height={resolution?.height < HDPixelHeight ? (resolution?.height / HDPixelHeight) * 100 : 100}
                      marginLeft={hiddenCropUI}
                      width={playerSizeHD.width * (item.zoom || 1)}
                      height={playerSizeHD.height * (item.zoom || 1)}
                      type={item?.transition?.type}
                      crop={item?.crop}
                    >
                      {renderTransition(item?.transition?.type, item?.urlTransition, item?.trim?.start)}
                    </VideoBoxTransition>
                  )
                );
              })}
            </MainRatioOverlaps>
            <SideBox />
          </RatioWrapper>
          {/* <ImageContainer className={showTransitionScreen ? 'transition-show' : 'transition-hidden'} /> */}
          {renderCaption()}
          <div ref={audioWrapperRef}>
            {audioPlayingList?.map((item) => (
              <audio key={item.uid} controls preload="auto" id={item.uid} style={{ visibility: 'hidden' }}>
                <source src={item?.filePath} type="audio/mpeg" />
              </audio>
            ))}
          </div>
        </PlayerWrapper>
        <VideoControlBar
          audioWrapperRef={audioWrapperRef}
          playerRef={playerRef}
          playerWrapperRef={playerWrapperRef}
          getRatioWidthHeight={getRatioWidthHeight}
          setPlayerSizeHD={setPlayerSizeHD}
          playerSizeHD={playerSizeHD}
          videoSelectedId={videoSelectedId}
        />
      </VideoSection>
    </VideoFrameContainer>
  );
};

export default VideoFrame;
