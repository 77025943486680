import { useDispatchRequest } from '@redux-requests/react';
import { RequestAction } from '@redux-requests/core';
import { useSelector } from 'react-redux';
import { getToken } from '@/features/video/store/authSlice';
import { useCallback } from 'react';

type GetActionFn = (token?: string) => RequestAction;

const useRequest = () => {
  const dispatchRequest = useDispatchRequest();
  const token = useSelector(getToken);

  const request = useCallback(
    async (getAction: GetActionFn) => {
      if (!token) {
        return;
      }
      return await dispatchRequest(getAction(token));
    },
    [dispatchRequest, token]
  );
  return { request };
};

export default useRequest;
