import React from 'react';

import { SVGProps } from './SVG.props';

const ForWard = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg {...props} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58853 5.00915L5.58971 3.62535C3.93163 2.47745 1.66669 3.66418 1.66669 5.68083L1.66669 14.3189C1.66669 16.3356 3.93164 17.5223 5.58971 16.3744L7.58853 14.9906C8.02198 16.5814 9.95907 17.3879 11.423 16.3744L17.6617 12.0554C19.0976 11.0612 19.0976 8.93853 17.6617 7.9444L11.423 3.62535C9.95906 2.61183 8.02198 3.41833 7.58853 5.00915ZM10.8797 10.685C11.3583 10.3537 11.3583 9.6461 10.8797 9.31472L4.64103 4.99567C4.08834 4.61304 3.33335 5.00861 3.33335 5.68083L3.33336 14.3189C3.33336 14.9912 4.08834 15.3867 4.64103 15.0041L10.8797 10.685ZM11.8283 7.9444C13.2643 8.93853 13.2643 11.0612 11.8283 12.0554L9.16669 13.8981V14.3189C9.16669 14.9912 9.92167 15.3867 10.4744 15.0041L16.713 10.685C17.1917 10.3537 17.1917 9.6461 16.713 9.31472L10.4744 4.99567C9.92167 4.61304 9.16669 5.00861 9.16669 5.68083V6.10172L11.8283 7.9444Z"
        fill={fill || "#030220"}
      />
    </svg>
  );
};

export default ForWard;
