import React from 'react';
import styled from '@emotion/styled';
import useRequest from '@/components/hooks/useRequest';
import { editSubtitle, getAllSubtitles } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { getSubtitles, selectSubtitle } from '@/features/video/store/subtitleSlice';
import { useSearchParams } from 'react-router-dom';
import TextField from '@/components/TextField';
import { useAppSelector } from '@/app/hooks';
import Spinner from '@/components/Spinner';

interface IRenameCaptionFileViewProps {
  onBackToOveral?: () => void;
}

const RenameCaptionFile: React.FC<IRenameCaptionFileViewProps> = ({ onBackToOveral = () => null }) => {
  const dispatch = useDispatch();
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const { subtitleDetail } = useAppSelector(selectSubtitle);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string | any>(subtitleDetail?.name);

  const onSubmit = async () => {
    setIsUploading(true);
    const editSub = await request(editSubtitle(subtitleDetail?.id as string, { name: fileName }));
    if (editSub?.data?.status === 200) {
      handleGetSubtitles();
      message.success('Edit caption file name successfully!');
      setIsUploading(false);
    } else {
      message.error('Edit subtitle name unsuccessfully!');
      setIsUploading(false);
    }
  };

  const handleCancelEdit = () => {
    setFileName(subtitleDetail?.name);
    onBackToOveral();
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  return (
    <Container>
      <Text>File Name</Text>
      <TextField
        containerStyle={{ marginTop: '4px' }}
        // label="Brand Kit Name"
        placeholder="Enter file name"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />
      <WrapperBtn>
        <CancelBtn onClick={() => handleCancelEdit()}>
          <span>Cancel</span>
        </CancelBtn>
        <SaveBtn onClick={() => onSubmit()}>
          {isUploading ? <Spinner width={14} height={14} /> : <span>Save</span>}
        </SaveBtn>
      </WrapperBtn>
    </Container>
  );
};

export default RenameCaptionFile;

const Container = styled.div<{ width?: number }>`
  height: 100%;
  padding: 12px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.altGrey.shade7};
  margin-bottom: 3px;
`;

const WrapperBtn = styled.div<{ width?: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  width: calc(100% - 24px);
  justify-content: space-between;
`;

const CancelBtn = styled.div`
  width: calc(50% - 6px);
  margin-bottom: 12px;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.neutral.white};
  outline: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:hover {
    opacity: 0.7;
  }

  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;

const SaveBtn = styled(CancelBtn)`
  background-color: ${({ theme }) => theme.brand.secondary};
  outline: none;
`;
