import React from 'react';

import { SVGProps } from './SVG.props';

const BackArrow = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-left">
        <path
          id="line"
          d="M6.86193 13.138C7.12228 13.3984 7.54439 13.3984 7.80474 13.138C8.06509 12.8777 8.06509 12.4556 7.80474 12.1952L4.27614 8.66663H13.3333C13.7015 8.66663 14 8.36815 14 7.99996C14 7.63177 13.7015 7.33329 13.3333 7.33329H4.27614L7.80474 3.8047C8.06509 3.54435 8.06509 3.12224 7.80474 2.86189C7.54439 2.60154 7.12228 2.60154 6.86193 2.86189L2.19526 7.52855C1.93491 7.7889 1.93491 8.21101 2.19526 8.47136L6.86193 13.138Z"
          fill={fill || '#030220'}
        />
      </g>
    </svg>
  );
};

export default BackArrow;
