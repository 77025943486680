import React from 'react';
import styled from '@emotion/styled';
import { CloseIcon, AddIcon } from '@/components/SVG';
import Typography from '../Typography';
import Audio from '@/assets/images/audio.png';
interface IProps {
  url?: string;
  videoUrl?: string;
  onAdd?: () => void;
  onRemove?: (id: string) => void;
  width?: string;
  duration?: string;
  id: string;
  name?: string;
  type?: string;
  isEmployer?: boolean;
  thumbnails?: string[] | [];
  isMediaStories?: boolean;
}

const Asset: React.FC<IProps> = ({
  url = '',
  videoUrl = '',
  onAdd,
  onRemove,
  duration,
  id,
  name,
  type,
  isEmployer,
  width,
  isMediaStories
}) => {
  const backgroundUrl = type === 'AUDIO' ? Audio : url;
  return (
    <AssetContainer bg={backgroundUrl} width={width}>
      {videoUrl && <video src={videoUrl} style={{ height: '100%', borderRadius: '12px', width: '100%' }}></video>}
      {name && <TypoCenter variant="label">{name}</TypoCenter>}
      {duration && (
        <DurationContainer>
          <DurationText>{duration}</DurationText>
        </DurationContainer>
      )}
      {!isMediaStories && onRemove && isEmployer && (
        <CloseIconContainer className="btn-remove" onClick={() => onRemove(id)}>
          <CloseIcon />
        </CloseIconContainer>
      )}
      <AddIconContainer onClick={onAdd}>
        <AddIcon fill="#070550" />
      </AddIconContainer>
    </AssetContainer>
  );
};

export default Asset;

const AssetContainer = styled.div<{ bg: string; width?: string }>`
  width: ${({ width }) => width || '100%'};
  height: 102px;
  border-radius: 4px;
  position: relative;
  background: ${({ bg }) => `url('${bg}') no-repeat`};
  background-color: ${({ theme }) => theme.grey.shade6};
  background-size: contain;
  background-position: center;

  &:hover {
    .btn-remove {
      display: flex;
    }
  }
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #ffffffbf;
`;

const CloseIconContainer = styled(IconContainer)`
  position: absolute;
  top: 6px;
  right: 6px;
  display: none;
`;

const AddIconContainer = styled(IconContainer)`
  position: absolute;
  bottom: 6px;
  right: 6px;
`;

const DurationContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
`;

const DurationText = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 3px;
`;

const TypoCenter = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// const Thumbnail = styled.img`
//   width: 100%;
//   height: 102px;
//   border-radius: 4px;
// `;
