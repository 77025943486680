import React from 'react';

import { SVGProps } from './SVG.props';

const Volume = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3535 1.74543C10.6463 1.88258 10.8334 2.17674 10.8334 2.50009V17.5001C10.8334 17.8234 10.6463 18.1176 10.3535 18.2548C10.0607 18.3919 9.71496 18.3473 9.46655 18.1403L4.69833 14.1668H1.66671C1.20647 14.1668 0.833374 13.7937 0.833374 13.3334V6.66676C0.833374 6.20652 1.20647 5.83343 1.66671 5.83343H4.69833L9.46655 1.85991C9.71496 1.6529 10.0607 1.60828 10.3535 1.74543ZM9.16671 4.27929L5.53353 7.30694C5.38376 7.43175 5.19499 7.50009 5.00004 7.50009H2.50004V12.5001H5.00004C5.19499 12.5001 5.38376 12.5684 5.53353 12.6932L9.16671 15.7209V4.27929Z"
        fill={fill || "#030220"}
      />
      <path
        d="M12.9189 5.85576C13.2422 5.52819 13.7698 5.52471 14.0974 5.84799C15.1682 6.90475 15.8334 8.37548 15.8334 10C15.8334 11.6204 15.1716 13.0877 14.1057 14.1438C13.7788 14.4678 13.2512 14.4653 12.9272 14.1384C12.6033 13.8115 12.6057 13.2839 12.9327 12.9599C13.6956 12.2039 14.1667 11.1578 14.1667 10C14.1667 8.83931 13.6932 7.79071 12.9267 7.03425C12.5991 6.71097 12.5956 6.18334 12.9189 5.85576Z"
        fill={fill || "#030220"}
      />
      <path
        d="M16.4167 3.45377C16.0881 3.13158 15.5605 3.13682 15.2383 3.46548C14.9161 3.79413 14.9213 4.32175 15.25 4.64393C16.6395 6.00609 17.5 7.90182 17.5 10C17.5 12.0983 16.6395 13.994 15.25 15.3562C14.9213 15.6783 14.9161 16.206 15.2383 16.5346C15.5605 16.8633 16.0881 16.8685 16.4167 16.5463C18.1128 14.8836 19.1667 12.5641 19.1667 10C19.1667 7.43594 18.1128 5.11648 16.4167 3.45377Z"
        fill={fill || "#030220"}
      />
    </svg>
  );
};

export default Volume;
