import React from 'react';

import { SVGProps } from './SVG.props';

const VerticalThreeDots = (props: SVGProps): JSX.Element => {
    const { width, height, fill } = props;
  return (
    <svg width={width ? width : 12} height={height ? height : 13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="menu-vertical-lite">
        <g id="line">
          <path
            d="M7 2.5C7 3.05228 6.55228 3.5 6 3.5C5.44772 3.5 5 3.05228 5 2.5C5 1.94772 5.44772 1.5 6 1.5C6.55228 1.5 7 1.94772 7 2.5Z"
            fill={fill ? fill : "white"}
          />
          <path
            d="M7 6.5C7 7.05228 6.55228 7.5 6 7.5C5.44772 7.5 5 7.05228 5 6.5C5 5.94772 5.44772 5.5 6 5.5C6.55228 5.5 7 5.94772 7 6.5Z"
            fill={fill ? fill : "white"}
          />
          <path
            d="M6 11.5C6.55228 11.5 7 11.0523 7 10.5C7 9.94772 6.55228 9.5 6 9.5C5.44772 9.5 5 9.94772 5 10.5C5 11.0523 5.44772 11.5 6 11.5Z"
            fill={fill ? fill : "white"}
          />
        </g>
      </g>
    </svg>
  );
};

export default VerticalThreeDots;
