import React, { MouseEvent, CSSProperties, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { TEXT_STYLES, TextPosition } from '@/features/video/constant/textTab';
import {
  addAddingTexts,
  getFontList,
  selectVideo,
  selectedAddingObjDetails,
  update,
  updateSelectedText
} from '@/features/video/store/videoSlice';
import { calculateUIValue } from '@/components/hooks/useDraft';
import styled from '@emotion/styled';
import Typography from '@/components/Typography';
import { BackArrow, Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight } from '@/components/SVG';
import { AddingText, FontConfig, ObjectType } from '@/features/video/models/FrameItem';
import { selectBrandKit } from '@/features/video/store/brandKitSlice';
import GroupDropdown from '@/components/DropDown/GroupDropdown.view';
import DropDown from '@/components/DropDown';
import Fonts from '@/utils/fonts';
import ColorPicker from '@/components/ColorPicker';
import { replaceFontLink } from '@/utils/replaceFontLink';

interface ITextsTab {}

const TextsTab: React.FC<ITextsTab> = () => {
  const dispatch = useAppDispatch();
  const { playerSize } = useAppSelector(selectVideo);
  const { colors, fonts } = useAppSelector(selectBrandKit);
  const fontList = useAppSelector(getFontList);
  const cardNameDetails = useAppSelector(selectedAddingObjDetails) as any;
  const textDetails = useAppSelector(selectedAddingObjDetails);
  const text = textDetails?.type === ObjectType.text ? (textDetails as AddingText) : undefined;
  const [backgroundColor, setBgColor] = useState(text?.styles.backgroundColor);
  const [color, setColor] = useState(text?.styles.color);
  const [selectedColor, setSelectedColor] = useState<number | null>();
  const [selectedColorBg, setSelectedColorBg] = useState<number | null>();
  const [fontOptions, setFontOptions] = useState<any[]>();
  const [brandKitFonts, setBrandKitFonts] = useState<any[]>();

  const handleAddText = (event: MouseEvent<HTMLElement>, styles: CSSProperties, idx?: number): void => {
    const { currentTarget } = event;
    const getPosition = TextPosition.find((x) => x.id === idx) as any;
    const position = calculateUIValue({ width: 1920, height: 1080 }, playerSize, getPosition?.position);
    dispatch(
      addAddingTexts({
        value: currentTarget.innerText,
        styles,
        fontFile: fontList[0],
        name: currentTarget.innerText,
        position: position
      })
    );
  };

  const onSelectObj = (uid: string, type: ObjectType, tab?: string, tabInside?: string) => {
    dispatch(update({ selectedObj: { uid, type } }));
    dispatch(update({ selectedTab: 'Text Effects & Cards' }));
    dispatch(update({ selectedTabInside: 'Text Effects' }));
  };

  const handleStyleChange = (styles: any) => {
    dispatch(updateSelectedText({ uid: text?.uid, styles: { ...styles } }));
  };

  useEffect(() => {
    const listBrandKitFonts =
      fonts?.map((item: any) => ({
        ...item,
        label: item.fontName,
        value: item.fontName,
        fontName: item.fontName,
        family: item.fontName,
        fontFile: item.Link,
        isBrandKit: true
      })) || [];
    const groupDropdownOptions = [
      {
        label: 'BRAND KIT',
        options: listBrandKitFonts
      },
      {
        label: 'GENERAL',
        options: fontList
      }
    ];
    setBrandKitFonts(listBrandKitFonts);
    if (fonts?.length > 0) {
      setFontOptions(groupDropdownOptions);
    } else {
      setFontOptions([
        {
          label: 'GENERAL',
          options: fontList
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);

  useEffect(() => {
    setBgColor(text?.styles.backgroundColor);
    setColor(text?.styles.color);
    setSelectedColorBg(null);
    setSelectedColorBg(null);
  }, [text]);

  const handleGetFontStyles = (fontWeight: string, fontStyle: string) => {
    if (fontWeight === 'bold' && fontStyle === 'italic') {
      return text?.styles?.listFontFiles?.['700italic'];
    }
    if (fontWeight === 'bold') {
      return text?.styles?.listFontFiles?.[700];
    }
    if (fontStyle === 'italic') {
      return text?.styles?.listFontFiles?.italic;
    }
    return text?.styles?.listFontFiles?.regular;
  };

  return (
    <div>
      {cardNameDetails?.type === ObjectType.text
        ? text && (
            <>
              <BackSubContainer
                onClick={() => {
                  // setView(Views.Subtitle);
                  onSelectObj('', ObjectType.image);
                }}
              >
                <IconBack>
                  <BackArrow />
                </IconBack>
                <TextBackToCaptions>Back to Text Effects & Cards</TextBackToCaptions>
              </BackSubContainer>
              <ContentContainer>
                <FontStyleContainer>
                  <FontStyle>
                    <TextLabel>Font Style</TextLabel>
                    <GroupDropdown
                      options={fontOptions}
                      width={'250px'}
                      onChange={(value: any) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        !value?.isBrandKit
                          ? (Fonts(value.family),
                            handleStyleChange({
                              // ...text.styles,
                              fontSize: text.styles.fontSize,
                              lineHeight: text.styles.lineHeight,
                              color: text.styles.color,
                              fontName: value.family,
                              fontFile: value.files
                                ? replaceFontLink(value.files?.regular)
                                : replaceFontLink(value.fontFile),
                              listFontFiles: value?.files,
                              textAlign: text.styles?.textAlign
                            }))
                          : handleStyleChange({
                              // ...text.styles,
                              fontSize: text.styles.fontSize,
                              lineHeight: text.styles.lineHeight,
                              color: text.styles.color,
                              fontName: value.family,
                              fontFile: value.files
                                ? replaceFontLink(value.files?.regular)
                                : replaceFontLink(value.fontFile),
                              listFontFiles: value?.files,
                              textAlign: text.styles?.textAlign
                            });
                      }}
                      value={fontList?.find((font: FontConfig) => font.family === text.styles?.fontName)}
                      valueBrandKit={brandKitFonts?.find((font: any) => font?.fontName === text.styles?.fontName)}
                    />
                  </FontStyle>
                  <FontSize>
                    <TextLabel>Font Size</TextLabel>
                    <DropDown
                      placeholder="Size"
                      options={new Array(100)
                        .fill(undefined)
                        .map((_, i) => ({ label: `${i + 1}px`, value: `${i + 1}px` }))}
                      customMargin="0px"
                      containerStyles={{ maxWidth: '120px' }}
                      onChange={(value) => handleStyleChange({ ...text.styles, fontSize: value.value })}
                      value={{ label: text.styles?.fontSize, value: text.styles?.fontSize }}
                    ></DropDown>
                  </FontSize>
                  {/* <FontSize style={{ marginLeft: '10px', width: '90px' }}>
                    <TextLabel style={{ marginTop: '3px' }}>Text Style</TextLabel>
                    <TextStyleContainer>
                      <IconContainer
                        isEnabled={text?.styles?.listFontFiles?.[700]}
                        isSelected={text.styles.fontWeight === 'bold'}
                      >
                        <Bold
                          onClick={() =>
                            text?.styles?.listFontFiles?.[700] &&
                            handleStyleChange({
                              ...text.styles,
                              fontWeight: text.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                              fontFile: handleGetFontStyles(
                                text.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                                text.styles.fontStyle as string
                              )
                            })
                          }
                        />
                      </IconContainer>
                      <IconContainer
                        isEnabled={text?.styles?.listFontFiles?.italic}
                        isSelected={text.styles.fontStyle === 'italic'}
                      >
                        <Italic
                          onClick={() =>
                            text?.styles?.listFontFiles?.italic &&
                            handleStyleChange({
                              ...text.styles,
                              fontStyle: text.styles.fontStyle === 'italic' ? 'normal' : 'italic',
                              fontFile: handleGetFontStyles(
                                text.styles.fontWeight as string,
                                text.styles.fontStyle === 'italic' ? 'normal' : 'italic'
                              )
                            })
                          }
                        />
                      </IconContainer>
                      {false && (
                        <IconContainer isSelected={text?.styles.textDecoration === 'underline'}>
                          <Underline
                            onClick={() =>
                              handleStyleChange({
                                ...text?.styles,
                                textDecoration: text?.styles.textDecoration === 'underline' ? 'unset' : 'underline'
                              })
                            }
                          />
                        </IconContainer>
                      )}
                    </TextStyleContainer>
                  </FontSize> */}
                </FontStyleContainer>
                <FontStyleContainer style={{ marginTop: '6px' }}>
                  <FontSize style={{ width: '120px' }}>
                    <TextLabel style={{ marginTop: '3px' }}>Text Align</TextLabel>
                    <TextStyleContainer>
                      <IconContainer
                        isEnabled={true}
                        isSelected={text.styles.textAlign === 'left'}
                        onClick={() => {
                          handleStyleChange({
                            ...text.styles,
                            textAlign: text.styles.textAlign === 'left' ? 'left' : 'left'
                          });
                        }}
                      >
                        <AlignLeft />
                      </IconContainer>
                      <IconContainer
                        isEnabled={true}
                        isSelected={text.styles.textAlign === 'center'}
                        onClick={() => {
                          handleStyleChange({
                            ...text.styles,
                            textAlign: text.styles.textAlign !== 'center' ? 'center' : 'left'
                          });
                        }}
                      >
                        <AlignCenter />
                      </IconContainer>
                      <IconContainer
                        isEnabled={true}
                        isSelected={text?.styles.textAlign === 'right'}
                        onClick={() => {
                          handleStyleChange({
                            ...text?.styles,
                            textAlign: text?.styles.textAlign !== 'right' ? 'right' : 'left'
                          });
                        }}
                      >
                        <AlignRight />
                      </IconContainer>
                    </TextStyleContainer>
                  </FontSize>
                  <FontSize style={{ width: '120px' }}>
                    <TextLabel style={{ marginTop: '3px' }}>Text Style</TextLabel>
                    <TextStyleContainer>
                      <IconContainer
                        isEnabled={text?.styles?.listFontFiles?.[700]}
                        isSelected={text.styles.fontWeight === 'bold'}
                      >
                        <Bold
                          onClick={() =>
                            text?.styles?.listFontFiles?.[700] &&
                            handleStyleChange({
                              ...text.styles,
                              fontWeight: text.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                              fontFile: handleGetFontStyles(
                                text.styles.fontWeight === 'bold' ? 'normal' : 'bold',
                                text.styles.fontStyle as string
                              )
                            })
                          }
                        />
                      </IconContainer>
                      <IconContainer
                        isEnabled={text?.styles?.listFontFiles?.italic}
                        isSelected={text.styles.fontStyle === 'italic'}
                      >
                        <Italic
                          onClick={() =>
                            text?.styles?.listFontFiles?.italic &&
                            handleStyleChange({
                              ...text.styles,
                              fontStyle: text.styles.fontStyle === 'italic' ? 'normal' : 'italic',
                              fontFile: handleGetFontStyles(
                                text.styles.fontWeight as string,
                                text.styles.fontStyle === 'italic' ? 'normal' : 'italic'
                              )
                            })
                          }
                        />
                      </IconContainer>
                      {false && (
                        <IconContainer isSelected={text?.styles.textDecoration === 'underline'}>
                          <Underline
                            onClick={() =>
                              handleStyleChange({
                                ...text?.styles,
                                textDecoration: text?.styles.textDecoration === 'underline' ? 'unset' : 'underline'
                              })
                            }
                          />
                        </IconContainer>
                      )}
                    </TextStyleContainer>
                  </FontSize>
                </FontStyleContainer>
                <TextLabel>Colour</TextLabel>
                <ColorPicker
                  containerStyle={{
                    width: '100%',
                    border: `${selectedColor === -1 ? '2px solid #7ED0A7' : ''}`,
                    borderRadius: `${selectedColor === -1 ? '14px' : ''}`
                  }}
                  color={color}
                  onColorChange={(color) => {
                    handleStyleChange({ ...text.styles, color: color });
                    setColor(color);
                  }}
                  onSelectColor={setSelectedColor}
                  selected={selectedColor === -1 ? true : false}
                  bgColor="#fff"
                  topPosition={-43}
                />
                <ListColourContainer>
                  {colors?.map((item, idx) => (
                    <ColourBrandKit
                      key={idx}
                      bgColor={item}
                      selected={idx === selectedColor ? selectedColor : -2}
                      onClick={() => {
                        setSelectedColor(idx);
                        setColor(item);
                        handleStyleChange({ ...text.styles, color: item });
                      }}
                    />
                  ))}
                </ListColourContainer>
                <TextLabel style={{ marginTop: '12px' }}>Background Colour</TextLabel>
                <ColorPicker
                  containerStyle={{
                    width: '100%',
                    border: `${selectedColorBg === -1 ? '2px solid #7ED0A7' : ''}`,
                    borderRadius: `${selectedColorBg === -1 ? '14px' : ''}`
                  }}
                  color={backgroundColor}
                  onColorChange={(color) => {
                    handleStyleChange({ ...text.styles, backgroundColor: color });
                    setBgColor(color);
                  }}
                  onSelectColor={setSelectedColorBg}
                  selected={selectedColorBg === -1 ? true : false}
                  bgColor="#fff"
                  topPosition={-100}
                />
                <ListColourContainer>
                  {colors?.map((item, idx) => (
                    <ColourBrandKit
                      key={idx}
                      bgColor={item}
                      selected={idx === selectedColorBg ? selectedColorBg : -2}
                      onClick={() => {
                        setSelectedColorBg(idx);
                        setBgColor(item);
                        handleStyleChange({ ...text.styles, backgroundColor: item });
                      }}
                    />
                  ))}
                </ListColourContainer>
              </ContentContainer>
            </>
          )
        : Object.keys(TEXT_STYLES).map((key, i) => {
            const { text, ...styles } = TEXT_STYLES[key];
            return (
              <TextContainer key={key} onClick={(e) => handleAddText(e, styles, i)}>
                <Typography style={{ ...styles, fontSize: i === 0 ? '20px' : i === 1 ? '16px' : '12px' }}>
                  {text}
                </Typography>
              </TextContainer>
            );
          })}
    </div>
  );
};

export default TextsTab;

const TextContainer = styled.div`
  height: 64px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #f6f3f4;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.brand.success}`};
  }
`;

const BackSubContainer = styled.div`
  cursor: pointer;
  height: 45px;
  // background: ${({ theme }) => theme.altGrey.shade4};
  background: ${({ theme }) => theme.grey.shade3};
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: 0;
  border-radius: 4px 4px 0;
`;

const TextBackToCaptions = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 14px;
  margin-left: 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const IconBack = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px 6px;
  cursor: pointer;
  margin-top: 8px;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  height: 368px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 290px;
  }
  overflow: auto;
  padding: 0 8px;
`;

const FontStyleContainer = styled.div`
  // height: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FontStyle = styled.div`
  height: 70px;
  width: 259px;
`;

const FontSize = styled.div`
  height: 70px;
  width: 122px;
`;

const TextLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey.shade7};
`;

const ListColourContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const ColourBrandKit = styled.div<{ bgColor?: string; selected?: any }>`
  width: 72px;
  height: 24px;
  max-width: 72px;
  background-color: ${({ bgColor }) => bgColor};
  outline: ${({ selected }) => (selected >= 0 ? '2px solid #7ED0A7' : 'none')};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  margin-right: 4px;
`;

const IconContainer = styled.div<{ isSelected?: boolean; isEnabled?: boolean }>`
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};
  display: flex;
  align-items: center;
  background: ${({ isSelected, theme }) => (isSelected ? theme.altGrey.shade5 : theme.altGrey.shade3)};
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 12px;
  margin-right: 5px;
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
`;

const TextStyleContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: start;
  margin-top: 10px;
`;
