import React from 'react';
import FeaturesTab from './FeaturesTab';
import styled from '@emotion/styled';
import { BackArrow } from '@/components/SVG';

interface IEditCaptionViewProps {
  onBackToOveral: () => void;
}

const EditCaption: React.FC<IEditCaptionViewProps> = ({ onBackToOveral }) => {
  return (
    <Container className="media-container">
      <BackSubContainer
        onClick={() => {
          onBackToOveral();
        }}
      >
        <IconBack>
          <BackArrow />
        </IconBack>
        <TextBackToCaptions>Back to Captions</TextBackToCaptions>
      </BackSubContainer>
      <FeaturesTab onBackToOveral={onBackToOveral} />
    </Container>
  );
};

export default EditCaption;

const Container = styled.div<{ width?: number }>`
  /* padding-top: 5px; */
  height: 100%;
`;

const BackSubContainer = styled.div`
  cursor: pointer;
  height: 42px;
  // background: ${({ theme }) => theme.altGrey.shade4};
  background: ${({ theme }) => theme.grey.shade3};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextBackToCaptions = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 12px;
  margin-left: 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const IconBack = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px 6px;
  cursor: pointer;
  margin-top: 6px;
`;

