import * as React from 'react';
import Draggable from '../Draggable';
import { DraggableData, DraggableEvent } from 'react-draggable';
import ContentEditable from 'react-contenteditable';
import { FontConfig } from '@/features/video/models/FrameItem';
import Fonts from '@/utils/fonts';
import { Global } from '@emotion/core';
import { getFormatFile } from '@/utils/getFormatFont';
import '../../constants/animations/styles.scss';
import { Popover } from 'antd';
import Typography from '../Typography';
import styled from '@emotion/styled';
import { Animation } from '../SVG';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  openDrawerAnimationBar,
  selectElement,
  selectTextOrImage,
  selectVideo,
  selectedAddingObjDetails,
  setCaptionSettings
} from '@/features/video/store/videoSlice';
import { FHDPixel } from '@/constants/screens';
import { replaceSpecialCharacter } from '@/utils/htmlHelper';
import { useMemo } from 'react';
import { httpToHttps } from '@/utils/string/httpConverter';

interface IMovableTextProps {
  value: string;
  styles: React.CSSProperties & { fontName?: string; fontFile?: string };
  onChange: (value: string, uid: string) => void;
  id: string;
  onDragStop?: (e: DraggableEvent, data: DraggableData) => void;
  onFocus?: (id: string) => void;
  onClickRemove?: (id: string) => void;
  isSelected?: boolean;
  x: number;
  y: number;
  position: { x: number; y: number };
  fontFile?: FontConfig;
  show?: boolean;
  item?: any;
  zIndex?: number;
}

const MovableText: React.FunctionComponent<IMovableTextProps> = ({
  value,
  styles,
  onChange,
  id,
  onDragStop,
  onFocus,
  onClickRemove,
  position,
  x,
  y,
  show,
  item,
  zIndex
}) => {
  const dispatch = useAppDispatch();
  const { totalPlayed, playerSizeHD, subtitleStyle } = useAppSelector(selectVideo);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const objectSelected = useAppSelector(selectedAddingObjDetails) as any;

  React.useEffect(() => {
    // load font
    Fonts(styles?.fontName!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // resize font size of text that match with UI and export
  const convertFontSize = (size?: string) => {
    const fontSize = parseInt(size?.replace('px', '') as string);
    const currentPlayerSizeHd = playerSizeHD as { width: number; height: number };
    if (fontSize) {
      const newSize = (currentPlayerSizeHd?.width / FHDPixel.width) * fontSize;
      return `${newSize}px`;
    }
    return null;
  };

  const handleSelectText = () => {
    dispatch(openDrawerAnimationBar());
    dispatch(selectElement(item));
    dispatch(selectTextOrImage(true));
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window?.Clipboard;
    let plainText = clipboardData.getData('text/plain');
    plainText = replaceSpecialCharacter(plainText);
    document.execCommand('insertText', false, plainText);
  };

  const handleDoubleClick = () => {
    if (contentRef.current) {
      const range = document.createRange();
      range.selectNodeContents(contentRef.current);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (contentRef.current && !contentRef.current.contains(event.target as any)) {
  //     const selection = window.getSelection();
  //     if (selection) {
  //       selection.removeAllRanges();
  //     }
  //   }
  // };

  React.useEffect(() => {
    if (!objectSelected?.uid) {
      // document.addEventListener('click', handleClickOutside);
      // return () => {
      //   document.removeEventListener('click', handleClickOutside);
      // };
      if (contentRef.current) {
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
        }
      }
    }
  }, [objectSelected]);

  const getFontWeight = (fontWeight: string | number) => {
    if (['bold', 'normal'].includes(fontWeight as string)) {
      return fontWeight;
    }
    const fontWeightNumber = parseInt(fontWeight as string);
    if (isNaN(fontWeightNumber) || fontWeightNumber < 700) {
      return 'normal';
    }

    return 'bold';
  };

  const fontFilePath = useMemo(() => {
    return httpToHttps(styles.fontFile as string);
  }, [styles.fontFile]);

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  return (
    <>
      {totalPlayed !== 0 && show && (
        <Draggable
          onDragStop={onDragStop}
          onClickRemove={() => onClickRemove?.(id)}
          position={{ x, y }}
          zIndex={zIndex || 10}
        >
          <Popover
            open={false}
            showArrow={false}
            trigger={'none'} // update click when open this one
            overlayInnerStyle={{ borderRadius: '12px', marginBottom: '-24px', height: '45px' }}
            content={
              <OptionContainer onClick={handleSelectText}>
                <Animation width={21} height={20} />
                <Typography style={{ marginLeft: '8px', fontSize: '14px' }}>Animation</Typography>
              </OptionContainer>
            }
          >
            <ContentEditable
              innerRef={contentRef}
              html={value}
              onFocus={() => {
                onFocus?.(id);
                handleClearCaptionSetting();
              }}
              onPaste={handlePaste}
              onDoubleClick={handleDoubleClick}
              style={{
                margin: '0px',
                padding: '0px',
                marginTop: 0,
                // background: 'transparent',
                border: 'none',
                cursor: 'text',
                background: styles.backgroundColor,
                fontFamily: styles.fontName,
                fontSize: convertFontSize(styles.fontSize as string),
                color: styles.color,
                lineHeight: convertFontSize(styles.fontSize as string),
                fontStyle: styles.fontStyle,
                fontWeight: getFontWeight(styles.fontWeight || 'normal'),
                textAlign: styles?.textAlign,
                fontSmoothing: 'antialiased',
                '-webkit-font-smoothing': 'antialiased'
              }}
              className={`target ${show ? `${item?.animation?.type}` : 'hidden'}`}
              onChange={(e) => onChange(e.currentTarget.innerHTML || '', id)}
            />
          </Popover>

          <Global
            key={fontFilePath}
            styles={[
              {
                '@font-face': {
                  fontFamily: styles.fontName,
                  src: `url(${fontFilePath}) ${getFormatFile(fontFilePath as string)}`
                }
              }
            ]}
          />
        </Draggable>
      )}
    </>
  );
};

export default MovableText;

const OptionContainer = styled.div`
  cursor: pointer;
  display: flex;
`;
