import { CSSProperties } from 'react';

import styled from '@emotion/styled';

interface TouchableBaseProps {
  circle?: boolean;
  width?: number;
  height?: number;
  dark?: boolean;
  style?: CSSProperties; // Added by Jr
}

export const TouchableBase = styled.button<TouchableBaseProps>`
  display: flex;
  border: none;
  color: white;
  padding: 8px;
  background-color: transparent;
  width: ${({ width }) => width};
  height: ${({ width, height, circle }) => (circle ? width : height)};
  border-radius: ${({ circle }) => (circle ? '50%' : '4px')};
  cursor: pointer;
  transition: background-color 0.25s ease-out;

  :hover {
    background-color: ${({ dark }) => (dark ? '#ffffff25' : '#00000010')};
  }
  :active {
    background-color: ${({ dark }) => (dark ? '#ffffff35' : '#00000018')};
  }
  :focus {
    outline: none;
  }
`;
