import React from 'react';

import { SVGProps } from './SVG.props';

const Add = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M8.66683 13.3955L8.66683 9.39551L12.6668 9.39551C12.8436 9.39551 13.0132 9.32527 13.1382 9.20025C13.2633 9.07522 13.3335 8.90565 13.3335 8.72884C13.3335 8.55203 13.2633 8.38246 13.1382 8.25744C13.0132 8.13241 12.8436 8.06217 12.6668 8.06217L8.66683 8.06217L8.66683 4.06217C8.66683 3.88536 8.59659 3.71579 8.47157 3.59077C8.34654 3.46575 8.17697 3.39551 8.00016 3.39551C7.82335 3.39551 7.65378 3.46575 7.52876 3.59077C7.40373 3.71579 7.3335 3.88536 7.3335 4.06217L7.3335 8.06217L3.3335 8.06217C3.15669 8.06217 2.98712 8.13241 2.86209 8.25744C2.73707 8.38246 2.66683 8.55203 2.66683 8.72884C2.66683 8.90565 2.73707 9.07522 2.86209 9.20024C2.98712 9.32527 3.15669 9.39551 3.3335 9.39551L7.3335 9.39551L7.3335 13.3955C7.3335 13.5723 7.40373 13.7419 7.52876 13.8669C7.65378 13.9919 7.82335 14.0622 8.00016 14.0622C8.17697 14.0622 8.34654 13.9919 8.47157 13.8669C8.59659 13.7419 8.66683 13.5723 8.66683 13.3955Z"
        fill={fill || '#B5ADB0'}
      />
    </svg>
  );
};

export default Add;
