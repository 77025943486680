import { useAppDispatch, useAppSelector } from '@/app/hooks';
import ColorStyles from '../commons/ColorStyles';
import { selectBrandKit } from '@/features/video/store/brandKitSlice';
import { useCallback, useMemo } from 'react';
import { selectVideo, setBackgroundColor, updatePlayableItem } from '@/features/video/store/videoSlice';
import styled from '@emotion/styled';
import CheckboxList from '@/components/CheckboxList';
import { AddingPlayableItem, PlayableType } from '@/features/video/models/FrameItem';
import { isInRange } from '@/features/video/services/videoService';

export interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}

const enum BackgroundType {
  videoBlur = 'videoBlur',
  backgroundColor = 'backgroundColor'
}

const bgTypeList = [
  {
    label: 'Blur Background',
    value: BackgroundType.videoBlur,
    isDisabled: false
  },
  {
    label: 'Background Color',
    value: BackgroundType.backgroundColor,
    isDisabled: false
  }
];

const defaultColor = 'rgba(0, 0, 0, 1)';

const FillerBackground = () => {
  const dispatch = useAppDispatch();
  const { backgroundColor, addingPlayableItems, totalPlayed } = useAppSelector(selectVideo);

  let { colors } = useAppSelector(selectBrandKit);
  colors = [defaultColor, ...colors];

  const videoItems = useMemo(
    () =>
      addingPlayableItems.filter(
        (item: AddingPlayableItem) =>
          item.type === PlayableType.video && isInRange(item.start || 0, item.end || 0, totalPlayed)
      ),
    [addingPlayableItems, totalPlayed]
  );

  const _bgTypeList = useMemo(() => {
    const backgroundOption = bgTypeList.find((item) => item.value === BackgroundType.videoBlur) || ({} as any);
    if (videoItems.length > 0) {
      const videoItem = videoItems[0];
      if (!videoItem.blurBackground) {
        backgroundOption.isDisabled = true;
      } else {
        backgroundOption.isDisabled = false;
      }
    } else {
      backgroundOption.isDisabled = true;
    }
    return bgTypeList;
  }, [videoItems]);

  const updateVideoBlurBackgroundMode = useCallback(
    (isBlur: boolean) => {
      videoItems.forEach((item) => {
        dispatch(updatePlayableItem({ uid: item.uid, isBlurBackground: isBlur }));
      });
    },
    [dispatch, videoItems]
  );

  const selectedColor = useMemo(() => {
    const index = colors.findIndex((color) => color === backgroundColor);
    return index;
  }, [backgroundColor, colors]);

  // behavior: pick color from color plate
  const onSetPickedColor = (val: string) => {
    dispatch(setBackgroundColor(val));
  };

  // behavior: pick color from brandkit
  const onSelectedColor = (val: number) => {
    dispatch(setBackgroundColor(colors[val]));
  };

  const optionsCheckHandler = (options: Option[]) => {
    const isCheckBackground = options.some((option) => option.value === BackgroundType.backgroundColor);
    const isCheckBlur = options.some((option) => option.value === BackgroundType.videoBlur);
    if (isCheckBackground) {
      dispatch(setBackgroundColor(colors[selectedColor && selectedColor >= 0 ? selectedColor : 0]));
    } else {
      dispatch(setBackgroundColor(''));
    }

    updateVideoBlurBackgroundMode(isCheckBlur);
  };

  const selectedBgType = useMemo(() => {
    const result = [];
    if (backgroundColor) {
      result.push(bgTypeList[1]);
    }
    if (videoItems.length > 0 && videoItems[0].isBlurBackground) {
      result.push(bgTypeList[0]);
    }

    return result;
  }, [backgroundColor, videoItems]);

  return (
    <div>
      <CheckboxList list={_bgTypeList} selected={selectedBgType} onCheckOption={optionsCheckHandler} />
      <ColorStylesWrapper>
        <ColorStyles
          listColorsBrandKit={colors}
          selectedColor={selectedColor}
          onSelectColor={onSelectedColor}
          color={backgroundColor}
          setColorPicked={onSetPickedColor}
          isDisableAlpha={true}
        />
      </ColorStylesWrapper>
    </div>
  );
};
export default FillerBackground;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const ColorStylesWrapper = styled.div`
  margin-top: 6px;
  margin-left: 30px;
`;
