import React from 'react';

import { SVGProps } from './SVG.props';

const Bold = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 18} height={height || 19} viewBox="0 0 18 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86464 4.65234C10.6123 4.65234 11.3293 4.9645 11.8579 5.52014C12.3866 6.07578 12.6836 6.82939 12.6836 7.61518C12.6836 8.40097 12.3866 9.15458 11.8579 9.71022C11.3293 10.2659 10.6123 10.578 9.86464 10.578H7.245C6.88996 10.578 6.60214 10.2755 6.60214 9.90234C6.60214 9.52918 6.88996 9.22667 7.245 9.22667H9.86464C10.2713 9.22667 10.6613 9.05689 10.9488 8.75467C11.2363 8.45246 11.3979 8.04257 11.3979 7.61518C11.3979 7.18779 11.2363 6.7779 10.9488 6.47569C10.6617 6.1739 10.2724 6.00417 9.86636 6.0037L5.46804 6.03071C5.11301 6.03289 4.82351 5.73215 4.82144 5.35899C4.81937 4.98584 5.1055 4.68156 5.46053 4.67938L9.86464 4.65234Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70429 9.90234C8.70429 9.52918 8.9921 9.22667 9.34714 9.22667H10.6811C11.4287 9.22667 12.1457 9.53882 12.6744 10.0945C13.203 10.6501 13.5 11.4037 13.5 12.1895C13.5 12.9753 13.203 13.7289 12.6744 14.2845C12.1457 14.8402 11.4287 15.1523 10.6811 15.1523H5.14286C4.78782 15.1523 4.5 14.8498 4.5 14.4767C4.5 14.1035 4.78782 13.801 5.14286 13.801H10.6811C11.0877 13.801 11.4777 13.6312 11.7652 13.329C12.0528 13.0268 12.2143 12.6169 12.2143 12.1895C12.2143 11.7621 12.0528 11.3522 11.7652 11.05C11.4777 10.7478 11.0877 10.578 10.6811 10.578H9.34714C8.9921 10.578 8.70429 10.2755 8.70429 9.90234Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42857 6.6591C6.78361 6.6591 7.07143 6.96161 7.07143 7.33478V12.453C7.07143 12.8262 6.78361 13.1287 6.42857 13.1287C6.07353 13.1287 5.78571 12.8262 5.78571 12.453V7.33478C5.78571 6.96161 6.07353 6.6591 6.42857 6.6591Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Bold;
