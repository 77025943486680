import React, { useState } from 'react';
import styled from '@emotion/styled';

interface IFeaturesTab {
  tabs: { key: string; name: string }[];
  active: string;
  onChange: (val: string) => void;
  style?: {
    tabItemStyle?: any;
    containerStyle?: any;
  };
}

const Tabs: React.FC<IFeaturesTab> = (props) => {
  const { active, tabs, onChange, style } = props;
  const [activeKey, setActiveKey] = useState<string>(active);

  return (
    <>
      <TabsContainer style={style?.containerStyle}>
        {tabs.map((item) => (
          <TabItem
            key={item.key}
            isSelected={activeKey === item.key}
            onClick={() => {
              setActiveKey(item.key);
              onChange(item.key);
            }}
            style={style?.tabItemStyle}
          >
            {item.name}
          </TabItem>
        ))}
      </TabsContainer>
    </>
  );
};

export default Tabs;

const TabsContainer = styled.div<{ width?: number }>`
  display: flex;
  height: 30px;
`;

const TabItem = styled.div<{ isSelected?: boolean }>`
  border-bottom: ${({ isSelected, theme }) =>
    isSelected ? `${theme.brand.secondary} solid 2px` : `${theme.grey.shade3} solid 2px`};
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  padding: 8px;
  &:hover {
    color: ${({ theme }) => theme.altGrey.shade8};
  }
`;
