import React from 'react';
import styled from '@emotion/styled';
import { AddIcon } from '@/components/SVG';
import Audio from '@/assets/images/audio.png';

interface IProps {
  onAdd?: () => void;
  width?: string;
  id: string;
}

const GraphicItem: React.FC<IProps> = ({ onAdd, width }) => {
  return (
    <GraphicItemContainer bg={Audio} width={width}>
      <GraphicIconContainer>
        <ContentContainer width={width}></ContentContainer>
      </GraphicIconContainer>
      <AddIconContainer onClick={onAdd}>
        <AddIcon />
      </AddIconContainer>
    </GraphicItemContainer>
  );
};

export default GraphicItem;

const GraphicItemContainer = styled.div<{ bg: string; width?: string }>`
  width: ${({ width }) => width};
  height: 120px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 120px;
  }

  position: relative;
  border-radius: 8px;
  background: ${({ theme }) => theme.grey.shade4};
  background-color: ${({ theme }) => theme.grey.shade4};
  background-size: contain;
  background-position: center;

  &:hover {
    .btn-remove {
      display: flex;
    }
  }
`;

const AddIconContainer = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 6px;
  padding-left: 4px;
  padding-top: 3px;
  cursor: pointer;
`;

const GraphicIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div<{ width?: string }>`
  width: 90px;
  height: 90px;
  background: #fff;
  padding: 20px;
  margin-top: 15px;
  margin-left: 1%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 95px;
    width: 95px;
  }
`;
