import { Resolution } from '../models/VideoFrame';

export const RESOLUTION: Resolution[] = [
  {
    description: 'SD (standard definition)',
    width: 640,
    height: 480,
    aspectRatio: '4:3'
  },
  {
    description: 'HD (hight definition)',
    width: 1280,
    height: 720,
    aspectRatio: '16:9'
  }
  // {
  //   description: 'Full HD',
  //   width: 1920,
  //   height: 1080,
  //   aspectRatio: '16:9'
  // },
  // {
  //   description: 'UHD',
  //   width: 3840,
  //   height: 2160,
  //   aspectRatio: '16:9'
  // }
];

export const RatioResolution: Record<string, string> = {
  '16:9': '1920x1080',
  '9:16': '1080x1920',
  // '4:5': '640x640'
  '4:5': '1080x1080'
};
