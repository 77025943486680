import React from 'react';

import { SVGProps } from './SVG.props';

const Link = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M10.8619 9.63821C11.1223 9.89856 11.5444 9.89856 11.8048 9.63821L13.1381 8.30487C14.503 6.93996 14.503 4.72699 13.1381 3.36207C11.7732 1.99715 9.5602 1.99715 8.19528 3.36207L6.86195 4.6954C6.6016 4.95575 6.6016 5.37786 6.86195 5.63821C7.1223 5.89856 7.54441 5.89856 7.80476 5.63821L9.13809 4.30488C9.98231 3.46066 11.3511 3.46066 12.1953 4.30488C13.0395 5.1491 13.0395 6.51785 12.1953 7.36207L10.8619 8.6954C10.6016 8.95575 10.6016 9.37786 10.8619 9.63821Z"
        fill={fill || "#070550"}
      />
      <path
        d="M2.86195 13.6382C4.22686 15.0032 6.43983 15.0032 7.80475 13.6382L9.13809 12.3049C9.39844 12.0445 9.39844 11.6224 9.13809 11.3621C8.87774 11.1017 8.45563 11.1017 8.19528 11.3621L6.86194 12.6954C6.01773 13.5396 4.64898 13.5396 3.80476 12.6954C2.96054 11.8512 2.96054 10.4825 3.80475 9.63823L5.13809 8.3049C5.39844 8.04455 5.39844 7.62244 5.13809 7.36209C4.87774 7.10174 4.45563 7.10174 4.19528 7.36209L2.86195 8.69542C1.49703 10.0603 1.49703 12.2733 2.86195 13.6382Z"
        fill={fill || "#070550"}
      />
      <path
        d="M6.47143 10.9715C6.21108 11.2319 5.78897 11.2319 5.52862 10.9715C5.26827 10.7112 5.26827 10.2891 5.52862 10.0287L9.52862 6.02873C9.78897 5.76838 10.2111 5.76838 10.4714 6.02873C10.7318 6.28908 10.7318 6.71119 10.4714 6.97154L6.47143 10.9715Z"
        fill={fill || "#070550"}
      />
    </svg>
  );
};

export default Link;
