import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import FileUpload from '@/components/FileUpload';
import Button from '@/components/Button';
import Spinner from '../Spinner';
import useRequest from '../hooks/useRequest';
import { getAllMedia, uploadFiles } from '@/features/video/store/videoAPI';
import message from 'antd/lib/message';
import { useDispatch } from 'react-redux';
import { update } from '@/features/video/store/videoSlice';

interface IModalUploadVideoProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const ModalUploadVideoCaption: React.FunctionComponent<IModalUploadVideoProps> = ({ isOpen, onClickClose }) => {
  const { request } = useRequest();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isUpload, setIsUpload] = React.useState(false);
  const [fileSelected, setFileSelected] = React.useState<File[] | null>([]);

  const onClose = () => {
    onClickClose();
    setFileSelected([]);
  };

  const onFilesChange = async (fls: File[] | null) => {
    setFileSelected(fls);
  };

  const handleUploadSubtitle = async () => {
    setIsUpload(true);
    const res = await request(uploadFiles(fileSelected as File[]));

    if (res?.data?.status === 200) {
      request(getAllMedia);
      setIsUpload(false);
      setFileSelected([]);
      onClose();
      dispatch(update({ selectedTabInside: 'Upload' }));
    } else {
      message.error('Upload file unsuccessfully!');
      onClose();
      setFileSelected([]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="683px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Upload Video</TitleConfirm>
      <FileUpload handleDrop={onFilesChange} allowTypes={['video']} isMultiple={false} />
      <ActionsContainer>
        <Button
          onClick={() => {
            !isUpload && onClose();
          }}
          variant="outline"
          disabled={isUpload}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleUploadSubtitle();
          }}
          disabled={!fileSelected?.length}
        >
          {isUpload ? <Spinner height={14} width={14} /> : 'Upload'}
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default ModalUploadVideoCaption;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
  margin-bottom: 16px;
`;

export const MessageConfirm = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
  font-family: 'Objective';
  font-weight: 400;
`;
