import styled from '@emotion/styled';
import { VolumeIcon, MuteVolumeIcon, BackWard, Playfill, ForWard } from '@/components/SVG';
export const PlayControllerSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  justify-content: space-between;
  background: #FFF;
  min-height: 60px;
  position: relative;
  border-radius: 0 0 4px 4px;
  /* outline: ${({ theme }) => `0.5px solid ${theme.altGrey.shade5}`}; */
`;

export const Volume = styled(VolumeIcon)`
  cursor: pointer;
`;

export const VolumeControlSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const MuteVolume = styled(MuteVolumeIcon)`
  cursor: pointer;
`;

export const PopoverCc = styled.div`
  cursor: pointer;
  margin-top: 8px;
`;

export const ListCcs = styled.div`
  cursor: pointer;
`;

export const OptionCc = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  padding: 0 6px;
  padding-top: 3px;
  margin: 6px 0;
  background: ${({ selected, theme }) => selected && theme.brand.mint};
  &:hover {
    background: ${({ theme }) => theme.brand.mint};
  }
`;

export const SeekBack = styled(BackWard)`
  cursor: pointer;
`;

export const PlayControl = styled(Playfill)`
  cursor: pointer;
`;

export const SeekForward = styled(ForWard)`
  cursor: pointer;
`;

export const TimeInfo = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

export const ZoomIcon =  styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  /* width: 41px;
  height: 20px; */
  padding: 4px;
  border-radius: 4px;
  background-color: #F2F0F1;
  margin-left: 10px;
`;

export const VerticalLine =  styled.div`
  width: 1px;
  height: 16px;
  background-color: #E6E3E4;
  border-radius: 2px;
  margin: 0 4px;
`
export const SplitWrapper =  styled.button`
  border: none;
  display: flex;
  align-items: center;
  margin: auto;
  /* width: 41px;
  height: 20px; */
  padding: 4px;
  border-radius: 4px;
  background-color: #F2F0F1;
  margin-left: 10px;
  cursor: pointer;
`

