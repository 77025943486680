import React from 'react';

import { SVGProps } from './SVG.props';

const AlignCenter = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '18'}
      height={height || '19'}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6 6.43178H14.4C14.5591 6.43178 14.7117 6.37534 14.8243 6.27487C14.9368 6.17441 15 6.03815 15 5.89607C15 5.75399 14.9368 5.61772 14.8243 5.51726C14.7117 5.41679 14.5591 5.36035 14.4 5.36035H3.6C3.44087 5.36035 3.28826 5.41679 3.17574 5.51726C3.06321 5.61772 3 5.75399 3 5.89607C3 6.03815 3.06321 6.17441 3.17574 6.27487C3.28826 6.37534 3.44087 6.43178 3.6 6.43178ZM6 7.50321C5.84087 7.50321 5.68826 7.55965 5.57574 7.66012C5.46321 7.76058 5.4 7.89684 5.4 8.03892C5.4 8.181 5.46321 8.31726 5.57574 8.41773C5.68826 8.5182 5.84087 8.57464 6 8.57464H12C12.1591 8.57464 12.3117 8.5182 12.4243 8.41773C12.5368 8.31726 12.6 8.181 12.6 8.03892C12.6 7.89684 12.5368 7.76058 12.4243 7.66012C12.3117 7.55965 12.1591 7.50321 12 7.50321H6ZM14.4 9.64607H3.6C3.44087 9.64607 3.28826 9.70251 3.17574 9.80297C3.06321 9.90344 3 10.0397 3 10.1818C3 10.3239 3.06321 10.4601 3.17574 10.5606C3.28826 10.6611 3.44087 10.7175 3.6 10.7175H14.4C14.5591 10.7175 14.7117 10.6611 14.8243 10.5606C14.9368 10.4601 15 10.3239 15 10.1818C15 10.0397 14.9368 9.90344 14.8243 9.80297C14.7117 9.70251 14.5591 9.64607 14.4 9.64607ZM12 11.7889H6C5.84087 11.7889 5.68826 11.8454 5.57574 11.9458C5.46321 12.0463 5.4 12.1826 5.4 12.3246C5.4 12.4667 5.46321 12.603 5.57574 12.7034C5.68826 12.8039 5.84087 12.8604 6 12.8604H12C12.1591 12.8604 12.3117 12.8039 12.4243 12.7034C12.5368 12.603 12.6 12.4667 12.6 12.3246C12.6 12.1826 12.5368 12.0463 12.4243 11.9458C12.3117 11.8454 12.1591 11.7889 12 11.7889Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default AlignCenter;
