import React from 'react';
import type { FC } from 'react';

import DroppableDisplay from './DroppableDisplay';
import { CustomDragLayer } from './CustomDragLayer';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const TrackControlVideoControl: FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DroppableDisplay />
      <CustomDragLayer />
    </DndProvider>
  );
};

export default TrackControlVideoControl;
