import React from 'react';

import { SVGProps } from './SVG.props';

const VideoRecorderFilled = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="videorecorder-filled" clip-path="url(#clip0_14253_58328)">
        <path
          id="line"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.142578 3.57073C0.142578 2.62396 0.91009 1.85645 1.85686 1.85645H8.71401C9.66078 1.85645 10.4283 2.62396 10.4283 3.57073V4.4922L13.1047 3.60005C13.279 3.54197 13.4705 3.57119 13.6196 3.67859C13.7686 3.78599 13.8569 3.95848 13.8569 4.14216V10.1422C13.8569 10.3332 13.7614 10.5116 13.6024 10.6176C13.4434 10.7236 13.242 10.7431 13.0657 10.6696L10.4283 9.57073V10.4279C10.4283 11.3746 9.66078 12.1422 8.71401 12.1422H1.85686C0.91009 12.1422 0.142578 11.3746 0.142578 10.4279V3.57073Z"
          fill="#060548"
        />
      </g>
      <defs>
        <clipPath id="clip0_14253_58328">
          <rect width="13.7143" height="13.7143" fill="white" transform="translate(0.142578 0.142578)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoRecorderFilled;
