import * as React from 'react';
import Moveable, { MoveableProps } from 'react-moveable';

interface IMovableProps extends MoveableProps {
  frame: any;
}

const Movable: React.FunctionComponent<IMovableProps> = ({ target, frame, ...props }) => {
  
  return (
    <Moveable
      target={target}
      draggable={true}
      startDragRotate={0}
      throttleDragRotate={0}
      zoom={1}
      origin={false}
      padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
      throttleRotate={0}
      onDragStart={(e) => {
        e.set(frame.translate);
      }}
      onDrag={(e) => {
        frame.translate = e.beforeTranslate;
        e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
      }}
      {...props}
    />
  );
};

export default Movable;
