/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Asset from '@/components/Asset';
import { addImages, addPlayableItem, getMediaFiles, selectVideo, update } from '@/features/video/store/videoSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { PlayableType } from '@/features/video/models/FrameItem';
import { VideoService, getDuration, getImageSize } from '@/features/video/services/videoService';
import useRequest from '@/components/hooks/useRequest';
import LoadingIcon from '@/components/LoadingIcon/LoadingIcon';
import { ContentType } from '@/constants/contentType';

interface IMediaProps {
  mediaList: any[];
  onRemoveMedia?: (value: { id?: string; link?: string }) => void;
}

type AssetType = 'IMAGE' | 'AUDIO' | 'VIDEO';

interface IAsset {
  companyId: string;
  fileKey: string;
  filePath: string;
  id: string;
  name: string;
  type: AssetType;
  userId: string;
  durationFormatted?: string;
  duration?: number;
  width?: number;
  height?: number;
  thumbnails?: string[];
  resolution?: { width: number; height: number };
  onRemove: () => void;
  logoName?: string;
  blurImage?: string;
  avatar?: string;
  assetType: string;
  contentType?: string;
}

const MediaList: React.FunctionComponent<IMediaProps> = ({ mediaList, onRemoveMedia }) => {
  const dispatch = useAppDispatch();
  const { addingPlayableItems, subtitlesInit } = useAppSelector(selectVideo);

  const [removedIds, setRemovedIds] = React.useState<string[]>([]);
  const [assets, setAssets] = React.useState<IAsset[]>([]);
  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);

  useEffect(() => {
    async function fullfilAssets() {
      const data = await Promise.all(
        mediaList.map(async (asset) => {
          const videoLink = asset.Link || asset.filePath;
          if (!videoLink) {
            return undefined;
          }
          let duration;
          let size;
          let resolution;

          if (asset.duration && asset.width && asset.height) {
            if (asset.type === PlayableType.video || asset.type === PlayableType.audio) {
              duration = asset.duration;
            } else {
              size = { width: asset.width, height: asset.height };
            }

            if (asset.type === PlayableType.video) {
              resolution = { width: asset.width, height: asset.height };
            }
          } else {
            if (asset.type === PlayableType.video || asset.type === PlayableType.audio) {
              const videoInfo = await getDuration(videoLink);
              duration = videoInfo.duration;
              if (asset.type === PlayableType.video) {
                resolution = videoInfo.resolution;
              }
            } else {
              size = await getImageSize(videoLink);
            }
          }

          return {
            ...asset,
            durationFormatted: duration ? VideoService.convertDurationToHHMMSS(duration) : undefined,
            duration,
            width: size?.width,
            height: size?.height,
            resolution: resolution,
            filePath: videoLink
          };
        })
      );
      setAssets(data.filter(Boolean) as IAsset[]);
    }
    fullfilAssets();
  }, [mediaList]);

  const onRemove = async (value: { id?: string; link?: string }) => {
    // setRemovedIds([...removedIds, id]);
    // await request(deleteMedia(id));
    onRemoveMedia && onRemoveMedia(value);
  };

  const onAdd = async (info: Partial<IAsset>) => {
    const { type, filePath, name, duration, fileKey, width, height, thumbnails, logoName, blurImage } = info;
    const listVideos = addingPlayableItems?.filter((x) => x.type === PlayableType.video && !x.isHide);
    const videoName = `Video ${listVideos?.length < 9 ? `0${listVideos?.length + 1}` : listVideos?.length + 1}`;
    const idSubtitleLinked = subtitlesInit?.filter((sub) => sub.file === filePath) as any[];

    if (Object.values<string>(PlayableType).includes(type!)) {
      const castType = type as PlayableType;
      dispatch(
        addPlayableItem({
          filePath,
          type: castType,
          duration,
          name: videoName,
          fileKey,
          thumbnails,
          idSubtitleLinked: idSubtitleLinked[0]?.id,
          blurBackground: blurImage
        })
      );
      if (type === PlayableType.audio) {
        dispatch(update({ totalPlayed: 0 }));
      }
    } else {
      dispatch(addImages({ filePath, name, fileKey, width, height, isLogo: !!logoName }));
    }
  };

  const getVideoThumbnail = (asset: IAsset) => {
    if (!asset.thumbnails) return;
    if (asset.avatar) {
      return asset.avatar;
    }
    if (asset.thumbnails[0]) {
      return asset.thumbnails[0];
    }
    return '';
  };

  return (
    <>
      {isLoadingData && (
        <LoadingContainer>
          <LoadingIcon width={30} height={30} />
        </LoadingContainer>
      )}
      <Container className="asset-upload">
        {assets.map((asset, index) => (
          <Asset
            url={asset.type === PlayableType.video ? getVideoThumbnail(asset) : asset.filePath}
            videoUrl={asset.type === PlayableType.video && !getVideoThumbnail(asset) ? asset.filePath : undefined}
            key={asset.id || index}
            width="calc(50% - 4px)"
            duration={asset.durationFormatted}
            onRemove={() => {
              onRemove({ link: asset.filePath, id: asset.id });
            }}
            id={asset.id}
            onAdd={() => onAdd(asset)}
            type={asset.type}
            isEmployer={true}
            thumbnails={asset?.thumbnails}
            isMediaStories={asset?.contentType === ContentType.library}
          />
        ))}
      </Container>
    </>
  );
};

export default MediaList;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow: auto;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
`;
