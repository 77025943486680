export function plusSecond(hour: any, sec: number) {
  // Split the time string into hours, minutes, seconds, and milliseconds
  const [hours, minutes, secondsWithMillis] = hour?.split(':');
  const [seconds, milliseconds] = secondsWithMillis?.split('.');

  // Convert the hours, minutes, seconds, and milliseconds to numbers
  const hoursNum = Number(hours);
  const minutesNum = Number(minutes);
  const secondsNum = Number(seconds);
  const millisecondsNum = Number(milliseconds || 0);

  // Compute the total number of milliseconds and add 64,000
  const totalMillisecondsMoveTo = hoursNum * 3600000 + minutesNum * 60000 + (secondsNum + sec) * 1000 + millisecondsNum;

  // Convert the total number of milliseconds back to hours, minutes, seconds, and milliseconds
  const newHoursNum = Math?.floor(totalMillisecondsMoveTo / 3600000) % 24;
  const newMinutesNum = Math?.floor(totalMillisecondsMoveTo / 60000) % 60;
  const newSecondsNum = Math?.floor(totalMillisecondsMoveTo / 1000) % 60;
  const newMillisecondsNum = totalMillisecondsMoveTo % 1000;

  // Convert the new hours, minutes, seconds, and milliseconds to strings with leading zeros if necessary
  const newHoursStr = newHoursNum?.toString()?.padStart(2, '0');
  const newMinutesStr = newMinutesNum?.toString()?.padStart(2, '0');
  const newSecondsStr = newSecondsNum?.toString()?.padStart(2, '0');
  const newMillisecondsStr = newMillisecondsNum?.toString()?.padStart(3, '0');

  // Combine the new hours, minutes, seconds, and milliseconds into a new time string
  let newTimeStr = `${newHoursStr}:${newMinutesStr}:${newSecondsStr}`;
  if (newMillisecondsNum > 0) {
    newTimeStr += `.${newMillisecondsStr}`;
  }

  return newTimeStr;
}
