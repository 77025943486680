import moment from 'moment';
import { AddingPlayableItem } from '../models/FrameItem';

export class VideoService {
  // Get the Greatest common divisor of 2 numbers
  private static gcd(a: number, b: number): number {
    return b === 0 ? a : this.gcd(b, a % b);
  }

  static convertDurationToHHMMSS(duration: number): string {
    const fullDateStr = new Date((duration || 0) * 1000)?.toISOString();
    const HHMMSS = fullDateStr.substr(11, 8);
    const display = HHMMSS.split(/^00:/);

    return display[1];
  }

  static convertDisplayMilliSecond(duration: number) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration % 3600) / 60;
    const seconds = duration % 60;

    const formattedHours = hours.toFixed().padStart(2, '0');
    let formattedMinutes = minutes.toFixed().padStart(2, '0');

    const secondPad = seconds < 10 ? `0${seconds.toFixed(1)}` : seconds.toFixed(1);
    if (minutes < 1) {
      return `${seconds.toFixed(1)}`;
    }

    if (hours < 1) {
      return `${formattedMinutes}:${secondPad}`;
    }

    return `${formattedHours}:${formattedMinutes}:${secondPad}`;
  }

  static convertPixelSizeToAspectRatio(width: number, height: number): string {
    if (!width || !height) {
      return '';
    }

    const r = this.gcd(width, height);

    return `${width / r}:${height / r}`;
  }
}

export const getDuration = (url: string) =>
  new Promise<{ duration: number; resolution: { width: number; height: number } }>((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);

      resolve({
        duration: video.duration === Infinity ? 0 : video.duration,
        resolution: { width: video.videoWidth, height: video.videoHeight }
      });
    };
    video.onerror = function () {
      resolve({ duration: 0, resolution: { width: 1920, height: 1080 } });
    };
    video.src = url;
  });

export const getTotalDuration = async (addingPlayableItems: AddingPlayableItem[]) => {
  const duration = await getDuration(addingPlayableItems[0].filePath);
  return duration;
};

export const isInRange = (start: number | string, end: number | string, totalPlayed: number) => {
  const startSeconds = typeof start === 'string' ? moment.duration(start.replace(',', '.')).asSeconds() : start;
  const endSeconds = typeof end === 'string' ? moment.duration(end.replace(',', '.')).asSeconds() : end;

  return startSeconds <= totalPlayed && (!endSeconds || endSeconds > totalPlayed);
};

export const isInRangeVideo = (start: number | string, end: number | string, totalPlayed: number) => {
  const startSeconds = typeof start === 'string' ? moment.duration(start.replace(',', '.')).asSeconds() : start;
  const endSeconds = typeof end === 'string' ? moment.duration(end.replace(',', '.')).asSeconds() : end;

  return startSeconds <= totalPlayed && (!endSeconds || endSeconds > totalPlayed);
};

export const isInRangeTransition = (start: number | string, totalPlayed: number) => {
  const startSeconds = typeof start === 'string' ? moment.duration(start.replace(',', '.')).asSeconds() : start;

  return startSeconds <= totalPlayed;
};

export const isInRangeSubtitle = (
  start: number | string,
  end: number | string,
  currentTime: number,
  trimStart?: number | any,
  trimEnd?: number | any,
  videoStartTime?: number | any
) => {
  const momentTimeStart = moment(start, 'HH:mm:ss.SSS');
  const momentTimeEnd = moment(end, 'HH:mm:ss.SSS');

  const startTime =
    momentTimeStart.seconds() +
    momentTimeStart.minutes() * 60 +
    momentTimeStart.hours() * 3600 +
    momentTimeStart.milliseconds() / 1000 +
    0.1;

  const endTime =
    momentTimeEnd.seconds() +
    momentTimeEnd.minutes() * 60 +
    momentTimeEnd.hours() * 3600 +
    momentTimeEnd.milliseconds() / 1000;

  return (
    startTime - trimStart <= currentTime &&
    endTime - trimStart >= currentTime &&
    videoStartTime + trimEnd - trimStart >= currentTime && 
    currentTime > videoStartTime
  );
};

export const getImageSize = (url: string) => {
  const img = new Image();
  return new Promise<{ width: number; height: number }>((resolve) => {
    try {
      img.addEventListener('load', function () {
        resolve({ width: this.naturalWidth, height: this.naturalHeight });
      });
    } catch (error) {
      resolve({ width: 0, height: 0 });
    }
    img.src = url;
  });
};

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

export const resizeImageToMin = (width = 0, height = 0) => {
  let resizeWidth = width;
  let resizeHeight = height;

  if (width > MAX_WIDTH) {
    resizeWidth = MAX_WIDTH;
    resizeHeight = height * (resizeWidth / width);
  }

  if (resizeHeight > MAX_HEIGHT) {
    resizeWidth = (resizeWidth || width) * (MAX_HEIGHT / resizeHeight);
    resizeHeight = MAX_HEIGHT;
  }
  return { width: resizeWidth, height: resizeHeight };
};

export const findMaxLine = ({ addingImages, addingTexts, addingCards, addingPlayableItems, totalPlayed }: any) => {
  let maxLine = 0;
  const timeLines = [...addingImages, ...addingTexts, ...addingCards, ...addingPlayableItems];

  timeLines.forEach((element: any) => {
    if (element.line > maxLine) {
      maxLine = element.line;
    }
  });
  let line = maxLine + 1;
  for (let index = 0; index <= Array(maxLine + 1).length; index++) {
    const results = [];
    const inLines = timeLines.filter((item: any) => item.line === index);
    inLines.forEach((item) => {
      if (totalPlayed <= item.start && totalPlayed + DURATION_STATIC_TYPE >= item.start) {
        results.push('over');
      }
      if (totalPlayed > item.start && totalPlayed < item.end) {
        results.push('over');
      }
    });
    if (!results.length) {
      line = index;
      break;
    }
  }
  return line;
};

export const DURATION_STATIC_TYPE = 10; // unit: 10s
