import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowFrontlineUp = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width ? width : 17} height={height ? height : 16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-frontline-up">
        <g id="line">
          <path
            d="M3.16666 1.99992C3.16666 1.63173 3.46513 1.33325 3.83332 1.33325H13.1666C13.5348 1.33325 13.8333 1.63173 13.8333 1.99992C13.8333 2.36811 13.5348 2.66659 13.1666 2.66659H3.83332C3.46513 2.66659 3.16666 2.36811 3.16666 1.99992Z"
            fill={fill ? fill : "#070550"}
          />
          <path
            d="M4.69525 8.47132C4.4349 8.21097 4.4349 7.78886 4.69525 7.52851L8.02859 4.19518C8.28893 3.93483 8.71105 3.93483 8.97139 4.19518L12.3047 7.52851C12.5651 7.78886 12.5651 8.21097 12.3047 8.47132C12.0444 8.73167 11.6223 8.73167 11.3619 8.47132L9.16666 6.27606L9.16666 13.9999C9.16666 14.3681 8.86818 14.6666 8.49999 14.6666C8.1318 14.6666 7.83333 14.3681 7.83333 13.9999L7.83332 6.27606L5.63806 8.47132C5.37771 8.73167 4.9556 8.73167 4.69525 8.47132Z"
            fill={fill ? fill : "#070550"}
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowFrontlineUp;
