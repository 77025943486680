import React from 'react';

import { SVGProps } from './SVG.props';

const Playfill = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg {...props} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3137 7.84517C17.9569 8.8118 17.9569 11.1882 16.3137 12.1548L7.10086 17.5741C5.43428 18.5545 3.33331 17.3528 3.33331 15.4193L3.33332 4.5807C3.33332 2.64716 5.43428 1.44552 7.10087 2.42586L16.3137 7.84517Z"
        fill={fill|| "#030220"}
      />
    </svg>
  );
};

export default Playfill;
