import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';

interface IProcessBarProps {
  isOpen: boolean;
  onClickClose: () => void;
  onClickExport?: () => void;
  setResolutionSelected?: (val?: Option) => void;
}

export interface Option {
  name: string;
  value: string;
}

const listResolutions = [
  {
    name: '720P',
    value: '1280x720'
  },
  {
    name: '1080P',
    value: '1920x1080'
  }
];

const SelectResolutionModal: React.FunctionComponent<IProcessBarProps> = ({
  isOpen,
  onClickClose,
  onClickExport = () => null,
  setResolutionSelected = () => null
}) => {
  const theme = useTheme();
  const [optionIdSelected, setOptionIdSelected] = React.useState<number | null>();

  const handleExport = () => {
    onClickExport();
    setOptionIdSelected(null);
  };

  const handleCancel = () => {
    onClickClose();
    setOptionIdSelected(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="320px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
      customPadding="20px 32px"
    >
      <TitleConfirm>Select a resolution</TitleConfirm>
      <ListOptions>
        {listResolutions?.map((item, idx) => (
          <OptionResolution
            key={idx + 1}
            onClick={() => {
              setOptionIdSelected(idx + 1);
              setResolutionSelected(item);
            }}
            isSelected={optionIdSelected === idx + 1}
          >
            {item.name}
          </OptionResolution>
        ))}
      </ListOptions>
      <ActionsContainer>
        <Button onClick={handleCancel} variant="outline">
          Cancel
        </Button>
        <Button disabled={!optionIdSelected} onClick={handleExport}>
          Export
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default SelectResolutionModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 22px;
  margin-bottom: 12px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  margin-bottom: 24px;
  font-weight: 700;
`;

const ListOptions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  justify-content: space-around;
`;

const OptionResolution = styled.div<{ isSelected?: boolean }>`
  padding: 8px;
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.brand.success : theme.grey.shade6)};
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 14px;
  width: 120px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme, isSelected }) => isSelected && theme.brand.success};
`;
