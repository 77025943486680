import { ObjectType } from './../../features/video/models/FrameItem';
import { useSelector } from 'react-redux';
import {
  clearActUndoRedo,
  pushVersionDraft,
  selectVideo,
  setVersionDraftSelected,
  VideoState
} from '@/features/video/store/videoSlice';
import { selectSubtitle as getSubtitlesByUserId } from '@/features/video/store/subtitleSlice';
import useRequest from './useRequest';
import { saveDraft } from '@/features/video/store/videoAPI';
import {
  AddingCard,
  AddingImage,
  AddingPlayableItem,
  AddingText,
  Card,
  PlayableType
} from '@/features/video/models/FrameItem';
import { getDuration } from '@/features/video/services/videoService';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RatioResolution } from '@/features/video/constant/resolution';
import { FHDPixel, FHDScreen } from '@/constants/screens';
import { ContentType } from '@/constants/contentType';
import { SubtitleV2 } from '@/features/video/models/VideoFrame';
import { useAppDispatch } from '@/app/hooks';
import { useSearchParams } from 'react-router-dom';
import { replaceSpecialCharacter } from '@/utils/htmlHelper';

export interface IResolution {
  width: number;
  height: number;
}

const MAX_STEPS = 300;

const calculateActualValue = (
  videoSize: IResolution = { width: 1920, height: 1080 }, // export view
  playerSize: IResolution, // current view on UI
  currentValue: { width: number; height: number } // position
) => {
  return {
    x: Math.round((videoSize.width / playerSize.width) * currentValue.width),
    y: Math.round((videoSize.height / playerSize.height) * currentValue.height)
  };
};

export const calculateUIValue = (
  videoSize: IResolution = { width: 1920, height: 1080 },
  playerSize: IResolution,
  currentValue: { width: number; height: number }
) => {
  return {
    x: (playerSize.width / videoSize.width) * currentValue.width,
    y: (playerSize.height / videoSize.height) * currentValue.height
  };
};

export const calculateActualScreenSize = (
  cropResolution: { width: number; height: number },
  cropPosition: { x: number; y: number },
  playerSizeHD: { width: number; height: number }
) => {
  const overlapWidth =
    Math.min(playerSizeHD.width, cropPosition.x + cropResolution.width) - Math.max(0, cropPosition.x);
  const overlapHeight =
    Math.min(playerSizeHD.height, cropPosition.y + cropResolution.height) - Math.max(0, cropPosition.y);

  return {
    width: overlapWidth,
    height: overlapHeight
  };
};

const getFontWeight = (fontWeight: string | number) => {
  if (['bold', 'normal'].includes(fontWeight as string)) {
    return fontWeight;
  }
  const fontWeightNumber = parseInt(fontWeight as string);
  if (isNaN(fontWeightNumber) || fontWeightNumber < 700) {
    return 'normal';
  }

  return 'bold';
};

const useDraft = () => {
  const {
    addingImages,
    addingTexts,
    addingPlayableItems,
    addingCards,
    nextAspectRatio,
    subtitles,
    subtitlesInit,
    contentId,
    contentType,
    platFromVideoId,
    playerSize,
    title,
    resolution,
    crop,
    storyHubMainVideoId,
    subtitleStyle,
    playerSizeHD,
    // language,
    createdAt,
    listVersions,
    actionUndoRedo,
    versionDraftSelected,
    backgroundColor,
    isShowCaption
  } = useSelector(selectVideo);

  const { subtitles: listSubtitlesByUserId } = useSelector(getSubtitlesByUserId);

  const [searchParams] = useSearchParams();
  const { request } = useRequest();
  const dispatch = useAppDispatch();
  const contentIdParam = searchParams.get('contentId');
  const contentTypeParam = searchParams.get('contentType');

  const parseResolutions = (inputResolutions?: string) => {
    const [width, height] = inputResolutions ? inputResolutions?.split('x') : ['1920', '1080'];
    return { width: parseInt(width), height: parseInt(height) };
  };

  const maxDuration = useMemo(() => {
    const maxPlayable = addingPlayableItems
      .filter((x) => !x.isHide)
      .reduce((out, item) => {
        return item.end && item.end > out ? item.end : out;
      }, 0);
    const maxImage = addingImages.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);
    const maxText = addingTexts.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);
    const maxCard = addingCards.reduce((out, item) => {
      return item.end && item.end > out ? item.end : out;
    }, 0);

    return Math.max(maxPlayable, maxImage, maxText, maxCard);
  }, [addingImages, addingPlayableItems, addingTexts, addingCards]);

  const buildDraftObj = (
    {
      isExporting,
      actionExport,
      exportResolution
    }: {
      isExporting?: boolean;
      actionExport?: boolean;
      exportResolution?: string;
    } = { isExporting: false }
  ) => {
    const parsedResolution = parseResolutions(resolution);
    const playerSizeHDCurrent = playerSizeHD as { width: number; height: number };
    const exportSubtitles = (arr: SubtitleV2[]) => {
      const results = arr.filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
        return false;
      });

      return results.map((item) => {
        const newItem = { ...item };
        delete newItem.trim;
        delete newItem.start;

        return newItem;
      });
    };

    const handelExportCaption = (isExport?: boolean, subtitles?: SubtitleV2[]) => {
      if (isExport) {
        if (isShowCaption) {
          return exportSubtitles(subtitles as SubtitleV2[]);
        } else {
          return [];
        }
      } else {
        return subtitles;
      }
    };

    const handelLinkCaption = (isExport?: boolean, idSubtitle?: string) => {
      if (isExport) {
        if (isShowCaption) {
          return idSubtitle ? idSubtitle : '';
        } else {
          return '';
        }
      } else {
        return idSubtitle ? idSubtitle : '';
      }
    };

    // trigger set language save draft base on list subtitlesInit
    const handleSaveLanguage = () => {
      if (!subtitlesInit?.length) {
        return {
          name: '',
          code: ''
        };
      } else if (subtitlesInit?.length === 1) {
        const subtitleId = subtitlesInit[0]?.id;
        const subtitleSelected = listSubtitlesByUserId?.filter((sub: any) => sub.id === subtitleId);
        // return only language when have only one subtitle
        return {
          name: subtitleSelected[0]?.langName,
          code: subtitleSelected[0]?.langCode
        };
      } else {
        // get list subtitle match by list subtitles by contentId of user and list subtitlesInit
        const listSubtitlesMatch = listSubtitlesByUserId?.filter((sub: any) =>
          subtitlesInit?.some((item) => item.id === sub.id)
        );
        // get langName of first item to compare others
        const sameLanguage = listSubtitlesMatch[0]?.langName;
        // check same language in list subtitles match with above condition
        const isSameLanguage = listSubtitlesMatch?.every((item: any) => item?.langName === sameLanguage);
        // if same language return language otherwise return empty string
        return {
          name: isSameLanguage ? listSubtitlesMatch[0]?.langName : '',
          code: isSameLanguage ? listSubtitlesMatch[0]?.langCode : ''
        };
      }
    };

    return {
      addingPlayableItems: addingPlayableItems
        .filter((vd) => !vd.isHide && (vd.trim?.end || 0) > (vd.trim?.start || 0) && (vd?.end || 0) > (vd?.start || 0))
        .map((item: any, index) => {
          // const currentElement = addingPlayableItems[index];
          // const nextElement = addingPlayableItems[index + 1];
          // const isNearNextVd =
          //   nextElement === undefined ? false : currentElement?.end === nextElement?.start ? true : false;
          const videoNextVideoAppliedTransition = addingPlayableItems.find(
            (vd: any) => vd.line === item.line && vd.start === item.end
          );
          const videoPreAppliedTransition = addingPlayableItems.find(
            (vd: any) => item.start === vd.end && item.line === vd.line
          );

          const isBehideVdTransition =
            videoPreAppliedTransition === undefined
              ? false
              : item?.start === videoPreAppliedTransition?.end && videoPreAppliedTransition?.isApplyTransition
              ? true
              : false;

          const isNextVdTransition =
            videoNextVideoAppliedTransition === undefined
              ? false
              : item?.end === videoNextVideoAppliedTransition?.start
              ? true
              : false;

          const cropPosition = calculateActualValue(parsedResolution, playerSizeHDCurrent, {
            width: item.crop?.x || 0,
            height: item.crop?.y || 0
          });

          const cropResolution = calculateActualValue(
            { width: parsedResolution.width * item.zoom, height: parsedResolution.height * item.zoom },
            playerSizeHDCurrent,
            { width: playerSizeHDCurrent.width, height: playerSizeHDCurrent.height }
          );

          const cropActualVideo = calculateActualScreenSize(
            { width: cropResolution.x, height: cropResolution.y },
            cropPosition,
            parsedResolution
          );

          let cropSize = {
            width: cropPosition.x ? `${cropActualVideo?.width}px` : FHDScreen.width,
            height: cropPosition.y ? `${cropActualVideo?.height}px` : FHDScreen.height
          };

          return {
            main: item?.main,
            type: item.type,
            duration: item.duration,
            links: { Location: item.filePath || item?.links?.Location, Key: item.fileKey },
            start: item.start,
            end: item.end,
            name: item.name,
            trim: item.trim,
            line: item.line,
            volume: item.volume,
            thumbnails: item.thumbnails?.length ? item.thumbnails : [],
            // idSubtitleLinked: item?.idSubtitleLinked ? item?.idSubtitleLinked : '',
            idSubtitleLinked: handelLinkCaption(actionExport, item?.idSubtitleLinked),
            isHide: item?.isHide,
            transition: !item?.isApplyTransition
              ? {}
              : item?.transition?.type
              ? {
                  type: item?.transition?.type,
                  start:
                    item?.end -
                    (item?.transition?.type === 'fadewhite' || item?.transition?.type === 'fadeblack' ? 1.5 : 1.3),
                  // start: item?.end - 1,
                  duration: item?.transition?.duration
                }
              : {},
            crop: {
              ...cropPosition,
              ...cropSize
            },
            // width: cropResolution?.x, // width
            // height: cropResolution?.y, // height
            width: FHDPixel.width * (item?.zoom || 1),
            height: FHDPixel.height * (item?.zoom || 1),
            zoom: item?.zoom || 1,
            isApplyTransition: isNextVdTransition && item?.transition?.type ? true : false,
            // isApplyTransition: item?.isApplyTransition || false
            effects: item?.effects,
            blurBackground: item?.blurBackground,
            isBlurBackground: item?.isBlurBackground,
            isNeedApplyTransition: isBehideVdTransition
          };
        }),
      // subtitlesInit: actionExport ? exportSubtitles(subtitlesInit as SubtitleV2[]) : subtitlesInit,
      // subtitles: actionExport ? exportSubtitles(subtitles as SubtitleV2[]) : subtitles,
      subtitlesInit: handelExportCaption(actionExport, subtitlesInit),
      subtitles: handelExportCaption(actionExport, subtitles),
      crop: nextAspectRatio
        ? {
            expectRatio: nextAspectRatio,
            x: crop?.x,
            y: crop?.y,
            width: crop?.width,
            height: crop?.height
          }
        : {},
      width: FHDScreen.width,
      height: FHDScreen.height,
      addingTexts: addingTexts.map((text: any) => {
        let newPos = calculateActualValue(parsedResolution, playerSizeHDCurrent, {
          width: text?.position.x || 0,
          height: text?.position.y || 0
        });

        let style = { ...text.styles };
        style.lineHeight = style.fontSize;
        style.fontWeight = getFontWeight(style.fontWeight);

        return {
          value: isExporting ? replaceSpecialCharacter(text.value) : text.value,
          position: newPos
            ? {
                // x: newPos.x,
                // y: newPos.y
                x: newPos?.x,
                y: newPos?.y
                // y: actionExport ? newPositionY : newPos?.y
              }
            : {
                x: text.position.x,
                y: text.position.y
              },
          start: text.start,
          end: text.end,
          line: text.line,
          styles: style,
          animation: text.animation,
          name: text?.name
        };
      }),
      addingImages: addingImages.map((image: any) => {
        let newPos = calculateActualValue(parsedResolution, actionExport ? playerSizeHDCurrent : playerSize, {
          width: image?.position.x || 0,
          height: image?.position.y || 0
        });
        let newSize = calculateActualValue(parsedResolution, actionExport ? playerSizeHDCurrent : playerSize, {
          width: image.width || 0,
          height: image.height || 0
        });
        let newLogo = calculateActualValue({ width: 1920, height: 1080 }, playerSizeHDCurrent, {
          width: image.width || 0,
          height: image.height || 0
        });

        return {
          imageKey: image.fileKey,
          links: {
            Location: image.filePath ? image.filePath : image?.links?.Location,
            Key: image.fileKey ? image.fileKey : image?.links?.Location
          },
          position: newPos
            ? { x: newPos.x, y: newPos.y }
            : {
                x: image.x,
                y: image.y
              },
          width: image?.isLogo ? (newLogo ? newLogo.x : image.width) : newSize ? newSize.x : image.width,
          height: image?.isLogo ? (newLogo ? newLogo.y : image.height) : newSize ? newSize.y : image.height,
          start: image.start,
          end: image.end,
          name: image.name,
          line: image.line,
          animation: image.animation,
          isLogo: image?.isLogo
        };
      }),
      addingCards: addingCards?.length
        ? addingCards.map((card: any) => {
            let newPos = calculateActualValue(parsedResolution, actionExport ? playerSizeHDCurrent : playerSize, {
              width: card?.position.x || 0,
              height: card?.position.y || 0
            });
            let newSize = calculateActualValue(parsedResolution, actionExport ? playerSizeHDCurrent : playerSize, {
              width: card?.width || 0,
              height: card?.height || 0
            });

            const calculatePositionElement = card?.elementTexts?.map((elm: Card | any, idx: number) => {
              const newPosGraphic = calculateActualValue(parsedResolution, playerSizeHDCurrent, {
                width: elm?.position?.x || 0,
                height: elm?.position?.y || 0
              });

              let styles = { ...elm.styles };
              styles.lineHeight = styles.fontSize;
              styles.fontWeight = getFontWeight(styles.fontWeight);

              return {
                uid: elm?.uid,
                value: elm?.isShow ? (isExporting ? replaceSpecialCharacter(elm.value) : elm.value) : '',
                styles: styles,
                name: elm?.name,
                isShow: elm?.isShow,
                position: newPosGraphic
              };
            });
            return {
              position: newPos
                ? { x: newPos.x, y: newPos.y }
                : {
                    x: card?.x,
                    y: card?.y
                  },
              // 3px to balance with width of text elements -  this trick just exactly with font size of text ~40px
              width: card.type === ObjectType.graphic ? FHDPixel.width : newSize ? newSize.x : card?.width,
              height: card.type === ObjectType.graphic ? FHDPixel.height : newSize ? newSize.y : card?.height,
              start: card?.start,
              end: card?.end,
              name: card?.name,
              // animation: card?.animation,
              line: card?.line,
              backgroundColor: card?.backgroundColor,
              elementTexts: calculatePositionElement,
              isChanged: card?.isChanged
            };
          })
        : [],
      merge: {},
      contentId: contentId ? contentId : contentIdParam,
      contentType: contentType ? contentType : contentTypeParam,
      // videoId: platFromVideoId,
      videoId: contentType === ContentType.library ? platFromVideoId : storyHubMainVideoId,
      title: title,
      duration: maxDuration,
      resolution: exportResolution ? exportResolution : resolution || RatioResolution[nextAspectRatio!],
      subtitleStyle: {
        fontSize:
          subtitleStyle?.fontSize === '14px' ? '40px' : subtitleStyle?.fontSize ? subtitleStyle?.fontSize : '40px',
        backgroundColor: subtitleStyle?.backgroundColor ? subtitleStyle?.backgroundColor : 'rgba(255, 255, 255, 1)',
        color: subtitleStyle?.color ? subtitleStyle?.color : 'rgba(0, 0, 0, 1)'
      },
      language: handleSaveLanguage(),
      createdAt: createdAt,
      backgroundColor: backgroundColor
    };
  };

  const convertDraft = async (data: any): Promise<Partial<VideoState>> => {
    const parsedResolution = parseResolutions(data?.resolution || RatioResolution[data?.crop?.expectRatio]);

    const playerSizeHDCurrent = playerSizeHD as { width: number; height: number };
    const playableItems: AddingPlayableItem[] =
      (await Promise.all(
        data.addingPlayableItems?.map(async (item: any, index: number) => {
          let resolution = item.resolution;
          if (!resolution) {
            const data = await getDuration(item.links?.Location);
            resolution = data.resolution;
          }

          const cropPosition = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
            width: item.crop?.x || 0,
            height: item.crop?.y || 0
          });

          const {
            links,
            Links,
            type,
            duration,
            trim,
            main,
            thumbnails,
            line,
            idSubtitleLinked,
            volume,
            isHide,
            transition,
            width,
            height,
            zoom,
            isApplyTransition,
            effects,
            blurBackground,
            isBlurBackground,
            isNeedApplyTransition
          } = item;

          return new AddingPlayableItem({
            filePath: links?.Location,
            type,
            duration,
            fileKey: Links?.Key,
            trim: trim ? trim : { start: 0, end: duration },
            resolution,
            main,
            thumbnails: thumbnails || [],
            frameStatus: {
              uid: '',
              line: item.line,
              start: item.start,
              end: item.end,
              name: item.name
                ? item.name
                : `${item.type === PlayableType.video ? 'Video' : 'Music'} ${
                    index + 1 < 10 ? `0${index + 1}` : index + 1
                  }`
            },
            line,
            idSubtitleLinked: idSubtitleLinked || '',
            volume,
            isHide,
            transition: isApplyTransition ? transition : {},
            crop: cropPosition,
            width: width || 1920,
            height: height || 1080,
            zoom,
            isApplyTransition: isApplyTransition && transition?.type ? true : false,
            effects,
            blurBackground,
            isBlurBackground,
            isNeedApplyTransition: isNeedApplyTransition || false
          });
        })
      )) || [];

    return {
      addingPlayableItems: playableItems,
      addingImages:
        data.addingImages?.map((item: any, index: number) => {
          const newPos = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
            width: item.position?.x || 0,
            height: item.position?.y || 0
          });
          const newSize = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
            width: item.width || 0,
            height: item.height || 0
          });
          return new AddingImage(item.links?.Location, item.Links?.Key, item?.isLogo, {
            uid: uuidv4(),
            start: item.start,
            end: item.end,
            width: newSize.x,
            height: newSize.y,
            x: newPos.x,
            y: newPos.y,
            line: item.line,
            name: item.name ? item.name : `Image  ${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
            animation: item?.animation,
            position: {
              x: newPos.x,
              y: newPos.y
            },
            type: ObjectType.image
          });
        }) || [],
      addingTexts:
        data.addingTexts?.map((item: any) => {
          const newPos = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
            width: item.position?.x || 0,
            height: item.position?.y || 0
          });

          return new AddingText(item.value, item.styles, item.fontFile, {
            uid: '',
            start: item.start,
            end: item.end,
            x: newPos.x,
            y: newPos.y,
            line: item.line,
            name: item.name,
            animation: item?.animation,
            position: {
              x: newPos.x,
              y: newPos.y
            },
            type: ObjectType.text
          });
        }) || [],
      addingCards:
        (data.addingCards &&
          data.addingCards?.map((item: any) => {
            const newPos = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
              width: item.position?.x || 0,
              height: item.position?.y || 0
            });
            const newSize = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
              width: item.width || 0,
              height: item.height || 0
            });

            const calculatePositionElement = item.elementTexts?.map((elm: Card | any, idx: number) => {
              const newPosElm = calculateUIValue(parsedResolution, playerSizeHDCurrent, {
                width: elm.position?.x || 0,
                height: elm.position?.y || 0
              });
              return {
                uid: elm?.uid,
                value: elm?.value,
                styles: elm?.styles,
                name: elm?.name,
                isShow: elm?.isShow,
                position: newPosElm
                  ? { x: newPosElm.x, y: newPosElm.y }
                  : {
                      x: item?.x,
                      y: item?.y
                    }
              };
            });

            return new AddingCard({
              backgroundColor: item.backgroundColor,
              elementTexts: calculatePositionElement,
              isChanged: item.isChanged,
              frameData: {
                uid: '',
                start: item?.start,
                end: item?.end,
                name: item?.name,
                line: item?.line,
                // animation: item?.animation,
                position: newPos
                  ? { x: newPos.x, y: newPos.y }
                  : {
                      x: item?.x,
                      y: item?.y
                    },
                width: newSize.x,
                height: newSize.y,
                x: newPos.x,
                y: newPos.y,
                type: item?.elementTexts?.length > 1 ? ObjectType.card : ObjectType.graphic
              }
            });
          })) ||
        [],
      nextAspectRatio: data.crop?.expectRatio,
      subtitles: data.subtitles?.length ? data.subtitles : [],
      subtitlesInit: data.subtitlesInit?.length ? data.subtitlesInit : [],
      // subtitleStyle: data.subtitleStyle ? data.subtitleStyle : {}, // set default style - BE is 14px for default - FE is 40px
      subtitleStyle: {
        fontSize:
          data.subtitleStyle?.fontSize === '14px'
            ? '40px'
            : data.subtitleStyle?.fontSize
            ? data.subtitleStyle?.fontSize
            : '40px',
        backgroundColor: data.subtitleStyle?.backgroundColor
          ? data.subtitleStyle?.backgroundColor
          : 'rgba(255, 255, 255, 1)',
        color: data.subtitleStyle?.color ? data.subtitleStyle?.color : 'rgba(0, 0, 0, 1)'
      },
      contentId: contentType === 'STORY' ? contentId : data.videoId,
      contentType: data.contentType,
      platFromVideoId: data.platFromVideoId,
      title: data.title,
      selectedSubtitleLanguage: { label: 'en', langCode: 'en' },
      resolution: data?.resolution || RatioResolution[data.crop?.expectRatio],
      width: data?.width ? data?.width : FHDScreen.width,
      height: data?.height ? data?.height : FHDScreen.height,
      crop: data?.crop?.expectRatio
        ? {
            expectRatio: data?.crop?.expectRatio,
            x: data?.crop?.x,
            y: data?.crop?.y,
            width: data?.crop?.width,
            height: data?.crop?.height
          }
        : {},
      language: {
        name: data?.language?.name ? data?.language?.name : '',
        code: data?.language?.code ? data?.language?.code : ''
      },
      createdAt: data?.createdAt,
      backgroundColor: data?.backgroundColor
    };
  };

  const pushVersionDraftToState = (ver: any) => {
    const catchListVersion = [...listVersions];
    catchListVersion.splice(versionDraftSelected + 1, 0, ver);

    if (catchListVersion.length > MAX_STEPS) {
      dispatch(pushVersionDraft(catchListVersion?.slice(1, MAX_STEPS + 1)));
      // no need to update versionDraftSelected
    } else {
      dispatch(pushVersionDraft(catchListVersion));
      dispatch(setVersionDraftSelected(versionDraftSelected + 1));
    }
  };

  const doSaveDraft = async () => {
    const draft = buildDraftObj({ isExporting: false });
    if (!actionUndoRedo) {
      const updateState = await convertDraft(draft);
      pushVersionDraftToState(updateState);
    }
    dispatch(clearActUndoRedo());

    return request(saveDraft(draft));
  };

  return { doSaveDraft, buildDraftObj, convertDraft, pushVersionDraftToState };
};

export default useDraft;
