import React from 'react';

import { SVGProps } from './SVG.props';

const TextFrame = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 29} height={height || 28} viewBox="0 0 29 28" fill="none" {...props}>
      <path
        d="M0.25 14C0.25 2.66 2.91 0 14.25 0C25.59 0 28.25 2.66 28.25 14C28.25 25.34 25.59 28 14.25 28C2.91 28 0.25 25.34 0.25 14Z"
        fill={fillbg || "#F2F0F1"}
      />
      <g clipPath="url(#clip0_469_13859)">
        <path
          d="M11.9167 11.3333C11.5485 11.3333 11.25 11.6318 11.25 12C11.25 12.3681 11.5485 12.6666 11.9167 12.6666H13.5833V16.6666C13.5833 17.0348 13.8818 17.3333 14.25 17.3333C14.6182 17.3333 14.9167 17.0348 14.9167 16.6666V12.6666H16.5833C16.9515 12.6666 17.25 12.3681 17.25 12C17.25 11.6318 16.9515 11.3333 16.5833 11.3333H11.9167Z"
          fill={fill ||"#070550"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.58332 6.66663C7.21513 6.66663 6.91666 6.9651 6.91666 7.33329V9.99996C6.91666 10.3681 7.21513 10.6666 7.58332 10.6666H8.24999V17.3333H7.58332C7.21513 17.3333 6.91666 17.6318 6.91666 18V20.6666C6.91666 21.0348 7.21513 21.3333 7.58332 21.3333H10.25C10.6182 21.3333 10.9167 21.0348 10.9167 20.6666V20H17.5833V20.6666C17.5833 21.0348 17.8818 21.3333 18.25 21.3333H20.9167C21.2848 21.3333 21.5833 21.0348 21.5833 20.6666V18C21.5833 17.6318 21.2848 17.3333 20.9167 17.3333H20.25V10.6666H20.9167C21.2848 10.6666 21.5833 10.3681 21.5833 9.99996V7.33329C21.5833 6.9651 21.2848 6.66663 20.9167 6.66663H18.25C17.8818 6.66663 17.5833 6.9651 17.5833 7.33329V7.99996H10.9167V7.33329C10.9167 6.9651 10.6182 6.66663 10.25 6.66663H7.58332ZM9.58332 17.3333V10.6666H10.25C10.6182 10.6666 10.9167 10.3681 10.9167 9.99996V9.33329H17.5833V9.99996C17.5833 10.3681 17.8818 10.6666 18.25 10.6666H18.9167V17.3333H18.25C17.8818 17.3333 17.5833 17.6318 17.5833 18V18.6666H10.9167V18C10.9167 17.6318 10.6182 17.3333 10.25 17.3333H9.58332ZM9.58332 9.33329V7.99996H8.24999V9.33329H9.58332ZM20.25 7.99996V9.33329H18.9167V7.99996H20.25ZM20.25 20H18.9167V18.6666H20.25V20ZM8.24999 20V18.6666H9.58332V20H8.24999Z"
          fill={fill ||"#070550"}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_13859">
          <rect width="16" height="16" fill="white" transform="translate(6.25 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextFrame;
