import Typography from '@/components/Typography';
import styled from '@emotion/styled';
import * as React from 'react';

interface IRatioOptionsProps {
  value?: string;
  onChange?: (value: string) => void;
}

const options = [
  { label: 'Landscape 16:9', value: '16:9' },
  { label: 'Portrait 9:16', value: '9:16' },
  { label: 'Square 1:1', value: '4:5' }
];

const RatioOptions: React.FunctionComponent<IRatioOptionsProps> = (props) => {
  return (
    <>
      {options.map(({ label, value }) => {
        return (
          <OptionContainer key={value} selected={value === props.value} onClick={() => props.onChange?.(value)}>
            <Typography>{label}</Typography>
          </OptionContainer>
        );
      })}
    </>
  );
};

export default RatioOptions;

const OptionContainer = styled.div<{ selected?: boolean }>`
  padding: 8px;
  cursor: pointer;
  ${({ selected, theme }) => {
    return `
    background:${selected ? theme.brand.mint : ''};
    `;
  }}
  &:hover {
    background: ${({ theme }) => theme.brand.mint};
  }
`;
