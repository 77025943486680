import memoizeOne from 'memoize-one';

import { Theme } from '@/types/theme/Theme';

const getFontSize = memoizeOne(() => {
  const body = document.getElementsByTagName('body')[0];
  const style = window.getComputedStyle(body, null).getPropertyValue('font-size');
  return parseFloat(style);
});

export const pxToRem = (n: number): string => {
  return `${n / getFontSize()}em`;
};

export const theme: Theme = {
  brand: {
    primary: '#070550',
    secondary: '#91DBB6',
    tertiary: '#98E0E5',
    info: '#1877F2',
    alert: '#FFB8AD',
    success: '#00C48C',
    warning: '#FFA26B',
    error: '#FF7991',
    dove: '#FFB8AD',
    canary: '#FFC937',
    arctic: '#98E0E5',
    mint: '#91DBB6' //adding this color name as per figma duplicate of secondary
  },
  altBrand: {
    mint: '#91DBB6',
    canary: '#FFC937',
    arctic: '#98E0E5',
    dove: '#FFB8AD',
    tangerine: '#FF9A76',
    sky: '#9DABDD',
    mauve: '#D9B4ED',
    tea: '#AAE0B0',
    jungle: '#2FAF9C'
  },
  opacity: {
    mint: '#DCF3E8',
    canary: '#FFEEBF',
    sky: '#E0E4F4',
    mauve: '#F3E7F9',
    tea: '#E4F5E6',
    arctic: '#DEF5F7',
    canery: '#FFC937' //Add color same like story color
  },
  grey: {
    shade1: '#F9FAFF',
    shade2: '#EDEFFA',
    shade3: '#FAF7F8',
    shade4: '#DED9DB',
    shade5: '#BBC2DC',
    shade6: '#B5ADB0',
    shade7: '#070550', //replaced '#565A6A' as per figma,
    shade8: '#111E2B',
    shade9: '#09131D',
    noshade: '#FFFFFF'
  },
  altGrey: {
    shade1: '#FFFCFC',
    shade2: '#FAF7F8',
    shade3: '#F6F3F4',
    shade4: '#F0ECEE',
    shade5: '#DED9DB',
    shade6: '#B5ADB0',
    shade7: '#070550',
    shade8: '#05043D',
    shade9: '#070632',
    noshade: '#FFFFFF'
  },
  neutral: {
    black: '#090909',
    white: '#FFFFFF',
    N30: '#999999'
  },
  states: {
    hover: '#A1EAC5',
    pressed: '#7ED0A7'
  },
  product: {
    information: {
      primary: '#5487F5',
      light: '#E5EDFE'
    },
    success: {
      primary: '#00C48C',
      light: '#D9F6EE'
    },
    alert: {
      primary: '#F1B422',
      light: '#FFF8E7'
    },
    warning: {
      primary: '#FFA26B',
      light: '#FFF1E9'
    },
    error: {
      primary: '#FF647C',
      light: '#FFE8EB'
    }
  },
  breakpoints: {
    xl: 1920,
    lg: 1600,
    md: 1280,
    sm: 960,
    xs: 600,
    desktop: 1024,
    mobile: 768
  },
  zIndex: {
    speedDial: 1050,
    appBar: 1100,
    modal: 1300,
    snackbar: 1400,
    tooltip: 9999
  }
};

export default theme;
