import FadeToBlack from '@/assets/images/Transitions/FadeToBlack.png';
import FadeToWhite from '@/assets/images/Transitions/FadeToWhite.png';
import WipeLeft from '@/assets/images/Transitions/WipeLeft.png';
import WipeRight from '@/assets/images/Transitions/WipeRight.png';
import WipeUp from '@/assets/images/Transitions/WipeUp.png';
import NoneV2 from '@/assets/images/Transitions/NoneV2.png';
// import CrossDissolve from '@/assets/images/Transitions/CrossDissolve.png';
// import CrossBlur from '@/assets/images/Transitions/CrossBlur.png';
// import SpinIn from '@/assets/images/Transitions/SpinIn.png';
// import SpinOut from '@/assets/images/Transitions/SpinOut.png';
// import CircleOpen from '@/assets/images/Transitions/CircleOpen.png';
// import CircleClose from '@/assets/images/Transitions/CircleClose.png';
// import DoorWay from '@/assets/images/Transitions/DoorWay.png';
// import Swap from '@/assets/images/Transitions/Swap.png';
// import Cube from '@/assets/images/Transitions/Cube.png';
// import Mosaic from '@/assets/images/Transitions/Mosaic.png';
// import FadeInOut from '@/assets/images/Transitions/FadeInOut.png'

export const listTransitions = [
  {
    label: 'None',
    value: '',
    img: NoneV2
  },
  {
    label: 'Fade to Black',
    value: 'fadeblack',
    img: FadeToBlack
  },
  {
    label: 'Fade to White',
    value: 'fadewhite',
    img: FadeToWhite
  },
  {
    label: 'Wipe Left',
    value: 'wipeleft',
    img: WipeLeft
  },
  {
    label: 'Wipe Right',
    value: 'wiperight',
    img: WipeRight
  },
  {
    label: 'Wipe Up',
    value: 'wipeup',
    img: WipeUp
  }
  // {
  //   label: 'Cross Blur',
  //   value: 'crossBlur',
  //   img: CrossBlur
  // },
  // {
  //   label: 'Cross Dissolve',
  //   value: 'fadeToBlack',
  //   // value: 'crossDissolve',
  //   img: CrossDissolve
  // },
  // {
  //   label: 'Spin In',
  //   value: 'fadeToBlack',
  //   // value: 'SpinIn',
  //   img: SpinIn
  // },
  // {
  //   label: 'Spin Out',
  //   value: 'fadeToBlack',
  //   // value: 'SpinOut',
  //   img: SpinOut
  // },
  // {
  //   label: 'Circle Open',
  //   value: 'fadeToBlack',
  //   // value: 'circleOpen',
  //   img: CircleOpen
  // },
  // {
  //   label: 'Circle Close',
  //   value: 'fadeToBlack',
  //   // value: 'circleClose',
  //   img: CircleClose
  // },
  // {
  //   label: 'Doorway',
  //   value: 'fadeToBlack',
  //   // value: 'doorway',
  //   img: DoorWay
  // },
  // {
  //   label: 'Swap',
  //   value: 'fadeToBlack',
  //   // value: 'swap',
  //   img: Swap
  // },
  // {
  //   label: 'Cube',
  //   value: 'fadeToBlack',
  //   // value: 'cube',
  //   img: Cube
  // },
  // {
  //   label: 'Mosaic',
  //   value: 'fadeToBlack',
  //   // value: 'mosaic',
  //   img: Mosaic
  // }
  // {
  //   label: 'Fade In/Out',
  //   value: 'fadeInOut',
  //   img: FadeInOut
  // },
];
