import { v4 as uuidv4 } from 'uuid';
import { CropRatio } from '../store/videoSlice';
import { IFrameStatus } from './VideoFrame';

export enum PlayableType {
  audio = 'AUDIO',
  video = 'VIDEO'
}
export enum ObjectType {
  text = 'TEXT',
  image = 'IMAGE',
  card = 'CARD',
  graphic = 'GRAPHIC'
}

export interface FontConfig {
  category: string;
  family: string;
  files: any;
  kind?: string;
  lastModified: string;
  subset?: string[];
  variants?: string[];
  version?: string;
}

export interface Animation {
  type: string;
  start: number;
  duration: number;
}

export interface Transition {
  type: string;
  start: number;
  duration: number;
}

export interface Position {
  x: number;
  y: number;
}

export interface Card {
  uid: string;
  name?: string;
  value: string;
  isShow?: boolean;
  styles: React.CSSProperties & { fontName?: string } & { listFontFiles?: any } & { fontFile?: string };
}

export const FPS = 5;
export class ThumbnailBase {
  videoId?: string;
  videoLink: string;
  // duration?: number;
  // fps?: number;
  // resolutions?: string;
  constructor(value: ThumbnailBase) {
    this.videoId = value.videoId;
    this.videoLink = value.videoLink;
    // this.duration = value.duration;
    // this.fps = value.fps ? value.fps : FPS;
    // this.resolutions = value?.resolutions;
  }
}

class FrameItemBase {
  uid: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  start?: number;
  end?: number;
  name?: string;
  line?: number;
  animation?: Animation;
  transition?: Transition;
  position?: Position;
  type?: string;

  constructor(value?: IFrameStatus) {
    this.uid = value?.uid || uuidv4();
    this.width = value?.width || 0;
    this.height = value?.height || 0;
    this.x = value?.x || 0;
    this.y = value?.y || 0;
    this.start = value?.start || 0;
    this.end = value?.end || 0;
    this.name = value?.name || '';
    this.line = value?.line || 0;
    this.animation = value?.animation || {};
    this.transition = value?.transition || {};
    this.position = value?.position || { x: 0, y: 0 };
    this.type = value?.type;
  }
}

class AddingText extends FrameItemBase {
  value;
  styles;
  fontFile;
  constructor(
    value: string,
    styles: React.CSSProperties & { fontName?: string } & { listFontFiles?: any },
    fontFile?: FontConfig,
    frameStatus?: FrameItemBase
  ) {
    super(frameStatus);
    this.value = value;
    this.styles = styles;
    this.fontFile = fontFile;
  }
}

class AddingCard extends FrameItemBase {
  backgroundColor;
  elementTexts;
  isChanged = false;
  constructor({
    backgroundColor,
    elementTexts,
    isChanged,
    frameData
  }: {
    backgroundColor: string;
    elementTexts?: Card[];
    isChanged?: boolean;
    frameData?: FrameItemBase;
  }) {
    super(frameData);
    this.backgroundColor = backgroundColor;
    this.elementTexts = elementTexts;
    this.isChanged = !!isChanged;
  }
}

class AddingImage extends FrameItemBase {
  filePath;
  fileKey;
  isLogo;
  constructor(filePath: string, fileKey: string, isLogo?: boolean, frameStatus?: FrameItemBase) {
    super(frameStatus);
    this.filePath = filePath;
    this.fileKey = fileKey;
    this.isLogo = isLogo;
  }
}

export interface IThumbnail {
  start: number;
  urls: string;
}

export type SoundEffect = {
  type: string;
  duration: number;
};

class AddingPlayableItem extends FrameItemBase {
  filePath: string;
  type: PlayableType;
  duration: number;
  fileKey: string;
  trim: { start: number; end: number };
  resolution: { width: number; height: number };
  main: boolean;
  thumbnails: string[];
  line?: number;
  idSubtitleLinked?: string;
  volume: number;
  isHide?: boolean;
  crop?: CropRatio;
  width?: number | undefined;
  height?: number | undefined;
  zoom?: number | undefined;
  isApplyTransition?: boolean;
  effects?: SoundEffect[];
  blurBackground?: string;
  isBlurBackground?: boolean;
  isNeedApplyTransition?: boolean;
  constructor(propsData: any) {
    const {
      filePath,
      type,
      duration,
      fileKey,
      trim,
      resolution,
      main,
      thumbnails,
      line,
      idSubtitleLinked,
      volume,
      isHide,
      transition,
      crop,
      width,
      height,
      zoom,
      isApplyTransition,
      effects,
      blurBackground,
      isBlurBackground,
      frameStatus,
      isNeedApplyTransition
    } = propsData;

    super(frameStatus);
    this.filePath = filePath;
    this.type = type;
    this.duration = duration;
    this.fileKey = fileKey;
    this.trim = trim;
    this.resolution = resolution;
    this.main = main;
    this.thumbnails = thumbnails;
    this.line = line;
    this.idSubtitleLinked = idSubtitleLinked;
    this.volume = volume !== undefined && volume >= 0 ? volume : 100;
    this.isHide = isHide ? isHide : false;
    this.transition = transition ? transition : {};
    this.crop = crop || {
      x: 0,
      y: 0,
      width: '1920px',
      height: '1080px'
    };
    this.width = width || 1920;
    this.height = height || 1080;
    this.zoom = zoom || 1;
    this.isApplyTransition = isApplyTransition;
    this.effects = effects || [
      {
        duration: 0,
        type: 'fadein'
      },
      {
        duration: 0,
        type: 'fadeout'
      }
    ];
    this.blurBackground = blurBackground;
    this.isBlurBackground = isBlurBackground;
    this.isNeedApplyTransition = isNeedApplyTransition
  }
}

export { AddingImage, AddingText, AddingPlayableItem, AddingCard };
