import React from 'react';
import styled from '@emotion/styled';
import ColorPicker from '@/components/ColorPicker';

interface IProps {
  label?: string;
  listColorsBrandKit?: any[];
  selectedColor?: number;
  color?: string;
  onSelectColor?: (val: number) => void;
  setColorPicked?: (val: string) => void;
  handleStyleChange?: any;
  valueStyles?: any;
  isChangeTextColor?: boolean;
  isDisableAlpha?: boolean;
}

const ColourStyles: React.FC<IProps> = ({
  label,
  listColorsBrandKit,
  selectedColor,
  color,
  onSelectColor = () => null,
  setColorPicked = () => null,
  handleStyleChange,
  valueStyles,
  isChangeTextColor,
  isDisableAlpha
}) => {
  return (
    <ListColors>
      {label && <TextLabel style={{ marginTop: '0px' }}>{label}</TextLabel>}

      <ColorPicker
        containerStyle={{
          width: '100%',
          border: `${selectedColor === -1 ? '2px solid #7ED0A7' : ''}`,
          borderRadius: `${selectedColor === -1 ? '14px' : ''}`
        }}
        color={color || 'rgba(0,0,0,1)'}
        onColorChange={(color) => {
          if (handleStyleChange) {
            if (isChangeTextColor) {
              handleStyleChange({ ...valueStyles, color: color }, valueStyles?.uid);
            } else {
              handleStyleChange({ ...valueStyles, backgroundColor: color }, valueStyles?.uid);
            }
          }
          setColorPicked(color);
        }}
        onSelectColor={onSelectColor}
        selected={selectedColor === -1}
        bgColor="#fff"
        rightPostition={5}
        disableAlpha={isDisableAlpha}
      />
      <ListColourContainer>
        {listColorsBrandKit?.map((item, idx) => (
          <ColourBrandKit
            key={idx}
            bgColor={item}
            selected={idx === selectedColor ? selectedColor : -2}
            onClick={() => {
              onSelectColor(idx);
              setColorPicked(item);
              if (handleStyleChange) {
                if (isChangeTextColor) {
                  handleStyleChange({ ...valueStyles, color: item }, valueStyles?.uid);
                } else {
                  handleStyleChange({ ...valueStyles, backgroundColor: item }, valueStyles?.uid);
                }
              }
            }}
          />
        ))}
      </ListColourContainer>
    </ListColors>
  );
};

export default ColourStyles;

const ListColors = styled.div`
  width: 100%;
`;

const TextLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey.shade7};
`;

const ListColourContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const ColourBrandKit = styled.div<{ bgColor?: string; selected?: any }>`
  width: 72px;
  height: 24px;
  max-width: 72px;
  background-color: ${({ bgColor }) => bgColor};
  outline: ${({ selected }) => (selected >= 0 ? '2px solid #7ED0A7' : 'none')};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  margin-right: 4px;
`;
