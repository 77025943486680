import React from 'react';

import { SVGProps } from './SVG.props';

const MagicWand = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M8.33337 3.1665C8.88566 3.1665 9.33337 2.71879 9.33337 2.1665C9.33337 1.61422 8.88566 1.1665 8.33337 1.1665C7.78109 1.1665 7.33337 1.61422 7.33337 2.1665C7.33337 2.71879 7.78109 3.1665 8.33337 3.1665Z"
        fill={fill || "#070550"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4143 4.97126C10.6332 4.19021 9.36688 4.19021 8.58583 4.97126L1.80478 11.7523C1.02373 12.5334 1.02373 13.7997 1.80478 14.5807L1.91916 14.6951C2.70021 15.4762 3.96654 15.4762 4.74759 14.6951L11.5286 7.91407C12.3097 7.13302 12.3097 5.86669 11.5286 5.08564L11.4143 4.97126ZM9.52864 5.91407C9.78899 5.65372 10.2111 5.65372 10.4714 5.91407L10.5858 6.02845C10.8462 6.2888 10.8462 6.71091 10.5858 6.97126L9.33337 8.22371L8.27618 7.16652L9.52864 5.91407ZM7.33337 8.10933L8.39057 9.16652L3.80478 13.7523C3.54443 14.0127 3.12232 14.0127 2.86197 13.7523L2.74759 13.6379C2.48724 13.3776 2.48724 12.9555 2.74759 12.6951L7.33337 8.10933Z"
        fill={fill || "#070550"}
      />
      <path
        d="M14.6667 10.4999C15.0349 10.4999 15.3334 10.2014 15.3334 9.83319C15.3334 9.465 15.0349 9.16652 14.6667 9.16652H14V8.49984C14 8.13165 13.7016 7.83317 13.3334 7.83317C12.9652 7.83317 12.6667 8.13165 12.6667 8.49984V9.16652H12C11.6319 9.16652 11.3334 9.465 11.3334 9.83319C11.3334 10.2014 11.6319 10.4999 12 10.4999H12.6667V11.1665C12.6667 11.5347 12.9652 11.8332 13.3334 11.8332C13.7016 11.8332 14 11.5347 14 11.1665V10.4999H14.6667Z"
        fill={fill || "#070550"}
      />
      <path
        d="M15.3334 3.16652C15.3334 3.53471 15.0349 3.83319 14.6667 3.83319H14V4.49984C14 4.86803 13.7016 5.1665 13.3334 5.1665C12.9652 5.1665 12.6667 4.86803 12.6667 4.49984V3.83319H12C11.6319 3.83319 11.3334 3.53471 11.3334 3.16652C11.3334 2.79833 11.6319 2.49986 12 2.49986L12.6667 2.49986V1.83317C12.6667 1.46498 12.9652 1.1665 13.3334 1.1665C13.7016 1.1665 14 1.46498 14 1.83317V2.49986L14.6667 2.49986C15.0349 2.49986 15.3334 2.79833 15.3334 3.16652Z"
        fill={fill || "#070550"}
      />
    </svg>
  );
};

export default MagicWand;
