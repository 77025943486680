import React from 'react';

import { SVGProps } from './SVG.props';

const Card = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 29} height={height || 28} viewBox="0 0 29 28" fill="none" {...props}>
      <path
        d="M0.75 14C0.75 2.66 3.41 0 14.75 0C26.09 0 28.75 2.66 28.75 14C28.75 25.34 26.09 28 14.75 28C3.41 28 0.75 25.34 0.75 14Z"
        fill={fillbg || '#F2F0F1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8792 8.49445C15.7839 8.13881 15.4183 7.92776 15.0627 8.02305L9.91108 9.40342C9.55544 9.49871 9.34438 9.86427 9.43968 10.2199L11.8553 19.2352C11.9506 19.5909 12.3162 19.8019 12.6718 19.7066L17.8234 18.3263C18.1791 18.231 18.3901 17.8654 18.2948 17.5098L15.8792 8.49445ZM17.1116 7.97431C16.7585 7.01441 15.7248 6.46527 14.7176 6.73515L9.56599 8.11552C8.49906 8.4014 7.86589 9.49807 8.15177 10.565L10.5674 19.5803C10.8533 20.6472 11.95 21.2804 13.0169 20.9945L18.1685 19.6142C18.9799 19.3968 19.5404 18.7105 19.6366 17.9249C19.6475 17.8998 19.657 17.8737 19.6649 17.8467L21.5857 11.2919C21.9015 10.2144 21.2686 9.08817 20.1841 8.79758L17.1116 7.97431ZM19.0538 15.1907L20.3062 10.9169C20.4114 10.5577 20.2005 10.1823 19.839 10.0855L17.5193 9.46393L19.0538 15.1907Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Card;
