import React from 'react';

import { SVGProps } from './SVG.props';

const CropFrame = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_469_7147)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.00001 1.66659C5.00001 1.20635 4.62691 0.833252 4.16668 0.833252C3.70644 0.833252 3.33334 1.20635 3.33334 1.66659V3.33325H1.66668C1.20644 3.33325 0.833344 3.70635 0.833344 4.16658C0.833344 4.62682 1.20644 4.99992 1.66668 4.99992H3.33334V15.8333C3.33334 16.2935 3.70644 16.6666 4.16668 16.6666H15V18.3333C15 18.7935 15.3731 19.1666 15.8333 19.1666C16.2936 19.1666 16.6667 18.7935 16.6667 18.3333V16.6666H18.3333C18.7936 16.6666 19.1667 16.2935 19.1667 15.8333C19.1667 15.373 18.7936 14.9999 18.3333 14.9999H16.6667V4.16658C16.6667 3.70635 16.2936 3.33325 15.8333 3.33325H5.00001V1.66659ZM5.00001 4.99992V14.9999H15V4.99992H5.00001Z"
          fill={fill || "#030220"}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_7147">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CropFrame;
