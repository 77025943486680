import React, { useMemo } from 'react';

import { SegmentedControlsProps } from './SegmentedControls.props';
import { Container, TabItems, TabName } from './SegmentedControls.style';

const SegmentedControls = (props: SegmentedControlsProps): JSX.Element => {
  const {
    options,
    optionsText,
    selectedOption,
    onClickControl,
    clearTop = false,
    size = 'medium',
    optionsShowNotification,
    style,
  } = props;

  const getFontSize = useMemo(() => {
    switch (size) {
      case 'small':
        return { lineHeight: '16px', fontSize: 12 };
      default:
        return { lineHeight: '24px', fontSize: 14 };
    }
  }, [size]);

  return (
    <Container clearTop={clearTop} style={style}>
      {options.map((_, index) => (
        <TabItems
          key={index}
          showNotificationDot={
            optionsShowNotification
              ? optionsShowNotification?.includes(_)
              : null
          }
          active={_ === selectedOption}
          onClick={() => onClickControl(_)}
        >
          <TabName style={{ ...getFontSize }}>
            {optionsText &&
            Array.isArray(optionsText) &&
            optionsText.length === options.length
              ? optionsText[index]
              : _}
          </TabName>
        </TabItems>
      ))}
    </Container>
  );
};

export default React.memo(SegmentedControls);
