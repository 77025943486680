/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';

import { createPortal } from 'react-dom';

import { ToolTipProps } from './ToolTip.props';
import { Container, Content, ToolTipArrow } from './ToolTip.style';

const ToolTip = (props: ToolTipProps): JSX.Element => {
  const {
    active: isActive = false,
    content,
    children,
    widthTooltip,
    disabled,
    maxWidth,
    maxHeight,
    background,
    position,
    onClick,
    showOnClick,
    hoverColor,
    style,
    checkConstantly,
  } = props;

  const [active, setActive] = useState(isActive);
  const [mouseDown, setMouseDown] = useState(false);
  const [direction, setDirection] = useState({
    horizontal: 'left',
    vertical: 'bottom',
  });
  const childRef: React.RefObject<any> = useRef();


  useEffect(()=> {
    if (childRef.current && childRef.current?.getBoundingClientRect && checkConstantly) {
      checkPosition(childRef.current?.getBoundingClientRect());
    }
  }, [checkConstantly])

  useEffect(()=> {
    const handleMouseUp = () => {
      setActive(false)
      setMouseDown(false)
      
    }
    window.addEventListener('mouseup',handleMouseUp)
    return () => {
      window.removeEventListener('mouseup',handleMouseUp)
    }
  },[])

  const checkPosition = (coordinates: any) => {
    if (coordinates) {
      const { height, bottom, left, top } = coordinates;
      // eslint-disable-next-line no-debugger
      // debugger
      const passMiddleVerticalScreen =
        bottom - height / 2 > window.innerHeight / 2;
      // const passMiddleHorizontalScreen =
      //   right - width / 2 > window.innerWidth / 2;
      // const getHorizontal = horizontalPos
      //   ? horizontalPos
      //   : passMiddleHorizontalScreen
      //     ? 'right'
      //     : 'left';
      const getVertical =
        position || passMiddleVerticalScreen ? 'top' : 'bottom';
      const direction = {
        horizontal: 'left',
        vertical: getVertical,
        ...(passMiddleVerticalScreen || position === 'top'
          ? { bottom: `${window.innerHeight - top + 16}px`, top: 'unset' }
          : { top: `${bottom + 16}px`, bottom: 'unset' }),
        // //@ts-ignore
        // ...(passMiddleHorizontalScreen && horizontalPos !== 'left'
        //   ? { left: 'unset', right: `${window.innerWidth - right - 20}px` }
        //   : { left: `${left - (widthTooltip / 2) + width}px`, right: 'unset' }),
        left: `${left - (widthTooltip / 2) + 12}px`,
        // left: `${left - (widthTooltip / 2)}px`,
        right: 'unset'
      };
      setDirection(direction);
    }
  };

  const showTip = () => {
    if (childRef.current && childRef.current?.getBoundingClientRect) {
      checkPosition(childRef.current?.getBoundingClientRect());
    }
    if (!disabled && !isActive) setActive(true);
  };

  const hideTip = () => {
    if (!disabled && !isActive && !mouseDown) setActive(false);
  };

  const onClickButton = () => {
    if (active) {
      showTip();
    } else {
      hideTip();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Container
        onMouseEnter={!showOnClick ? showTip : undefined}
        onMouseLeave={!showOnClick ? hideTip : undefined}
        onMouseDown={()=> setMouseDown(true)}
        onClick={onClickButton}
        ref={childRef}
        hoverColor={hoverColor}
        disabled={disabled}
        style={style}
      >
        {React.Children.map(children, (child: any) => {
          return React.cloneElement(child);
        })}
      </Container>
      {active &&
        content &&
        createPortal(
          <ToolTipArrow
            className='tooltip-arrow'
            direction={direction}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            width={widthTooltip}
            background={background}
          >
            {typeof content === 'object' ? (
              content
            ) : (
              <Content
                altVariant="caption"
                altColor={'shade1'}
                weight="400"
              >
                {content}
              </Content>
            )}
          </ToolTipArrow>,
          document.body,
        )}
    </>
  );
};

export default ToolTip;
