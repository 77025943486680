import { RootState } from '@/app/store';
import { createSlice } from '@reduxjs/toolkit';

export const storey = createSlice({
  name: 'stories',
  initialState: {
    stories: []
  },
  reducers: {
    updateStory: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const selectStories = (state: RootState) => {
  return state.stories.stories;
};

export const { updateStory } = storey.actions;

export default storey.reducer;
