import React from 'react';

import { SVGProps } from './SVG.props';

const Italic = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 18} height={height || 19} viewBox="0 0 18 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6538 4.6884C11.0032 4.81057 11.192 5.20693 11.0757 5.57371L8.189 14.6734C8.07265 15.0402 7.69515 15.2384 7.34582 15.1163C6.9965 14.9941 6.80764 14.5978 6.92399 14.231L9.81066 5.13131C9.92701 4.76453 10.3045 4.56624 10.6538 4.6884Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 5.35251C8.66667 4.96593 8.96514 4.65254 9.33333 4.65254H11.3333C11.7015 4.65254 12 4.96593 12 5.35251C12 5.7391 11.7015 6.05249 11.3333 6.05249H9.33333C8.96514 6.05249 8.66667 5.7391 8.66667 5.35251Z"
        fill={fill || '#070550'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.4522C6 14.0656 6.29848 13.7522 6.66667 13.7522H8.66667C9.03486 13.7522 9.33333 14.0656 9.33333 14.4522C9.33333 14.8388 9.03486 15.1522 8.66667 15.1522H6.66667C6.29848 15.1522 6 14.8388 6 14.4522Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Italic;
