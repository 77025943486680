import React from 'react';
import { DropdownArrow } from '@/components/SVG';

import Select, { components } from 'react-select';
import { StylesConfig } from 'react-select';
import { useTheme } from '@emotion/react';

interface IGroupDropdownProps {
  options: any;
  width?: string;
  defaultValue?: string;
  value?: string;
  valueBrandKit?: string | any;
  onChange?: (val: any) => void;
  bgColor?: string;
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow fill="#070550" />
    </components.DropdownIndicator>
  );
};

const GroupDropdown: React.FunctionComponent<IGroupDropdownProps> = ({
  options,
  width,
  value,
  valueBrandKit,
  onChange,
  bgColor
}) => {
  const theme = useTheme();

  const commonStyles: StylesConfig<any, any> = {
    control: (styles, state) => ({
      ...styles,
      borderRadius: '12px',
      backgroundColor: bgColor ? bgColor : theme.grey.shade3,
      fontFamily: 'Objective',
      fontSize: '14px',
      lineHeight: '24px',
      color: theme.grey.shade7,
      boxShadow: 'none',
      border: 'none'
      // width: width
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: theme.grey.shade7,
      fontWeight: 500
      // width: width
    }),
    option: (provided: any, state) => ({
      ...provided,
      color: theme.grey.shade7,
      fontFamily: 'Objective',
      fontSize: '14px',
      lineHeight: '24px',
      // width: width,

      backgroundColor: state.isSelected
        ? theme.brand.secondary
        : state.isFocused
        ? theme.grey.shade2
        : theme.grey.noshade,
      ':active': {
        ...provided[':active'],
        backgroundColor: theme.brand.secondary,
        fontWeight: 500
      }
    }),
    menuList: (provided: any, state) => ({
      ...provided,
      height: '220px'
      // width: width
    }),
    valueContainer: (provided: any, state) => ({
      ...provided,
      paddingTop: '9.5px',
      paddingBottom: '9.5px'
    }),
    input: (provided: any, state) => ({
      ...provided,
      padding: '0',
      margin: '0'
    }),
    placeholder: (provided: any, state) => ({
      ...provided,
      color: theme.grey.shade7
    }),
    indicatorSeparator: () => ({ display: 'none' })
  };

  const [initVal, setInitVal] = React.useState<any>(value || valueBrandKit);

  React.useEffect(() => {
    setInitVal(value || valueBrandKit);
  }, [value, valueBrandKit]);

  return (
    <div style={{ width: width }}>
      <Select
        styles={commonStyles}
        components={{ DropdownIndicator }}
        placeholder={'Select font'}
        options={options}
        value={initVal}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
      />
    </div>
  );
};
export default React.memo(GroupDropdown);
