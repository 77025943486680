import WebFont from 'webfontloader';

const Fonts = (font: string): any => {
  if (!font) return;
  WebFont.load({
    google: {
      families: [font]
    }
  });
};

export default Fonts;
