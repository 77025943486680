/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { Sound2 } from '@/components/SVG';
import MuteVolume from '@/components/SVG/MuteVolume';
import Volume from '@/components/SVG/Volume';
import Typography from '@/components/Typography';
import { AddingPlayableItem, PlayableType } from '@/features/video/models/FrameItem';
import { selectVideo, update, updatePlayableItem } from '@/features/video/store/videoSlice';
import Slider from 'antd/lib/slider';
import { AudioName, AudioVolume, Container, EditAudioText } from './styles';
import { VideoService } from '@/features/video/services/videoService';

const EditAudio = () => {
  const dispatch = useAppDispatch();
  const { addingPlayableItems, audioSelected } = useAppSelector(selectVideo);
  const [inputValue, setInputValue] = useState(100);
  const [fadeIn, setFadeIn] = useState(0);
  const [fadeOut, setFadeOut] = useState(0);

  const audio = useMemo(() => {
    return addingPlayableItems.filter((x) => !x.isHide).find((item) => item.uid === audioSelected.uid);
  }, [audioSelected?.uid, addingPlayableItems]);

  useEffect(() => {
    if (audio) {
      setInputValue(audio.volume);
      if (audio.effects && audio.effects[0]) {
        setFadeIn(audio.effects[0].duration * 10);
      }
      if (audio.effects && audio.effects[1]) {
        setFadeOut(audio.effects[1].duration * 10);
      }
    }
  }, [audio]);

  const onChange = (newValue: number) => {
    setInputValue(newValue);
    dispatch(
      update({
        audioSelected: {
          uid: audioSelected?.uid,
          volume: newValue
        }
      })
    );
    if (audio) {
      const item: AddingPlayableItem = { ...audio };
      item.volume = newValue;
      dispatch(updatePlayableItem(item));
    }
  };

  const onMuteVolume = () => {
    if (audio?.volume && audioSelected?.uid) {
      onChange(0);
    } else {
      onChange(100);
    }
  };

  const onFadeIn = (newValue: number) => {
    if (audio) {
      const item: AddingPlayableItem = JSON.parse(JSON.stringify(audio));
      if (item.effects && item.effects[0]) {
        item.effects[0].duration = newValue / 10; // million second
        item.effects[0].type = 'fadein';
      }
      dispatch(updatePlayableItem(item));
    }
    // setFadeIn(newValue / 10)
  };

  const onFadeOut = (newValue: number) => {
    if (audio) {
      const item: AddingPlayableItem = JSON.parse(JSON.stringify(audio));
      if (item.effects && item.effects[1]) {
        item.effects[1].duration = newValue / 10; // million second
        item.effects[1].type = 'fadeout';
      }
      dispatch(updatePlayableItem(item));
    }
    // setFadeOut(newValue / 10)
  };
  const formatter = (value: number | any) => `${VideoService.convertDisplayMilliSecond(value / 10)}s`;

  return (
    <Container>
      <EditAudioText>Edit audio</EditAudioText>
      <AudioName>
        <Typography variant="overline" color="shade6">
          {audio?.name}
        </Typography>
      </AudioName>
      <div style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}>
        <Typography variant="overline" color="shade6" style={{ lineHeight: '16px', marginBottom: '4px' }}>
          volume
        </Typography>
        <AudioVolume>
          {audio?.volume ? (
            <Volume style={{ cursor: 'pointer' }} onClick={onMuteVolume} />
          ) : (
            <MuteVolume style={{ cursor: 'pointer' }} onClick={onMuteVolume} />
          )}

          <div style={{ flex: 1, marginLeft: '4px' }}>
            <Slider min={0} max={100} onChange={onChange} value={typeof inputValue === 'number' ? inputValue : 0} />
          </div>
        </AudioVolume>
      </div>
      {/* remove FALSE to OPEN this component */}
      {false && audioSelected.type === PlayableType.audio && (
        <>
          <div style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}>
            <Typography variant="overline" color="shade6" style={{ lineHeight: '16px', marginBottom: '4px' }}>
              Fade In
            </Typography>
            <AudioVolume>
              <Sound2 />

              <div style={{ flex: 1, marginLeft: '4px' }}>
                <Slider
                  min={0}
                  max={50}
                  onChange={onFadeIn}
                  value={typeof fadeIn === 'number' ? fadeIn : 0}
                  tooltip={{ formatter }}
                />
              </div>
            </AudioVolume>
          </div>
          <div style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}>
            <Typography variant="overline" color="shade6" style={{ lineHeight: '16px', marginBottom: '4px' }}>
              Fade Out
            </Typography>
            <AudioVolume>
              {/* {audio?.volume ? (
            <Volume style={{ cursor: 'pointer' }} onClick={onMuteVolume} />
          ) : (
            <MuteVolume style={{ cursor: 'pointer' }} onClick={onMuteVolume} />
          )} */}
              <Sound2 />
              <div style={{ flex: 1, marginLeft: '4px' }}>
                <Slider
                  min={0}
                  max={50}
                  onChange={onFadeOut}
                  value={typeof fadeOut === 'number' ? fadeOut : 0}
                  tooltip={{ formatter }}
                />
              </div>
            </AudioVolume>
          </div>
        </>
      )}
    </Container>
  );
};
export default EditAudio;
