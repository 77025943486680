import React, { useRef, useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { useTheme } from '@emotion/react';
import Typography from '@/components/Typography';
import { FileUploadIcon } from '../SVG';

import { FileUploadProps } from './FileUpload.props';
import { Container, DropContainer, DropContainerSimple, DropContent, SVGContainer } from './FileUpload.style';

const FILE_TYPE_MAPPING: Record<string, string> = {
  image: 'image/*',
  video: 'video/*',
  audio: 'audio/*',
  doc: '.doc, docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  srt: '.srt',
  vtt: '.vtt',
  ttf: '.ttf, font/ttf',
  otf: '.otf, font/otf',
  woff: '.woff, font/woff',
  woff2: '.woff2, font/woff2'
};

export const FILE_TYPE_VALIDATE: Record<string, (type: string) => boolean> = {
  image: (type) => type.includes('image'),
  video: (type) => type.includes('video'),
  audio: (type) => type.includes('audio'),
  doc: (type) => FILE_TYPE_MAPPING.video.includes(type),
  ttf: (type) => FILE_TYPE_MAPPING.video.includes(type),
  otf: (type) => FILE_TYPE_MAPPING.video.includes(type),
  woff: (type) => FILE_TYPE_MAPPING.video.includes(type),
  woff2: (type) => FILE_TYPE_MAPPING.video.includes(type)
};

const FileUpload = (props: FileUploadProps): JSX.Element => {
  const {
    onClick,
    Svg = FileUploadIcon,
    currentPhotos,
    isMultiple,
    allowTypes = ['image', 'video', 'doc', 'audio', 'srt', 'vtt', 'ttf', 'otf', 'woff', 'woff2'],
    uploadedFiles = [],
    subLabel,
    handleDrop,
    isLoading,
    CustomDropContainer,
    dropSection,
    isSimpleFile,
    height,
    callBackClearFiles
  } = props;

  const theme = useTheme();

  const filePicker = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef<HTMLInputElement | null>(null);
  const callbackRef = useRef(callBackClearFiles);

  const [files, setFiles] = useState<File[]>(uploadedFiles);

  const acceptType = useMemo(() => {
    return allowTypes
      ?.map((allowType) => {
        return FILE_TYPE_MAPPING[allowType];
      })
      .filter(Boolean)
      .join(',');
  }, [allowTypes]);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDropHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const imageFiles: File[] = [];
        for (let x = 0; x < e.dataTransfer.files.length; x++) {
          const isValidType = allowTypes.some((allowType) => {
            return FILE_TYPE_VALIDATE[allowType]?.(e.dataTransfer.files[x].type);
          });
          if (isValidType) {
            imageFiles.push(e.dataTransfer.files[x]);
          }
        }

        setFiles(imageFiles);
        handleDrop?.(imageFiles);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowTypes]
  );

  const handleOnClick = () => {
    // handle image
    if (filePicker && filePicker.current) {
      filePicker.current.click();
    }
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadingFiles = Array.from(e.target.files || []);

    setFiles(uploadingFiles);
    handleDrop?.(uploadingFiles);
  };

  useEffect(() => {
    const div = dropRef.current;
    if (div !== null) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', (event) => handleDropHandler(event));
    }

    return () => {
      if (div !== null) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDropHandler);
      }
    };
  }, [currentPhotos, allowTypes, handleDropHandler]);

  useLayoutEffect(() => {
    if (!uploadedFiles?.length) {
      callbackRef.current = callBackClearFiles;
      setFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callBackClearFiles]);

  return (
    <>
      <input
        ref={filePicker}
        type="file"
        hidden
        name="test"
        onChange={handleFileChange}
        multiple={isMultiple}
        accept={acceptType}
        disabled={isLoading}
      />
      <Container>
        {CustomDropContainer ? (
          <CustomDropContainer onClick={handleOnClick} ref={dropRef}>
            {dropSection}
          </CustomDropContainer>
        ) : !isSimpleFile ? (
          <DropContainer onClick={handleOnClick} ref={dropRef}>
            <DropContent>
              <SVGContainer>
                <Svg />
              </SVGContainer>
              {isLoading ? (
                <Typography variant="label" color="shade6">
                  Uploading...
                </Typography>
              ) : files.length ? (
                files.map((file) => {
                  return (
                    <Typography style={{ marginLeft: '12px' }} variant="label">
                      {file?.name}
                    </Typography>
                  );
                })
              ) : (
                <>
                  <Typography
                    variant="label"
                    color="shade6"
                    style={{
                      display: 'inline-flex',
                      marginTop: '16px',
                      gap: '5px'
                    }}
                  >
                    Drag & drop or <span style={{ color: theme.grey.shade7 }}>Browse</span> files to upload!
                  </Typography>
                  <Typography variant="label" color="shade6">
                    {subLabel}
                  </Typography>
                </>
              )}
            </DropContent>
          </DropContainer>
        ) : (
          <DropContainerSimple height={height} onClick={handleOnClick} ref={dropRef}>
            <DropContent>
              {isLoading ? (
                <Typography variant="label" color="shade6">
                  Uploading...
                </Typography>
              ) : files?.length ? (
                files.map((file) => {
                  return (
                    <Typography style={{ marginLeft: '12px' }} variant="label">
                      {file?.name}
                    </Typography>
                  );
                })
              ) : (
                <>
                  <Typography
                    variant="label"
                    color="shade6"
                    style={{
                      display: 'inline-flex',
                      marginTop: '3px',
                      gap: '5px'
                    }}
                  >
                    Drag & drop or <span style={{ color: theme.grey.shade7 }}>Browse</span> files to upload!
                  </Typography>
                  <Typography variant="label" color="shade6">
                    {subLabel}
                  </Typography>
                </>
              )}
            </DropContent>
          </DropContainerSimple>
        )}
      </Container>
    </>
  );
};

export default React.memo(FileUpload);
