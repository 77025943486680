import type { CSSProperties, FC } from 'react'
import { memo,} from 'react'

const getStyle = ({width, height}: any): CSSProperties => {
  // debugger
  return {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: "rgb(220, 243, 232)",
    borderRadius: "8px",
    border:"2px solid rgb(145, 219, 182)",
    opacity: 0.5,
  }
}

export interface BoxDragPreviewProps {
 // title: string
 itemId: any,
}

export const BoxDragPreview: FC<BoxDragPreviewProps> = memo(
  function BoxDragPreview({itemId}) {
    const elm = document.querySelector(`[id='time-box-${itemId}']`)as HTMLElement;
    const elmBounding = elm?.getBoundingClientRect()
    return (
      <div style={getStyle({width: elmBounding?.width, height: elmBounding?.height})}> 
      </div>
    )
  },
)
