import React from 'react';

import { SVGProps } from './SVG.props';

const Subtitle = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 24} height={height || 25} viewBox="0 0 24 25" fill="none" {...props}>
      <g>
        <path
          d="M0 12.8906C0 3.17062 2.28 0.890625 12 0.890625C21.72 0.890625 24 3.17062 24 12.8906C24 22.6106 21.72 24.8906 12 24.8906C2.28 24.8906 0 22.6106 0 12.8906Z"
          fill={fillbg || 'white'}
        />
      </g>
      <path
        d="M8.5 10.8906H15.5C15.6326 10.8906 15.7598 10.8379 15.8536 10.7442C15.9473 10.6504 16 10.5232 16 10.3906C16 10.258 15.9473 10.1308 15.8536 10.0371C15.7598 9.9433 15.6326 9.89062 15.5 9.89062H8.5C8.36739 9.89062 8.24021 9.9433 8.14645 10.0371C8.05268 10.1308 8 10.258 8 10.3906C8 10.5232 8.05268 10.6504 8.14645 10.7442C8.24021 10.8379 8.36739 10.8906 8.5 10.8906ZM16.5 12.3906H7.5C7.36739 12.3906 7.24021 12.4433 7.14645 12.5371C7.05268 12.6308 7 12.758 7 12.8906C7 13.0232 7.05268 13.1504 7.14645 13.2442C7.24021 13.3379 7.36739 13.3906 7.5 13.3906H16.5C16.6326 13.3906 16.7598 13.3379 16.8536 13.2442C16.9473 13.1504 17 13.0232 17 12.8906C17 12.758 16.9473 12.6308 16.8536 12.5371C16.7598 12.4433 16.6326 12.3906 16.5 12.3906ZM15.5 14.8906H8.5C8.36739 14.8906 8.24021 14.9433 8.14645 15.0371C8.05268 15.1308 8 15.258 8 15.3906C8 15.5232 8.05268 15.6504 8.14645 15.7442C8.24021 15.8379 8.36739 15.8906 8.5 15.8906H15.5C15.6326 15.8906 15.7598 15.8379 15.8536 15.7442C15.9473 15.6504 16 15.5232 16 15.3906C16 15.258 15.9473 15.1308 15.8536 15.0371C15.7598 14.9433 15.6326 14.8906 15.5 14.8906Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Subtitle;
