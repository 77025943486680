import axios from 'axios';
import { API } from '@/const';

const instance = axios.create({
  baseURL: API.URL
});

instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error) => {
    const { status } = error?.response || {};
    if (status === 401) {
    }
  }
);

export const createAxiosInstance = () => {
  return instance;
};
