import React from 'react';

import { CheckboxListProps } from './CheckboxList.props';
import { Container, Checkbox } from './CheckboxList.style';
import Typography from '../Typography';
import TickBox from '../TickBox';

const CheckboxList = (props: CheckboxListProps): JSX.Element => {
  const { list, selected, onCheckOption = () => null } = props;

  const isChecked = (checkbox: any) => {
    return selected.find((item: any) => item.value === checkbox.value);
  };

  const checkOptionHandler = (checkbox: any) => {
    const isChecking = isChecked(checkbox);
    if (isChecking) {
      const newSelected = selected.filter((item: any) => item.value !== checkbox.value);
      onCheckOption(newSelected);
    } else if (!checkbox.isDisabled) {
      onCheckOption([...selected, checkbox]);
    }
  };

  return (
    <Container>
      {list.map((checkbox, key) => (
        <Checkbox key={key}>
          <TickBox
            disabled={checkbox.isDisabled}
            checked={!!isChecked(checkbox)}
            onClick={() => checkOptionHandler(checkbox)}
          />
          <Typography
            variant="body"
            altColor="shade7"
            style={{ fontSize: '12px', fontWeight: '600', color: checkbox.isDisabled ? '#B5ADB0' : '' }}
          >
            {checkbox.label}
          </Typography>
        </Checkbox>
      ))}
    </Container>
  );
};

export default React.memo(CheckboxList);
