import styled from '@emotion/styled';

import Typography from '../Typography';

export const Container = styled.div<{
  clearTop: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: ${({ clearTop }) => (clearTop ? 0 : 20)}px;
`;

export const TabItems = styled.div<{
  active?: boolean;
  showNotificationDot?: boolean | null;
}>`
  padding: 8px 12px;
  border-bottom: 2px solid ${({ theme }) => theme.grey.shade4};
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 12px;
    height: 12px;
  }

  ${({ active, theme }) =>
    active
      ? `
    border-bottom: 2px solid ${theme.brand.mint};
`
      : null}

  ${({ showNotificationDot, theme }) => {
    if (showNotificationDot) {
      return `
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: ${theme.product.error.primary};
      }
      `;
    }
  }}
`;

export const TabName = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.grey.shade7};
`;
