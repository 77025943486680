/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import { SubtitleDetail } from '@/features/video/models/VideoFrame';
import useRequest from '@/components/hooks/useRequest';
import { editSubtitle, getAllSubtitles } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { selectSubtitleDetail, getSubtitles } from '@/features/video/store/subtitleSlice';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { selectVideo, update } from '@/features/video/store/videoSlice';

interface IEditSubtitleProps {
  isOpen: boolean;
  onClickClose: () => void;
  subtitleDetail?: SubtitleDetail;
  refetchSubtitle?: () => void;
  subtitleId: number;
}

const EditSubtitleModal: React.FunctionComponent<IEditSubtitleProps> = ({
  isOpen,
  onClickClose,
  subtitleDetail,
  refetchSubtitle = () => null,
  subtitleId
}) => {
  const { request } = useRequest();
  const theme = useTheme();
  const [subtitleText, setSubtileText] = React.useState<string | any>(subtitleDetail?.name);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const { subtitles, subtitlesInit } = useAppSelector(selectVideo);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const subtitleLinked = subtitles?.some((i) => i?.id?.includes(subtitleDetail?.id));
    const updatingSub = subtitleDetail?.transcribe?.find((item) => item.id === subtitleId);
    const captionEdited = {
      id: updatingSub?.id,
      start: updatingSub?.start,
      end: updatingSub?.end,
      sub: subtitleText
    };
    const bodyReq = {
      transcribe: getNewPayloadListTranscribes(captionEdited)
    };
    const editSub = await request(editSubtitle(subtitleDetail?.id as string, bodyReq));
    onClickClose();
    if (editSub?.data?.status === 200) {
      message.success('Edit caption successfully!');
      refetchSubtitle();
      handleGetSubtitles();
      dispatch(
        selectSubtitleDetail({
          ...subtitleDetail,
          transcribe: getNewPayloadListTranscribes(captionEdited)
        })
      );
      if (subtitleLinked) {
        handleUpdateSubtitleInStore(getNewPayloadListTranscribes(captionEdited));
      }
      setIsLoading(false);
    } else {
      message.error('Edit caption unsuccessfully!');
      setIsLoading(false);
    }
  };

  const handleUpdateSubtitleInStore = (listTranscribe?: any[]) => {
    const modifySubtitles = subtitles?.map((item: any) => {
      if (item.file === subtitleDetail?.file) {
        return {
          id: subtitleDetail?.id,
          file: item?.file,
          transcribe: listTranscribe
        };
      } else {
        return { ...item };
      }
    });
    const modifySubtitlesInit = subtitlesInit?.map((item: any) => {
      if (item.file === subtitleDetail?.file) {
        return {
          id: subtitleDetail?.id,
          file: item?.file,
          transcribe: listTranscribe
        };
      } else {
        return { ...item };
      }
    });

    dispatch(
      update({
        subtitles: modifySubtitles,
        subtitlesInit: modifySubtitlesInit
      })
    );
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  const getNewPayloadListTranscribes = (editedObject?: any) => {
    const transcribe = subtitleDetail?.transcribe;
    const newArray = transcribe?.map((obj: any) => {
      if (obj.id === editedObject.id) {
        return editedObject;
      }
      return obj;
    });

    return newArray;
  };

  const onClose = () => {
    onClickClose();
  };

  React.useEffect(() => {
    const subTitleText = subtitleDetail?.transcribe?.find((item) => item.id === subtitleId);
    setSubtileText(subTitleText?.sub);
  }, [subtitleId]);

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="500px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <ModalTileUpload>Edit caption</ModalTileUpload>
      <div />
      <Textarea
        placeholder="Enter caption"
        value={subtitleText}
        onChange={(e) => setSubtileText(e.target.value)}
        rows={2}
      />
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
        <Button onClick={onSubmit} loading={isLoading} style={{ width: '95px' }}>
          {!isLoading && 'Change'}
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default EditSubtitleModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
`;
