import React from 'react';

import { SVGProps } from './SVG.props';

const FloppyDisk = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_469_7819)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00008 7.33329C6.52732 7.33329 5.33341 8.5272 5.33341 9.99996C5.33341 11.4727 6.52732 12.6666 8.00008 12.6666C9.47284 12.6666 10.6667 11.4727 10.6667 9.99996C10.6667 8.5272 9.47284 7.33329 8.00008 7.33329ZM6.66675 9.99996C6.66675 9.26358 7.2637 8.66663 8.00008 8.66663C8.73646 8.66663 9.33341 9.26358 9.33341 9.99996C9.33341 10.7363 8.73646 11.3333 8.00008 11.3333C7.2637 11.3333 6.66675 10.7363 6.66675 9.99996Z"
          fill={fill || "#070550"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66675 0.666626C1.56218 0.666626 0.666748 1.56206 0.666748 2.66663V13.3333C0.666748 14.4379 1.56218 15.3333 2.66675 15.3333H13.3334C14.438 15.3333 15.3334 14.4379 15.3334 13.3333V6.12012C15.3334 5.67903 15.1876 5.25032 14.9187 4.9007L11.8619 0.926821C11.7357 0.762763 11.5404 0.666626 11.3334 0.666626H2.66675ZM2.00008 2.66663C2.00008 2.29844 2.29856 1.99996 2.66675 1.99996H3.33341V3.99994C3.33341 5.10451 4.22885 5.99994 5.33341 5.99994H10.0001C11.1047 5.99994 12.0001 5.10451 12.0001 3.99994V3.29339L13.8618 5.71365C13.9515 5.83019 14.0001 5.97309 14.0001 6.12012V13.3333C14.0001 13.7015 13.7016 14 13.3334 14H2.66675C2.29856 14 2.00008 13.7015 2.00008 13.3333V2.66663ZM4.66675 3.99994V1.99996H10.6668V3.99994C10.6668 4.36813 10.3683 4.66661 10.0001 4.66661H5.33341C4.96522 4.66661 4.66675 4.36813 4.66675 3.99994Z"
          fill={fill || "#070550"}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_7819">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FloppyDisk;
