import React from 'react';

import { SVGProps } from './SVG.props';

const BackWard = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg {...props} width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4115 5.00942C11.978 3.41858 10.0409 2.61206 8.57696 3.62559L2.33833 7.94465C0.90236 8.93878 0.902362 11.0615 2.33833 12.0556L8.57696 16.3747C10.041 17.3882 11.978 16.5817 12.4115 14.9908L14.4103 16.3747C16.0684 17.5226 18.3334 16.3358 18.3334 14.3192V5.68108C18.3334 3.66442 16.0684 2.47769 14.4103 3.62559L12.4115 5.00942ZM3.28701 9.31497C2.80836 9.64634 2.80836 10.3539 3.28701 10.6853L9.52564 15.0043C10.0783 15.387 10.8333 14.9914 10.8333 14.3192V13.8983L8.17171 12.0556C6.73575 11.0615 6.73574 8.93878 8.17171 7.94465L10.8333 6.102V5.68108C10.8333 5.00886 10.0783 4.61328 9.52564 4.99591L3.28701 9.31497ZM9.12039 9.31497C8.64174 9.64634 8.64174 10.3539 9.1204 10.6853L15.359 15.0043C15.9117 15.387 16.6667 14.9914 16.6667 14.3192V5.68108C16.6667 5.00886 15.9117 4.61328 15.359 4.99591L9.12039 9.31497Z"
        fill={fill || "#030220"}
      />
    </svg>
  );
};

export default BackWard;
