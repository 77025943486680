import React from 'react';
import styled from '@emotion/styled';
import { Eye, StarYellow, AddBlack } from '@/components/SVG';
import Audio from '@/assets/images/audio.png';
import Typography from '@/components/Typography';

interface IProps {
  url: string;
  onAdd?: () => void;
  onMoveTemplate?: (id: string) => void;
  width?: string;
  duration?: string;
  name?: string;
  type?: string;
  id?: string;
  tag?: string;
  favorite?: boolean;
}

const Asset: React.FC<IProps> = ({
  url = '',
  onAdd,
  onMoveTemplate,
  tag,
  width,
  duration,
  type,
  id,
  name,
  favorite
}) => {
  const backgroundUrl = type === 'AUDIO' ? Audio : url;
  return (
    <Container width={width}>
      <AssetContainer bg={backgroundUrl} width={width}>
        {duration && (
          <DurationContainer>
            <Typography variant="caption" color="white">
              {duration}
            </Typography>
          </DurationContainer>
        )}
        {onMoveTemplate && (
          <CloseIconContainer className="btn-remove" onClick={() => onMoveTemplate(id as string)}>
            <Eye />
          </CloseIconContainer>
        )}
        <AddIconContainer onClick={onAdd}>
          <AddBlack />
        </AddIconContainer>
        {favorite && (
          <StarIconContainer>
            <StarYellow fill="#D9A21F" />
          </StarIconContainer>
        )}
        {tag && <TagName>{tag}</TagName>}
      </AssetContainer>
      <TextName>{name}</TextName>
    </Container>
  );
};

export default Asset;

const Container = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  height: 125px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 125px;
  }
`;

const AssetContainer = styled.div<{ bg: string; width?: string }>`
  width: 100%;
  height: 110px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: 110px;
  }

  position: relative;
  border-radius: 4px;
  background: ${({ bg }) => `url('${bg}') no-repeat`};
  background-color: ${({ theme }) => theme.grey.shade6};
  background-size: contain;
  background-position: center;

  &:hover {
    .btn-remove {
      display: flex;
    }
  }
`;

const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${({ theme }) => theme.grey.shade2};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseIconContainer = styled(IconContainer)`
  position: absolute;
  top: 6px;
  right: 6px;
  display: none;
`;

const AddIconContainer = styled(IconContainer)`
  position: absolute;
  bottom: 6px;
  right: 6px;
`;

const DurationContainer = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
  padding: 8px;
  background: rgba(222, 217, 219, 0.37);
  backdrop-filter: blur(2px);
  border-radius: 8px;
`;

const TextName = styled.div`
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.grey.shade7};
  font-size: 11px;
  margin-top: 2px;
`;

const TagName = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.grey.shade1};
  background-color: ${({ theme }) => theme.grey.shade8};
  border-radius: 8px;
  padding: 1px 4px 0px;
  font-size: 11px;
`;

const StarIconContainer = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.grey.shade8};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  position: absolute;
  top: 6px;
  right: 6px;
  border-radius: 12px;
`;
