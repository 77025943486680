import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import TextField from '@/components/TextField';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ObjectType, PlayableType } from '@/features/video/models/FrameItem';
import {
  selectVideo,
  update,
  updatePlayableItem,
  updateSelectedCard,
  updateSelectedImage,
  updateSelectedText
} from '@/features/video/store/videoSlice';

interface IEditElementNameProps {
  isOpen: boolean;
  onClickClose: () => void;
  name?: any;
  type?: string;
  id?: string;
}

const EditElementNameModal: React.FunctionComponent<IEditElementNameProps> = ({
  isOpen,
  onClickClose,
  name,
  type,
  id
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [elementName, setElementName] = React.useState<string | any>(name);
  const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
  const { addingPlayableItems, addingTexts, addingImages, addingCards } = useAppSelector(selectVideo);

  const onSubmit = async () => {
    if (type === PlayableType.video || type === PlayableType.audio) {
      dispatch(updatePlayableItem({ uid: id, name: elementName }));
    } else if (type === ObjectType.image) {
      dispatch(updateSelectedImage({ uid: id, name: elementName }));
    } else if (type === ObjectType.text) {
      dispatch(updateSelectedText({ uid: id, name: elementName }));
    } else {
      dispatch(updateSelectedCard({ uid: id, name: elementName }));
    }
    onClickClose();
  };

  React.useEffect(() => {
    setElementName(name);
  }, [name]);

  const unSelectObjectTimeline = () => {
    dispatch(
      update({
        timeLineSelected: { uid: '', type: '' }
      })
    );
  };

  const handleChangeName = (val: string) => {
    setElementName(val);
    let listWithoutVal = [];
    unSelectObjectTimeline();

    switch (type) {
      case PlayableType.video:
        listWithoutVal = addingPlayableItems
          .filter((x) => x.type !== PlayableType.audio && !x.isHide)
          .map((x) => x.name?.toLowerCase())
          .filter((x) => x !== name?.toLowerCase());
        break;
      case PlayableType.audio:
        listWithoutVal = addingPlayableItems
          .filter((x) => x.type !== PlayableType.video)
          .map((x) => x.name?.toLowerCase())
          .filter((x) => x !== name?.toLowerCase());
        break;
      case ObjectType.image:
        listWithoutVal = addingImages.map((x) => x.name?.toLowerCase()).filter((x) => x !== name?.toLowerCase());
        break;
      case ObjectType.text:
        listWithoutVal = addingTexts.map((x) => x.name?.toLowerCase()).filter((x) => x !== name?.toLowerCase());
        break;
      default:
        listWithoutVal = addingCards.map((x) => x.name?.toLowerCase()).filter((x) => x !== name?.toLowerCase());
        break;
    }
    setIsDuplicate(listWithoutVal.includes(val?.toLowerCase()));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="500px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          unSelectObjectTimeline();
        }}
      >
        <ModalTileUpload>Edit file name</ModalTileUpload>
        <TextField
          containerStyle={{ marginTop: '14px' }}
          placeholder="Enter file name"
          value={elementName}
          onChange={(e) => handleChangeName(e.target.value)}
        />
        {isDuplicate && <TextDuplicate>Duplicate file name</TextDuplicate>}
        <ActionsContainer>
          <Button onClick={onClickClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={!elementName || isDuplicate}>
            Change
          </Button>
        </ActionsContainer>
      </div>
    </Modal>
  );
};

export default EditElementNameModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

const TextDuplicate = styled.p`
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #f73b3b;
  margin-top: 4px;
  position: absolute;
`;
