import React, { useEffect } from 'react';

import ReactDOM from 'react-dom';

import { CloseIcon } from '../SVG';
import { ModalProps } from './Modal.props';
import { Backdrop, ModalContainer, ExitButton, Title, ContentContainer, ModalWrapper } from './Modal.style';

const target = document.querySelector('body') as Element;

const Modal = (props: ModalProps): any => {
  const { isOpen, onClickClose, children, title, titleColor } = props;

  useEffect(() => {
    const keyEscDown = (e: any) => {
      if (e.code === 'Escape') {
        onClickClose();
      }
    };
    document.addEventListener('keydown', keyEscDown);
    return () => {
      document.removeEventListener('keydown', keyEscDown);
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = '';
      document.body.style.touchAction = '';
    }
  }, [isOpen]);

  let renderComponent = (
    <div>
      {!props.fullscreen && !props.isConfirmDlg && (
        <ExitButton
          onClick={(e) => {
            e.preventDefault();
            onClickClose();
          }}
          insideClose={props.insideClose}
          positionCLoseBtn={props.positionCLoseBtn}
          hasTitle={!!title}
        >
          <CloseIcon fill="#070550" />
        </ExitButton>
      )}
      {children}
    </div>
  );

  if (title !== undefined) {
    renderComponent = (
      <ContentContainer>
        <ExitButton
          onClick={(e) => {
            e.preventDefault();
            onClickClose();
          }}
          hasTitle={!!title}
          {...props}
        >
          <CloseIcon fill="#070550" />
        </ExitButton>
        {children}
      </ContentContainer>
    );
  }

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalWrapper id="modal">
      <Backdrop
        isOpen={isOpen}
        onClick={(e) => {
          e.preventDefault();
          onClickClose();
        }}
      />
      <ModalContainer
        backgroundColor={props.backgroundColor}
        style={props.style}
        width={props.customWidth}
        height={props.customHeight}
        minWidth={props.customMinWidth}
        padding={props.customPadding}
        fullscreen={props.fullscreen}
        maxHeight={props.customMaxHeight}
        maxWidth={props.customMaxWidth}
        fixedWidth={props.customFixedWidth}
        fixedHeight={props.customFixedHeight}
        isShareLink={props.isShareLink}
        borderRadius={props.borderRadius}
        overflow={props.overflow}
      >
        {title !== undefined && (
          <Title color={titleColor || 'shade1'} variant={'title5'}>
            {title}
          </Title>
        )}
        {renderComponent}
      </ModalContainer>
    </ModalWrapper>,
    target
  );
};

export default Modal;
