/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import styled from '@emotion/styled';
import Handler from '@/assets/images/video-frame-handler.svg';
import { Trim } from './DraggableBox';
import { PlayableType, ObjectType } from '@/features/video/models/FrameItem';
import {
  selectVideo,
  update,
  updatePlayableItem,
  updateSelectedCard,
  updateSelectedImage,
  updateSelectedText
} from '../../../store/videoSlice';
import { TIME_PX } from '../VideoControl';
import { VideoService } from '@/features/video/services/videoService';
import ToolTip from '@/components/ToolTipV2';
import ToolTipV1 from '@/components/ToolTip';
import { Mirror, CloseIcon } from '@/components/SVG';
import { listTransitions } from '../../video-slidebar/v2/Transitions/data';

// un-comment for audio limit resize
// const MIN_GAP_DURATION_AUDIO = 1; // 1s
const MIN_GAP_DURATION_VIDEO = 4; // 4s
//container is 100% but it can play max width is PERCENT_WIDTH_ACTUAL_PLAY%
const PERCENT_WIDTH_ACTUAL_PLAY = 90;
const ADDING_DURATION = 15;

export interface BoxDragProps {
  children: React.ReactNode;
  style: any;
  duration: number;
  trim: Trim;
  type: string;
  id: string;
  start: number;
  end: number;
  line: number;
  width: number;
  isMainVideo: boolean;
  isHide?: boolean;
  isApplyTransition?: boolean;
  isNeedApplyTransition?: boolean;
}

const ResizeAbleBox: FC<BoxDragProps> = ({
  children,
  style,
  duration,
  trim,
  type,
  id,
  start,
  end,
  line,
  width,
  isMainVideo,
  isHide,
  isApplyTransition,
  isNeedApplyTransition
}) => {
  const { addingPlayableItems, addingImages, addingTexts, addingCards, previousTab, totalDuration } =
    useAppSelector(selectVideo);
  const panelRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const [direction, setDirection] = useState('');
  const [mouseDown, setMouseDown] = useState(false);
  const [firstAccess, setFistAccess] = useState(true);
  const [coordinateData, setCoordinateData] = useState<any>({ trim: { ...trim }, start, end });
  let coordinateDataLatest: any;
  const videoSelected = addingPlayableItems.find((x) => x.uid === id) as any;
  const videoPrevious = addingPlayableItems.find((x) => x.end === start && x.line === line) as any;
  const [transitionNameRight, setTransitionNameRight] = useState<string>('');
  const [transitionNameLeft, setTransitionNameLeft] = useState<string>('');

  const videoList = useMemo(() => {
    return addingPlayableItems.filter((item) => item.type === PlayableType.video && !item.isHide);
  }, [addingPlayableItems]);

  useEffect(() => {
    const listVdSortByStart = videoList.sort((a: any, b: any) => a.start - b.start);
    const currentVd = listVdSortByStart.find((x) => x.uid === id) as any;
    const previosVd = listVdSortByStart.find((x) => x.end === currentVd?.start && x.line === currentVd?.line) as any;

    const trackingTransition = (video: any, setTransitionName: any) => {
      switch (video?.transition?.type) {
        case listTransitions[1].value:
          setTransitionName(listTransitions[1].label);
          break;
        case listTransitions[2].value:
          setTransitionName(listTransitions[2].label);
          break;
        case listTransitions[3].value:
          setTransitionName(listTransitions[3].label);
          break;
        case listTransitions[4].value:
          setTransitionName(listTransitions[4].label);
          break;
        case listTransitions[5].value:
          setTransitionName(listTransitions[5].label);
          break;
        default:
          setTransitionName(listTransitions[0].label);
          break;
      }
    };
    trackingTransition(currentVd, setTransitionNameRight);
    trackingTransition(previosVd, setTransitionNameLeft);
  }, [addingPlayableItems]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      update({
        timeLineSelected: { uid: '', type: '' }
      });
      if (!direction) return;
      // const ratio = window.devicePixelRatio;
      handleResize(direction, e.movementX);
    };

    if (mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mouseDown, direction]);

  useEffect(() => {
    const handleMouseUp = (e: any) => {
      setMouseDown(false);
      // click outside of card_item_container => unselect
      if (e.target.closest('.card_item_container') === null) {
        unSelectObjectTimeline();
      }
    };
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!firstAccess && !mouseDown) {
      onDurationChange(type, id, coordinateDataLatest);
      setCoordinateData({ ...coordinateDataLatest });
    }
    setFistAccess(false);
  }, [mouseDown]);

  useEffect(() => {
    setCoordinateData({ trim: { ...trim }, start, end });
  }, [trim, start, end]);

  coordinateDataLatest = { ...coordinateData };

  const handleMouseDown = (direction: any) => () => {
    setDirection(direction);
    setMouseDown(true);
  };

  const unSelectObjectTimeline = () => {
    dispatch(
      update({
        timeLineSelected: { uid: '', type: '' }
      })
    );
  };

  const onDurationChange = (type: string, uid: string, coordinateDataLatest: any) => {
    switch (type) {
      case PlayableType.video:
        dispatch(updatePlayableItem({ uid, ...coordinateDataLatest }));
        break;
      case ObjectType.image:
        dispatch(updateSelectedImage({ uid, start: coordinateDataLatest.start, end: coordinateDataLatest.end }));
        break;
      case ObjectType.text:
        dispatch(updateSelectedText({ uid, start: coordinateDataLatest.start, end: coordinateDataLatest.end }));
        break;
      case ObjectType.card:
        dispatch(updateSelectedCard({ uid, start: coordinateDataLatest.start, end: coordinateDataLatest.end }));
        break;
      case PlayableType.audio:
        dispatch(updatePlayableItem({ uid, ...coordinateDataLatest }));
        break;
      default:
        break;
    }
  };

  // trim.end is total time of time line item
  // trim.start is that begin position when user want cut first of time line
  // trim.last is time end position when user want to cut last of time line
  // const minSizePercent = 2; // min duration for timeline - 2.5s
  const minSizePercentVideo = (MIN_GAP_DURATION_VIDEO * PERCENT_WIDTH_ACTUAL_PLAY) / (totalDuration - ADDING_DURATION); // min duration for timeline - 4.1s
  // un-comment for audio limit resize
  // const minSizePercentAudio = (MIN_GAP_DURATION_AUDIO * PERCENT_WIDTH_ACTUAL_PLAY) / (totalDuration - ADDING_DURATION); // min duration for timeline - 4.1s

  const handleResize = (direction: string, movementX: number) => {
    const width: number = panelRef.current?.clientWidth as number;
    const panel: any = panelRef.current;
    const resizeRightAblePlay = () => {
      const widthPercent =
        ((coordinateDataLatest.end - coordinateDataLatest.start) * PERCENT_WIDTH_ACTUAL_PLAY) /
        (totalDuration - ADDING_DURATION);

      if (movementX === 0) return;
      if (movementX > 0) {
        if (coordinateDataLatest.trim.end >= duration || onCheckOverlap(coordinateDataLatest.end)) {
          return;
        }
        coordinateDataLatest.trim.end = coordinateDataLatest.trim.end + movementX * TIME_PX;
        coordinateDataLatest.end = coordinateDataLatest.end + movementX * TIME_PX;
      } else {
        if (Math.abs(widthPercent) < Math.abs(minSizePercentVideo) && type === PlayableType.video) return;
        // un-comment for audio limit resize
        // if (Math.abs(widthPercent) < Math.abs(minSizePercentAudio) && type === PlayableType.audio) return;

        coordinateDataLatest.trim.end = coordinateDataLatest.trim.end + movementX * TIME_PX;
        coordinateDataLatest.end = coordinateDataLatest.end + movementX * TIME_PX;
      }
      panel.style.width = `${width + movementX}px`;
    };

    const resizeLeftAblePlay = () => {
      const widthPercent =
        ((coordinateDataLatest.end - coordinateDataLatest.start) * PERCENT_WIDTH_ACTUAL_PLAY) /
        (totalDuration - ADDING_DURATION);

      if (movementX === 0) return;
      if (movementX < 0) {
        if (coordinateDataLatest.trim.start <= 0) {
          coordinateDataLatest.trim.start = 0;
          return;
        }
        if (coordinateDataLatest.start <= 0) {
          coordinateDataLatest.start = 0;
          return;
        }
        if (onCheckOverlap(coordinateDataLatest.start)) {
          return;
        }
        const trimValue = coordinateDataLatest.trim.start + movementX * TIME_PX;
        const startValue = coordinateDataLatest.start + movementX * TIME_PX;
        coordinateDataLatest.trim.start = trimValue < 0 ? 0 : trimValue;
        coordinateDataLatest.start = startValue < 0 ? 0 : startValue;
        dispatch(
          update({
            resizeLeftX: {
              value: trimValue < 0 ? 0 : trimValue,
              id: id
            }
          })
        );
      } else {
        if (Math.abs(widthPercent) < Math.abs(minSizePercentVideo) && type === PlayableType.video) return;
        // un-comment for audio limit resize
        // if (Math.abs(widthPercent) < Math.abs(minSizePercentAudio) && type === PlayableType.audio) return;

        coordinateDataLatest.trim.start = coordinateDataLatest.trim.start + movementX * TIME_PX;
        coordinateDataLatest.start = coordinateDataLatest.start + movementX * TIME_PX;
        dispatch(
          update({
            resizeLeftX: {
              value: coordinateDataLatest.trim.start + movementX * TIME_PX,
              id: id
            }
          })
        );
      }
      //handle transform
      panel.style.width = `${width + -movementX}px`;
      const transformValue = panel.style.transform;
      const position = transformValue.match(/\d+/g);
      const value = +position[1] + movementX;
      panel.style.transform = `translate3d(${value > 0 ? value : 0}px,${
        position.length > 4 ? position[3] : position[2]
      }px, 0px)`;
    };

    const resizeRightStaticPlay = () => {
      if (movementX > 0) {
        if (coordinateDataLatest.trim.end >= duration || onCheckOverlap(coordinateDataLatest.end)) {
          return;
        }
        coordinateDataLatest.end = coordinateDataLatest.end + movementX * TIME_PX;
      } else {
        coordinateDataLatest.end = coordinateDataLatest.end + movementX * TIME_PX;
      }
      panel.style.width = `${width + movementX}px`;
    };

    const resizeLeftStaticPlay = () => {
      if (movementX === 0) return;
      if (movementX > 0) {
        coordinateDataLatest.start = coordinateDataLatest.start + movementX * TIME_PX;
      } else {
        if (coordinateDataLatest.start <= 0) {
          coordinateDataLatest.start = 0;
          return;
        }
        if (onCheckOverlap(coordinateDataLatest.start)) return;
        coordinateDataLatest.start = coordinateDataLatest.start + movementX * TIME_PX;
      }
      panel.style.width = `${width + -movementX}px`;
      // transform
      const transformValue = panel.style.transform;
      const position = transformValue.match(/\d+/g);
      panel.style.transform = `translate3d(${+position[1] + movementX}px,${
        position.length > 4 ? position[3] : position[2]
      }px, 0px)`;
    };

    setCoordinateData({ ...coordinateDataLatest });

    switch (direction) {
      case 'drag_right':
        if (type === PlayableType.video || type === PlayableType.audio) {
          resizeRightAblePlay();
        } else if (type === ObjectType.image || type === ObjectType.text || type === ObjectType.card) {
          resizeRightStaticPlay();
          unSelectObjectTimeline();
        }
        break;
      case 'drag_left':
        if (type === PlayableType.video || type === PlayableType.audio) {
          resizeLeftAblePlay();
        } else if (type === ObjectType.image || type === ObjectType.text || type === ObjectType.card) {
          resizeLeftStaticPlay();
          unSelectObjectTimeline();
        }
        break;
      default:
        break;
    }
  };

  const onClickDrag = () => {
    if (type === PlayableType.audio || type === PlayableType.video) {
      dispatch(
        update({
          selectedTab: 'Edit Audio',
          audioSelected: {
            uid: id
          }
        })
      );
    } else {
      dispatch(update({ selectedTab: previousTab }));
    }
    dispatch(
      update({
        timeLineSelected: { uid: id, type, main: isMainVideo }
      })
    );
  };

  const getItemsOnLine = useCallback(() => {
    const videoInOneLine = addingPlayableItems.filter((item) => item.line === line && !item.isHide);
    const textInOneLine = addingImages.filter((item) => item.line === line);
    const imgInOneLine = addingTexts.filter((item) => item.line === line);
    const cardInOneLine = addingCards.filter((item) => item.line === line);
    return [...videoInOneLine, ...textInOneLine, ...imgInOneLine, ...cardInOneLine].filter((items) => items.uid !== id);
  }, [addingPlayableItems, addingImages, addingTexts, addingCards, line]);

  const onCheckOverlap = (value: number) => {
    return getItemsOnLine()?.some((item: any) => value >= item.start && value <= item.end);
  };

  const handleRemoveTransition = (id: string) => {
    dispatch(updatePlayableItem(Object.assign({ uid: id, transition: {}, isApplyTransition: false })));
  };

  return (
    <Container
      ref={panelRef}
      width={width}
      line={line}
      className="card_item_container"
      style={{ ...style }}
      onBlur={() => unSelectObjectTimeline()}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <div className="drag_left" onMouseDown={handleMouseDown('drag_left')} onClick={onClickDrag}>
          <ToolTip
            arrow
            position="top"
            widthTooltip={60}
            background="#1B1C1E"
            content={
              <Duration>
                {VideoService.convertDisplayMilliSecond(coordinateDataLatest.end - coordinateDataLatest.start)}
              </Duration>
            }
            checkConstantly={coordinateDataLatest}
          >
            <div className="bg_icon left_icon"></div>
          </ToolTip>
        </div>
        {children}
        <div className="drag_right" onMouseDown={handleMouseDown('drag_right')} onClick={onClickDrag}>
          <ToolTip
            arrow
            position="top"
            widthTooltip={60}
            background="#1B1C1E"
            content={
              <Duration>
                {VideoService.convertDisplayMilliSecond(coordinateDataLatest.end - coordinateDataLatest.start)}
              </Duration>
            }
            checkConstantly={coordinateDataLatest}
          >
            <div className="bg_icon right_icon"></div>
          </ToolTip>
        </div>
      </div>
      <IconTransitionLeft isBlock={isNeedApplyTransition} line={line}>
        <ToolTipV1 arrow content={transitionNameLeft} styleText={{ fontSize: '12px' }} style={{ borderRadius: '6px' }}>
          <MirrorIconContainer>
            <Mirror width={12} height={12} />
          </MirrorIconContainer>
        </ToolTipV1>
        <CloseIconContainer className="times-icon" onClick={() => handleRemoveTransition(videoPrevious.uid)}>
          <ToolTipV1
            arrow
            content={transitionNameLeft}
            styleText={{ fontSize: '12px' }}
            style={{ borderRadius: '6px' }}
          >
            <CloseIcon />
          </ToolTipV1>
        </CloseIconContainer>
      </IconTransitionLeft>
      <IconTransitionRight isBlock={isApplyTransition} line={line}>
        <ToolTipV1 arrow content={transitionNameRight} styleText={{ fontSize: '12px' }} style={{ borderRadius: '6px' }}>
          <MirrorIconContainer>
            <Mirror width={12} height={12} />
          </MirrorIconContainer>
        </ToolTipV1>
        <CloseIconContainer className="times-icon" onClick={() => handleRemoveTransition(videoSelected.uid)}>
          <ToolTipV1
            arrow
            content={transitionNameRight}
            styleText={{ fontSize: '12px' }}
            style={{ borderRadius: '6px' }}
          >
            <CloseIcon />
          </ToolTipV1>
        </CloseIconContainer>
      </IconTransitionRight>
    </Container>
  );
};

const Container = styled.div<{ width: number; line: number; isHide?: boolean }>`
  display: ${({ isHide }) => (isHide ? 'none' : 'flex')};
  // display: flex;
  .drag_left,
  .drag_right {
    display: flex;
    z-index: 11;
    cursor: ew-resize;
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    height: 100%;
    .bg_icon {
      height: ${({ line }) => (line === 0 ? '18px' : '10px')};
      ${({ width }) => width <= 2 && 'opacity: 0'}
      background: url('${Handler}') no-repeat;
      background-size: contain;
      margin-left: 6px;
      width: ${({ line }) => (line === 0 ? '10px' : '5px')};
    }
  }
  .drag_right {
    right: 0;
    .right_icon {
      margin-left: ${({ line }) => (line === 0 ? '6px' : '8px')};
    }
  }
  &:hover {
    .drag_left,
    .drag_right {
      display: flex;
    }
  }
`;

const IconTransitionRight = styled.div<{ isBlock?: boolean; line?: number }>`
  z-index: 100;
  position: absolute;
  right: -13px;
  // top: 13px;
  cursor: pointer;
  top: ${({ line }) => (line ? '4px' : '13px')};
  display: ${({ isBlock }) => (isBlock ? 'block' : 'none')};
  width: 26px;

  .times-icon {
    display: none;
    z-index: 100;
    position: absolute;
    right: 2px;
    cursor: pointer;
    top: ${({ line }) => (line ? '4px' : 0)};
  }

  &:hover {
    .times-icon {
      display: block;
    }
  }
`;

const IconTransitionLeft = styled(IconTransitionRight)<{ isBlock?: boolean }>`
  z-index: 99;
  left: -13px;
  display: ${({ isBlock }) => (isBlock ? 'block' : 'none')};
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #e4f5e6;
`;

const CloseIconContainer = styled(IconContainer)`
  position: absolute;
  top: 12px;
  right: 12px;
  display: none;
  padding: 4px;
`;

const MirrorIconContainer = styled(IconContainer)`
  position: absolute;
  padding: 4px;
  background: #fff;
`;

const Duration = styled.div`
  font-family: 'Objective';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

export default ResizeAbleBox;
