/* eslint-disable react/display-name */
import React, { ChangeEvent, useRef, useState } from 'react';
import { CloseFilled, MagnifyingGlass } from '../SVG';

import { SearchBarProps } from './SearchBar.props';
import { FieldContainer, CloseFilledContainer, SvgContainer } from './SearchBar.style';

const SearchBar = React.forwardRef((props: SearchBarProps, ref): JSX.Element => {
  const inputRef: React.RefObject<any> = useRef();
  const {
    didChanged,
    longSearchBar,
    timeout,
    someKey,
    border = true,
    value,
    className,
    customFontSize,
    customWidth,
    disabledWith,
    haveNotIcon,
    handleSearchTemplate,
    ...inputProps
  } = props;
  // const [searchKey, setSearchKey] = useState('');
  const [changeTimeOut, setChangeTimeOut] = useState<NodeJS.Timeout>();

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    setText: (value: string) => {
      inputRef.current.value = value;
    },
    clearText: () => {
      inputRef.current.value = null;
    }
  }));

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    if (changeTimeOut) {
      clearTimeout(changeTimeOut);
    }
    // setSearchKey(value);
    setChangeTimeOut(
      setTimeout(() => {
        didChanged && didChanged(value);
      }, timeout || 500)
    );
  };

  const handleResetValue = () => {
    // setSearchKey('');
    inputRef.current.value = null;
    didChanged && didChanged('');
  };

  React.useEffect(() => {
    if (!value) {
      handleResetValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FieldContainer
      className={className}
      isLong={longSearchBar}
      border={border}
      key={someKey}
      fontSize={customFontSize}
      width={customWidth}
      disabledWith={disabledWith}
    >
      {!haveNotIcon && (
        <SvgContainer onClick={handleSearchTemplate}>
          <MagnifyingGlass height={16} width={16} />
        </SvgContainer>
      )}
      <input
        ref={inputRef}
        type="text"
        placeholder={props.placeholder || 'Search for an advocate'}
        {...inputProps}
        defaultValue={value ? value : ''}
        onChange={handleOnChange}
      />
      {inputRef.current?.value && (
        <CloseFilledContainer onClick={handleResetValue}>
          <CloseFilled fill="#B5ADB0" height={20} width={20} />
        </CloseFilledContainer>
      )}
    </FieldContainer>
  );
});

export default React.memo(SearchBar);
