import { widthPlayerUI13Inch, widthPlayerUI24Inch } from '@/constants/screens';

export const resizeFontSize = (fontSize: number, playerWidthSizeHD: number, expectRatio: string) => {
  if (playerWidthSizeHD >= widthPlayerUI24Inch) {
    if (expectRatio === '16:9') {
      return fontSize * 1.65;
    } else if (expectRatio === '9:16') {
      return fontSize * 1.3;
    } else {
      return fontSize * 1.55;
    }
  } else if (playerWidthSizeHD <= widthPlayerUI24Inch && playerWidthSizeHD >= widthPlayerUI13Inch) {
    if (expectRatio === '16:9') {
      return fontSize * 1.4;
    } else if (expectRatio === '9:16') {
      return fontSize * 0.85;
    } else {
      return fontSize * 1.3;
    }
  } else if (playerWidthSizeHD <= widthPlayerUI13Inch) {
    if (expectRatio === '16:9') {
      return fontSize;
    } else if (expectRatio === '9:16') {
      return fontSize * 0.58;
    } else {
      return fontSize * 0.9;
    }
  }
};
