import React from 'react';

export interface IFrameStatus {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  start?: number;
  end?: number;
  uid: string;
  name?: string;
  main?: boolean;
  line?: number;
  animation?: any;
  transition?: any;
  position?: Position;
  type?: string;
}
export interface Resolution {
  width: number;
  height: number;
  aspectRatio: string;
  description?: string;
}

export enum TabV2 {
  media = 'Media',
  effect = 'Text Effects & Cards',
  captions = 'Captions',
  transitions = 'Transitions'
}

export enum TabInsideV2 {
  texts = 'Text Effects',
  cards = 'Cards'
}

export interface VideoCropPayload {
  id: string;
  aspectRatio: string;
}

export interface Trim {
  start: number;
  end: number;
}

export interface VideoTrimPayload {
  id: string;
  start: number;
  end: number;
}

export interface SubLine {
  index: number;
  start: string;
  end: string;
  sub: string;
}

export interface SubtitleInit {
  start: string;
  end: string;
  sub: string;
}

export interface Language {
  label: string;
  langCode: string;
}

export interface Subtitle {
  language: Language;
  subLines: SubLine[];
  id: string;
  styles?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
}

export interface SubtitleV2 {
  transcribe: Transcribe[];
  file: string;
  id: string;
  trim?: Trim;
  start?: number;
}

export interface SubtitleLinked {
  transcribe: Transcribe[];
  file: string;
}

export interface Transcribe {
  id: number;
  start: string;
  end: string;
  sub: string;
}

export interface LanguageV2 {
  name: string;
  code: string;
}

export interface Position {
  x: number;
  y: number;
}

export interface SubtitleDetail {
  code: string;
  companyId: string;
  fileKey: string;
  fileType: string;
  id: string;
  language: string;
  userId: string;
  transcribe: Transcribe[];
  name?: string;
  file?: string;
  langCode?: string;
  langName?: string;
}

export interface SubtitleStyles {
  backgroundColor: string;
  color: string;
  fontSize: string;
}
