import React from 'react';

import { SVGProps } from './SVG.props';

const Repeat = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width ? width : 16} height={height ? height : 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4701 1.52851C12.2098 1.26816 11.7876 1.26816 11.5273 1.52851C11.2669 1.78886 11.2669 2.21097 11.5273 2.47132L12.3892 3.33325H4.66536C3.19261 3.33325 1.9987 4.52716 1.9987 5.99992V6.66659C1.9987 7.03477 2.29717 7.33325 2.66536 7.33325C3.03355 7.33325 3.33203 7.03477 3.33203 6.66659V5.99992C3.33203 5.26354 3.92899 4.66659 4.66536 4.66659H12.3892L11.5273 5.52851C11.2669 5.78886 11.2669 6.21097 11.5273 6.47132C11.7876 6.73167 12.2098 6.73167 12.4701 6.47132L14.4701 4.47132C14.7305 4.21097 14.7305 3.78886 14.4701 3.52851L12.4701 1.52851Z"
        fill={fill ? fill : "#070550"}
      />
      <path
        d="M4.4701 10.4713C4.73045 10.211 4.73045 9.78886 4.4701 9.52851C4.20975 9.26816 3.78764 9.26816 3.52729 9.52851L1.52729 11.5285C1.26694 11.7889 1.26694 12.211 1.52729 12.4713L3.52729 14.4713C3.78764 14.7317 4.20975 14.7317 4.4701 14.4713C4.73045 14.211 4.73045 13.7889 4.4701 13.5285L3.60817 12.6666H11.9987C13.4715 12.6666 14.6654 11.4727 14.6654 9.99992V9.33325C14.6654 8.96506 14.3669 8.66659 13.9987 8.66659C13.6305 8.66659 13.332 8.96506 13.332 9.33325V9.99992C13.332 10.7363 12.7351 11.3333 11.9987 11.3333H3.60817L4.4701 10.4713Z"
        fill={fill ? fill : "#070550"}
      />
    </svg>
  );
};

export default Repeat;
