/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, FC, useEffect, useState, useRef, useMemo } from 'react';
import { Popover } from 'antd';
import { FPS, ObjectType, PlayableType } from '@/features/video/models/FrameItem';
import styled from '@emotion/styled';
import EditElementNameModal from './EditElementNameModal';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { AnyAction } from '@reduxjs/toolkit';
import {
  selectVideo,
  update,
  removeImage,
  removeText,
  removePlayableItem,
  removeCard,
  updatePlayableItem,
  updateSelectedImage,
  updateSelectedText,
  updateSelectedCard,
  selectIdElement,
  setCaptionSettings
} from '../../../store/videoSlice';
import { TIME_PX } from '../VideoControl';
import { TimeLineBehavior } from './useBehavior';
import { FadeInRight, VerticalThreeDots, FadeInRightRemove, CcOnIcon } from '@/components/SVG';
import ToolTip from '@/components/ToolTipV2';
import { Tooltip as TooltipAnd } from 'antd';
import {
  selectSubtitle as getSubtitlesByUserId,
} from '@/features/video/store/subtitleSlice';
// import { Sword } from '@/components/SVG';

export interface BoxProps {
  title?: string;
  displayDuration: string;
  type: string;
  index: number;
  id: string;
  isMainVideo: boolean;
  thumbnails: any[];
  isFirstLine: boolean;
  duration: number;
  start: number;
  transformLeft: number;
  animation?: any;
  idSubtitleLinked?: string;
}

const Box: FC<BoxProps> = ({
  title,
  displayDuration,
  type,
  index,
  id,
  isMainVideo,
  thumbnails,
  isFirstLine,
  duration,
  start,
  transformLeft,
  animation,
  idSubtitleLinked
}) => {
  const dispatch = useAppDispatch();
  const { subtitles } = useAppSelector(getSubtitlesByUserId);
  const { previousTab, resizeLeftX, idElementSelected, subtitleStyle } = useAppSelector(selectVideo);
  const [boxHoverId, setBoxHoverId] = useState('');
  const overPreSeek = useRef(false);
  
  const { handleMouseMove } = TimeLineBehavior({
    transformLeft,
    boxHoverId,
    boxId: id,
    type
  });

  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const boxWrapperRef = useRef<any>(null);

  useEffect(() => {
    // handle: keep thumbnail display correct
    const thumbnailControl = boxWrapperRef?.current?.querySelector(`[id='thumbnail-box-${id}']`) as HTMLDivElement;
    if (thumbnailControl) {
      if (resizeLeftX && resizeLeftX.id === id) {
        thumbnailControl.style.left = `${-(resizeLeftX.value / TIME_PX)}px`;
      } else {
        thumbnailControl.style.left = `${-(start / TIME_PX)}px`;
      }
    }
  }, [resizeLeftX, start, TIME_PX]);

  useEffect(() => {
    // handle: keep thumbnail display correct when recover
    const thumbnailControl = boxWrapperRef?.current?.querySelector(`[id='thumbnail-box-${id}']`) as HTMLDivElement;
    if (thumbnailControl) {
      if (start === 0) {
        thumbnailControl.style.left = `${-(start / TIME_PX)}px`;
      }
    }
  }, [start]);

  const getSizeThumbnail = useMemo(() => {
    return FPS / TIME_PX;
  }, []);

  const handleClearCaptionSetting = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  const onSelectItem = () => {
    dispatch(selectIdElement(id));
    handleClearCaptionSetting();
    if (type === PlayableType.audio || type === PlayableType.video) {
      dispatch(
        update({
          selectedTab: 'Edit Audio',
          audioSelected: {
            uid: id,
            type
          }
        })
      );
      dispatch(
        update({
          timeLineSelected: { uid: id, type, main: isMainVideo }
        })
      );
    } else {
      dispatch(
        update({
          timeLineSelected: { uid: id, type, main: isMainVideo }
        })
      );
      // dispatch(update({ selectedObj: { uid: '', type: ObjectType.image } }));
      // dispatch(update({ selectedCard: { cardId: '', textId: '' } }));
      dispatch(update({ selectedTab: previousTab }));
    }
  };

  const onOutFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (e.relatedTarget?.id === 'split-timeline-02de3') {
      return;
    }
    dispatch(
      update({
        timeLineSelected: { uid: '', type: '' }
      })
    );
    dispatch(update({ selectedObj: { uid: '', type: ObjectType.image } }));
    dispatch(update({ selectedCard: { cardId: '', textId: '' } }));
    // dispatch(update({ selectedTab: previousTab }));
  };

  function createMarkup() {
    return { __html: title || '' };
  }

  const onAddAnimation = () => {
    const animationVal = {
      type: animation?.type === 'fadein' ? 'none' : 'fadein',
      start: 0,
      duration: 1.5
    };
    if (type === ObjectType.image) {
      dispatch(updateSelectedImage({ uid: id, animation: animationVal }));
    } else if (type === ObjectType.text) {
      dispatch(updateSelectedText({ uid: id, animation: animationVal }));
    } else if (type === ObjectType.card) {
      dispatch(updateSelectedCard({ uid: id, animation: animationVal }));
    }
  };

  const renderCaptionNameLinked = () => {
    const captionFinded = subtitles?.find((sub: any) => sub.id === idSubtitleLinked);
    return captionFinded?.name
  }

  return (
    <BoxWrapper
      id={'time-box-' + id}
      onFocus={onSelectItem}
      onBlur={onOutFocus}
      tabIndex={index}
      type={type}
      fistLine={isFirstLine}
      style={{ border: `${id === idElementSelected ? '2px solid #ffc937' : ''}` }}
      // style={{ border: `${id === timeLineSelected?.uid ? '2px solid #ffc937' : ''}` }}
      onClick={(e) => isOpenEdit && e.stopPropagation()}
      ref={boxWrapperRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setBoxHoverId(id)}
      onMouseLeave={() => {
        if (!overPreSeek.current) {
          setBoxHoverId('');
        }
      }}
      isLinked={idSubtitleLinked ? true : false}
    >
      {/* type === PlayableType.video || type === PlayableType.audio || type === ObjectType.card */}
      {/* change true to this one to open action animation  */}
      {true ? null : (
        <div
          className="box-action text-effect"
          onClick={(e) => {
            e.stopPropagation();
            onAddAnimation();
          }}
        >
          <ToolTip
            arrow
            position="top"
            widthTooltip={115}
            background="#1B1C1E"
            content={'Effect Animation'}
            checkConstantly={1}
          >
            {animation?.type === 'fadein' ? <FadeInRight /> : <FadeInRightRemove />}
          </ToolTip>
        </div>
      )}
      <Popover
        content={actionContent(id, type, dispatch, previousTab, setIsOpenEdit, isMainVideo, animation)}
        trigger="click"
      >
        <div
          className="box-action"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <VerticalThreeDots />
        </div>
      </Popover>
      {/* )} */}

      <div className="video-displayDuration">
        <div>{displayDuration}</div>
      </div>
      {!!thumbnails?.length && (
        <ThumbnailWrapper className="thumbnail-wrapper" id={'thumbnail-box-' + id}>
          {thumbnails.map((item: any, index: any) => {
            return <Thumbnail key={index} url={item.urls} width={getSizeThumbnail} />;
          })}
        </ThumbnailWrapper>
      )}
      {idSubtitleLinked && (
        <div className="linked-wrapper">
          <TooltipAnd title={renderCaptionNameLinked()} overlayStyle={{maxWidth: '140px'}}>
            <CcOnIcon width={16} height={16} />
          </TooltipAnd>
        </div>
      )}
      <div className="video-title" dangerouslySetInnerHTML={createMarkup()}></div>
      {/* {id === boxHoverId && type === PlayableType.video && (
        <PreSeekView
          left={localMousePos?.x}
          onMouseOver={() => {
            overPreSeek.current = true;
          }}
          onMouseLeave={() => {
            overPreSeek.current = false;
          }}
        >
          <SwordWrap
            onMouseLeave={() => {
              overPreSeek.current = false;
              setBoxHoverId('');
            }}
            onClick={(e) => {
              e.stopPropagation();
              onSplitTimeline(e);
            }}
          >
            <Sword />
          </SwordWrap>
          <PreSeekLine>
            <TriangleDown />
            <VerticalLine firstLine={isFirstLine} />
            <TriangleUp />
          </PreSeekLine>
        </PreSeekView>
      )} */}
      <EditElementNameModal
        isOpen={isOpenEdit}
        onClickClose={() => setIsOpenEdit(false)}
        name={title}
        id={id}
        type={type}
      />
    </BoxWrapper>
  );
};

export default Box;

const actionContent = (
  uid: string,
  type: string,
  dispatch: Dispatch<AnyAction>,
  previousTab: string,
  setIsOpenEdit?: any,
  isMainVideo?: boolean,
  animation?: any
) => {
  const onRemove = () => {
    if (type === PlayableType.video || type === PlayableType.audio) {
      if (isMainVideo) {
        dispatch(updatePlayableItem(Object.assign({ uid, type: type, isHide: true })));
      } else {
        dispatch(removePlayableItem({ uid }));
      }
      if (type === PlayableType.audio) {
        dispatch(update({ selectedTab: previousTab }));
      }
    } else if (type === ObjectType.image) {
      dispatch(removeImage({ uid }));
    } else if (type === ObjectType.text) {
      dispatch(removeText({ uid }));
    } else {
      dispatch(removeCard({ uid }));
    }
  };

  return (
    <ActionContentWrapper>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpenEdit(true);
        }}
      >
        Edit name
      </div>
      <div
        className="action-item"
        onClick={(e) => {
          e.stopPropagation();
          onRemove();
        }}
      >
        Remove
      </div>
    </ActionContentWrapper>
  );
};

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  transform: translateX(0px);
`;

const Thumbnail = styled.div<{ url: string; width: number }>`
  background-image: ${({ url }) => `url(${url})`};
  min-width: ${({ width }) => `${width}px`};
  background-size: 100px 100%;
  background-position: center;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  background-repeat: repeat-x;
  height: 100%;
`;

const BoxWrapper = styled.div<{ type: string; fistLine: boolean, isLinked?: boolean }>`
  border: ${({ type }) => {
    let color = '2px solid #98E0E5';
    if (type === ObjectType.image || type === PlayableType.video) {
      color = '2px solid rgb(145, 219, 182)';
    }
    if (type === ObjectType.text) {
      color = '2px solid #DED9DB';
    }
    return color;
  }};
  overflow: hidden;
  height: ${({ fistLine }) => (fistLine ? '52px' : '32px')};
  background-color: ${({ type }) => {
    let color = '#DEF5F7';
    if (type === ObjectType.image || type === PlayableType.video) {
      color = 'rgb(220, 243, 232)';
    }
    if (type === ObjectType.text) {
      color = '#F0ECEE';
    }
    return color;
  }};
  &:hover {
    .box-action {
      display: flex;
    }
  }
  cursor: move;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  //height:  ${({ type }) => (type === PlayableType.video ? '84px' : 'auto')};
  .video-displayDuration {
    background: ${({ theme }) => theme.grey.shade7};
    position: absolute;
    color: #fff;
    font-size: 10px;
    padding: 0px 6px;
    line-height: 15px;
    top: 5px;
    left: 15px;
    text-align: center;
    z-index: 11;
    border-radius: 10px;
    opacity: 0.5;
    div {
      margin-top: 2px;
    }
  }

  .video-title {
    font-size: 10px;
    line-height: 19px;
    font-family: 'Objective';
    font-weight: 500;
    color: #070550;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: ${({ isLinked }) => (isLinked ? '84px' : '60px')};
    top: 5px;
    z-index: 1;
  }

  .linked-wrapper {
    height: 17px;
    padding: 0 3px;
    padding-bottom: -2px;
    cursor: default;
    border-radius: 4px;
    background: ${({ theme }) => theme.opacity.arctic};
    position: absolute;
    left: 60px;
    top: 5px;
    z-index: 1;
  }

  .box-action {
    z-index: 11;
    display: none;
    position: absolute;
    right: 21px;
    top: 3px;
    height: 20px;
    align-items: center;
    justify-content: center;
    width: 20px;
    /* padding-bottom: 4px; */
    background: rgba(0, 0, 0, 0.75);
    /* padding: 0px 11px; */
    border-radius: 50%;
    /* opacity: 0.5; */

    ${({ fistLine }) =>
      fistLine
        ? `
        top: 11px;
     width: 25px;
    height: 25px;
`
        : `
    height: 20px;
    width: 20px;`}

    cursor: pointer;
  }
  .box-action.text-effect {
    right: 45px;
    ${({ fistLine }) =>
      fistLine &&
      `
        right: 50px;`}
  }
`;
// const DotsAction = styled.div<{ fistLine: boolean }>`
//   ${({ fistLine }) =>
//     fistLine
//       ? `
//     height: 4px;
//     width: 18px;
// `
//       : `
//   height: 4px;
//   width: 8px;`}

//   background-image: url('/assets/video-editor/three-dots-white.svg');
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
// `;

export const ActionContentWrapper = styled.div`
  .action-item {
    padding: 5px 10px;
    &:hover {
      background: ${({ theme }) => theme.altGrey.shade4};
      cursor: pointer;
      border-radius: 2px;
    }
  }
`;

export const PreSeekView = styled.div<{ left: number }>`
  position: fixed;
  z-index: 10; // z-index is lower than resized icon
  ${({ left }) => left && `left: ${left}px;`}
  margin-top: -24px;
  transform: translate(-50%);
`;

export const PreSeekLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-width: 6px 3.2px 0 3.2px;
  border-color: #030220 transparent transparent;
  border-style: solid;
  transform: translateX(0.5px);
`;

export const TriangleUp = styled(TriangleDown)`
  rotate: 180deg;
  transform: translateX(-0.5px);
`;

export const VerticalLine = styled.div<{ firstLine: boolean }>`
  height: ${({ firstLine }) => (firstLine ? '52px' : '36px')};
  border-left: 1px dashed;
  width: 1px;
`;

export const SwordWrap = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #030220;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 3px;
`;
