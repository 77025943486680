import React from 'react';

import { SVGProps } from './SVG.props';

const TextWrap = (props: SVGProps & { fillbg?: string }): JSX.Element => {
  const { width, height, fill, fillbg } = props;
  return (
    <svg width={width || 29} height={height || 28} viewBox="0 0 29 28" fill="none" {...props}>
      <path
        d="M0.75 14C0.75 2.66 3.41 0 14.75 0C26.09 0 28.75 2.66 28.75 14C28.75 25.34 26.09 28 14.75 28C3.41 28 0.75 25.34 0.75 14Z"
        fill={fillbg || "#F2F0F1"}
      />
      <g clipPath="url(#clip0_469_13867)">
        <path
          d="M7.41669 9.33329C7.41669 9.70148 7.71516 9.99996 8.08335 9.99996H21.4167C21.7849 9.99996 22.0834 9.70148 22.0834 9.33329C22.0834 8.9651 21.7849 8.66663 21.4167 8.66663H8.08335C7.71516 8.66663 7.41669 8.9651 7.41669 9.33329Z"
          fill={fill ||"#070550"}
        />
        <path
          d="M7.41669 13.3333C7.41669 13.7015 7.71516 14 8.08335 14H15.4167C15.7849 14 16.0834 13.7015 16.0834 13.3333C16.0834 12.9651 15.7849 12.6666 15.4167 12.6666H8.08335C7.71516 12.6666 7.41669 12.9651 7.41669 13.3333Z"
          fill={fill ||"#070550"}
        />
        <path
          d="M8.08335 16.6666C7.71516 16.6666 7.41669 16.9651 7.41669 17.3333C7.41669 17.7015 7.71516 18 8.08335 18L12.75 18C13.1182 18 13.4167 17.7015 13.4167 17.3333C13.4167 16.9651 13.1182 16.6666 12.75 16.6666L8.08335 16.6666Z"
          fill={fill ||"#070550"}
        />
        <path
          d="M19.1945 14C20.1214 14 20.75 14.6612 20.75 15.3333C20.75 16.0053 20.1214 16.6666 19.1945 16.6666H17.0262L17.8881 15.8047C18.1484 15.5443 18.1484 15.1222 17.8881 14.8619C17.6277 14.6015 17.2056 14.6015 16.9453 14.8619L14.9453 16.8619C14.8203 16.9869 14.75 17.1565 14.75 17.3333C14.75 17.5101 14.8203 17.6797 14.9453 17.8047L16.9453 19.8047C17.2056 20.065 17.6277 20.065 17.8881 19.8047C18.1484 19.5443 18.1484 19.1222 17.8881 18.8619L17.0262 18H19.1945C20.7221 18 22.0834 16.8704 22.0834 15.3333C22.0834 13.7962 20.7221 12.6666 19.1945 12.6666H18.0834C17.7152 12.6666 17.4167 12.9651 17.4167 13.3333C17.4167 13.7015 17.7152 14 18.0834 14H19.1945Z"
          fill={fill ||"#070550"}
        />
      </g>
      <defs>
        <clipPath id="clip0_469_13867">
          <rect width="16" height="16" fill="white" transform="translate(6.75 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextWrap;
