import React from 'react';
import styled from '@emotion/styled';
import { BackArrow, MagicWand } from '@/components/SVG';
import DropDown from '@/components/DropDown';
import { useDispatch } from 'react-redux';
import { getLanguagesTranslate, selectVideo, update } from '@/features/video/store/videoSlice';
import { Language, Transcribe, Trim } from '@/features/video/models/VideoFrame';
import useRequest from '@/components/hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { PlayableType } from '@/features/video/models/FrameItem';
import { generateSubtitle, getAllSubtitles } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { getSubtitles } from '@/features/video/store/subtitleSlice';
import Typography from '@/components/Typography';
import Spinner from '@/components/Spinner';

interface IGenerateCaptionViewProps {
  onBackToOveral: () => void;
}

const GenerateCaption: React.FC<IGenerateCaptionViewProps> = ({ onBackToOveral }) => {
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const languageOptions = useAppSelector(getLanguagesTranslate);
  const { addingPlayableItems, subtitleStyle, subtitles, subtitlesInit } = useAppSelector(selectVideo);

  const removeDuplicateFilePaths = () => {
    const uniqueFilePaths = new Set();
    const uniqueVideos = [];

    for (const video of addingPlayableItems) {
      if (!uniqueFilePaths.has(video.filePath)) {
        uniqueFilePaths.add(video.filePath);
        uniqueVideos.push(video);
      }
    }
    return uniqueVideos;
  };

  const videoOptions = React.useMemo(() => {
    const listVideosRemoveDup = removeDuplicateFilePaths();
    return listVideosRemoveDup
      .filter((item) => item.type === PlayableType.video && !item.isHide)
      .map((item) => ({ ...item, label: item.name, value: item.filePath }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingPlayableItems]);

  // const selectedLanguage = languageOptions.find((opt: Language) => opt.langCode === 'en');
  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>(
    languageOptions?.find((opt: Language) => opt.langCode === 'en')
  );
  const [selectedVideo, setSelectedVideo] = React.useState(videoOptions[0]);

  const handleLinkSubtitle = (data: any, trim?: Trim, start?: number) => {
    const modifyListVideos = addingPlayableItems
      .filter((x) => !x.isHide)
      .map((item: any) => {
        if (item.filePath === data?.file) {
          return { ...item, idSubtitleLinked: data?.id };
        } else {
          return { ...item };
        }
      });

    const newSubtitle = {
      id: data?.id,
      file: data?.file,
      trim: trim,
      start: start,
      transcribe: data?.transcribe?.map((datum: Transcribe) => {
        return {
          start: datum.start,
          end: datum.end,
          id: datum.id,
          sub: datum.sub
        };
      })
    };

    dispatch(
      update({
        subtitleStyle: subtitleStyle
          ? subtitleStyle
          : {
              fontSize: '40px',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              color: 'rgba(0, 0, 0, 1)'
            },
        // language: { name: data?.langName, code: data?.langCode },
        subtitles: subtitles?.concat(newSubtitle) as any,
        subtitlesInit: subtitlesInit?.concat(newSubtitle) as any,
        addingPlayableItems: modifyListVideos
      })
    );
  };

  const onGenerateMain = async () => {
    setIsLoading(true);
    const params = {
      contentId: contentId,
      videoLink: selectedVideo.filePath,
      langCode: selectedLanguage.langCode
    };
    try {
      const res = await request(generateSubtitle(params));
      if (res?.data?.status === 200) {
        setIsLoading(false);
        handleGetSubtitles();
        handleLinkSubtitle(res?.data?.data, selectedVideo.trim, selectedVideo.start);
        //   backToAllSubtitles();
        message.success('Generated subtitle successfully!');
      } else {
        message.error('There’s something wrong while generating the caption. Please try again or select other media.');
        setIsLoading(false);
      }
    } catch (error) {
      message.error('There’s something wrong while generating the caption. Please try again or select other media.');
      setIsLoading(false);
    }
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  React.useEffect(() => {
    setSelectedVideo(videoOptions[0]);
  }, [videoOptions]);

  return (
    <Container>
      <BackSubContainer
        onClick={() => {
          //   setView(Views.Subtitle);
          //   clearSubtitleDetail();
          onBackToOveral();
        }}
      >
        <IconBack>
          <BackArrow />
        </IconBack>
        <TextBackToCaptions>Back to Captions</TextBackToCaptions>
      </BackSubContainer>
      <AutoSubtitleContainer>
        <Typography variant="label" color="shade7">
          Which video file do you want to add captions to?
        </Typography>
        <DropDown
          customMargin="2px 0 0"
          placeholder="Select video file"
          options={videoOptions}
          value={selectedVideo}
          onChange={(value) => setSelectedVideo(value)}
        ></DropDown>
        <Typography variant="label" color="shade7" style={{ marginTop: '20px' }}>
          Which language is being spoken?
        </Typography>
        <DropDown
          customMargin="2px 0 0"
          options={languageOptions}
          isClearable={false}
          value={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value)}
        ></DropDown>
        <GenerateBtn onClick={onGenerateMain}>
          {isLoading ? (
            <Spinner width={14} height={14} />
          ) : (
            <>
              <span>Auto Generate Caption</span> <MagicWand width={15} height={15} />
            </>
          )}
        </GenerateBtn>
      </AutoSubtitleContainer>
    </Container>
  );
};

export default GenerateCaption;

const Container = styled.div`
  height: 100%;
`;

const BackSubContainer = styled.div`
  cursor: pointer;
  height: 42px;
  // background: ${({ theme }) => theme.altGrey.shade4};
  background: ${({ theme }) => theme.grey.shade3};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextBackToCaptions = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 12px;
  margin-left: 10px;
  color: ${({ theme }) => theme.altGrey.shade7};
`;

const IconBack = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background: ${({ theme }) => theme.neutral.white};
  padding: 5px 6px;
  cursor: pointer;
  margin-top: 6px;
`;

const AutoSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const GenerateBtn = styled.div`
  position: absolute;
  bottom: 12px;
  padding: 10px 12px;
  width: calc(100% - 24px);
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.brand.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  &:hover {
    opacity: 0.7;
  }
  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;
