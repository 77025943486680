import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fonts, Logos } from '../models/brandKit';

export interface BrandKitState {
  colors: string[];
  createdAt: string;
  fonts: Fonts[];
  id: string;
  logos: Logos[];
  updatedAt: string;
  userId: string;
  videos: any[];
  musics: any[];
  images: any[];
  quantityBrandItems: number;
}

// Define the initial state using that type
const initialState: BrandKitState = {
  colors: [],
  createdAt: '',
  fonts: [],
  id: '',
  logos: [],
  updatedAt: '',
  userId: '',
  videos: [],
  musics: [],
  images: [],
  quantityBrandItems: 0
};

export const brandKitSlice = createSlice({
  name: 'brandKit',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    storeBrandKit: (state, action: PayloadAction<Partial<BrandKitState>>) => {
      const data = action.payload as BrandKitState;

      // Sort fonts by fontName
      data?.fonts?.sort((f1, f2) => {
        return f1.fontName.localeCompare(f2.fontName);
      });
      console.log(data);
      return {
        ...state,
        ...state,
        colors: data?.colors || [],
        createdAt: data?.createdAt,
        fonts: data?.fonts || [],
        id: data?.id,
        logos: data?.logos || [],
        updatedAt: data?.updatedAt,
        userId: data?.userId,
        videos: data?.videos || [],
        musics: data?.musics || [],
        images: data?.images || []
      };
    },
    updateBrandKit: (state, action: PayloadAction<Partial<BrandKitState>>) => {
      const data = action.payload as BrandKitState;
      return {
        ...state,
        ...data
      };
    },
    uploadLogos: (state, action: PayloadAction<Partial<Logos[]>>) => {
      const data = action.payload as Logos[];
      return {
        ...state,
        logos: data
      };
    },
    uploadFonts: (state, action: PayloadAction<Partial<Fonts[]>>) => {
      let data = action.payload as Fonts[];
      return {
        ...state,
        fonts: data
      };
    }
  }
});

export const { storeBrandKit, updateBrandKit, uploadFonts, uploadLogos } = brandKitSlice.actions;

export const selectBrandKit = (state: RootState) => state.brandKit;

export default brandKitSlice.reducer;
