import React from 'react';

// import { useTheme } from 'utils/Theme';
import { RadioProps } from './Radio.props';
import { Container, InnerCircle, CustomRadio, Label } from './Radio.style';
import { theme } from '@/utils/theme';

const Radio = ({
  checked = false,
  label,
  size = 20,
  color = theme.brand.mint,
  typographyProps,
  colorInner,
  customLabel,
  uncheckColor,
  ...props
}: RadioProps): JSX.Element => {
  // const theme = useTheme();

  const radioVariant = typographyProps?.variant === undefined ? 'label' : typographyProps?.variant;

  return (
    <Container onClick={props.onClick}>
      <input
        onChange={(event) => event.stopPropagation()}
        checked={checked}
        type="radio"
        {...props}
        style={{ position: 'absolute', opacity: 0 }}
      />
      <CustomRadio checked={checked} size={size} color={color} disabled={props.disabled} uncheckColor={uncheckColor}>
        {checked && <InnerCircle size={size} />}
      </CustomRadio>
      {label && (
        <Label {...typographyProps} variant={radioVariant} color="primary">
          {label}
        </Label>
      )}
      {customLabel && customLabel}
    </Container>
  );
};

export default React.memo(Radio);
