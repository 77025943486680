/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import SearchBar from '@/components/SearchBar';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '@/components/FileUpload';
import MediaList from '../../commons/MediaAssets';
import ArrowFrontlineUp from '@/components/SVG/ArrowFrontlineUp';
import { update } from '@/features/video/store/videoSlice';
import { selectStories } from '@/features/video/store/storySlice';
import Spinner from '@/components/Spinner';
interface IStoriesTab {}

const StoriesTab: React.FC<IStoriesTab> = () => {
  const mediaFiles = useSelector(selectStories);
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState<string>('');
  // const { videos, images, musics, logos } = useAppSelector(selectBrandKit);
  const [isUpload] = useState(false);

  // const { request } = useRequest();

  const handleSearch = async () => {
    // apply search
  };

  const handleUploadFile = async (files: File[]) => {
    // try {
    //   const res = await request(brandKitUpload(files, `${type}s` as BrandKitUploadType));
    //   const tempLogos = [...logos];
    //   const newLogos = tempLogos?.concat(res?.data?.data);
    //   const bodyReq = {
    //     logos: newLogos
    //   };
    //   if (activeKey === 'logo') {
    //     const data = await request(updateBrandKitService(bodyReq));
    //     dispatch(updateBrandKit({ logos: data?.data?.data?.logos }));
    //   } else {
    //     const {images, logos, musics, videos} =  res?.data?.data
    //     dispatch(updateBrandKit({ images, logos, musics, videos}));
    //   }
    //   setIsUpload(false);
    // } catch (error) {
    //   setIsUpload(false);
    //   console.log('upload fail');
    // }
  };

  const onUploadFile = async (files: File[] | null) => {
    if (files) {
    }
  };

  const storiesList = useMemo(() => {
    const stories = mediaFiles?.map((item: any) => {
      return { ...item, Link: item?.files?.link };
    });
    dispatch(update({ quantityStoryItems: stories?.length || 0 }));
    return stories;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContentWrapper className="content">
        <SearchView>
          <SearchBar
            placeholder="Search for..."
            longSearchBar
            value={searchVal}
            didChanged={(value) => setSearchVal(value)}
            handleSearchTemplate={handleSearch}
          />
        </SearchView>
        <ScrollContent className="scroll">
          {!!storiesList.length && <MediaList mediaList={storiesList} />}
        </ScrollContent>
      </ContentWrapper>
      {/* <BottomView>
        <FileUpload
          isLoading={isUpload}
          allowTypes={['video']}
          handleDrop={onUploadFile}
          CustomDropContainer={UploadWrapper}
          dropSection={
            <UploadBtn isLoading={isUpload}>
              <span style={{marginRight: '6px'}}>Upload</span> {isUpload ? <Spinner height={14} width={14} /> : <ArrowFrontlineUp /> }
            </UploadBtn>
          }
        />
      </BottomView> */}
    </Container>
  );
};

export default StoriesTab;

const Container = styled.div<{ width?: number }>`
  height: 100%;
`;

const SearchView = styled.div<{ width?: number }>`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const ContentWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 32px;
`;

const UploadBtn = styled.div<{ isLoading?: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  text-align: center;
  background-color: #91dbb6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  ${({ isLoading }) => (isLoading ? `opacity: 0.5; cursor: not-allowed;` : '')}
  span {
    color: #070550;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
  }
`;

export const ScrollContent = styled.div`
  height: calc(100% - 120px);
  margin-top: 12px;
`;

const BottomView = styled.div<{ width?: number }>`
  height: 64px;
  width: 100%;
  box-shadow: 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  padding: 16px;
`;
