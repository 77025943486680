import React from 'react';

import { SVGProps } from './SVG.props';

const Trash = (props: SVGProps): JSX.Element => {
  const { width, height, fill, cursor, opacity } = props;
  return (
    <svg
      width={width || 24}
      height={height || 25}
      cursor={cursor}
      opacity={opacity}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M20 6.89062H16V5.89062C16 5.09498 15.6839 4.33191 15.1213 3.7693C14.5587 3.2067 13.7956 2.89063 13 2.89062H11C10.2044 2.89063 9.44129 3.2067 8.87868 3.7693C8.31607 4.33191 8 5.09498 8 5.89062V6.89062H4C3.73478 6.89062 3.48043 6.99598 3.29289 7.18352C3.10536 7.37105 3 7.62541 3 7.89062C3 8.15584 3.10536 8.4102 3.29289 8.59773C3.48043 8.78527 3.73478 8.89062 4 8.89062H5V19.8906C5 20.6863 5.31607 21.4493 5.87868 22.0119C6.44129 22.5746 7.20435 22.8906 8 22.8906H16C16.7956 22.8906 17.5587 22.5746 18.1213 22.0119C18.6839 21.4493 19 20.6863 19 19.8906V8.89062H20C20.2652 8.89062 20.5196 8.78527 20.7071 8.59773C20.8946 8.4102 21 8.15584 21 7.89062C21 7.62541 20.8946 7.37105 20.7071 7.18352C20.5196 6.99598 20.2652 6.89062 20 6.89062ZM10 5.89062C10 5.62541 10.1054 5.37105 10.2929 5.18352C10.4804 4.99598 10.7348 4.89062 11 4.89062H13C13.2652 4.89062 13.5196 4.99598 13.7071 5.18352C13.8946 5.37105 14 5.62541 14 5.89062V6.89062H10V5.89062ZM17 19.8906C17 20.1558 16.8946 20.4102 16.7071 20.5977C16.5196 20.7853 16.2652 20.8906 16 20.8906H8C7.73478 20.8906 7.48043 20.7853 7.29289 20.5977C7.10536 20.4102 7 20.1558 7 19.8906V8.89062H17V19.8906Z"
        fill={fill || '#B5ADB0'}
      />
    </svg>
  );
};

export default Trash;
