import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import { Progress } from 'antd';

interface IProcessBarProps {
  isOpen: boolean;
  onClickClose: () => void;
  percent?: number;
  type?: string;
}

const ProgressBarModal: React.FunctionComponent<IProcessBarProps> = ({ isOpen, onClickClose, percent, type }) => {
  const theme = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={() => null}
      backgroundColor={theme.neutral.white}
      customFixedWidth="470px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>{type === 'download' ? 'Downloading...' : 'Saving to story...'}</TitleConfirm>
      <Progress percent={percent} strokeColor={'#91DBB6'} style={{ width: '98%' }} strokeWidth={20} />
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default ProgressBarModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  margin-bottom: 24px;
  font-weight: 700;
`;
