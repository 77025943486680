import Button from '@/components/Button';
import Girl from '@/assets/images/Girl.png';
// import HomeEmptyImg from '@/assets/images/HomeEmpty.png';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '@/app/hooks';
import { getToken, update } from '../video/store/authSlice';
import { setFinishedLoading, update as updateVideo } from '../video/store/videoSlice';
import { login } from '../video/store/authActions';
import { useDispatchRequest } from '@redux-requests/react';
import { useSelector } from 'react-redux';
import { EnvironmentVariable } from '@/global/constant/Environment';
import { useSearchParams } from 'react-router-dom';
import './Home.scss';
import Typography from '@/components/Typography';
import ModalLoading from '../video/components/video-slidebar/template-tab/components/ModalLoading';
import axios from 'axios';
import { isSafari } from 'react-device-detect';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const requestDispatch = useDispatchRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const template = searchParams.get('template');
  const contentType = searchParams.get('contentType');
  const ssid = searchParams.get('ssid');

  const size = searchParams.get('size');
  const [showLogin, setShowLogin] = useState(false);

  const token = useSelector(getToken);
  const loginRef = useRef<boolean>(false);

  useEffect(() => {
    let waitLogin = setTimeout(() => setShowLogin(true), 4000);
    return () => {
      clearTimeout(waitLogin);
    };
  }, []);

  const onMessage = useCallback(
    (cb: any) => async (e: any) => {
      const message = e.data;
      if (
        // (message.source === EnvironmentVariable.PLATFORM_URL || EnvironmentVariable.envName.match(/local/)) &&
        message.token !== token &&
        !loginRef.current &&
        message.token
      ) {
        loginRef.current = true;
        const { data } = await requestDispatch(login(message.token));
        dispatch(
          update({
            token: data.data.token,
            company_id: data.data.company_id,
            platform: data.data.platform,
            service: data.data.service
          })
        );
        dispatch(
          updateVideo({
            isInitial: true,
            contentId: contentId || '',
            contentType: contentType || ''
          })
        );
        cb?.();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [dispatch, requestDispatch, token]
  );

  const gettingTokenBySSID = useCallback(() => {
    axios.get(`${EnvironmentVariable.PLATFORM_API_URL}/v1/temporary-token-switch/${ssid}`).then((res) => {
      localStorage.setItem('plat_tk', res.data.data.token);
      localStorage.setItem('plat_rftk', res.data.data.refresh_token);
      onMessage(onLoginSuccess)(res.data);

      axios.delete(`${EnvironmentVariable.PLATFORM_API_URL}/v1/temporary-token-switch/${ssid}`);
    });
  }, [ssid]);

  useEffect(() => {
    console.log('ssid', ssid);
    /**
     *  CASE_1: if have new ssid -> Re-login via api
     *  CASE_2: if local storage already has token -> Using current login token to login
     *  CASE_3: if local storage has token but cannot login -> Show login button
     * */ 
    if (ssid) {
      const previousSSID = localStorage.getItem('ssid');
      if (ssid !== previousSSID) {
        localStorage.setItem('ssid', ssid);
        gettingTokenBySSID();
        return;
      }
    }
    const token = localStorage.getItem('plat_tk');

    if (token) {
      onMessage(onLoginSuccess)({ data: { token } });
      return;
    }

  }, [ssid]);

  const onClick = () => {
    localStorage.clear();
    if (!isSafari) window.open(`${EnvironmentVariable.PLATFORM_URL}`, '__blank');
    else window.location.href = `${EnvironmentVariable.PLATFORM_URL}`;
  };

  const onLoginSuccess = useCallback(() => {
    loginRef.current = false;
  }, []);

  // useEffect(() => {
  //   window.addEventListener('message', onMessage(onLoginSuccess));
  // }, [onLoginSuccess, onMessage]);

  useEffect(() => {
    if (size) {
      dispatch(updateVideo({ screenDimension: size }));
      // finish loading background when create a template studio
      dispatch(setFinishedLoading());
    }
    if (template) {
      dispatch(updateVideo({ selectedTab: 'Templates' }));
    } else {
      dispatch(updateVideo({ selectedTab: 'Media' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className="header" style={{ padding: '0 24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
          <img className="logo" src="./assets/logo.svg" alt="The Martec Logo" />
          <h2 className="header-text">Studio</h2>
        </div>
      </div>
      {!showLogin ? (
        <Container>
          {/* <HomeEmpty>
            <img src={HomeEmptyImg} alt="homeEmpty" style={{ width: '99vw', height: '99vh', overflow: 'hidden' }} />
          </HomeEmpty> */}
          <ModalLoading isOpen={!showLogin} />
          {/* <iframe
            id="receiver"
            title="sso-auth"
            src={`${EnvironmentVariable.PLATFORM_URL}/sso-auth`}
            height={0}
            width={0}
            style={{ all: 'unset' }}
          /> */}
        </Container>
      ) : (
        <Container>
          <img src={Girl} alt="girl" />
          <>
            <Typography variant="title9" color="shade7">
              Can not get your account, please login first
            </Typography>
            <Button style={{ width: '200px', marginTop: '8px' }} onClick={onClick} loading={loginRef.current}>
              Login
            </Button>
          </>
        </Container>
      )}
    </div>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
