/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DropDown from '@/components/DropDown';
import { Language } from '@/features/video/models/VideoFrame';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguagesTranslate, selectVideo, update } from '@/features/video/store/videoSlice';
import { useAppSelector } from '@/app/hooks';
import {
  getSubtitles,
  selectSubtitle as getSubtitlesByUserId,
  selectSubtitle
} from '@/features/video/store/subtitleSlice';
import { Repeat } from '@/components/SVG';
import useRequest from '@/components/hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { getAllSubtitles, translateSubtitles } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { plusSecond } from '@/utils/moveSecond';
import { PlayableType } from '@/features/video/models/FrameItem';
import Spinner from '@/components/Spinner';

interface ITranslateViewProps {}

const Translate: React.FC<ITranslateViewProps> = () => {
  const dispatch = useDispatch();
  const { request } = useRequest();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('contentId');
  const { subtitleDetail } = useAppSelector(selectSubtitle);

  const languageOptions = useSelector(getLanguagesTranslate);
  const { subtitles } = useAppSelector(getSubtitlesByUserId);
  const { addingPlayableItems, subtitlesInit, language } = useAppSelector(selectVideo);

  const [listLanguagesTranslate, setListLanguagesTranslate] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | any>(listLanguagesTranslate[0]);
  const [listLanguages, setListLanguages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filterListLanguagesUnselected = (listLangs: any[]) => {
    const languagesToRemove = listLangs.map((item: any) => item.label);
    const filteredArr = languageOptions.filter((item: any) => !languagesToRemove.includes(item.label));
    setListLanguagesTranslate(filteredArr);
  };

  const groupListSubtitles = async () => {
    const listSubtitlesLinked = subtitles.filter((sub: any) => sub.file !== '');
    const groupedObj = listSubtitlesLinked.reduce((acc: any, item: any) => {
      if (!acc[item.langName]) {
        acc[item.langName] = {
          label: item.langName,
          code: item.langCode,
          value: [item]
        };
      } else {
        acc[item.langName].value.push(item);
      }
      return acc;
    }, {});

    setListLanguages(Object.values(groupedObj));
    await filterListLanguagesUnselected(Object.values(groupedObj));
  };

  const handleTranslate = async () => {
    // get list subtitle id from list subtitle init
    const listSubtitleId = subtitlesInit?.map((item) => {
      return item.id;
    });
    setIsLoading(true);

    const params = {
      contentId: contentId,
      langCode: selectedLanguage.langCode,
      subtitlesId: [subtitleDetail?.id]
    };
    try {
      const res = await request(translateSubtitles(params));
      if (res?.data?.status === 200) {
        //   await setSelectedSubtitleVersion({
        //     label: selectedLanguage.label,
        //     code: selectedLanguage?.langCode,
        //     value: res?.data?.data
        //   });
        handleGetSubtitles();
        await handleApplySubtitleInStudio(res?.data?.data, selectedLanguage.label, selectedLanguage.langCode);
        message.success('Translated subtitles successfully!');
        setIsLoading(false);
        setTimeout(() => {
          message.success(`The ${selectedLanguage.label} subtitle version has been successfully linked!`);
        }, 800);
      } else {
        message.error('Error on translating subtitles');
        setIsLoading(false);
      }
    } catch (error) {
      message.error('Error on translating subtitles');
      setIsLoading(false);
    }
  };

  const handleGetSubtitles = async () => {
    const listSubtitles = await request(getAllSubtitles(contentId as string));
    dispatch(getSubtitles(listSubtitles?.data?.data));
  };

  const handleApplySubtitleInStudio = (payloadSubtitleInit: any, langName?: string, langCode?: string) => {
    const payloadSubtitle = payloadSubtitleInit.map((sub: any) => {
      const matchingVideo = addingPlayableItems.filter((x) => !x.isHide).find((vd) => sub.file === vd.filePath);

      if (!matchingVideo) return sub;

      const newTranscribe = sub.transcribe.map((sub: any) => {
        let newStart = plusSecond(sub?.start, matchingVideo?.start as number);
        let newEnd = plusSecond(sub?.end, matchingVideo?.start as number);

        return {
          ...sub,
          start: newStart,
          end: newEnd
        };
      });

      return {
        ...sub,
        trim: matchingVideo?.trim,
        start: matchingVideo?.start,
        transcribe: newTranscribe
      };
    });

    // clear all idSubtitleLinked in addingPlayableItems
    const clearSubtitleIdLinked = addingPlayableItems?.map((vd) => {
      if (vd.type === PlayableType.video) {
        return {
          ...vd,
          idSubtitleLinked: ''
        };
      } else {
        return { ...vd };
      }
    });

    const flattenAndFilterArray = (arr: any) => {
      return arr.flatMap((subArray: any) => subArray.filter((obj: any) => obj.idSubtitleLinked !== ''));
    };

    // get list videos have no linked by subtitleId
    const videosSubtitleIdLinked = addingPlayableItems?.filter((vd) => !vd.idSubtitleLinked);

    // link subtitleId to idSubtitleLinked of list videos if file of subtitle = filePath of video
    const modifyListVideosLinked: any = clearSubtitleIdLinked
      ?.filter((item) => item.type === PlayableType.video)
      ?.map((item: any) =>
        payloadSubtitleInit?.map((sub: any) => {
          if (item.filePath === sub?.file) {
            return {
              ...item,
              idSubtitleLinked: sub?.id
            };
          } else {
            return { ...item };
          }
        })
      );

    // filter list audios to push on array addingPlayableItems
    const listAudio = addingPlayableItems?.filter((au) => au.type === PlayableType.audio);

    // flat list object addingPlayableItems - without audios and videos have no linked by subtitleId
    const payloadVideo = flattenAndFilterArray(modifyListVideosLinked);

    dispatch(
      update({
        subtitles: payloadVideo?.length ? (payloadSubtitle as any) : [],
        subtitlesInit: payloadVideo?.length ? (payloadSubtitleInit as any) : [],
        addingPlayableItems: payloadVideo?.length
          ? payloadVideo.concat(listAudio.concat(videosSubtitleIdLinked))
          : clearSubtitleIdLinked,
        language: {
          name: langName as string,
          code: langCode as string
        }
      })
    );
  };

  useEffect(() => {
    groupListSubtitles();
  }, [subtitles]);

  return (
    <Container>
      <Text>Translate Language</Text>
      <DropDown
        placeholder="Select language"
        options={listLanguagesTranslate}
        label={''}
        customMargin="0px"
        value={selectedLanguage}
        onChange={(value) => setSelectedLanguage(value)}
      />
      <TranslateBtn onClick={handleTranslate}>
        <span>Translate Caption</span>{' '}
        {isLoading ? <Spinner width={14} height={14} /> : <Repeat width={15} height={15} />}
      </TranslateBtn>
    </Container>
  );
};

export default Translate;

const Container = styled.div<{ width?: number }>`
  height: 100%;
  padding: 12px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.altGrey.shade7};
  margin-bottom: 3px;
`;

const TranslateBtn = styled.div`
  position: absolute;
  bottom: 12px;
  padding: 10px 12px;
  width: calc(100% - 24px);
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.brand.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  &:hover {
    opacity: 0.7;
  }
  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;
