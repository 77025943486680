import styled from '@emotion/styled';

export const FieldContainer = styled.div<{
  border?: boolean;
  isLong: boolean | undefined;
  fontSize?: number;
  width?: string;
  disabledWith?: boolean;
}>`
  background: #FFFFFF;

  ${({ border, theme }) => {
    if (border) {
      return `border: 1px solid ${theme.grey.shade4};`;
    }
  }}

  box-sizing: border-box;
  border-radius: 8px;
  max-width: ${(props) => (props.isLong ? '100%' : '320px')};
  width: ${({ width, disabledWith }) => (width || disabledWith ? undefined : '100%')};
  padding: 10px 15px;
  max-height: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  input {
    flex: 1;
    border: 0;
    margin: 0 10px;
    font-family: 'Objective';
    color: ${({ theme }) => theme.brand.primary};
    font-size: ${({ fontSize }) => fontSize || 14}px;
    line-height: 24px;
    width: calc(100% - 56px);

    :focus {
      outline: none;
      border: none;
    }

    ::placeholder {
      color: ${(props) => props.theme.grey.shade5}};
    }

    
  }
`;

export const CloseFilledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;

  svg {
    border-radius: 100px;
  }

  svg:hover {
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  }
`;

export const SvgContainer = styled.div`
  width: 16px;
  height: 16px;
`;
