import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubtitleDetail } from '../models/VideoFrame';

// Define the initial state using that type
const initialState: any = {
  subtitles: [],
  subtitleDetail: null,
  subtitleViewing: []
};
export const subtitleSlice = createSlice({
  name: 'subtitle',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    getSubtitles: (state, action: PayloadAction<Partial<any>>) => {
      const data = action.payload as any[];
      return {
        ...state,
        subtitles: data
      };
    },
    selectSubtitleDetail: (state, action: PayloadAction<Partial<SubtitleDetail | null>>) => {
      return {
        ...state,
        subtitleDetail: action.payload as SubtitleDetail | null
      };
    },
    setSubtitleViewing: (state, action: PayloadAction<Partial<any>>) => {
      const data = action.payload as any[];
      return {
        ...state,
        subtitleViewing: data
      };
    }
  }
});

export const { getSubtitles, selectSubtitleDetail, setSubtitleViewing } = subtitleSlice.actions;

export const selectSubtitle = (state: RootState) => state.subtitle;

export default subtitleSlice.reducer;
