/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { listTransitions } from './data';
import ThumbnailTransition from '@/assets/images/ThumbnailFrame.png';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectVideo, updatePlayableItem } from '@/features/video/store/videoSlice';
import { message } from 'antd';
import { AddingPlayableItem, PlayableType } from '@/features/video/models/FrameItem';

interface ITransitions {}

const Transitions: React.FC<ITransitions> = () => {
  const { addingPlayableItems, audioSelected, maxDuration } = useAppSelector(selectVideo);
  const dispatch = useAppDispatch();
  const videoSelected = addingPlayableItems.find((x) => x.uid === audioSelected.uid) as any;
  const [nextVideo, setNextVideo] = useState<AddingPlayableItem>();

  const videoList = useMemo(() => {
    const playing = addingPlayableItems.filter((item) => item.type === PlayableType.video && !item.isHide);
    return playing;
  }, [addingPlayableItems]);

  useEffect(() => {
    setNextVideo(getNextVideo() as AddingPlayableItem);
  }, [videoSelected]);

  const getNextVideo = () => {
    const listVdSortByStart = videoList.sort((a: any, b: any) => a.start - b.start);
    const currentVdId = videoSelected?.uid;
    const currentIndex = listVdSortByStart.findIndex((element) => element.uid === currentVdId);

    if (currentIndex !== -1 && currentIndex < listVdSortByStart.length - 1) {
      return listVdSortByStart[currentIndex + 1];
    }

    return null; // Return null if the element is not found or if it is the last element in the array
  };

  const handleApplyTransition = async (idx: number, transitionType?: string) => {
    if (!videoSelected) {
      message.info('Select a video on the timeline to apply the template');
    } else {
      if (videoSelected?.end === maxDuration) {
        message.info(
          'Please select a clip other than the last one, as transitions cannot be applied to the final clip'
        );
      } else if (videoSelected?.end !== nextVideo?.start) {
        message.info('Transition cannot be applied due to a gap between videos');
      } else {
        if (videoSelected?.end - videoSelected?.start < 3) {
          message.info('Transition cannot be applied to videos shorter than 3 seconds');
        } else {
          const transition = {
            type: transitionType,
            start:
              (videoSelected?.end as number) -
              (transitionType === 'fadewhite' || transitionType === 'fadeblack' ? 1.5 : 1.3),
            duration: transitionType === 'fadewhite' || transitionType === 'fadeblack' ? 3 : 1.5
          };
          await dispatch(
            updatePlayableItem(
              Object.assign({ uid: audioSelected.uid, transition: transition, isApplyTransition: true })
            )
          );
          await dispatch(updatePlayableItem(Object.assign({ uid: nextVideo?.uid, isNeedApplyTransition: true })));
        }
      }
    }
  };

  return (
    <Container>
      <ScrollWrapper>
        {listTransitions.map((item, idx) => (
          <TransitionContainer
            onClick={() => handleApplyTransition(idx, item?.value)}
            select={videoSelected?.transition?.type === item?.value}
            key={idx}
          >
            <ImageWrapper isNoneImg={idx === 0}>
              <TransitionImage isNoneImg={idx === 0} src={item.img ? item.img : ThumbnailTransition} alt="" />
            </ImageWrapper>
            <TransitionLabel>{item.label}</TransitionLabel>
          </TransitionContainer>
        ))}
      </ScrollWrapper>
    </Container>
  );
};

export default Transitions;

const Container = styled.div<{ width?: number }>`
  padding: 16px;
  padding-bottom: 10px;
  height: 100%;
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-height: calc(100% - 2px);
  overflow: auto;
  padding-bottom: 5px;
`;

const TransitionContainer = styled.div<{ select?: boolean }>`
  width: calc((100% / 3) - 10px);
  height: 85px;
  margin: 2px 5px 0 5px;
  border-radius: 4px;
  ${({ select, theme }) => (select ? `outline: 1px solid ${theme.altBrand.mint}` : '')};
  cursor: pointer;
  background: ${({ theme, select }) => select && theme.opacity.arctic};
`;

const ImageWrapper = styled.div<{ isNoneImg?: boolean }>`
  width: 100%;
  height: 64px;
  text-align: center;
  border-radius: 4px;
  background: ${({ theme, isNoneImg }) => isNoneImg && theme.altGrey.shade4};
  padding-top: ${({ isNoneImg }) => (isNoneImg ? '15px' : '0')};
`;

const TransitionImage = styled.img<{ isNoneImg?: boolean }>`
  border-radius: 4px;
  width: ${({ isNoneImg }) => (isNoneImg ? '35px' : '100%')};
  height: ${({ isNoneImg }) => (isNoneImg ? '35px' : '64px')};
`;

const TransitionLabel = styled.p`
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 12px;
  margin-top: 5px;
`;
