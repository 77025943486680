/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import SearchBar from '@/components/SearchBar';
import styled from '@emotion/styled';
import Tabs from '../../commons/tabs/Tabs';
import FileUpload, { FILE_TYPE_VALIDATE } from '@/components/FileUpload';
import { useAppSelector } from '@/app/hooks';
import { selectBrandKit, updateBrandKit } from '@/features/video/store/brandKitSlice';
import ArrowFrontlineUp from '@/components/SVG/ArrowFrontlineUp';
import useRequest from '@/components/hooks/useRequest';
import {
  BrandKitUploadType,
  brandKitUpload,
  updateBrandKitService
} from '@/features/video/store/ApiServices/brankitServices';
import MediaList from '../../commons/MediaAssets';
import { ObjectType, PlayableType } from '@/features/video/models/FrameItem';
import { useDispatch } from 'react-redux';
import { update } from '@/features/video/store/videoSlice';
import Spinner from '@/components/Spinner';
// import { ArrowFrontierUp } from '@/components/SVG';

interface IBrandTab {}

const brandTabs: { name: string; key: string }[] = [
  {
    name: 'ALL',
    key: 'all'
  },
  {
    name: 'Logos',
    key: 'logo'
  },
  {
    name: 'Videos',
    key: 'video'
  },
  {
    name: 'Music',
    key: 'audio'
  },
  {
    name: 'Images',
    key: 'image'
  }
];

const BrandTab: React.FC<IBrandTab> = () => {
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>('all');
  const { videos, images, musics, logos } = useAppSelector(selectBrandKit);
  const [isUpload, setIsUpload] = useState(false);

  const { request } = useRequest();

  const handleSearch = async () => {
    // apply search
  };

  const onUploadFile = async (files: File[] | null) => {
    if (files) {
      setIsUpload(true);
      const type = activeKey === 'audio' ? 'music' : activeKey;
      if (activeKey === 'all') {
        if (files && files.length > 0) {
          const filesAccept: File[] = [];
          for (let x = 0; x < files.length; x++) {
            const isValidType = acceptTypes.some((allowType) => {
              return FILE_TYPE_VALIDATE[allowType]?.(files[x].type);
            });
            if (isValidType) {
              filesAccept.push(files[x]);
            }
          }
          handleUploadFile(filesAccept, type);
        }
      } else {
        handleUploadFile(files, type);
      }
    }
  };

  const handleUploadFile = async (files: File[], type: string) => {
    try {
      const res = await request(brandKitUpload(files, `${type}s` as BrandKitUploadType));
      const tempLogos = [...logos];
      const newLogos = tempLogos?.concat(res?.data?.data);
      const bodyReq = {
        logos: newLogos
      };
      if (activeKey === 'logo') {
        const data = await request(updateBrandKitService(bodyReq));
        dispatch(updateBrandKit({ logos: data?.data?.data?.logos }));
      } else {
        const { images, logos, musics, videos } = res?.data?.data;
        dispatch(updateBrandKit({ images, logos, musics, videos }));
      }
      setIsUpload(false);
    } catch (error) {
      setIsUpload(false);
      console.log('upload fail');
    }
  };

  const brandkitList = useMemo(() => {
    const videoList =
      videos.length > 0
        ? videos?.map((item: any) => {
            return { ...item, type: PlayableType.video };
          })
        : [];
    const imageList =
      images.length > 0
        ? images?.map((item: any) => {
            return { ...item, type: ObjectType.image };
          })
        : [];
    const logoList =
      logos.length > 0
        ? logos?.map((item: any) => {
            return { ...item, type: ObjectType.image };
          })
        : [];
    const musicList =
      musics.length > 0
        ? musics?.map((item: any) => {
            return { ...item, type: PlayableType.audio };
          })
        : [];
    const all = [...videoList, ...imageList, ...musicList, ...logoList];
    dispatch(update({ quantityBrandItems: all?.length || 0 }));
    const data: any = {
      all: all,
      logo: logoList,
      audio: musicList,
      video: videoList,
      image: imageList
    };
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos, images, musics, logos]);

  const acceptTypes = useMemo(() => {
    if (activeKey === 'logo') {
      return ['image'];
    } else if (activeKey === 'all') {
      return ['image', 'video', 'audio'];
    } else {
      return [activeKey];
    }
  }, [activeKey]);

  const onRemove = async (link: string | undefined) => {
    const videoList = videos.filter((item) => item.Link !== link);
    const imageList = images.filter((item) => item.Link !== link);
    const logoList = logos.filter((item) => item.Link !== link);
    const musicList = musics.filter((item) => item.Link !== link);
    const bodyReq = {
      videos: videoList,
      images: imageList,
      logos: logoList,
      musics: musicList
    };
    const data = await request(updateBrandKitService(bodyReq));
    await dispatch(updateBrandKit(data?.data?.data));
  };

  return (
    <Container>
      <ContentWrapper className="content">
        <SearchView>
          <SearchBar
            placeholder="Search for..."
            longSearchBar
            value={searchVal}
            didChanged={(value) => setSearchVal(value)}
            handleSearchTemplate={handleSearch}
          />
        </SearchView>
        <Tabs tabs={brandTabs} active={activeKey} onChange={setActiveKey} />
        <ScrollContent className="scroll">
          {!!brandkitList[`${activeKey}`]?.length ? (
            <MediaList mediaList={brandkitList[activeKey]} onRemoveMedia={(val) => onRemove(val.link)} />
          ) : (
            <FileUpload isLoading={isUpload} handleDrop={onUploadFile} allowTypes={acceptTypes as any} />
          )}
        </ScrollContent>
      </ContentWrapper>
      <BottomView>
        <FileUpload
          isLoading={isUpload}
          allowTypes={acceptTypes as any}
          handleDrop={onUploadFile}
          CustomDropContainer={UploadWrapper}
          dropSection={
            <UploadBtn isLoading={isUpload}>
              <span style={{ marginRight: '6px' }}>Upload</span>{' '}
              {isUpload ? <Spinner height={14} width={14} /> : <ArrowFrontlineUp />}
            </UploadBtn>
          }
        />
      </BottomView>
    </Container>
  );
};

export default BrandTab;

const Container = styled.div<{ width?: number }>`
  height: 100%;
`;

const SearchView = styled.div<{ width?: number }>`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const ContentWrapper = styled.div`
  padding: 0 16px;
  height: 100%;
`;

const UploadWrapper = styled.div`
  width: 100%;
  height: 32px;
`;

const UploadBtn = styled.div<{ isLoading?: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  text-align: center;
  background-color: #91dbb6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  ${({ isLoading }) => (isLoading ? `opacity: 0.5; cursor: not-allowed;` : '')}
  span {
    color: #070550;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
  }
`;

export const ScrollContent = styled.div`
  height: calc(100% - 206px);
  margin-top: 12px;
`;

const BottomView = styled.div<{ width?: number }>`
  height: 64px;
  width: 100%;
  box-shadow: 0px -8px 15px -3px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  padding: 16px;
`;

export const ButtonUploadWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center; ;
`;
