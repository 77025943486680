import { RootState } from '@/app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { getMutationSelector } from '@redux-requests/core';
import { AUTH } from './actionType';

interface AuthState {
  token?: string;
  company_id: string;
  platform: string;
  service: string;
}

const initialState: AuthState = {
  token: '',
  company_id: '',
  platform: '',
  service: ''
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    update: (state, action: PayloadAction<Partial<AuthState>>) => {
      return {
        ...state,
        ...action.payload
      };
    },
    clear: () => {
      return initialState;
    }
  }
});

export const authSelector = getMutationSelector({ type: AUTH.LOGIN });

export const getAuthToken = createSelector(authSelector, (data) => {
  return data;
});

export const { update, clear } = authSlice.actions;

export const getToken = (state: RootState) => state.auth.token;
export const getRole = (state: RootState) => state.auth.platform;

export default authSlice.reducer;
