/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Modal from '@/components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@/components/Button';
import SearchBar from '@/components/SearchBar';
import Radio from '@/components/Radio';
import { useAppSelector } from '@/app/hooks';
import { selectVideo, update } from '@/features/video/store/videoSlice';
import useRequest from '@/components/hooks/useRequest';
import { editSubtitle } from '@/features/video/store/videoAPI';
import { message } from 'antd';
import { SubtitleDetail, Transcribe } from '@/features/video/models/VideoFrame';
import { useDispatch } from 'react-redux';

interface ILinkProps {
  isOpen: boolean;
  onClickClose: () => void;
  subtitleDetail?: SubtitleDetail;
  refetchListSubtitles?: () => void;
}

const LinkSubtitleModal: React.FunctionComponent<ILinkProps> = ({
  isOpen,
  onClickClose,
  subtitleDetail,
  refetchListSubtitles = () => null
}) => {
  const { request } = useRequest();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { addingPlayableItems, subtitles, subtitlesInit, subtitleStyle } = useAppSelector(selectVideo);
  // const listVideoUnlinked = addingPlayableItems?.filter((item) => !item?.idSubtitleLinked);
  const [searchVal, setSearchVal] = React.useState<string>('');
  const [checked, setChecked] = React.useState<any | null>(null);
  const [listVideos, setListVideos] = React.useState<any[]>(addingPlayableItems);

  const removeDuplicateFilePaths = () => {
    const uniqueFilePaths = new Set();
    const uniqueVideos = [];

    for (const video of addingPlayableItems) {
        if (!uniqueFilePaths.has(video.filePath)) {
            uniqueFilePaths.add(video.filePath);
            uniqueVideos.push(video);
        }
    }
    return uniqueVideos;
  }

  const handleLinkSubtitle = () => {
    const modifyListVideos = addingPlayableItems
      .filter((x) => !x.isHide)
      .map((item: any) => {
        if (item.filePath === checked?.filePath) {
          return { ...item, idSubtitleLinked: subtitleDetail?.id };
        } else {
          return { ...item };
        }
      });

    const newSubtitle = {
      id: subtitleDetail?.id,
      file: checked?.filePath,
      trim: checked?.trim,
      start: checked?.start,
      transcribe: subtitleDetail?.transcribe?.map((datum: Transcribe) => {
        return {
          start: datum.start,
          end: datum.end,
          id: datum.id,
          sub: datum.sub
        };
      })
    };

    dispatch(
      update({
        subtitleStyle: subtitleStyle
          ? subtitleStyle
          : {
              fontSize: '14px',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              color: 'rgba(0, 0, 0, 1)'
            },
        // language: { name: subtitleDetail?.langName as string, code: subtitleDetail?.langCode as string },
        subtitles: subtitles?.concat(newSubtitle) as any,
        subtitlesInit: subtitlesInit?.concat(newSubtitle) as any,
        addingPlayableItems: modifyListVideos
      })
    );
  };

  const onAddLink = async () => {
    const bodyReq = {
      file: checked?.filePath
    };
    const editSub = await request(editSubtitle(subtitleDetail?.id as string, bodyReq));
    if (editSub?.data?.status === 200) {
      refetchListSubtitles();
      handleLinkSubtitle();
    } else {
      message.error('Link subtitle unsuccessfully!');
    }
    await onClickClose();
  };

  const onClose = async () => {
    await onClickClose();
  };

  const handleSearchVideo = () => {
    const listVideoRemoveDup = removeDuplicateFilePaths()
    setListVideos(
      listVideoRemoveDup
        .filter((x) => !x.isHide)
        .filter((item: any) => item?.name?.toLowerCase()?.includes(searchVal.toLowerCase()))
    );
  };

  React.useEffect(() => {
    handleSearchVideo();
  }, [searchVal]);

  React.useEffect(() => {
    if (isOpen) {
      setChecked(null);
    }
    const listVideoRemoveDup = removeDuplicateFilePaths()
    setListVideos(listVideoRemoveDup.filter((x) => !x.isHide));
  }, [isOpen]);

  React.useEffect(() => {
    const listVideoRemoveDup = removeDuplicateFilePaths()
    setListVideos(listVideoRemoveDup.filter((x) => !x.isHide));
  }, []);

  // console.log('listVideos: ', listVideos);
  // console.log('addingPlayableItems: ', addingPlayableItems);

  return (
    <Modal
      isOpen={isOpen}
      onClickClose={onClose}
      backgroundColor={theme.neutral.white}
      customFixedWidth="470px"
      overflow="auto"
      isConfirmDlg={true}
      borderRadius="12"
    >
      <TitleConfirm>Link Caption</TitleConfirm>
      <PickVideoText>Which video file do you want to add captions to?</PickVideoText>
      <SearchBar placeholder="Search" longSearchBar value={searchVal} didChanged={(value) => setSearchVal(value)} />
      <ListVideos>
        {listVideos?.length ? (
          listVideos?.map((item, idx) => (
            <Radio
              checked={checked?.uid === item?.uid}
              onClick={() => setChecked(item)}
              label={item?.name}
              key={idx}
            />
          ))
        ) : (
          <TextNoResults>No results found</TextNoResults>
        )}
      </ListVideos>
      <ActionsContainer>
        <Button onClick={onClickClose} variant="outline">
          Cancel
        </Button>
        <Button onClick={onAddLink} disabled={!checked}>
          Add
        </Button>
      </ActionsContainer>
    </Modal>
  );
};

export default LinkSubtitleModal;

export const ModalTileUpload = styled.span`
  font-family: 'Objective';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #070550;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;
`;

export const TitleConfirm = styled.div`
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Objective';
  font-weight: 700;
`;

export const PickVideoText = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #070550;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ListVideos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;

export const TextNoResults = styled.p`
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #070550;
`;
