import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch } from '@/app/hooks';
import { useSelector } from 'react-redux';
import { selectVideo, update, setCaptionSettings } from '@/features/video/store/videoSlice';
import ColorPicker from '@/components/ColorPicker';
import DropDown from '@/components/DropDown';
import { useTheme } from '@emotion/react';
import { message } from 'antd';
import { useIsOnline } from 'react-use-is-online';

interface ISubtitleStylesViewProps {
  //   setView: React.Dispatch<React.SetStateAction<Views>>;
}

const ColorSettings = [
  { label: 'Background Color', defaultColor: 'rgba(0,0,0,1)', key: 'backgroundColor' },
  { label: 'Text Color', defaultColor: 'rgba(0,0,0,1)', key: 'color' }
];

const sizeOptions = [
  { label: 'Small', value: '31px' },
  { label: 'Medium', value: '40px' },
  { label: 'Big', value: '54px' }
];

const CaptionsStyles: React.FC<ISubtitleStylesViewProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { subtitleStyle, captionStyleSettings } = useSelector(selectVideo);
  const { isOffline } = useIsOnline();

  const onSubColorChange = (color: string, key: string) => {
    dispatch(
      setCaptionSettings({
        ...captionStyleSettings,
        [key]: color
      })
    );
  };

  const onFontSizeChange = (size: string) => {
    dispatch(
      setCaptionSettings({
        ...captionStyleSettings,
        fontSize: size
      })
    );
  };

  const handleCancelEdit = () => {
    dispatch(setCaptionSettings(subtitleStyle));
  };

  const handleSaveSetting = async () => {
    if(isOffline) {
      message.error('Something went wrong. Please try again.');
    } else {
      dispatch(
        update({
          subtitleStyle: captionStyleSettings
        })
      );
      await message.success('Caption styles have been udpated.');
    }
  };

  return (
    <ContentContainer>
      <TextLabel>Size</TextLabel>
      <DropDownBox>
        <DropDown
          placeholder="Small/Medium/Big"
          onChange={(value) => onFontSizeChange(value.value)}
          options={sizeOptions}
          label={''}
          customMargin="0px"
          value={sizeOptions.find((opt) => opt.value === captionStyleSettings?.fontSize)}
          bgColor={'#fff'}
          customBorder={`1px solid ${theme.altGrey.shade3}`}
        />
      </DropDownBox>
      <>
        {ColorSettings.map(({ label, key }, i) => (
          <div key={key}>
            {i === 0 ? <TextLabel>Background Color</TextLabel> : <TextLabel>Text Color</TextLabel>}
            <ColorPicker
              containerStyle={{ marginTop: '16px' }}
              // label={label}
              //@ts-ignore
              color={captionStyleSettings?.[key]}
              onColorChange={(color) => onSubColorChange(color, key)}
              disableAlpha={true}
            />
          </div>
        ))}
      </>
      <WrapperBtn>
        <CancelBtn onClick={() => handleCancelEdit()}>
          <span>Cancel</span>
        </CancelBtn>
        <SaveBtn onClick={() => handleSaveSetting()}>
          <span>Save</span>
        </SaveBtn>
      </WrapperBtn>
    </ContentContainer>
  );
};

export default CaptionsStyles;

const ContentContainer = styled.div`
  height: 28px;
  width: 96%;
  margin-left: 2%;
  padding: 0 8px;
`;

const DropDownBox = styled.div`
  height: 43px;
  margin-top: 16px;
`;

const TextLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: -14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey.shade7};
`;

const WrapperBtn = styled.div<{ width?: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  width: calc(100% - 24px);
  justify-content: space-between;
`;

const CancelBtn = styled.div`
  width: calc(50% - 6px);
  margin-bottom: 12px;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  text-align: center;
  background-color: ${({ theme }) => theme.neutral.white};
  outline: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  &:hover {
    opacity: 0.7;
  }

  span {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    margin-right: 4px;
    margin-top: 4px;
  }
`;

const SaveBtn = styled(CancelBtn)`
  background-color: ${({ theme }) => theme.brand.secondary};
  outline: none;
`;
